import React, { useState, useEffect, useLayoutEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LockOutlineIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import oilandgaslogo from "../../assets/oilandgaslogo.png";
import Emailstep1 from "../../components/Emailstep1";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Menutab from "../../components/Tabs/Menutab";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getCountries,
  getFormStage4,
  submitFormStage3,
} from "../../Services/apiServices/Form";
import { Modal } from "@material-ui/core";
import FormStagePreview from "../../components/preview/FormStagePreview";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";

document.body.style = "background: #f5f5f5;";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "13px",
      },
    },
  },
})(TextField);

const countriesLoad = [{ name: "Nigeria" }];
function Applicationform3(props) {
  // let top100Films = axios.get().then((response => {
  //   setWestafricancountrystage3(response.data)
  // }));
  const {
    snakBarSetter,
    errorSetter,
    formloaderSetter,
    stageSetter,
    rootid,
    stage,
    formloader,
    whichapplication,
  } = props;

  const [serverData, setserverData] = useState(false);
  const [previewData, setpreviewData] = useState();
  const [showPreviewForm, setshowPreviewForm] = useState(false);
  const [formEdit, setformEdit] = useState(false);
  const [previousFIlledData, setpreviousFIlledData] = useState();
  const [imgtype, setImgtype] = React.useState("img");
  const [img, setImg] = React.useState("");
  const [openimg, setOpenimg] = React.useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [countries, setcountries] = useState(countriesLoad);

  //stage 3 states
  const [activitystage3, setActivitystage3] = useState("");
  const [westafricancountrystage3, setWestafricancountrystage3] = useState({});

  //stage 3 validator state
  const [activitystage3validator, setActivitystage3validator] = useState(true);

  const classes = useStyles();
  // stage 3 handlers

  const formPreviewSetter = (value) => {
    setshowPreviewForm(value);
  };

  const westAfricanCountryStage3 = (e) => {
    setWestafricancountrystage3(e);
  };
  const activityStage3 = (e) => {
    setActivitystage3(e.target.value);
  };

  const getAllCountries = async () => {
    const res = await getCountries();
    console.log(res, "djksjs");
    setcountries(res?.data?.result);
  };
  useEffect(() => {
    console.log("LODDD88DDDDDD");
    async function getAllCountries() {
      const res = await getCountries();
      console.log(res, "djksjs");
      setcountries(res?.data?.result);
    }

    getAllCountries();
  }, []);

  // useEffect(() => {
  //   console.log('LODDDDDDDDD')
  //   getAllCountries();

  // }, [countries])

  // useLayoutEffect(() => {
  //   console.log('LAYOITTTT')
  //   getAllCountries();
  // }, [])

  useEffect(() => {
    async function fetchFormData() {
      const response = await getFormStage4(rootid);
      console.log(response, "kinjdjds");
      if (response?.data?.result.descriptionofactivity && !formEdit) {
        setpreviousFIlledData(response?.data?.result);
        setserverData(true);
      }
    }
    fetchFormData();
  }, [serverData]);

  const previewStage3 = async () => {
    formloaderSetter(false);

    if (activitystage3.length < 1) {
      setActivitystage3validator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Activity cant be empty");
      return false;
    }

    const stage3Data = [
      {
        name: "countries",
        value: `${westafricancountrystage3}`,
        arrayInputs: westafricancountrystage3,
      },
      { name: "description", value: `${activitystage3}` },
    ];

    setpreviewData(stage3Data);
    setshowPreviewForm(true);
  };

  const submitStage3 = async () => {
    formloaderSetter(true);

    if (activitystage3.length < 1) {
      setActivitystage3validator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Activity cant be empty");
      return false;
    }

    const stage3Data = {
      countries: westafricancountrystage3,
      root_id: rootid,
      description: activitystage3,
    };

    await submitFormStage3(stage3Data).then((res) => {
      if (res.data.status == 1) {
        formloaderSetter(false);
        stageSetter(res.data.result.application_stage);
      }
      formloaderSetter(false);
    });
  };

  const onSelectSetter = async (value) => {
    console.log("clicked");
    stageSetter(value);
    const response = await getFormStage4(rootid);

    await getAllCountries();

    if (response?.data?.result.descriptionofactivity && !formEdit) {
      setpreviousFIlledData(response?.data?.result);

      console.log(response, "kinjdjds");
      setserverData(true);
    }

    //load
    // if data set visibilitiy to true and change display
  };

  const handleCloseImg = () => {
    setImg("");
    setOpenimg(false);
  };
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const showimage = (img) => {
    console.log(img, "nsjssj");
    if (img.split(".").pop() == "pdf") {
      setImgtype("pdf");
      let newimg = "http://api.ogfza-registry.com/uploads/" + img;
      setImg(newimg);
      window.open(
        newimg,
        "sharer",
        "toolbar=no,status=yes,width=900,height=500"
      );

      return false;
    } else {
      setImgtype("img");
    }
    let newimg = "http://api.ogfza-registry.com/uploads/" + img;
    setImg(newimg);
    setOpenimg(true);
    console.log(newimg);
  };

  console.log("COUNTRIESSSSS", countries);

  return (
    <React.Fragment>
      <Modal
        open={showPreviewForm}
        onClose={() => setshowPreviewForm(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
          overflow: "scroll",
          border: "2px solid blue",
          height: "100vh",
          marginTop: "10px",
          // overflowX: 'visible',
          // backgroundColor:'white'
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            height: "maxContent",
            width: window.innerWidth - 50,
            padding: "1rem 2rem",
            border: "2px solid orange",
          }}
        >
          <FormStagePreview
            data={[previewData]}
            formloaderSetter={formloaderSetter}
            formPreviewSetter={formPreviewSetter}
          />
          <Button
            style={{ marginRight: "1rem" }}
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => {
              setshowPreviewForm(false);
              if (!formloader) {
                return submitStage3();
              } else {
                return null;
              }
            }}
          >
            {" "}
            Confirm Save and Next{" "}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              formloaderSetter(false);
              setshowPreviewForm(false);
            }}
          >
            {" "}
            No{" "}
          </Button>
        </div>
      </Modal>
      <AppBar position="absolute" color="#ffffff" className={classes.appBar}>
        <Toolbar className={classes.layout}>
          <img
            src={oilandgaslogo}
            alt="Logo"
            style={{
              width: "80px",
              height: "60px",
              textAlign: "center",
              alignSelf: "center",
            }}
          />
        </Toolbar>
      </AppBar>

      <Grid container component="main" className={classes.layout}>
        <Menutab
          onSelectSetter={onSelectSetter}
          whichapplication={whichapplication}
          stage={stage}
        />
        {!serverData ? (
          <>
            <Grid container xs={12} spacing={1} style={{ marginTop: 25 }}>
              <h4>
                {" "}
                4. Description of activity : oil producing, exploration,
                drilling, callibration
              </h4>
              <Grid item xs={12}>
                <TextField
                  id="outlined-full-width"
                  placeholder=""
                  value={activitystage3}
                  fullWidth
                  margin="normal"
                  onChange={activityStage3}
                  InputLabelProps={{
                    shrink: true,
                    className: !activitystage3validator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    style: {
                      minHeight: 200,
                      padding: "14px 14px",
                    },
                    classes: {
                      focused: !activitystage3validator
                        ? classes.errorstyle
                        : null,
                      notchedOutline: !activitystage3validator
                        ? classes.errorstyle
                        : null,
                    },
                  }}
                  variant="outlined"
                  multiline
                  style={{ backgroundColor: "#fff" }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              spacing={1}
              style={{ marginBottom: "10px" }}
            >
              <h4> 5. Select/Choose Your Country:</h4>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  onChange={(event, newValue) => {
                    console.log(newValue, "jdjjjdjsj");
                    westAfricanCountryStage3(newValue);
                  }}
                  id="tags-outlined"
                  options={countries}
                  getOptionLabel={(option) => option?.name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                    label="select countries"
                      {...params}
                      variant="outlined"
                      style={{ backgroundColor: "#fff" }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              justify="flex-end"
              style={{ marginBottom: "100px" }}
            >
              <Box component="span">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={!formloader ? previewStage3 : null}
                  style={{ backgroundColor: "#060454", height: "30px" }}
                  className={classes.button}
                  endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                >
                  Preview & Continue{" "}
                  {formloader ? <CircularProgress size={24} /> : null}
                </Button>
              </Box>
            </Grid>
          </>
        ) : (
          <div
            style={{
              backgroundColor: "#fff",
              marginTop: 25,
              padding: 30,
              width: "100%",
              marginBottom: "7rem",
            }}
          >
            {" "}
            {previousFIlledData?.descriptionofactivity && (
              <Grid xs={6}>
                {previousFIlledData?.descriptionofactivity?.status === 0 && (
                  <>
                    <label>Activity Description</label>
                    <input
                      disabled
                      value={
                        previousFIlledData?.descriptionofactivity?.description
                      }
                    />
                  </>
                )}
              </Grid>
            )}
            <label>Country</label>
            <Grid container>
              
              {previousFIlledData?.natureofbusinessoperation && (
                <>
                  {previousFIlledData?.natureofbusinessoperation?.map(
                    (country) => (
                      <Grid xs={4}>
                        <input disabled value={country?.country_id} />
                      </Grid>
                    )
                  )}
                </>
              )}
            </Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                // onClick={!formloader ? previewStageData : null}
                style={{ backgroundColor: "#060454", height: "30px" }}
                className={classes.button}
                endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                onClick={() => {
                  setformEdit(true);
                  setserverData(false);
                }}
              >
                Refill Form
              </Button>
            </Grid>
          </div>
        )}
      </Grid>
      <AppBar position="fixed" className={classes.appBarButom} color="#060454">
        <Typography
          variant="span"
          color="inherit"
          noWrap
          style={{ textAlign: "center", color: "#fff", alignItems: "center" }}
          justify="center"
        >
          Copyright © 2022 <b>Oil and Gas Free Zones Authority</b>. All Rights Reserved.
        </Typography>
      </AppBar>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    position: "relative",
  },
  checkboxLabel: {
    fontSize: 14,
  },
  appBarButom: {
    top: "auto",
    bottom: 0,
    height: "40px",
    backgroundColor: "#060454",
    paddingTop: "5px",
    paddingButtom: "5px",
    alignItems: "center",
    justifyContent: "center",
  },
  errorstyle: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "60%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

export default Applicationform3;
