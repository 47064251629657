import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ClearIcon from "@material-ui/icons/Clear";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import HourglassFullOutlinedIcon from "@material-ui/icons/HourglassFullOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import CancelPresentationOutlinedIcon from "@material-ui/icons/CancelPresentationOutlined";
import Box from "@material-ui/core/Box";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// use to display list
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import Divider from "@material-ui/core/Divider";
import oilandgaslogo from "../../assets/oilandgaslogo.png";
import pdf from "../../assets/pdf.png";
import txt from "../../assets/txt.png";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Approvalbuttons from "../../Approvalbuttons";
import Approvalbuttons from "../../components/Button/Approvalbuttons";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Snackbar from "@material-ui/core/Snackbar";
import { Document, Page, pdfjs } from "react-pdf";
import RegistryTab from "../../components/Tabs/RegistryTab";
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Applicantdetails(props) {
  const styles = useStyles();
  const [expanded, setExpanded] = React.useState("panel1");
  const [imgtype, setImgtype] = React.useState("img");
  const [img, setImg] = React.useState("");
  const [openimg, setOpenimg] = React.useState(false);
  const [openstate, setOpenstate] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const { vertical, horizontal, open } = openstate;

  const handleOpen = (id) => {
    setOpenstate({
      open: true,
      vertical: "top",
      horizontal: "center",
    });
  };

  const handleClose = () => {
    setOpenstate({
      open: false,
      vertical: "top",
      horizontal: "center",
    });
  };

  const showimage = (img) => {
    if (img?.split(".")?.pop() == "pdf") {
      setImgtype("pdf");
      let newimg = "http://api.ogfza-registry.com/uploads/" + img;
      setImg(newimg);
      window.open(
        newimg,
        "sharer",
        "toolbar=no,status=yes,width=900,height=500"
      );

      return false;
    } else {
      setImgtype("img");
    }
    let newimg = "http://api.ogfza-registry.com/uploads/" + img;
    setImg(newimg);
    setOpenimg(true);
    console.log(newimg);
  };

  const handleCloseImg = () => {
    setImg("");
    setOpenimg(false);
  };

  const DASHBOARDPRIVCONTENTTITLE = (value) => {
    switch (value) {
      case "2":
        return "REGISTRY";
      case "3":
        return "Registry HOD";
      case "4":
        return "CEO";

      default:
        return "GENERAL";
    }
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const classes = useStyles();
  return (
    <React.Fragment>
      <Modal
        open={openimg}
        onClose={handleCloseImg}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "scroll",
        }}
      >
        <div className={classes.modalview}>
          {imgtype == "pdf" ? (
            <Document file={img} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
          ) : (
            <img src={img} />
          )}
        </div>
      </Modal>
      <Grid container>
        <Grid item xs={10} sm={10} md={10}>
          <Typography
            style={{ color: "#060454", paddingBottom: 30 }}
            variant="h4"
          >
            {DASHBOARDPRIVCONTENTTITLE(props?.applicantdetails?.priv?.toString())}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={2}>
          <ClearIcon
            onClick={() => {
              props?.closedetails(1);
            }}
            style={{
              fontSize: 15,
              color: "red",
              fontSize: 40,
              cursor: "pointer",
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        style={{ backgroundColor: "#fff", padding: 50, borderRadius: 10 }}
      >
        {props.applicantdetails.applicant[0]?.approval_stage_decline > 0 ? (
          <Alert severity="error" color="error">
            This Application was Declined{" "}
            <Button
              onClick={() => {
                handleOpen(1);
              }}
            >
              {" "}
              Reason
            </Button>
          </Alert>
        ) : null}
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message={props?.applicantdetails?.applicant[0]?.reason}
          key={vertical + horizontal}
        />
        <RegistryTab data={props} showimage={showimage} />
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundColor: "#fff",
  },
  emailcontainer: {
    border: 1,
  },
  multilineColor: {
    color: "#fff",
  },
  floatingLabelFocusStyle: {
    color: "#fff",
  },
  image: {
    backgroundImage: "url(../../img/loginillustration.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.type === "light" ? "#fff" : "#fff",
    backgroundSize: "67%",
    backgroundPosition: "center",
  },

  signinFormGrid: {
    boxShadow: "none",
    backgroundColor: "#060454",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#0043ce",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formHolder: {
    width: "60%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#fff",
    borderRadius: "0.4rem",
    color: "#060454",
    width: "100%",
  },
  textfield: {
    borderRadius: "2rem",
  },
  email: {
    width: "60%",
    borderWidth: 2,
  },
  modalview: {
    position: "absolute",
    width: 800,
    height: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "scroll",
  },
}));

export default Applicantdetails;
