import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LockOutlineIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import oilandgaslogo from "../oilandgaslogo.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import RemitaPaymentApp from "../Views/Payment/RemitaPaymentApp";
import axios from "axios";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "13px",
      },
    },
  },
})(TextField);

function Final(props) {
  const {
    formloaderSetter,
    errorSetter,
    snakBarSetter,
    stageSetter,
    rootid,
    stage,
    formloader,
    vendorApplicant,
  } = props;

  console.log(props)
  const [paymentpage, setPaymentPage] = React.useState(false);
  const classes = useStyles();

  const onChangeEmail = (e) => {
    props.onChangeEmailParent(e.target.value);
  };
  const submitEmail = () => {
    props.submitEmailParent();
  };

  useEffect(() => {}, [paymentpage]);

  const paymentFormSetter = (value)=>{
    setPaymentPage(value)
  }


  console.log(paymentpage, 'hhjhggyyy')


  return (
    <React.Fragment>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />

        <Grid
          container
          spacing={0}
          xs={12}
          sm={12}
          md={12}
          className={classes.signinFormGrid}
          alignItems="center"
          justify="center"
        >
          <div className={classes.formHolder}>
            {paymentpage ? (
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={12}>
                  <h3 style={{ color: "#fff", textAlign: "center" }}>
                    {" "}
                    YOUR APPLICATION HAS BEEN SUBMITED FOR REVIEW, WE WOULD SEND
                    A FEEDBACK THROUGH YOUR COMPANY EMAIL PROVIDED PREVIOUSLY .
                  </h3>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={12}>
                  <RemitaPaymentApp
                    stage={stage}
                    errorSetter={errorSetter}
                    formloaderSetter={formloaderSetter}
                    rootid={rootid}
                    formloader={formloader}
                    stageSetter={stageSetter}
                    snakBarSetter={snakBarSetter}
                    paymentFormSetter={paymentFormSetter}
                  />
                </Grid>
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundColor: "#fff",
  },
  emailcontainer: {
    border: 1,
  },
  multilineColor: {
    color: "#fff",
  },
  floatingLabelFocusStyle: {
    color: "#fff",
  },
  floatingLabelFocusStyleError: {
    color: "red",
  },
  emailbordererror: {
    border: "solid 1px red",
  },
  emailborder: {
    border: "solid 1px #fff",
  },
  image: {
    backgroundImage: "url(../../img/loginillustration.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.type === "light" ? "#fff" : "#fff",
    backgroundSize: "67%",
    backgroundPosition: "center",
  },

  signinFormGrid: {
    boxShadow: "none",
    background: `linear-gradient(${"rgba(255,255,255,0.5)"}, ${"transparent"})`,
    backgroundColor: "#060454",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#0043ce",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formHolder: {
    width: "60%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#fff",
    borderRadius: "0.4rem",
    color: "#060454",
    width: "100%",
  },
  textfield: {
    borderRadius: "2rem",
  },
  email: {
    width: "60%",
    borderWidth: 2,
  },
}));

export default Final;
