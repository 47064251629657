import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LockOutlineIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import oilandgaslogo from "../../assets/oilandgaslogo.png";
import Renewalbusinesscorporate from "../Renewal/Renewalbusinesscorporate";
import Renewalcompanybasicinformationpage from "../Renewal/Renewalcompanybasicinformationpage";
// import Renewalcompanybasicinformationpage
import Renewalcompanyregistrationpage from "../Renewal/Renewalcompanyregistrationpage";
import Renewaldirectshareinfo from "../Renewal/Renewaldirectshareinfo";
// import Emailstep1 from "./Emailstep1";
import Emailstep1 from "../../components/Emailstep1";
import Paymentproof from "../Payment/Paymentproof";
import Landingpage from "../../Views/LandingPage/Landingpage";
import Applicationform0 from "./Applicationform0";
import Applicantform1 from "./Applicantform1";
import Applicationform2 from "./Applicationform2";
import Applicationform3 from "./Applicationform3";
import Applicationform4 from "./Applicationform4";
import Applicationform5 from "./Applicationform5";
import Applicationform6 from "./Applicationform6";
import Applicationform7 from "./Applicationform7";
import Final from "../../components/Final";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InputBase from "@material-ui/core/InputBase";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import {
  submitFormStage0,
  submitFormStage1,
  submitFormStage2,
  submitFormStage3,
  submitFormStage4,
  submitFormStage5,
  submitFormStage6,
  submitFormStage7,
  validateFormEmail,
} from "../../Services/apiServices/Form";
import RequiredDocument from "../Renewal/RequiredDocument";
import RemitaPayment from "../Payment/RemitaPaymentApp";
import RemitaPaymentApp from "../Payment/RemitaPaymentApp";
import RenewalFinal from "../../components/RenewalFinal";
import Presentation from "../Presentation/Presentation";

document.body.style = "background: #f5f5f5;";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "13px",
      },
    },
  },
})(TextField);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export const ApplicationType = React.createContext();

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

function Applicationform() {
  const classes = useStyles();
  // register email state
  const [stage, setStage] = useState(""); // use to track stage
  const [emailstage, setEmailstage] = useState(""); // holds email of company

  const [vendorApplicant, setvendorApplicant] = useState(0);

  //contact / company basic info useState
  const [rootid, setRootid] = useState("");
  const [number, setNumber] = useState("");

  // validators
  const [emailstagevalidation, setEmailstagevalidation] = useState(true);
  const [formloader, setFormloader] = useState(false);
  const [opensnakbar, setOpensnakbar] = useState(false);
  const [error, setError] = useState("");

  const [checker, setChecker] = useState(null);
  const [applicantType, setapplicantType] = useState();

  const closesnakbar = () => {
    setOpensnakbar(false);
    setError("");
  };

  // functions to handle forms
  const validateEmail = (email) => {
    let re = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return re.test(email);
  };
  const onChangeEmailParent = (email) => {
    setEmailstage(email);
  };

  const submitEmailParent = async () => {
    setFormloader(true);
    var email;

    if (whichapplication === 1) {
      email = {
        email: emailstage,
        application_type: "licence",
      };
    } else if (whichapplication === 3) {
      email = {
        email: emailstage,
        application_type: "vendor",
      };
    } else {
      email = {
        email: emailstage,
      };
    }

    if (validateEmail(emailstage)) {
      await validateFormEmail(email).then((res) => {
        console.log(res);
        setFormloader(false);
        if (res.data.status == 1) {
          setRootid(res.data.result.id);
          setStage(res.data.result.application_stage);
          setPaymentPage(res.data.result.processing_fee_status);
          setOpen(false);
        }
      });
    } else {
      setFormloader(false);
      setEmailstagevalidation(false);
      window.scrollTo(0, 0);
      setOpensnakbar(true);
      setError("Please Enter a good email");
      // scroll to top and show snackbar
    }
  };

  const formloaderSetter = (value) => {
    setFormloader(value);
  };

  const errorSetter = (value) => {
    setError(value);
  };

  const snakBarSetter = (value) => {
    setOpensnakbar(value);
  };

  const stageSetter = (value) => {
    setStage(value);
  };

  const paymentPageSetter = (value) => {
    setPaymentPage(value);
  };

  const numberOnchange = (value) => {
    setNumber(value);
  };

  const [open, setOpen] = React.useState(false);

  const [whichapplication, setWhichapplication] = React.useState(0);

  const handleClose = () => setOpen(false);
  const rand = () => {
    return Math.round(Math.random() * 20) - 10;
  };

  const getModalStyle = () => {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  };

  const [modalStyle] = React.useState(getModalStyle);

  // process landingpage buttons

  const newApplication = () => {
    setOpen(true);
    setWhichapplication(1);
  };

  const renewCertificate = () => {
    setOpen(true);
    setWhichapplication(2);
  };

  const vendorApplication = () => {
    setOpen(true);
    setWhichapplication(3);
    setvendorApplicant(1);
  };

  //payment page state
  const [paymentpage, setPaymentPage] = React.useState("");

  // process payment page
  const onClickPaymentButton = () => {
    setPaymentPage(true);
  };

  console.log(vendorApplicant, whichapplication);

  const landingModal = () => {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={modalStyle} className={classes.paper}>
          <Emailstep1
            email={emailstage}
            onChangeEmailParent={onChangeEmailParent}
            submitEmailParent={
              // if(whichapplication == 1){
              //   submitEmailParent = submitEmailParent
              // }else{
              //   submitEmailParent = submitEmailForNewCert
              // }

              whichapplication === 2 ? submitEmailForNewCert : submitEmailParent
              // whichapplication === 1 || whichapplication === 3
              //   ? vendorApplicant === 1
              //     ? submitEmailParent
              //     : submitEmailParent
              //   : submitEmailForNewCert
            }
            emailstagevalidation={emailstagevalidation}
            formloader={formloader}
          />
        </Box>
      </Modal>
    );
  };

  const submitEmailForNewCert = async () => {
    setFormloader(true);

    var email;
    if (whichapplication === 1) {
      email = {
        email: emailstage,
        application_type: "licence",
      };
    } else if (whichapplication === 3) {
      email = {
        email: emailstage,
        application_type: "vendor",
      };
    } else {
      email = {
        email: emailstage,
      };
    }

    if (validateEmail(emailstage)) {
      //await axios.post('https://ogfzaapi.skillzserver.com/aplicationrenewalform/create',  email )
      await axios
        .post(
          "https://ogfzaapi.skillzserver.com/aplicationrenewalform/create",
          email
        )
        .then((res) => {
          setFormloader(false);
          if (res.data.status == 1) {
            setRootid(res.data.result.id);
            setStage(res.data.result.application_stage);
            setOpen(false);
          }
        });
    } else {
      setFormloader(false);
      setEmailstagevalidation(false);
      window.scrollTo(0, 0);
      setOpensnakbar(true);
      setError("Please Enter a good email");
      setOpen(false);
      // scroll to top and show snackbar
    }
  };

  // process renewal company data

  // the renewal company data process functions end here

  const renderSwitch = (stage) => {
    if (whichapplication == 1 || whichapplication == 3) {
      // if (paymentpage > 0) {
      switch (stage) {
        case 1:
          return (
            // <ApplicationType.Provider value={whichapplication}>
            <Applicantform1
              email={emailstage}
              stage={stage}
              formloaderSetter={formloaderSetter}
              snakBarSetter={snakBarSetter}
              errorSetter={errorSetter}
              stageSetter={stageSetter}
              rootid={rootid}
              formloader={formloader}
              whichapplication={whichapplication}
            />
            // </ApplicationType.Provider>
          );

        case 2:
          return (
            <Applicationform0
              stage={stage}
              formloaderSetter={formloaderSetter}
              snakBarSetter={snakBarSetter}
              errorSetter={errorSetter}
              stageSetter={stageSetter}
              rootid={rootid}
              formloader={formloader}
              vendorApplicant={vendorApplicant}
              whichapplication={whichapplication}
            />
          );
        case 3:
          return (
            <Applicationform2
              formloaderSetter={formloaderSetter}
              snakBarSetter={snakBarSetter}
              errorSetter={errorSetter}
              stageSetter={stageSetter}
              rootid={rootid}
              stage={stage}
              formloader={formloader}
              whichapplication={whichapplication}
            />
          );
        case 4:
          return (
            <Applicationform3
              formloaderSetter={formloaderSetter}
              snakBarSetter={snakBarSetter}
              errorSetter={errorSetter}
              stageSetter={stageSetter}
              rootid={rootid}
              stage={stage}
              formloader={formloader}
              whichapplication={whichapplication}
            />
          );
        case 5:
          return (
            <Applicationform4
              rootid={rootid}
              formloaderSetter={formloaderSetter}
              snakBarSetter={snakBarSetter}
              stageSetter={stageSetter}
              errorSetter={errorSetter}
              stage={stage}
              formloader={formloader}
              whichapplication={whichapplication}
            />
          );
        case 6:
          return (
            <Applicationform5
              stageSetter={stageSetter}
              snakBarSetter={snakBarSetter}
              errorSetter={errorSetter}
              formloaderSetter={formloaderSetter}
              rootid={rootid}
              stage={stage}
              formloader={formloader}
              whichapplication={whichapplication}
            />
          );
        case 8:
          return (
            <Applicationform6
              stageSetter={stageSetter}
              snakBarSetter={snakBarSetter}
              errorSetter={errorSetter}
              formloaderSetter={formloaderSetter}
              rootid={rootid}
              stage={stage}
              formloader={formloader}
              whichapplication={whichapplication}
            />
          );
        case 7:
          return (
            <Applicationform7
              stage={stage}
              errorSetter={errorSetter}
              formloaderSetter={formloaderSetter}
              rootid={rootid}
              formloader={formloader}
              stageSetter={stageSetter}
              snakBarSetter={snakBarSetter}
              whichapplication={whichapplication}
            />
          );
        case 9:
          return (
            <Final
              stage={stage}
              errorSetter={errorSetter}
              formloaderSetter={formloaderSetter}
              rootid={rootid}
              formloader={formloader}
              stageSetter={stageSetter}
              snakBarSetter={snakBarSetter}
              whichapplication={whichapplication}
            />
          );
        default:
          return (
            <Landingpage
              onClickOnNewApplication={newApplication}
              onClickVendorApplication={vendorApplication}
              onClickOnRenewCertificate={renewCertificate}
              whichapplication={whichapplication}
            />
          );
      }
    } else if (whichapplication == 2) {
      switch (stage) {
        case 1:
          return (
            <Renewalcompanybasicinformationpage
              stage={stage}
              snakBarSetter={snakBarSetter}
              errorSetter={errorSetter}
              formloaderSetter={formloaderSetter}
              rootid={rootid}
              formloader={formloader}
              stageSetter={stageSetter}
              whichapplication={whichapplication}
            />
          );

        case 2:
          return (
            <Renewalcompanyregistrationpage
              stage={stage}
              snakBarSetter={snakBarSetter}
              errorSetter={errorSetter}
              formloaderSetter={formloaderSetter}
              rootid={rootid}
              formloader={formloader}
              stageSetter={stageSetter}
              whichapplication={whichapplication}
            />
          );
        case 3:
          return (
            <Renewaldirectshareinfo
              stage={stage}
              snakBarSetter={snakBarSetter}
              errorSetter={errorSetter}
              formloaderSetter={formloaderSetter}
              rootid={rootid}
              formloader={formloader}
              stageSetter={stageSetter}
              whichapplication={whichapplication}
            />
          );
        case 4:
          return (
            <Renewalbusinesscorporate
              stage={stage}
              snakBarSetter={snakBarSetter}
              errorSetter={errorSetter}
              formloaderSetter={formloaderSetter}
              rootid={rootid}
              stageSetter={stageSetter}
              formloader={formloader}
              whichapplication={whichapplication}
            />
          );
        case 5:
          return (
            <RequiredDocument
              rootid={rootid}
              formloaderSetter={formloaderSetter}
              snakBarSetter={snakBarSetter}
              stageSetter={stageSetter}
              errorSetter={errorSetter}
              stage={stage}
              formloader={formloader}
              whichapplication={whichapplication}
            />
          );
        case 6:
          return (
            
            <RenewalFinal
              stage={stage}
              errorSetter={errorSetter}
              formloaderSetter={formloaderSetter}
              rootid={rootid}
              formloader={formloader}
              stageSetter={stageSetter}
              snakBarSetter={snakBarSetter}
              whichapplication={whichapplication}
            />
          );
        default:
          return (
            <Landingpage
              onClickVendorApplication={vendorApplication}
              onClickOnNewApplication={newApplication}
              onClickOnRenewCertificate={renewCertificate}
              whichapplication={whichapplication}
            />
          );
      }
    } else {
      return (
        <Landingpage
          onClickVendorApplication={vendorApplication}
          onClickOnNewApplication={newApplication}
          onClickOnRenewCertificate={renewCertificate}
          whichapplication={whichapplication}
        />
        // <RemitaPayment/>
        // <Presentation/>
      );
    }
  };
  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={opensnakbar}
        onClose={closesnakbar}
        message={error}
        key={"topcenter"}
        ContentProps={{
          "aria-describedby": "message-id",
          className: classes.backgroundError,
        }}
      />
      {landingModal()}
      {renderSwitch(stage)}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  backgroundError: {
    backgroundColor: "red",
  },
  appBar: {
    position: "relative",
  },
  checkboxLabel: {
    fontSize: 14,
  },
  appBarButom: {
    top: "auto",
    bottom: 0,
    height: "40px",
    backgroundColor: "#060454",
    paddingTop: "5px",
    paddingButtom: "5px",
    alignItems: "center",
    justifyContent: "center",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "60%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    position: "absolute",
    width: 800,
    backgroundColor: "#333333",
  },
}));

export default Applicationform;
