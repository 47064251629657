import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LockOutlineIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import oilandgaslogo from "../../assets/oilandgaslogo.png";
import Menutab from "../../components/Tabs/Menutab";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import DoneIcon from "@material-ui/icons/Done";
import { getFormStage8, submitFormStage6 } from "../../Services/apiServices/Form";
import { Modal } from "@material-ui/core";
import FormStagePreview from "../../components/preview/FormStagePreview";

document.body.style = "background: #f5f5f5;";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "13px",
      },
    },
  },
})(TextField);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

function Applicationform6(props) {
  const {
    snakBarSetter,
    stageSetter,
    errorSetter,
    formloaderSetter,
    rootid,
    formloader,
    stage,
    whichapplication
  } = props;

  const classes = useStyles();

  const [serverData, setserverData] = useState(false);
  // understanding states
  const [compunderstandingname, setCompunderstandingname] = useState("");
  const [compunderstandingdesignation, setCompunderstandingdesignation] =
    useState("");
  const [compunderstandingdate, setCompunderstandingdate] = useState("");
  const [compunderstandingsignature, setCompunderstandingsignature] = useState(
    []
  );
  const [compunderstandingstamp, setCompunderstandingstamp] = useState([]);

  // understanding validator states
  const [compunderstandingnamevalidator, setCompunderstandingnamevalidator] =
    useState(true);
  const [
    compunderstandingdesignationvalidator,
    setCompunderstandingdesignationvalidator,
  ] = useState(true);
  const [compunderstandingdatevalidator, setCompunderstandingdatevalidator] =
    useState(true);
  const [
    compunderstandingsignaturevalidator,
    setCompunderstandingsignaturevalidator,
  ] = useState(true);
  const [compunderstandingstampvalidator, setCompunderstandingstampvalidator] =
    useState(true);

    const [previewData, setpreviewData] = useState();
    const [showPreviewForm, setshowPreviewForm] = useState(false);


    useEffect(() => {
      async function fetchFormData() {
        // const response = await getFormStage2(rootid);
        // console.log(response, "djjkdjd");
        setserverData(true);
      }
      fetchFormData();
    }, [serverData]);

  // understanding

  const compunderstandingnameOnchange = (e) => {
    setCompunderstandingname(e.target.value);
  };
  const compunderstandingdesignationOnchange = (e) => {
    setCompunderstandingdesignation(e.target.value);
  };
  const compunderstandingdateOnchange = (e) => {
    setCompunderstandingdate(e.target.value);
  };
  const compunderstandingsignatureOnchange = (e) => {
    setCompunderstandingsignature(e.target.files);
  };
  const compunderstandingstampOnchange = (e) => {
    setCompunderstandingstamp(e.target.files);
  };



  const onPreviewUnderstanding = async () => {
    formloaderSetter(true);

    if (compunderstandingname < 1) {
      setCompunderstandingnamevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Name cant be empty");
      return false;
    } else {
      setCompunderstandingnamevalidator(true);
    }

    if (compunderstandingdesignation < 1) {
      setCompunderstandingdesignationvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Designation cant be empty");
      return false;
    } else {
      setCompunderstandingdesignationvalidator(true);
    }

    if (compunderstandingdate < 1) {
      setCompunderstandingdatevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Select a valid date");
      return false;
    } else {
      setCompunderstandingdatevalidator(true);
    }

    if (compunderstandingsignature[0] == undefined) {
      setCompunderstandingsignaturevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload a Signature");
      return false;
    } else {
      setCompunderstandingsignaturevalidator(true);
    }

    if (compunderstandingstamp[0] == undefined) {
      setCompunderstandingstampvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload Company Stamp");
      return false;
    } else {
      setCompunderstandingstampvalidator(true);
    }


    formloaderSetter(false)




    const stageunderstandingData = [
      { name: "Company understanding date", value: `${compunderstandingdate}` },
      { name: "Company understanding Designation", value: `${compunderstandingdesignation}` },
      { name: "Company understanding Name", value: `${compunderstandingname}` },
      { name: "Stamp", value: `${compunderstandingstamp[0].name}`, file: compunderstandingstamp[0] },
      { name: "Company understanding Signature", value: `${compunderstandingsignature[0].name}`, file: compunderstandingsignature[0] },
   
   ];

   setpreviewData(stageunderstandingData);
    setshowPreviewForm(true);

  };

  const onSubmitUnderstanding = async () => {
    formloaderSetter(true);

    if (compunderstandingname < 1) {
      setCompunderstandingnamevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Name cant be empty");
      return false;
    } else {
      setCompunderstandingnamevalidator(true);
    }

    if (compunderstandingdesignation < 1) {
      setCompunderstandingdesignationvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Designation cant be empty");
      return false;
    } else {
      setCompunderstandingdesignationvalidator(true);
    }

    if (compunderstandingdate < 1) {
      setCompunderstandingdatevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Select a valid date");
      return false;
    } else {
      setCompunderstandingdatevalidator(true);
    }

    if (compunderstandingsignature[0] == undefined) {
      setCompunderstandingsignaturevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload a Signature");
      return false;
    } else {
      setCompunderstandingsignaturevalidator(true);
    }

    if (compunderstandingstamp[0] == undefined) {
      setCompunderstandingstampvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload Company Stamp");
      return false;
    } else {
      setCompunderstandingstampvalidator(true);
    }
    const stageunderstandingData = new FormData();

    stageunderstandingData.append("root_id", rootid);
    stageunderstandingData.append("name", compunderstandingname);
    stageunderstandingData.append("designation", compunderstandingdesignation);
    stageunderstandingData.append("date", compunderstandingdate);
    stageunderstandingData.append("signature", compunderstandingsignature[0]);
    stageunderstandingData.append("stamp", compunderstandingstamp[0]);

    await submitFormStage6(stageunderstandingData).then((res) => {
      if (res.data.status == 1) {
        formloaderSetter(false);
        stageSetter(res.data.result.application_stage);
      }
      formloaderSetter(false);
    });

  };

  const formPreviewSetter = (value) => {
    setshowPreviewForm(value);
  };

  const onSelectSetter = async (value) => {
    console.log('clicked')
    stageSetter(value);
    const response = await getFormStage8(rootid);
   
    setserverData(true);
    //load
    // if data set visibilitiy to true and change display
  };
  return (
    <React.Fragment>
      <Modal
        open={showPreviewForm}
        onClose={() => setshowPreviewForm(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
          overflow: "scroll",
          border: "2px solid blue",
          height: "100vh",
          marginTop: "10px",
          // overflowX: 'visible',
          // backgroundColor:'white'
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            height: "maxContent",
            width: window.innerWidth - 50,
            padding: "1rem 2rem",
            border: "2px solid orange",
          }}
        >
          <FormStagePreview
            data={[previewData]}
            formloaderSetter={formloaderSetter}
            formPreviewSetter={formPreviewSetter}
          />
          <Button
           style={{marginRight:'1rem'}}
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => {
              setshowPreviewForm(false);
              if (!formloader) {
                return onSubmitUnderstanding();
              } else {
                return null;
              }
            }}
          >
            {" "}
            Confirm Save and Next{" "}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              formloaderSetter(false);
              setshowPreviewForm(false);
            }}
          >
            {" "}
            No{" "}
          </Button>
        </div>
      </Modal>
      <AppBar position="absolute" color="#ffffff" className={classes.appBar}>
        <Toolbar className={classes.layout}>
          <img
            src={oilandgaslogo}
            alt="Logo"
            style={{
              width: "80px",
              height: "60px",
              textAlign: "center",
              alignSelf: "center",
            }}
          />
        </Toolbar>
      </AppBar>

      <Grid container component="main" className={classes.layout}>
        <Grid item xs={12} style={{ marginBottom: 25 }}>
          <Menutab onSelectSetter={onSelectSetter} whichapplication={whichapplication}  stage={stage} />
          <h4> 10. Understanding:</h4>
          <Box component="span" color="rgba(0, 0, 0, 0.3)">
            In accordance with Section 9 (1) of the OGEFZ Act CAP 05, LFN 2010,
            we formally apply for Free Zone registration to operate in the Oil &
            Gas Free Zone. We also fully understand that the Free Zone
            Management has the right to screen all applications and to accept
            projects that comply and to reject applications that are not
            consistent with the development requirements of the Oil and Gas Free
            Zone.
          </Box>
        </Grid>
        <Grid
          container
          xs={12}
          spacing={1}
          style={{ backgroundColor: "#fff", marginTop: 25, padding: 30 }}
        >
          <Grid item xs={4}>
            <TextField
              id="outlined-full-width"
              label="Name"
              placeholder="Name"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
                className: !compunderstandingnamevalidator
                  ? classes.errorstylelabel
                  : null,
              }}
              InputProps={{
                classes: {
                  focused: !compunderstandingnamevalidator
                    ? classes.errorstyle
                    : null,
                  notchedOutline: !compunderstandingnamevalidator
                    ? classes.errorstyle
                    : null,
                },
              }}
              variant="outlined"
              value={compunderstandingname}
              onChange={compunderstandingnameOnchange}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="outlined-full-width"
              label="Designation"
              placeholder="Designation"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
                className: !compunderstandingdesignationvalidator
                  ? classes.errorstylelabel
                  : null,
              }}
              InputProps={{
                classes: {
                  focused: !compunderstandingdesignationvalidator
                    ? classes.errorstyle
                    : null,
                  notchedOutline: !compunderstandingdesignationvalidator
                    ? classes.errorstyle
                    : null,
                },
              }}
              variant="outlined"
              value={compunderstandingdesignation}
              onChange={compunderstandingdesignationOnchange}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="outlined-full-width"
              label="Date"
              type="date"
              placeholder="Date"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
                className: !compunderstandingdatevalidator
                  ? classes.errorstylelabel
                  : null,
              }}
              InputProps={{
                classes: {
                  focused: !compunderstandingdatevalidator
                    ? classes.errorstyle
                    : null,
                  notchedOutline: !compunderstandingdatevalidator
                    ? classes.errorstyle
                    : null,
                },
              }}
              variant="outlined"
              value={compunderstandingdate}
              onChange={compunderstandingdateOnchange}
            />
          </Grid>

          <Grid item xs={12}>
            <input
              accept="image/*,application/pdf"
              className={classes.input}
              style={{ display: "none" }}
              id="signature"
              multiple
              type="file"
              //value = {compunderstandingdate}
              onChange={compunderstandingsignatureOnchange}
            />
            <label htmlFor="signature">
              <Button
                fullWidth
                style={{ backgroundColor: "#fff", minHeight: 60 }}
                variant="outlined"
                component="span"
                className={
                  !compunderstandingsignaturevalidator
                    ? classes.errorstyle
                    : compunderstandingsignature.length > 0
                    ? classes.successupload
                    : classes.uploads
                }
                endIcon={
                  compunderstandingsignature.length > 0 ? (
                    <DoneIcon style={{ fontSize: "20px", color: "green" }} />
                  ) : null
                }
              >
                <Button
                  style={{ backgroundColor: "#060454", color: "white" }}
                  variant="outlined"
                  component="span"
                  className={classes.button}
                >
                  {compunderstandingsignature.length > 0
                    ? compunderstandingsignature[0].name
                    : "Upload Signature"}
                </Button>
              </Button>
            </label>
          </Grid>

          <Grid item xs={12}>
            <input
              accept="image/*,application/pdf"
              className={classes.input}
              style={{ display: "none" }}
              id="stamp"
              multiple
              type="file"
              onChange={compunderstandingstampOnchange}
            />
            <label htmlFor="stamp">
              <Button
                fullWidth
                style={{ backgroundColor: "#fff", minHeight: 60 }}
                variant="outlined"
                component="span"
                className={
                  !compunderstandingstampvalidator
                    ? classes.errorstyle
                    : compunderstandingstamp.length > 0
                    ? classes.successupload
                    : classes.uploads
                }
                endIcon={
                  compunderstandingstamp.length > 0 ? (
                    <DoneIcon style={{ fontSize: "20px", color: "green" }} />
                  ) : null
                }
              >
                <Button
                  style={{ backgroundColor: "#060454", color: "white" }}
                  variant="outlined"
                  component="span"
                  className={classes.button}
                >
                  {compunderstandingstamp.length > 0
                    ? compunderstandingstamp[0].name
                    : "Upload Company Stamp"}
                </Button>
              </Button>
            </label>
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          justify="flex-end"
          style={{ marginBottom: "100px", marginTop: 25 }}
        >
                <Box component="span">
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#060454", height: "30px" }}
              className={classes.button}
              endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
              onClick={!formloader ? onPreviewUnderstanding : null}
            >
              Preview & Continue{" "}
              {formloader ? <CircularProgress size={24} /> : null}
            </Button>
          </Box>
          {/* <Box component="span">
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#060454", height: "30px" }}
              className={classes.button}
              endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
              onClick={!formloader ? onSubmitUnderstanding : null}
            >
              Save & Continue{" "}
              {formloader ? <CircularProgress size={24} /> : null}
            </Button>
          </Box> */}
        </Grid>
      </Grid>
      <AppBar position="fixed" className={classes.appBarButom} color="#060454">
        <Typography
          variant="span"
          color="inherit"
          noWrap
          style={{ textAlign: "center", color: "#fff", alignItems: "center" }}
          justify="center"
        >
          Copyright © 2022 <b>Oil and Gas Free Zones Authority</b>. All Rights Reserved.
        </Typography>
      </AppBar>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    position: "relative",
  },
  checkboxLabel: {
    fontSize: 14,
  },
  appBarButom: {
    top: "auto",
    bottom: 0,
    height: "40px",
    backgroundColor: "#060454",
    paddingTop: "5px",
    paddingButtom: "5px",
    alignItems: "center",
    justifyContent: "center",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "60%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  errorstyle: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
  errorstylelabel: {
    color: "red",
  },
  uploads: {
    border: "1px dashed #ccc",
  },
  successupload: {
    border: "1px dashed green",
  },
}));

export default Applicationform6;
