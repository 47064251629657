import React, {useState,useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import LockOutlineIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles,withStyles, } from '@material-ui/core/styles';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import oilandgaslogo from "../../assets/oilandgaslogo.png";
import MenuIcon from '@material-ui/icons/Menu';
import DescriptionIcon from '@material-ui/icons/Description';
import Applicantlist from '../Applicants/Applicantlist';
import Applicantdetails from '../Applicants/Applicantdetails';
import Dashboardcontent from './Dashboardcontent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ClearIcon from '@material-ui/icons/Clear';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import HourglassFullOutlinedIcon from '@material-ui/icons/HourglassFullOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import CancelPresentationOutlinedIcon from '@material-ui/icons/CancelPresentationOutlined';
import axios from 'axios';

function Dashboardceo(props) {

    return (
        <React.Fragment>

        <Grid item  xs={12} sm={3} md={3}>
        <Box component = "div" style = {{minHeight:100, borderTopLeftRadius:10,borderTopRightRadius:10,padding:5}}>
            <Box component = "div" style={{backgroundColor:"#fff",display:"flex",alignItems:"center",justifyContent:"center",color:"#000",paddingTop:20,borderTopLeftRadius:10,borderTopRightRadius:10}}>
                <HourglassFullOutlinedIcon style={{fontSize:50,color:"#060454"}}/>
            </Box>

            <Box component = "div" style={{minHeight:80,backgroundColor:"#fff",display:"flex",alignItems:"center",justifyContent:"center",color: parseInt(props.dashboardcount.ceo.Await) > 0 ? "red":"#000",paddingTop:10,paddingBottom:10,fontSize:15,textAlign:"center"}}>
                {props.dashboardcount.ceo.Await} Awaiting CEO Approval
            </Box>

            <Box onClick = {() => { props.getApplicantListbyroute('startapprovalceo') }} component = "div"  style={{backgroundColor:"#060454",height:50,display:"flex",alignItems:"center",justifyContent:"center",color:"#fff",fontSize:18,borderBottomLeftRadius:10,borderBottomRightRadius:10,cursor:"pointer"}}>
             Start Approval
            </Box>
        </Box>
        </Grid>
        <Grid item  xs={12} sm={3} md={3}>
        <Box component = "div" style = {{minHeight:100, borderTopLeftRadius:10,borderTopRightRadius:10,padding:5}}>
            <Box component = "div" style={{backgroundColor:"#fff",display:"flex",alignItems:"center",justifyContent:"center",color:"#000",paddingTop:20,borderTopLeftRadius:10,borderTopRightRadius:10}}>
                <CancelPresentationOutlinedIcon style={{fontSize:50,color:"#060454"}}/>
            </Box>

            <Box component = "div" style={{minHeight:80,backgroundColor:"#fff",display:"flex",alignItems:"center",justifyContent:"center",color:"#000",paddingTop:10,paddingBottom:10,fontSize:15,textAlign:"center"}}>
                {props.dashboardcount.ceo.Decline} Rejected by CEO
            </Box>

            <Box onClick = {() => { props.getApplicantListbyroute('declinebyceo') }} component = "div"  style={{backgroundColor:"#060454",height:50,display:"flex",alignItems:"center",justifyContent:"center",color:"#fff",fontSize:18,borderBottomLeftRadius:10,borderBottomRightRadius:10,cursor:"pointer"}}>
             View
            </Box>
        </Box>
        </Grid>
        <Grid item  xs={12} sm={3} md={3}>
        <Box component = "div" style = {{minHeight:100, borderTopLeftRadius:10,borderTopRightRadius:10,padding:5}}>
            <Box component = "div" style={{backgroundColor:"#fff",display:"flex",alignItems:"center",justifyContent:"center",color:"#000",paddingTop:20,borderTopLeftRadius:10,borderTopRightRadius:10}}>
                <DoneAllIcon style={{fontSize:50,color:"#060454"}}/>
            </Box>

            <Box component = "div" style={{minHeight:80,backgroundColor:"#fff",display:"flex",alignItems:"center",justifyContent:"center",color:"#000",paddingTop:10,paddingBottom:10,fontSize:15,textAlign:"center"}}>
                {props.dashboardcount.ceo.Approve} Accepted by CEO
            </Box>

            <Box onClick = {() => { props.getApplicantListbyroute('approvedbyceo') }} component = "div"  style={{backgroundColor:"#060454",height:50,display:"flex",alignItems:"center",justifyContent:"center",color:"#fff",fontSize:18,borderBottomLeftRadius:10,borderBottomRightRadius:10,cursor:"pointer"}}>
             View
            </Box>
        </Box>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
        <Box
          component="div"
          style={{
            minHeight: 100,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            padding: 5,
          }}
        >
          <Box
            component="div"
            style={{
              backgroundColor: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#000",
              paddingTop: 20,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
          >
            <HourglassFullOutlinedIcon
              style={{ fontSize: 50, color: "#060454" }}
            />
          </Box>

          <Box
            component="div"
            style={{
              minHeight: 80,
              backgroundColor: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#000",
              paddingTop: 10,
              paddingBottom: 10,
              fontSize: 15,
              textAlign: "center",
            }}
          >
            {props?.dashboardcount?.ceo?.Renewal} NUMBER OF PENDING LICENCE RENEWAL
          </Box>

          <Box
            onClick={() => {
              props.getApplicantListbyroute("totalRenewal");
            }}
            component="div"
            style={{
              backgroundColor: "#060454",
              height: 50,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
              fontSize: 18,
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
              cursor: "pointer",
            }}
          >
            View
          </Box>
        </Box>
      </Grid>
      </React.Fragment>
    );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    backgroundColor:'#fff',
  },
  emailcontainer: {
    border: 1,
  },
  multilineColor:{
        color:'#fff'
    },
    floatingLabelFocusStyle: {
        color: "#fff"
    },
  image: {
    backgroundImage: 'url(../../img/loginillustration.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? '#fff' : '#fff',
    backgroundSize: '67%',
    backgroundPosition: 'center',
  },

  signinFormGrid: {
    boxShadow:'none',
    backgroundColor: '#0F0E83',

  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor:'#0043ce',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formHolder: {
    width: '60%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#fff',
    borderRadius: '0.4rem',
    color:'#0F0E83',
    width:'100%'
  },
  textfield: {
    borderRadius: '2rem',
  },
  email:{
      width:'60%',
      borderWidth:2,

  },
  pending:{
      border:"solid 1px orange",
      color:"orange"
  },
  decline:{
      border:"solid 1px red",
      color:"red"
  },
  aprove:{
      border:"solid 1px green",
      color:"green"
  }
}));

export default Dashboardceo;
