import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LockOutlineIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import oilandgaslogo from "../../assets/oilandgaslogo.png";
import Emailstep1 from "../../components/Emailstep1";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InputBase from "@material-ui/core/InputBase";
import Menutab from "../../components/Tabs/Menutab";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getFormStage6,
  submitFormStage5,
} from "../../Services/apiServices/Form";
import FormStagePreview from "../../components/preview/FormStagePreview";
import { Modal } from "@material-ui/core";

document.body.style = "background: #f5f5f5;";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "13px",
      },
    },
  },
})(TextField);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

function Applicationform5(props) {
  const {
    stageSetter,
    snakBarSetter,
    errorSetter,
    formloaderSetter,
    rootid,
    formloader,
    stage,
    whichapplication,
  } = props;

  const [serverData, setserverData] = useState(false);
  const [formEdit, setformEdit] = useState(false);
  const [previousFIlledData, setpreviousFIlledData] = useState();
  // state for stage 5
  const [seatypeofcargo, setSeatypeofcargo] = useState("");
  const [seatons, setSeatons] = useState("");
  const [seacontainer, setSeacontainer] = useState("");
  const [seavalue, setSeavalue] = useState("");
  const [airtypeofcargo, setAirtypeofcargo] = useState("");
  const [airtons, setAirtons] = useState("");
  const [aircontainer, setAircontainer] = useState("");
  const [airvalue, setAirvalue] = useState("");
  const [roadtypeofcargo, setRoadtypeofcargo] = useState("");
  const [roadtons, setRoadtons] = useState("");
  const [roadcontainer, setRoadcontainer] = useState("");
  const [roadvalue, setRoadvalue] = useState("");
  const [seatypeofcargoexp, setSeatypeofcargoexp] = useState("");
  const [seatonsexp, setSeatonsexp] = useState("");
  const [seacontainerexp, setSeacontainerexp] = useState("");
  const [seavalueexp, setSeavalueexp] = useState("");
  const [airtypeofcargoexp, setAirtypeofcargoexp] = useState("");
  const [airtonsexp, setAirtonsexp] = useState("");
  const [aircontainerexp, setAircontainerexp] = useState("");
  const [airvalueexp, setAirvalueexp] = useState("");
  const [roadtypeofcargoexp, setRoadtypeofcargoexp] = useState("");
  const [roadtonsexp, setRoadtonsexp] = useState("");
  const [roadcontainerexp, setRoadcontainerexp] = useState("");
  const [roadvalueexp, setRoadvalueexp] = useState("");

  // stage 5 validators state
  const [seatypeofcargovalidator, setSeatypeofcargovalidator] = useState(true);
  const [seatonsvalidator, setSeatonsvalidator] = useState(true);
  const [seacontainervalidator, setSeacontainervalidator] = useState(true);
  const [seavaluevalidator, setSeavaluevalidator] = useState(true);
  const [airtypeofcargovalidator, setAirtypeofcargovalidator] = useState(true);
  const [airtonsvalidator, setAirtonsvalidator] = useState(true);
  const [aircontainervalidator, setAircontainervalidator] = useState(true);
  const [airvaluevalidator, setAirvaluevalidator] = useState(true);
  const [roadtypeofcargovalidator, setRoadtypeofcargovalidator] =
    useState(true);
  const [roadtonsvalidator, setRoadtonsvalidator] = useState(true);
  const [roadcontainervalidator, setRoadcontainervalidator] = useState(true);
  const [roadvaluevalidator, setRoadvaluevalidator] = useState(true);
  const [seatypeofcargoexpvalidator, setSeatypeofcargoexpvalidator] =
    useState(true);
  const [seatonsexpvalidator, setSeatonsexpvalidator] = useState(true);
  const [seacontainerexpvalidator, setSeacontainerexpvalidator] =
    useState(true);
  const [seavalueexpvalidator, setSeavalueexpvalidator] = useState(true);
  const [airtypeofcargoexpvalidator, setAirtypeofcargoexpvalidator] =
    useState(true);
  const [airtonsexpvalidator, setAirtonsexpvalidator] = useState(true);
  const [aircontainerexpvalidator, setAircontainerexpvalidator] =
    useState(true);
  const [airvalueexpvalidator, setAirvalueexpvalidator] = useState(true);
  const [roadtypeofcargoexpvalidator, setRoadtypeofcargoexpvalidator] =
    useState(true);
  const [roadtonsexpvalidator, setRoadtonsexpvalidator] = useState(true);
  const [roadcontainerexpvalidator, setRoadcontainerexpvalidator] =
    useState(true);
  const [roadvalueexpvalidator, setRoadvalueexpvalidator] = useState(true);

  const [previewData, setpreviewData] = useState();
  const [showPreviewForm, setshowPreviewForm] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    async function fetchFormData() {
      const response = await getFormStage6(rootid);
      if (response?.data?.result.import.length !== 0 && !formEdit) {
        setpreviousFIlledData(response?.data?.result);
        setserverData(true);
      }
    }
    fetchFormData();
  }, [serverData]);

  const formPreviewSetter = (value) => {
    setshowPreviewForm(value);
  };
  // stage 5 handlers
  const seatypeofcargoOnchange = (e) => {
    setSeatypeofcargo(e.target.value);
  };

  const seatonsOnchange = (e) => {
    setSeatons(e.target.value);
    console.log(seatons);
  };

  const seacontainerOnchange = (e) => {
    setSeacontainer(e.target.value);
  };

  const seavalueOnchange = (e) => {
    setSeavalue(e.target.value);
  };

  const airtypeofcargoOnchange = (e) => {
    setAirtypeofcargo(e.target.value);
  };

  const airtonsOnchange = (e) => {
    console.log(e.target.value);
    setAirtons(e.target.value);
  };

  const aircontainerOnchange = (e) => {
    setAircontainer(e.target.value);
  };

  const airvalueOnchange = (e) => {
    setAirvalue(e.target.value);
  };

  const roadtypeofcargoOnchange = (e) => {
    setRoadtypeofcargo(e.target.value);
  };

  const roadtonsOnchange = (e) => {
    setRoadtons(e.target.value);
  };

  const roadcontainerOnchange = (e) => {
    setRoadcontainer(e.target.value);
  };

  const roadvalueOnchange = (e) => {
    setRoadvalue(e.target.value);
  };

  const seatypeofcargoOnchangeexp = (e) => {
    setSeatypeofcargoexp(e.target.value);
  };

  const seatonsOnchangeexp = (e) => {
    setSeatonsexp(e.target.value);
  };

  const seacontainerOnchangeexp = (e) => {
    setSeacontainerexp(e.target.value);
  };

  const seavalueOnchangeexp = (e) => {
    setSeavalueexp(e.target.value);
  };

  const airtypeofcargoOnchangeexp = (e) => {
    setAirtypeofcargoexp(e.target.value);
  };

  const airtonsOnchangeexp = (e) => {
    setAirtonsexp(e.target.value);
  };

  const aircontainerOnchangeexp = (e) => {
    setAircontainerexp(e.target.value);
  };

  const airvalueOnchangeexp = (e) => {
    setAirvalueexp(e.target.value);
  };

  const roadtypeofcargoOnchangeexp = (e) => {
    setRoadtypeofcargoexp(e.target.value);
  };

  const roadtonsOnchangeexp = (e) => {
    setRoadtonsexp(e.target.value);
  };

  const roadcontainerOnchangeexp = (e) => {
    setRoadcontainerexp(e.target.value);
  };
  const roadvalueOnchangeexp = (e) => {
    setRoadvalueexp(e.target.value);
  };

  // const submitStage5 = ()=>{
  //     props.submitStage5()
  // }

  const previewStage5 = async () => {
    formloaderSetter(true);
    let importsea = 0;
    let importair = 0;
    let importroad = 0;
    let exportsea = 0;
    let exportair = 0;
    let exportroad = 0;

    // check for import sea
    if (seatypeofcargo.length > 0) {
      importsea = importsea + 1;
    }
    if (seatons.length > 0) {
      importsea = importsea + 1;
    }
    if (seavalue.length > 0) {
      importsea = importsea + 1;
    }
    if (seacontainer.length > 0) {
      importsea = importsea + 1;
    }

    // check for import air
    if (airtypeofcargo.length > 0) {
      importair = importair + 1;
    }
    if (airtons.length > 0) {
      importair = importair + 1;
    }
    if (airvalue.length > 0) {
      importair = importair + 1;
    }
    if (aircontainer.length > 0) {
      importair = importair + 1;
    }

    // check for import road
    if (roadtypeofcargo.length > 0) {
      importroad = importroad + 1;
    }
    if (roadtons.length > 0) {
      importroad = importroad + 1;
    }
    if (roadvalue.length > 0) {
      importroad = importroad + 1;
    }
    if (roadcontainer.length > 0) {
      importroad = importroad + 1;
    }

    // Export starts here
    // check for import sea
    if (seatypeofcargoexp.length > 0) {
      exportsea = exportsea + 1;
    }
    if (seatonsexp.length > 0) {
      exportsea = exportsea + 1;
    }
    if (seavalueexp.length > 0) {
      exportsea = exportsea + 1;
    }
    if (seacontainerexp.length > 0) {
      exportsea = exportsea + 1;
    }

    // check for import air
    if (airtypeofcargoexp.length > 0) {
      exportair = exportair + 1;
    }
    if (airtonsexp.length > 0) {
      exportair = exportair + 1;
    }
    if (airvalueexp.length > 0) {
      exportair = exportair + 1;
    }
    if (aircontainerexp.length > 0) {
      exportair = exportair + 1;
    }

    // check for import road
    if (roadtypeofcargoexp.length > 0) {
      exportroad = exportroad + 1;
    }
    if (roadtonsexp.length > 0) {
      exportroad = exportroad + 1;
    }
    if (roadvalueexp.length > 0) {
      exportroad = exportroad + 1;
    }
    if (roadcontainerexp.length > 0) {
      exportroad = exportroad + 1;
    }

    // check for import sea and return false

    if (importsea > 0 && importsea !== 4) {
      if (seatypeofcargo.length < 1) {
        setSeatypeofcargovalidator(false);
      }
      if (seatons.length < 1) {
        setSeatonsvalidator(false);
      }
      if (seavalue.length < 1) {
        setSeavaluevalidator(false);
      }
      if (seacontainer.length < 1) {
        setSeacontainervalidator(false);
      }

      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter(
        "Please note that all input related to importation by Sea is require, Note : if you dont have all value available, then clear the inputs you have already filled "
      );
      return false;
    } else {
      setSeatypeofcargovalidator(true);
      setSeatonsvalidator(true);
      setSeavaluevalidator(true);
      setSeacontainervalidator(true);
    }

    // check for import air and return false

    if (importair > 0 && importair !== 4) {
      if (airtypeofcargo.length < 1) {
        setAirtypeofcargovalidator(false);
      }
      if (airtons.length < 1) {
        setAirtonsvalidator(false);
      }
      if (airvalue.length < 1) {
        setAirvaluevalidator(false);
      }
      if (aircontainer.length < 1) {
        setAircontainervalidator(false);
      }

      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter(
        "Please note that all input related to importation by Air is require, Note : if you dont have all value available, then clear the inputs you have already filled "
      );
      return false;
    } else {
      setAirtypeofcargovalidator(true);
      setAirtonsvalidator(true);
      setAirvaluevalidator(true);
      setAircontainervalidator(true);
    }

    // check for import air and return false

    if (importroad > 0 && importroad !== 4) {
      if (roadtypeofcargo.length < 1) {
        setRoadtypeofcargovalidator(false);
      }
      if (roadtons.length < 1) {
        setRoadtonsvalidator(false);
      }
      if (roadvalue.length < 1) {
        setRoadvaluevalidator(false);
      }
      if (roadcontainer.length < 1) {
        setRoadcontainervalidator(false);
      }

      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter(
        "Please note that all input related to importation by Road is require, Note : if you dont have all value available, then clear the inputs you have already filled "
      );
      return false;
    } else {
      setRoadtypeofcargovalidator(true);
      setRoadtonsvalidator(true);
      setRoadvaluevalidator(true);
      setRoadcontainervalidator(true);
    }

    // check for export sea and return false
    if (exportsea > 0 && exportsea !== 4) {
      if (seatypeofcargoexp.length < 1) {
        setSeatypeofcargoexpvalidator(false);
      }
      if (seatonsexp.length < 1) {
        setSeatonsexpvalidator(false);
      }
      if (seavalueexp.length < 1) {
        setSeavalueexpvalidator(false);
      }
      if (seacontainerexp.length < 1) {
        setSeacontainerexpvalidator(false);
      }

      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter(
        "Please note that all input related to exportation by Sea is require, Note : if you dont have all value available, then clear the inputs you have already filled "
      );
      return false;
    } else {
      setSeatypeofcargoexpvalidator(true);
      setSeatonsexpvalidator(true);
      setSeavalueexpvalidator(true);
      setSeacontainerexpvalidator(true);
    }

    if (exportair > 0 && exportair !== 4) {
      if (airtypeofcargoexp.length < 1) {
        setAirtypeofcargoexpvalidator(false);
      }
      if (airtonsexp.length < 1) {
        setAirtonsexpvalidator(false);
      }
      if (airvalueexp.length < 1) {
        setAirvalueexpvalidator(false);
      }
      if (aircontainerexp.length < 1) {
        setAircontainerexpvalidator(false);
      }

      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter(
        "Please note that all input related to exportation by Air is require, Note : if you dont have all value available, then clear the inputs you have already filled "
      );
      return false;
    } else {
      setAirtypeofcargoexpvalidator(true);
      setAirtonsexpvalidator(true);
      setAirvalueexpvalidator(true);
      setAircontainerexpvalidator(true);
    }

    if (exportroad > 0 && exportroad !== 4) {
      if (roadtypeofcargoexp.length < 1) {
        setRoadtypeofcargoexpvalidator(false);
      }
      if (roadtonsexp.length < 1) {
        setRoadtonsexpvalidator(false);
      }
      if (roadvalueexp.length < 1) {
        setRoadvalueexpvalidator(false);
      }
      if (roadcontainerexp.length < 1) {
        setRoadcontainerexpvalidator(false);
      }

      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter(
        "Please note that all input related to exportation by Road is require, Note : if you dont have all value available, then clear the inputs you have already filled "
      );
      return false;
    } else {
      setRoadtypeofcargoexpvalidator(true);
      setRoadtonsexpvalidator(true);
      setRoadvalueexpvalidator(true);
      setRoadcontainerexpvalidator(true);
    }

    let holdImport = [
      {
        type: "sea",
        cargo: seatypeofcargo,
        tons: seatons,
        container: seacontainer,
        value: seavalue,
      },
      {
        type: "air",
        cargo: airtypeofcargo,
        tons: airtons,
        container: aircontainer,
        value: airvalue,
      },
      {
        type: "road",
        cargo: roadtypeofcargo,
        tons: roadtons,
        container: roadcontainer,
        value: roadvalue,
      },
    ];
    let holdExport = [
      {
        type: "sea",
        cargo: seatypeofcargoexp,
        tons: seatonsexp,
        container: seacontainerexp,
        value: seavalueexp,
      },
      {
        type: "air",
        cargo: airtypeofcargoexp,
        tons: airtonsexp,
        container: aircontainerexp,
        value: airvalueexp,
      },
      {
        type: "road",
        cargo: roadtypeofcargoexp,
        tons: roadtonsexp,
        container: roadcontainerexp,
        value: roadvalueexp,
      },
    ];

    // var Imports = [
    //   {
    //     name: "sea",
    //     data: [
    //       { cargo: seatypeofcargoexp },
    //       { tons: seatonsexp },
    //       { container: seacontainerexp },
    //       { value: seavalueexp },
    //     ],
    //   },
    //   {
    //     name: "air",
    //     data: [
    //       {cargo: airtypeofcargoexp},
    //       {tons: airtonsexp},
    //       {container: aircontainerexp},
    //       {value: airvalueexp},
    //     ],
    //   },
    //   {
    //     name: "road",
    //     data: [
    //      {name:  ` cargo: ${ roadtypeofcargoexp}`},
    //       {name: `tons:${roadtonsexp}}`,
    //       {container: roadcontainerexp},
    //       {value: roadvalueexp},
    //     ],
    //   },

    // ];

    var Imports = [
      {
        name: "sea",
        data: [
          { name: `cargo: ${seatypeofcargo}` },
          { name: `tons: ${seatons}` },
          { name: `container: ${seacontainer}` },
          { name: `value: ${seavalue}` },
        ],
      },
      {
        name: "air",
        data: [
          { name: `cargo: ${airtypeofcargo}` },
          { name: `tons: ${airtons}` },
          { name: `container: ${aircontainer}` },
          { name: `value: ${airvalue}` },
        ],
      },
      {
        name: "road",
        data: [
          { name: `cargo: ${roadtypeofcargo}` },
          { name: `tons: ${roadtons}` },
          { name: `container: ${roadcontainer}` },
          { name: `value: ${roadvalue}` },
        ],
      },
    ];

    // seatypeofcargoexp

    var Exports = [
      {
        name: "sea",
        data: [
          { name: `cargo: ${seatypeofcargoexp}` },
          { name: `tons: ${seatonsexp}` },
          { name: `container: ${seacontainerexp}` },
          { name: `value: ${seavalueexp}` },
        ],
      },
      {
        name: "air",
        data: [
          { name: `cargo: ${airtypeofcargoexp}` },
          { name: `tons: ${airtonsexp}` },
          { name: `container: ${aircontainerexp}` },
          { name: `value: ${airvalueexp}` },
        ],
      },
      {
        name: "road",
        data: [
          { name: `cargo: ${roadtypeofcargoexp}` },
          { name: `tons: ${roadtonsexp}` },
          { name: `container: ${roadcontainerexp}` },
          { name: `value: ${roadvalueexp}` },
        ],
      },
    ];
    // const stage5Data = {
    //   import: holdImport,
    //   root_id: rootid,
    //   export: holdExport,
    // };

    formloaderSetter(false);
    console.log(holdImport);

    const stage5Data = [
      { name: "sea", value: `IMPORTS`, nestedData: Imports },
      { name: "export", value: `EXPORTS`, nestedData: Exports },
    ];

    console.log(stage5Data);

    setpreviewData(stage5Data);
    setshowPreviewForm(true);
  };

  const submitStage5 = async () => {
    formloaderSetter(true);
    let importsea = 0;
    let importair = 0;
    let importroad = 0;
    let exportsea = 0;
    let exportair = 0;
    let exportroad = 0;

    // check for import sea
    if (seatypeofcargo.length > 0) {
      importsea = importsea + 1;
    }
    if (seatons.length > 0) {
      importsea = importsea + 1;
    }
    if (seavalue.length > 0) {
      importsea = importsea + 1;
    }
    if (seacontainer.length > 0) {
      importsea = importsea + 1;
    }

    // check for import air
    if (airtypeofcargo.length > 0) {
      importair = importair + 1;
    }
    if (airtons.length > 0) {
      importair = importair + 1;
    }
    if (airvalue.length > 0) {
      importair = importair + 1;
    }
    if (aircontainer.length > 0) {
      importair = importair + 1;
    }

    // check for import road
    if (roadtypeofcargo.length > 0) {
      importroad = importroad + 1;
    }
    if (roadtons.length > 0) {
      importroad = importroad + 1;
    }
    if (roadvalue.length > 0) {
      importroad = importroad + 1;
    }
    if (roadcontainer.length > 0) {
      importroad = importroad + 1;
    }

    // Export starts here
    // check for import sea
    if (seatypeofcargoexp.length > 0) {
      exportsea = exportsea + 1;
    }
    if (seatonsexp.length > 0) {
      exportsea = exportsea + 1;
    }
    if (seavalueexp.length > 0) {
      exportsea = exportsea + 1;
    }
    if (seacontainerexp.length > 0) {
      exportsea = exportsea + 1;
    }

    // check for import air
    if (airtypeofcargoexp.length > 0) {
      exportair = exportair + 1;
    }
    if (airtonsexp.length > 0) {
      exportair = exportair + 1;
    }
    if (airvalueexp.length > 0) {
      exportair = exportair + 1;
    }
    if (aircontainerexp.length > 0) {
      exportair = exportair + 1;
    }

    // check for import road
    if (roadtypeofcargoexp.length > 0) {
      exportroad = exportroad + 1;
    }
    if (roadtonsexp.length > 0) {
      exportroad = exportroad + 1;
    }
    if (roadvalueexp.length > 0) {
      exportroad = exportroad + 1;
    }
    if (roadcontainerexp.length > 0) {
      exportroad = exportroad + 1;
    }

    // check for import sea and return false

    if (importsea > 0 && importsea !== 4) {
      if (seatypeofcargo.length < 1) {
        setSeatypeofcargovalidator(false);
      }
      if (seatons.length < 1) {
        setSeatonsvalidator(false);
      }
      if (seavalue.length < 1) {
        setSeavaluevalidator(false);
      }
      if (seacontainer.length < 1) {
        setSeacontainervalidator(false);
      }

      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter(
        "Please note that all input related to importation by Sea is require, Note : if you dont have all value available, then clear the inputs you have already filled "
      );
      return false;
    } else {
      setSeatypeofcargovalidator(true);
      setSeatonsvalidator(true);
      setSeavaluevalidator(true);
      setSeacontainervalidator(true);
    }

    // check for import air and return false

    if (importair > 0 && importair !== 4) {
      if (airtypeofcargo.length < 1) {
        setAirtypeofcargovalidator(false);
      }
      if (airtons.length < 1) {
        setAirtonsvalidator(false);
      }
      if (airvalue.length < 1) {
        setAirvaluevalidator(false);
      }
      if (aircontainer.length < 1) {
        setAircontainervalidator(false);
      }

      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter(
        "Please note that all input related to importation by Air is require, Note : if you dont have all value available, then clear the inputs you have already filled "
      );
      return false;
    } else {
      setAirtypeofcargovalidator(true);
      setAirtonsvalidator(true);
      setAirvaluevalidator(true);
      setAircontainervalidator(true);
    }

    // check for import air and return false

    if (importroad > 0 && importroad !== 4) {
      if (roadtypeofcargo.length < 1) {
        setRoadtypeofcargovalidator(false);
      }
      if (roadtons.length < 1) {
        setRoadtonsvalidator(false);
      }
      if (roadvalue.length < 1) {
        setRoadvaluevalidator(false);
      }
      if (roadcontainer.length < 1) {
        setRoadcontainervalidator(false);
      }

      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter(
        "Please note that all input related to importation by Road is require, Note : if you dont have all value available, then clear the inputs you have already filled "
      );
      return false;
    } else {
      setRoadtypeofcargovalidator(true);
      setRoadtonsvalidator(true);
      setRoadvaluevalidator(true);
      setRoadcontainervalidator(true);
    }

    // check for export sea and return false
    if (exportsea > 0 && exportsea !== 4) {
      if (seatypeofcargoexp.length < 1) {
        setSeatypeofcargoexpvalidator(false);
      }
      if (seatonsexp.length < 1) {
        setSeatonsexpvalidator(false);
      }
      if (seavalueexp.length < 1) {
        setSeavalueexpvalidator(false);
      }
      if (seacontainerexp.length < 1) {
        setSeacontainerexpvalidator(false);
      }

      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter(
        "Please note that all input related to exportation by Sea is require, Note : if you dont have all value available, then clear the inputs you have already filled "
      );
      return false;
    } else {
      setSeatypeofcargoexpvalidator(true);
      setSeatonsexpvalidator(true);
      setSeavalueexpvalidator(true);
      setSeacontainerexpvalidator(true);
    }

    if (exportair > 0 && exportair !== 4) {
      if (airtypeofcargoexp.length < 1) {
        setAirtypeofcargoexpvalidator(false);
      }
      if (airtonsexp.length < 1) {
        setAirtonsexpvalidator(false);
      }
      if (airvalueexp.length < 1) {
        setAirvalueexpvalidator(false);
      }
      if (aircontainerexp.length < 1) {
        setAircontainerexpvalidator(false);
      }

      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter(
        "Please note that all input related to exportation by Air is require, Note : if you dont have all value available, then clear the inputs you have already filled "
      );
      return false;
    } else {
      setAirtypeofcargoexpvalidator(true);
      setAirtonsexpvalidator(true);
      setAirvalueexpvalidator(true);
      setAircontainerexpvalidator(true);
    }

    if (exportroad > 0 && exportroad !== 4) {
      if (roadtypeofcargoexp.length < 1) {
        setRoadtypeofcargoexpvalidator(false);
      }
      if (roadtonsexp.length < 1) {
        setRoadtonsexpvalidator(false);
      }
      if (roadvalueexp.length < 1) {
        setRoadvalueexpvalidator(false);
      }
      if (roadcontainerexp.length < 1) {
        setRoadcontainerexpvalidator(false);
      }

      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter(
        "Please note that all input related to exportation by Road is require, Note : if you dont have all value available, then clear the inputs you have already filled "
      );
      return false;
    } else {
      setRoadtypeofcargoexpvalidator(true);
      setRoadtonsexpvalidator(true);
      setRoadvalueexpvalidator(true);
      setRoadcontainerexpvalidator(true);
    }

    let holdImport = [
      {
        type: "sea",
        cargo: seatypeofcargo,
        tons: seatons,
        container: seacontainer,
        value: seavalue,
      },
      {
        type: "air",
        cargo: airtypeofcargo,
        tons: airtons,
        container: aircontainer,
        value: airvalue,
      },
      {
        type: "road",
        cargo: roadtypeofcargo,
        tons: roadtons,
        container: roadcontainer,
        value: roadvalue,
      },
    ];
    let holdExport = [
      {
        type: "sea",
        cargo: seatypeofcargoexp,
        tons: seatonsexp,
        container: seacontainerexp,
        value: seavalueexp,
      },
      {
        type: "air",
        cargo: airtypeofcargoexp,
        tons: airtonsexp,
        container: aircontainerexp,
        value: airvalueexp,
      },
      {
        type: "road",
        cargo: roadtypeofcargoexp,
        tons: roadtonsexp,
        container: roadcontainerexp,
        value: roadvalueexp,
      },
    ];

    const stage5Data = {
      import: holdImport,
      root_id: rootid,
      export: holdExport,
    };

    console.log(stage5Data);

    await submitFormStage5(stage5Data).then((res) => {
      if (res.data.status == 1) {
        stageSetter(res.data.result.application_stage);
        formloaderSetter(false);
      }
      formloaderSetter(false);
    });
  };

  const onSelectSetter = async (value) => {
    console.log("clicked");
    stageSetter(value);
    const response = await getFormStage6(rootid);

    if (response?.data?.result.import.length !== 0 && !formEdit) {
      setpreviousFIlledData(response?.data?.result);
      setserverData(true);
    }
    //load
    // if data set visibilitiy to true and change display
  };

  return (
    <React.Fragment>
      <Modal
        open={showPreviewForm}
        onClose={() => setshowPreviewForm(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
          overflow: "scroll",
          border: "2px solid blue",
          height: "100vh",
          marginTop: "10px",
          // overflowX: 'visible',
          // backgroundColor:'white'
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            height: "maxContent",
            width: window.innerWidth - 50,
            padding: "1rem 2rem",
            border: "2px solid orange",
          }}
        >
          <FormStagePreview
            data={[previewData]}
            formloaderSetter={formloaderSetter}
            formPreviewSetter={formPreviewSetter}
          />
          <Button
            style={{ marginRight: "1rem" }}
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => {
              setshowPreviewForm(false);
              if (!formloader) {
                return submitStage5();
              } else {
                return null;
              }
            }}
          >
            {" "}
            Confirm Save and Next{" "}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              formloaderSetter(false);
              setshowPreviewForm(false);
            }}
          >
            {" "}
            No{" "}
          </Button>
        </div>
      </Modal>
      <AppBar position="absolute" color="#ffffff" className={classes.appBar}>
        <Toolbar className={classes.layout}>
          <img
            src={oilandgaslogo}
            alt="Logo"
            style={{
              width: "80px",
              height: "60px",
              textAlign: "center",
              alignSelf: "center",
            }}
          />
        </Toolbar>
      </AppBar>

      <Grid container component="main" className={classes.layout}>
        <Menutab
          onSelectSetter={onSelectSetter}
          whichapplication={whichapplication}
          stage={stage}
        />
        {!serverData ? (
          <>
            <Grid item xs={12} style={{ marginBottom: 25 }}>
              <h4> 9. Cargo Movement</h4>
              <Box component="span">
                Please indicate the projected cargo movement from your project:
              </Box>
            </Grid>

            <Grid container xs={12} spacing={1}>
              <Grid item xs={12}>
                <TableContainer>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Estimate of imports: </TableCell>
                        <TableCell style={{ backgroundColor: "#fff" }}>
                          Type of Cargo
                        </TableCell>
                        <TableCell style={{ backgroundColor: "#fff" }}>
                          Tons
                        </TableCell>
                        <TableCell style={{ backgroundColor: "#fff" }}>
                          Container
                        </TableCell>
                        <TableCell style={{ backgroundColor: "#fff" }}>
                          Value
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody component={Paper}>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Through Sea Port
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Type of Cargo"
                            value={seatypeofcargo}
                            margin="normal"
                            onChange={seatypeofcargoOnchange}
                            InputLabelProps={{
                              shrink: true,
                              className: !seatypeofcargovalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !seatypeofcargovalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !seatypeofcargovalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Tons"
                            value={seatons}
                            onChange={seatonsOnchange}
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                              className: !seatonsvalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !seatonsvalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !seatonsvalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Container"
                            value={seacontainer}
                            margin="normal"
                            onChange={seacontainerOnchange}
                            InputLabelProps={{
                              shrink: true,
                              className: !seacontainervalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !seacontainervalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !seacontainervalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Value"
                            value={seavalue}
                            margin="normal"
                            onChange={seavalueOnchange}
                            InputLabelProps={{
                              shrink: true,
                              className: !seavaluevalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !seavaluevalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !seavaluevalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell component="th" scope="row">
                          By Air
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Cago Type"
                            fullWidth
                            margin="normal"
                            value={airtypeofcargo}
                            onChange={airtypeofcargoOnchange}
                            InputLabelProps={{
                              shrink: true,
                              className: !airtypeofcargovalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !airtypeofcargovalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !airtypeofcargovalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Tons"
                            fullWidth
                            margin="normal"
                            value={airtons}
                            onChange={airtonsOnchange}
                            InputLabelProps={{
                              shrink: true,
                              className: !airtonsvalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !airtonsvalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !airtonsvalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Container"
                            fullWidth
                            margin="normal"
                            value={aircontainer}
                            onChange={aircontainerOnchange}
                            InputLabelProps={{
                              shrink: true,
                              className: !aircontainervalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !aircontainervalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !aircontainervalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Value"
                            fullWidth
                            margin="normal"
                            value={airvalue}
                            onChange={airvalueOnchange}
                            InputLabelProps={{
                              shrink: true,
                              className: !airvaluevalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !airvaluevalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !airvaluevalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell component="th" scope="row">
                          By Road
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Cargo Type"
                            fullWidth
                            margin="normal"
                            value={roadtypeofcargo}
                            onChange={roadtypeofcargoOnchange}
                            InputLabelProps={{
                              shrink: true,
                              className: !roadtypeofcargovalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !roadtypeofcargovalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !roadtypeofcargovalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Tons"
                            fullWidth
                            margin="normal"
                            value={roadtons}
                            onChange={roadtonsOnchange}
                            InputLabelProps={{
                              shrink: true,
                              className: !roadtonsvalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !roadtonsvalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !roadtonsvalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Container"
                            fullWidth
                            margin="normal"
                            value={roadcontainer}
                            onChange={roadcontainerOnchange}
                            InputLabelProps={{
                              shrink: true,
                              className: !roadcontainervalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !roadcontainervalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !roadcontainervalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Value"
                            fullWidth
                            margin="normal"
                            value={roadvalue}
                            onChange={roadvalueOnchange}
                            InputLabelProps={{
                              shrink: true,
                              className: !roadvaluevalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !roadvaluevalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !roadvaluevalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12} style={{ marginTop: 25 }}>
                <TableContainer>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Estimate of Export: </TableCell>
                        <TableCell style={{ backgroundColor: "#fff" }}>
                          Type of Cargo
                        </TableCell>
                        <TableCell style={{ backgroundColor: "#fff" }}>
                          Tons
                        </TableCell>
                        <TableCell style={{ backgroundColor: "#fff" }}>
                          Container
                        </TableCell>
                        <TableCell style={{ backgroundColor: "#fff" }}>
                          Value
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody component={Paper}>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Through Sea Port
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Cargo Type"
                            margin="normal"
                            value={seatypeofcargoexp}
                            onChange={seatypeofcargoOnchangeexp}
                            InputLabelProps={{
                              shrink: true,
                              className: !seatypeofcargoexpvalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !seatypeofcargoexpvalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !seatypeofcargoexpvalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>

                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Tons"
                            margin="normal"
                            value={seatonsexp}
                            onChange={seatonsOnchangeexp}
                            InputLabelProps={{
                              shrink: true,
                              className: !seatonsexpvalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !seatonsexpvalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !seatonsexpvalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>

                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Container"
                            margin="normal"
                            value={seacontainerexp}
                            onChange={seacontainerOnchangeexp}
                            InputLabelProps={{
                              shrink: true,
                              className: !seacontainerexpvalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !seacontainerexpvalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !seacontainerexpvalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>

                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Value"
                            margin="normal"
                            value={seavalueexp}
                            onChange={seavalueOnchangeexp}
                            InputLabelProps={{
                              shrink: true,
                              className: !seavalueexpvalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !seavalueexpvalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !seavalueexpvalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell component="th" scope="row">
                          By Air
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Cargo Type"
                            fullWidth
                            margin="normal"
                            value={airtypeofcargoexp}
                            onChange={airtypeofcargoOnchangeexp}
                            InputLabelProps={{
                              shrink: true,
                              className: !airtypeofcargoexpvalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !airtypeofcargoexpvalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !airtypeofcargoexpvalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Tons"
                            fullWidth
                            margin="normal"
                            value={airtonsexp}
                            onChange={airtonsOnchangeexp}
                            InputLabelProps={{
                              shrink: true,
                              className: !airtonsexpvalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !airtonsexpvalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !airtonsexpvalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="container"
                            fullWidth
                            margin="normal"
                            value={aircontainerexp}
                            onChange={aircontainerOnchangeexp}
                            InputLabelProps={{
                              shrink: true,
                              className: !aircontainerexpvalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !aircontainerexpvalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !aircontainerexpvalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Value"
                            fullWidth
                            margin="normal"
                            value={airvalueexp}
                            onChange={airvalueOnchangeexp}
                            InputLabelProps={{
                              shrink: true,
                              className: !airvalueexpvalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !airvalueexpvalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !airvalueexpvalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell component="th" scope="row">
                          By Road
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Cargo Type"
                            fullWidth
                            margin="normal"
                            value={roadtypeofcargoexp}
                            onChange={roadtypeofcargoOnchangeexp}
                            InputLabelProps={{
                              shrink: true,
                              className: !roadtypeofcargoexpvalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !roadtypeofcargoexpvalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !roadtypeofcargoexpvalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Tons"
                            fullWidth
                            margin="normal"
                            value={roadtonsexp}
                            onChange={roadtonsOnchangeexp}
                            InputLabelProps={{
                              shrink: true,
                              className: !roadtonsexpvalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !roadtonsexpvalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !roadtonsexpvalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Container"
                            fullWidth
                            margin="normal"
                            value={roadcontainerexp}
                            onChange={roadcontainerOnchangeexp}
                            InputLabelProps={{
                              shrink: true,
                              className: !roadcontainerexpvalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !roadcontainerexpvalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !roadcontainerexpvalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Value"
                            fullWidth
                            margin="normal"
                            value={roadvalueexp}
                            onChange={roadvalueOnchangeexp}
                            InputLabelProps={{
                              shrink: true,
                              className: !roadvalueexpvalidator
                                ? classes.errorstylelabel
                                : null,
                            }}
                            InputProps={{
                              classes: {
                                focused: !roadvalueexpvalidator
                                  ? classes.errorstyle
                                  : null,
                                notchedOutline: !roadvalueexpvalidator
                                  ? classes.errorstyle
                                  : null,
                              },
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              justify="flex-end"
              style={{ marginBottom: "100px", marginTop: 25 }}
            >
              <Box component="span">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: "#060454", height: "30px" }}
                  className={classes.button}
                  onClick={!formloader ? previewStage5 : null}
                  endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                >
                  Preview & Continue{" "}
                  {formloader ? <CircularProgress size={24} /> : null}
                </Button>
              </Box>
              {/* <Box component="span">
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#060454", height: "30px" }}
              className={classes.button}
              onClick={!formloader ? submitStage5 : null}
              endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
            >
              Save & Continue{" "}
              {formloader ? <CircularProgress size={24} /> : null}
            </Button>
          </Box> */}
            </Grid>
          </>
        ) : (
          <div
            style={{
              backgroundColor: "#fff",
              marginTop: 25,
              padding: 30,
              marginBottom: "7rem",
              width: "100%",
            }}
          >
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "20px",
              }}
            >
              {previousFIlledData?.import && (
                <div>
                  <div>
                    <b>IMPORT</b>
                  </div>

                  {previousFIlledData?.import?.map((e, index) => (
                    <>
                      {e.status === 0 && (
                        <>
                          {" "}
                          <Grid xs={12}>
                            <div>
                              <b>Route Type: </b> {e.route_type}
                            </div>
                            <div>
                              <b>Type of Cargo</b>: {e.cargo_type}
                            </div>
                            <div>
                              <b>Tonns</b>: {e.tons}
                            </div>
                            <div>
                              <b>Container</b>: {e.container}
                            </div>
                            <div>
                              <b>Value</b>
                            </div>
                            <input disabled value={e?.value} />
                          </Grid>
                        </>
                      )}
                    </>
                  ))}
                </div>
              )}
              {previousFIlledData?.import && (
                <div>
                  <div>
                    <b>EXPORT</b>
                  </div>

                  {previousFIlledData?.export?.map((e, index) => (
                    <>
                      {e.status === 0 && (
                        <>
                          {" "}
                          <Grid xs={12}>
                            <div>
                              <b>Route Type: </b> {e.route_type}
                            </div>
                            <div>
                              <b>Type of Cargo</b>: {e.cargo_type}
                            </div>
                            <div>
                              <b>Tonns</b>: {e.tons}
                            </div>
                            <div>
                              <b>Container</b>: {e.container}
                            </div>
                            <div>
                              <b>Value</b>
                            </div>
                            <input disabled value={e?.value} />
                          </Grid>
                        </>
                      )}
                    </>
                  ))}
                </div>
              )}
            </Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                // onClick={!formloader ? previewStageData : null}
                style={{ backgroundColor: "#060454", height: "30px" }}
                className={classes.button}
                endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                onClick={() => {
                  setformEdit(true);
                  setserverData(false);
                }}
              >
                Refill Form
              </Button>
            </Grid>
          </div>
        )}
      </Grid>
      <AppBar position="fixed" className={classes.appBarButom} color="#060454">
        <Typography
          variant="span"
          color="inherit"
          noWrap
          style={{ textAlign: "center", color: "#fff", alignItems: "center" }}
          justify="center"
        >
          Copyright © 2022 <b>Oil and Gas Free Zones Authority</b>. All Rights Reserved.
        </Typography>
      </AppBar>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    position: "relative",
  },
  checkboxLabel: {
    fontSize: 14,
  },
  appBarButom: {
    top: "auto",
    bottom: 0,
    height: "40px",
    backgroundColor: "#060454",
    paddingTop: "5px",
    paddingButtom: "5px",
    alignItems: "center",
    justifyContent: "center",
  },
  errorstyle: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "60%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

export default Applicationform5;
