import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LockOutlineIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import oilandgaslogo from "../../assets/oilandgaslogo.png";
import Emailstep1 from "../../components/Emailstep1";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import Menutab from './Menutab';
import Menutab from "../../components/Tabs/Menutab";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { submitRequiredStageForm } from "../../Services/apiServices/Form";

document.body.style = "background: #f5f5f5;";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "13px",
      },
    },
  },
})(TextField);

function RequiredDocument(props) {
  const {
    snakBarSetter,
    stageSetter,
    errorSetter,
    formloaderSetter,
    rootid,
    formloader,
    stage,
    whichapplication,
  } = props;

  const [auditAccount, setAuditAccount] = useState("");
  const [whtRemitance, setWhtRemitance] = useState("");
  const [taxReturns, setTaxReturns] = useState("");
  const [localStaffPaye, setLocalStaffPaye] = useState("");
  const [expatriate, setExpatriate] = useState("");
  const [newEmploye, setNewEmploye] = useState("");
  const [staffProfile, setStaffProfile] = useState("");
  const [landRegistrationCharge, setLandRegistrationChange] = useState("");
  const [skillsAcquired, setSkillsAcquired] = useState("");
  const [fzVehicle, setFzVehicle] = useState("");
  const [ozfzaAdminCharge, setOzfzaAdminCharge] = useState("");
  const [freeZoneFee, setFreeZoneFee] = useState("");
  const [leaseAgreement, setLeaseAgreement] = useState("");
  const [contractAgreement, setContractAgreement] = useState("");
  const [hseComplieanceFee, setHseComplieanceFee] = useState("");
  const [envioromentalServiceProvider, setEnvioromentalServiceProvider] =
    useState("");
  const [volumeOfCapital, setVolumeOfCapital] = useState("");
  const [valueOfCapital, setValueOfCapital] = useState("");
  const [additionalCapital, setAdditionalCapital] = useState("");
  const [certOfCapImortance, setCertOfCapImortance] = useState("");
  const [landSpace, setLandSpace] = useState("");
  const [wareHouse, setWareHouse] = useState("");
  const [office, setOffice] = useState("");
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [telephone, setTelephone] = useState("");
  const [dateCreated, setDateCreated] = useState("");
  const [directorSig, setDirectorSig] = useState("");
  const [secretarySig, setSecretarySig] = useState("");

  const requiredDocumentSubmit = async () => {
    const data = new FormData();
    data.append("renewal_id", rootid);
    data.append("auditAccount", auditAccount[0]);
    data.append("whtRemitance", whtRemitance[0]);
    data.append("taxReturns", taxReturns[0]);
    data.append("localStaffPaye", localStaffPaye[0]);
    data.append("expatriate", expatriate[0]);
    data.append("newEmploye", newEmploye[0]);
    data.append("staffProfile", staffProfile[0]);
    data.append("landRegistrationCharge", landRegistrationCharge[0]);
    data.append("skillsAcquired", skillsAcquired[0]);
    data.append("fzVehicle", fzVehicle[0]);
    data.append("ozfzaAdminCharge", ozfzaAdminCharge[0]);
    data.append("freeZoneFee", freeZoneFee[0]);
    data.append("leaseAgreement", leaseAgreement[0]);
    data.append("contractAgreement", contractAgreement[0]);
    data.append("hseComplieanceFee", hseComplieanceFee[0]);
    data.append(
      "envioromentalServiceProvider",
      envioromentalServiceProvider[0]
    );
    data.append("volumeOfCapital", volumeOfCapital[0]);
    data.append("certOfCapImortance", certOfCapImortance[0]);
    data.append("valueOfCapital", valueOfCapital);
    data.append("additionalCapital", additionalCapital);
    data.append("wareHouse", wareHouse);
    data.append("landSpace", landSpace);
    data.append("name", name);
    data.append("designation", designation);
    data.append("telephone", telephone);
    data.append("dateCreated", dateCreated);
    data.append("directorSig", directorSig[0]);
    data.append("secretarySig", secretarySig[0]);

    formloaderSetter(true);

    // const res = await 
    await submitRequiredStageForm(data)
      .then((res) => {
        formloaderSetter(false);
        if (res?.data?.status == 1) {
          stageSetter(res?.data?.result?.application_stage);
          // stageSetter(6);
        }
        console.log(res.data);
      })
      .catch((error) => {
        formloaderSetter(false);
      });

    // console.log(res);
    // await axios
    //   .post(
    //     "https://ogfzaapi.skillzserver.com/aplicationrenewalrequireddocs/create",
    //     value
    //   )
    //   .then((res) => {
    //     formloaderSetter(false);
    //     if (res?.data?.status == 1) {
    //       stageSetter(res?.data?.result?.application_stage);
    //     }
    //     console.log(res.data);
    //   })
    //   .catch((error) => {
    //     formloaderSetter(false);
    //   });
  };

  const auditAccountChange = (value) => {
    console.log(value);
    setAuditAccount(value.target.files);
  };
  const whtRemitanceChange = (value) => {
    console.log(value);
    setWhtRemitance(value.target.files);
  };
  const taxReturnsChange = (value) => {
    setTaxReturns(value.target.files);
  };
  const localStaffPayeChange = (value) => {
    setLocalStaffPaye(value.target.files);
  };
  const expatriateChange = (value) => {
    setExpatriate(value.target.files);
  };
  const newEmployeChange = (value) => {
    setNewEmploye(value.target.files);
  };
  const staffProfileChange = (value) => {
    setStaffProfile(value.target.files);
  };
  const landRegistrationChargeChange = (value) => {
    setLandRegistrationChange(value.target.files);
  };
  const skillsAcquiredChange = (value) => {
    setSkillsAcquired(value.target.files);
  };
  const fzVehicleChange = (value) => {
    setFzVehicle(value.target.files);
  };
  const ozfzaAdminChargeChange = (value) => {
    setOzfzaAdminCharge(value.target.files);
  };
  const freeZoneFeeChange = (value) => {
    setFreeZoneFee(value.target.files);
  };
  const leaseAgreementChange = (value) => {
    setLeaseAgreement(value.target.files);
  };
  const contractAgreementChange = (value) => {
    setContractAgreement(value.target.files);
  };
  const hseComplieanceFeeChange = (value) => {
    setHseComplieanceFee(value.target.files);
  };
  const envioromentalServiceProviderChange = (value) => {
    setEnvioromentalServiceProvider(value.target.files);
  };
  const volumeOfCapitalChange = (value) => {
    setVolumeOfCapital(value.target.value);
  };
  const valueOfCapitalChange = (value) => {
    setValueOfCapital(value.target.value);
  };
  const additionalCapitalChange = (value) => {
    setAdditionalCapital(value.target.value);
  };
  const certOfCapImortanceChange = (value) => {
    setCertOfCapImortance(value.target.files);
  };
  const landSpaceChange = (value) => {
    setLandSpace(value.target.value);
  };
  const wareHouseChange = (value) => {
    setWareHouse(value.target.value);
  };
  const officeChange = (value) => {
    setOffice(value.target.value);
  };
  const nameChange = (value) => {
    setName(value.target.value);
  };
  const designationChange = (value) => {
    setDesignation(value.target.value);
  };
  const telephoneChange = (value) => {
    setTelephone(value.target.value);
  };

  const dateCreatedChange = (value) => {
    setDateCreated(value.target.value);
  };
  const directorSigChange = (value) => {
    setDirectorSig(value.target.files);
  };
  const secretarySigChange = (value) => {
    setSecretarySig(value.target.files);
  };

  const onSelectSetter = async (value) => {
    console.log("clicked");
    stageSetter(value);
    // const response = await getFormStage1(rootid);
    // console.log(response, 'djjkdjd')
    // setserverData(true);
    //load
    // if data set visibilitiy to true and change display
  };

  const classes = useStyles();
  return (
    <React.Fragment>
      <AppBar position="absolute" color="#ffffff" className={classes.appBar}>
        <Toolbar className={classes.layout}>
          <img
            src={oilandgaslogo}
            alt="Logo"
            style={{
              width: "80px",
              height: "60px",
              textAlign: "center",
              alignSelf: "center",
            }}
          />
        </Toolbar>
      </AppBar>

      <Grid container component="main" className={classes.layout}>
        {/* <Menutab stage = {stage} /> */}
        <Menutab
          onSelectSetter={onSelectSetter}
          whichapplication={whichapplication}
          stage={stage}
        />

        <Grid item xs={12}>
          <h4></h4>
        </Grid>

        <Grid
          container
          xs={12}
          spacing={0}
          style={{ backgroundColor: "#fff", padding: 20 }}
        >
          <Grid container xs={12} spacing={1}>
            <Grid item xs={12}>
              <h4> Document Upload</h4>
            </Grid>
            <Grid item xs={3}>
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: "none" }}
                id={"a"}
                multiple
                type="file"
                name="renewalcompanyregistrationincome"
                //value={witnessessignature}
                onChange={auditAccountChange}
              />
              <label htmlFor={"a"}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#fff",
                    minHeight: 55,
                    marginTop: 15,
                  }}
                  variant="outlined"
                  component="span"
                  className={
                    auditAccount.length == 0
                      ? classes.errorstyle
                      : classes.uploads
                  }
                >
                  {auditAccount.length > 0
                    ? auditAccount[0].name
                    : "Audited Account"}
                </Button>
              </label>
            </Grid>

            <Grid item xs={3}>
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: "none" }}
                id={"b"}
                multiple
                type="file"
                name="renewalcompanyregistrationliability"
                //value={witnessessignature}
                onChange={whtRemitanceChange}
              />
              <label htmlFor={"b"}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#fff",
                    minHeight: 55,
                    marginTop: 15,
                  }}
                  variant="outlined"
                  component="span"
                  className={
                    whtRemitance.length == 0
                      ? classes.errorstyle
                      : classes.uploads
                  }
                >
                  {whtRemitance.length > 0
                    ? whtRemitance[0].name
                    : "WHT Reminance"}
                </Button>
              </label>
            </Grid>

            <Grid item xs={3}>
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: "none" }}
                id={"c"}
                multiple
                type="file"
                name="renewalcompanyregistrationexternal"
                //value={witnessessignature}
                onChange={taxReturnsChange}
              />
              <label htmlFor={"c"}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#fff",
                    minHeight: 55,
                    marginTop: 15,
                  }}
                  variant="outlined"
                  component="span"
                  className={
                    taxReturns.length == 0
                      ? classes.errorstyle
                      : classes.uploads
                  }
                >
                  {taxReturns.length > 0 ? taxReturns[0].name : "Tax Returns"}
                </Button>
              </label>
            </Grid>

            <Grid item xs={3}>
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: "none" }}
                id={"d"}
                multiple
                type="file"
                name="renewalcompanyregistrationotherincome"
                //value={witnessessignature}
                onChange={localStaffPayeChange}
              />
              <label htmlFor={"d"}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#fff",
                    minHeight: 55,
                    marginTop: 15,
                  }}
                  variant="outlined"
                  component="span"
                  className={
                    localStaffPaye.length == 0
                      ? classes.errorstyle
                      : classes.uploads
                  }
                >
                  {localStaffPaye.length > 0
                    ? localStaffPaye[0].name
                    : "Local Staff Payee"}
                </Button>
              </label>
            </Grid>

            <Grid item xs={3}>
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: "none" }}
                id={"e"}
                multiple
                type="file"
                name="renewalcompanyregistrationincome"
                //value={witnessessignature}
                onChange={expatriateChange}
              />
              <label htmlFor={"e"}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#fff",
                    minHeight: 55,
                    marginTop: 15,
                  }}
                  variant="outlined"
                  component="span"
                  className={
                    expatriate.length == 0
                      ? classes.errorstyle
                      : classes.uploads
                  }
                >
                  {expatriate.length > 0
                    ? expatriate[0].name
                    : "Expatriate Staff"}
                </Button>
              </label>
            </Grid>

            <Grid item xs={3}>
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: "none" }}
                id={"f"}
                multiple
                type="file"
                name="renewalcompanyregistrationliability"
                //value={witnessessignature}
                onChange={newEmployeChange}
              />
              <label htmlFor={"f"}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#fff",
                    minHeight: 55,
                    marginTop: 15,
                  }}
                  variant="outlined"
                  component="span"
                  className={
                    newEmploye.length == 0
                      ? classes.errorstyle
                      : classes.uploads
                  }
                >
                  {newEmploye.length > 0 ? newEmploye[0].name : "New Employee"}
                </Button>
              </label>
            </Grid>

            <Grid item xs={3}>
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: "none" }}
                id={"g"}
                multiple
                type="file"
                name="renewalcompanyregistrationexternal"
                //value={witnessessignature}
                onChange={staffProfileChange}
              />
              <label htmlFor={"g"}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#fff",
                    minHeight: 55,
                    marginTop: 15,
                  }}
                  variant="outlined"
                  component="span"
                  className={
                    staffProfile.length == 0
                      ? classes.errorstyle
                      : classes.uploads
                  }
                >
                  {staffProfile.length > 0
                    ? staffProfile[0].name
                    : "Staff Profile"}
                </Button>
              </label>
            </Grid>

            <Grid item xs={3}>
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: "none" }}
                id={"h"}
                multiple
                type="file"
                name="renewalcompanyregistrationotherincome"
                //value={witnessessignature}
                onChange={landRegistrationChargeChange}
              />
              <label htmlFor={"h"}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#fff",
                    minHeight: 55,
                    marginTop: 15,
                  }}
                  variant="outlined"
                  component="span"
                  className={
                    landRegistrationCharge.length == 0
                      ? classes.errorstyle
                      : classes.uploads
                  }
                >
                  {landRegistrationCharge.length > 0
                    ? landRegistrationCharge[0].name
                    : "Land Registration"}
                </Button>
              </label>
            </Grid>

            <Grid item xs={3}>
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: "none" }}
                id={"i"}
                multiple
                type="file"
                name="renewalcompanyregistrationincome"
                //value={witnessessignature}
                onChange={skillsAcquiredChange}
              />
              <label htmlFor={"i"}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#fff",
                    minHeight: 55,
                    marginTop: 15,
                  }}
                  variant="outlined"
                  component="span"
                  className={
                    skillsAcquired == 0 ? classes.errorstyle : classes.uploads
                  }
                >
                  {skillsAcquired.length > 0
                    ? skillsAcquired[0].name
                    : "Skils Accquired"}
                </Button>
              </label>
            </Grid>

            <Grid item xs={3}>
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: "none" }}
                id={"j"}
                multiple
                type="file"
                name="renewalcompanyregistrationliability"
                //value={witnessessignature}
                onChange={fzVehicleChange}
              />
              <label htmlFor={"j"}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#fff",
                    minHeight: 55,
                    marginTop: 15,
                  }}
                  variant="outlined"
                  component="span"
                  className={
                    fzVehicle.length == 0 ? classes.errorstyle : classes.uploads
                  }
                >
                  {fzVehicle.length > 0
                    ? fzVehicle[0].name
                    : "FZ Vehicle Renewals"}
                </Button>
              </label>
            </Grid>

            <Grid item xs={3}>
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: "none" }}
                id={"k"}
                multiple
                type="file"
                name="renewalcompanyregistrationexternal"
                //value={witnessessignature}
                onChange={ozfzaAdminChargeChange}
              />
              <label htmlFor={"k"}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#fff",
                    minHeight: 55,
                    marginTop: 15,
                  }}
                  variant="outlined"
                  component="span"
                  className={
                    ozfzaAdminCharge == 0 ? classes.errorstyle : classes.uploads
                  }
                >
                  {ozfzaAdminCharge.length > 0
                    ? ozfzaAdminCharge[0].name
                    : "OGFZA Admin Charge"}
                </Button>
              </label>
            </Grid>

            <Grid item xs={3}>
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: "none" }}
                id={"l"}
                multiple
                type="file"
                name="renewalcompanyregistrationotherincome"
                //value={witnessessignature}
                onChange={freeZoneFeeChange}
              />
              <label htmlFor={"l"}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#fff",
                    minHeight: 55,
                    marginTop: 15,
                  }}
                  variant="outlined"
                  component="span"
                  className={
                    freeZoneFee.length == 0
                      ? classes.errorstyle
                      : classes.uploads
                  }
                >
                  {freeZoneFee.length > 0
                    ? freeZoneFee[0].name
                    : " Free Zone Fee"}
                </Button>
              </label>
            </Grid>

            <Grid item xs={3}>
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: "none" }}
                id={"m"}
                multiple
                type="file"
                name="renewalcompanyregistrationincome"
                //value={witnessessignature}
                onChange={leaseAgreementChange}
              />
              <label htmlFor={"m"}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#fff",
                    minHeight: 55,
                    marginTop: 15,
                  }}
                  variant="outlined"
                  component="span"
                  className={
                    leaseAgreement.length == 0
                      ? classes.errorstyle
                      : classes.uploads
                  }
                >
                  {leaseAgreement.length > 0
                    ? leaseAgreement[0].name
                    : " Lease Agreement"}
                </Button>
              </label>
            </Grid>

            <Grid item xs={3}>
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: "none" }}
                id={"n"}
                multiple
                type="file"
                name="renewalcompanyregistrationliability"
                //value={witnessessignature}
                onChange={contractAgreementChange}
              />
              <label htmlFor={"n"}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#fff",
                    minHeight: 55,
                    marginTop: 15,
                  }}
                  variant="outlined"
                  component="span"
                  className={
                    contractAgreement.length == 0
                      ? classes.errorstyle
                      : classes.uploads
                  }
                >
                  {contractAgreement.length > 0
                    ? contractAgreement[0].name
                    : "Contract Agreement"}
                </Button>
              </label>
            </Grid>

            <Grid item xs={3}>
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: "none" }}
                id={"o"}
                multiple
                type="file"
                name="renewalcompanyregistrationexternal"
                //value={witnessessignature}
                onChange={hseComplieanceFeeChange}
              />
              <label htmlFor={"o"}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#fff",
                    minHeight: 55,
                    marginTop: 15,
                  }}
                  variant="outlined"
                  component="span"
                  className={
                    hseComplieanceFee.length == 0
                      ? classes.errorstyle
                      : classes.uploads
                  }
                >
                  {hseComplieanceFee.length > 0
                    ? hseComplieanceFee[0].name
                    : "HSE Compliance Fee"}
                </Button>
              </label>
            </Grid>

            <Grid item xs={3}>
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: "none" }}
                id={"p"}
                multiple
                type="file"
                name="renewalcompanyregistrationotherincome"
                //value={witnessessignature}
                onChange={envioromentalServiceProviderChange}
              />
              <label htmlFor={"p"}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#fff",
                    minHeight: 55,
                    marginTop: 15,
                  }}
                  variant="outlined"
                  component="span"
                  className={
                    envioromentalServiceProvider.length == 0
                      ? classes.errorstyle
                      : classes.uploads
                  }
                >
                  {envioromentalServiceProvider.length > 0
                    ? envioromentalServiceProvider[0].name
                    : "Envioromental Service Provider"}
                </Button>
              </label>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          spacing={1}
          style={{ backgroundColor: "#fff", padding: 20 }}
        >
          <Grid item xs={12}>
            <h4> FOREIGN DIRECT INVESTMENT </h4>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-full-width"
              placeholder="Volume of Initial Capital Importation & Equipment"
              name=""
              fullWidth
              multiline
              value={volumeOfCapital}
              onChange={volumeOfCapitalChange}
              margin="normal"
              style={{ backgroundColor: "#fff" }}
              InputLabelProps={{
                shrink: true,
                //  className: !renewalcompanyregistrationdebenturevalidator ? classes.errorstylelabel:null
              }}
              InputProps={{
                classes: {
                  //    focused: !renewalcompanyregistrationdebenturevalidator ? classes.errorstyle:null,
                  //    notchedOutline:!renewalcompanyregistrationdebenturevalidator ? classes.errorstyle:null,
                },
              }}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="outlined-full-width"
              placeholder="Value of Initial Capital Importation & Equipment"
              name=""
              fullWidth
              multiline
              value={valueOfCapital}
              onChange={valueOfCapitalChange}
              margin="normal"
              style={{ backgroundColor: "#fff" }}
              InputLabelProps={{
                shrink: true,
                //  className: !renewalcompanyregistrationdebenturevalidator ? classes.errorstylelabel:null
              }}
              InputProps={{
                classes: {
                  //    focused: !renewalcompanyregistrationdebenturevalidator ? classes.errorstyle:null,
                  //    notchedOutline:!renewalcompanyregistrationdebenturevalidator ? classes.errorstyle:null,
                },
              }}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="outlined-full-width"
              placeholder="Additional Capital Importation & Equipment"
              name=""
              fullWidth
              multiline
              value={additionalCapital}
              onChange={additionalCapitalChange}
              margin="normal"
              style={{ backgroundColor: "#fff" }}
              InputLabelProps={{
                shrink: true,
                //  className: !renewalcompanyregistrationdebenturevalidator ? classes.errorstylelabel:null
              }}
              InputProps={{
                classes: {
                  //    focused: !renewalcompanyregistrationdebenturevalidator ? classes.errorstyle:null,
                  //    notchedOutline:!renewalcompanyregistrationdebenturevalidator ? classes.errorstyle:null,
                },
              }}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6}>
            <input
              accept="image/*"
              className={classes.input}
              style={{ display: "none" }}
              id={"CertificateofCapitalImportation"}
              type="file"
              name="renewalcompanyregistrationotherincome"
              //value={witnessessignature}
              onChange={certOfCapImortanceChange}
            />
            <label htmlFor={"CertificateofCapitalImportation"}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "#fff",
                  minHeight: 55,
                  marginTop: 15,
                }}
                variant="outlined"
                component="span"
                // className={
                //   certOfCapImortance.length == 0
                //     ? classes.errorstyle
                //     : classes.uploads
                // }

                InputProps={{
                  classes: {
                    focused: !certOfCapImortance ? classes.errorstyle : null,
                    notchedOutline: !certOfCapImortance
                      ? classes.errorstyle
                      : null,
                  },
                }}
              >
                {certOfCapImortance.length > 0
                  ? certOfCapImortance[0].name
                  : "Certificate of Capital Importation"}
              </Button>
            </label>
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          spacing={1}
          style={{ backgroundColor: "#fff", padding: 20, marginTop: "50px" }}
        >
          <h4>SIZE OF OCCUPIED SPACE IN THE ZONE</h4>
          <Grid item xs={12}>
            <TextField
              id="outlined-full-width"
              placeholder="Building Land Space/Stacking Area"
              name=""
              fullWidth
              multiline
              value={landSpace}
              onChange={landSpaceChange}
              margin="normal"
              style={{ backgroundColor: "#fff" }}
              InputLabelProps={{
                shrink: true,
                //  className: !renewalcompanyregistrationdebenturevalidator ? classes.errorstylelabel:null
              }}
              InputProps={{
                classes: {
                  //    focused: !renewalcompanyregistrationdebenturevalidator ? classes.errorstyle:null,
                  //    notchedOutline:!renewalcompanyregistrationdebenturevalidator ? classes.errorstyle:null,
                },
              }}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="outlined-full-width"
              placeholder="Warehouse"
              name=""
              fullWidth
              multiline
              value={wareHouse}
              onChange={wareHouseChange}
              margin="normal"
              style={{ backgroundColor: "#fff" }}
              InputLabelProps={{
                shrink: true,
                //  className: !renewalcompanyregistrationdebenturevalidator ? classes.errorstylelabel:null
              }}
              InputProps={{
                classes: {
                  //    focused: !renewalcompanyregistrationdebenturevalidator ? classes.errorstyle:null,
                  //    notchedOutline:!renewalcompanyregistrationdebenturevalidator ? classes.errorstyle:null,
                },
              }}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="outlined-full-width"
              placeholder="Office Space"
              name=""
              fullWidth
              multiline
              value={office}
              onChange={officeChange}
              margin="normal"
              style={{ backgroundColor: "#fff" }}
              InputLabelProps={{
                shrink: true,
                //  className: !renewalcompanyregistrationdebenturevalidator ? classes.errorstylelabel:null
              }}
              InputProps={{
                classes: {
                  //    focused: !renewalcompanyregistrationdebenturevalidator ? classes.errorstyle:null,
                  //    notchedOutline:!renewalcompanyregistrationdebenturevalidator ? classes.errorstyle:null,
                },
              }}
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          spacing={1}
          style={{ backgroundColor: "#fff", padding: 20, marginTop: "50px" }}
        >
          <h4>Declation of Oath:</h4>
          <Grid item xs={12}></Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-full-width"
              placeholder="Name"
              name=""
              fullWidth
              multiline
              value={name}
              onChange={nameChange}
              margin="normal"
              style={{ backgroundColor: "#fff" }}
              InputLabelProps={{
                shrink: true,
                //  className: !renewalcompanyregistrationdebenturevalidator ? classes.errorstylelabel:null
              }}
              InputProps={{
                classes: {
                  //    focused: !renewalcompanyregistrationdebenturevalidator ? classes.errorstyle:null,
                  //    notchedOutline:!renewalcompanyregistrationdebenturevalidator ? classes.errorstyle:null,
                },
              }}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="outlined-full-width"
              placeholder="Designation"
              name=""
              fullWidth
              multiline
              value={designation}
              onChange={designationChange}
              margin="normal"
              style={{ backgroundColor: "#fff" }}
              InputLabelProps={{
                shrink: true,
                //  className: !renewalcompanyregistrationdebenturevalidator ? classes.errorstylelabel:null
              }}
              InputProps={{
                classes: {
                  //    focused: !renewalcompanyregistrationdebenturevalidator ? classes.errorstyle:null,
                  //    notchedOutline:!renewalcompanyregistrationdebenturevalidator ? classes.errorstyle:null,
                },
              }}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="outlined-full-width"
              placeholder="Telephone"
              name=""
              fullWidth
              value={telephone}
              onChange={telephoneChange}
              margin="normal"
              style={{ backgroundColor: "#fff" }}
              InputLabelProps={{
                shrink: true,
                //  className: !renewalcompanyregistrationdebenturevalidator ? classes.errorstylelabel:null
              }}
              InputProps={{
                classes: {
                  //    focused: !renewalcompanyregistrationdebenturevalidator ? classes.errorstyle:null,
                  //    notchedOutline:!renewalcompanyregistrationdebenturevalidator ? classes.errorstyle:null,
                },
              }}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="outlined-full-width"
              placeholder="Date"
              type="Date"
              label="date"
              name=""
              fullWidth
              value={dateCreated}
              onChange={dateCreatedChange}
              margin="normal"
              style={{ backgroundColor: "#fff" }}
              InputLabelProps={{
                shrink: true,
                //  className: !renewalcompanyregistrationdebenturevalidator ? classes.errorstylelabel:null
              }}
              InputProps={{
                classes: {
                  //    focused: !renewalcompanyregistrationdebenturevalidator ? classes.errorstyle:null,
                  //    notchedOutline:!renewalcompanyregistrationdebenturevalidator ? classes.errorstyle:null,
                },
              }}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6}>
            <input
              accept="image/*"
              className={classes.input}
              style={{ display: "none" }}
              id={"aqws"}
              multiple
              type="file"
              name="renewalcompanyregistrationincome"
              //value={witnessessignature}
              onChange={directorSigChange}
            />
            <label htmlFor={"aqws"}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "#fff",
                  minHeight: 55,
                  marginTop: 15,
                }}
                variant="outlined"
                component="span"
                className={
                  directorSig.length == 0 ? classes.errorstyle : classes.uploads
                }
              >
                {directorSig.length > 0
                  ? directorSig[0].name
                  : "Secretary Signature"}
              </Button>
            </label>
          </Grid>

          <Grid item xs={6}>
            <input
              accept="image/*"
              className={classes.input}
              style={{ display: "none" }}
              id={"aqw"}
              multiple
              type="file"
              name="renewalcompanyregistrationincome"
              //value={witnessessignature}
              onChange={secretarySigChange}
            />
            <label htmlFor={"aqw"}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "#fff",
                  minHeight: 55,
                  marginTop: 15,
                }}
                variant="outlined"
                component="span"
                className={
                  secretarySig.length == 0
                    ? classes.errorstyle
                    : classes.uploads
                }
              >
                {secretarySig.length > 0
                  ? secretarySig[0].name
                  : "Secretary Signature"}
              </Button>
            </label>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        xs={12}
        justify="flex-end"
        style={{ marginBottom: "50px" }}
      >
        <Box component="span">
          <Button
            variant="contained"
            color="primary"
            onClick={!formloader ? requiredDocumentSubmit : null}
            style={{
              backgroundColor: "#060454",
              height: "30px",
              marginTop: "15px",
              marginRight: "255px",
            }}
            className={classes.button}
            endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
          >
            Save & Continue {formloader ? <CircularProgress size={24} /> : null}
          </Button>
        </Box>
      </Grid>

      <AppBar position="fixed" className={classes.appBarButom} color="#060454">
        <Typography
          variant="span"
          color="inherit"
          noWrap
          style={{ textAlign: "center", color: "#fff", alignItems: "center" }}
          justify="center"
        >
          Copyright © 2022 <b>Oil and Gas Free Zones Authority</b>. All Rights Reserved.
        </Typography>
      </AppBar>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    position: "relative",
  },
  checkboxLabel: {
    fontSize: 14,
  },
  errorstyle: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
  uploads: {
    borderColor: "green !important",
  },
  appBarButom: {
    top: "auto",
    bottom: 0,
    height: "40px",
    backgroundColor: "#060454",
    paddingTop: "5px",
    paddingButtom: "5px",
    alignItems: "center",
    justifyContent: "center",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "60%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

export default RequiredDocument;
