import { TextField } from "@material-ui/core";
import axios from "axios";
import React, { useState, useEffect } from "react";
import RemitaPayment from "react-remita";
import {
  confirmApplicantPayment,
  getApplicantDetailsPayment,
} from "../../Services/apiServices/Form";
import "./Remita.css";

export default function RemitaPaymentApp(props) {
  console.log(props);
  const {
    email,
    snakBarSetter,
    stageSetter,
    errorSetter,
    formloaderSetter,
    rootid,
    formloader,
    paymentFormSetter,
  } = props;

  const [error, setError] = useState();
  const [applicatDetails, setapplicatDetails] = useState();
  const [paymentData, setpaymentData] = useState({
    key: "REVNT05UR0lGVHw0MDgyNTIxNHwxZTI1NGNlNTVhMzkyYTgxYjYyNjQ2ZWIwNWU0YWE4ZTNjOTU0ZWFlODllZGEwMTUwMjYyMTk2ZmFmOGMzNWE5ZGVjYmU3Y2JkOGI5ZWI5YzFmZWMwYTI3MGI5MzA0N2FjZWEzZDhiZjUwNDY5YjVjOGY3M2NhYjQzMTg3NzI4Mg==", // enter your key here
    customerId: "140700251",
    firstName: `${applicatDetails?.first_name}`,
    lastName: "Spark",
    email: "demo@remita.net",
    amount: 5000,
    narration: "good gut",
  });

  useEffect(() => {
    getApplicantDetails(rootid);
  }, []);

  const getApplicantDetails = async (rootid) => {
    try {
      const res = await getApplicantDetailsPayment(rootid);
      console.log(res);
      console.log(res?.result);
      setapplicatDetails(res?.data?.result);
    } catch (err) {
      console.log(err);
    }
  };

  const onClickPaymentButton = async (value) => {
    // formloaderSetter(true);
    paymentFormSetter(true);
    // axios
    //   .post(
    //     "https://ogfzaapi.skillzserver.com/aplicationprocessingfee/create",
    //     value
    //   )
    //   .then((res) => {
    //     console.log(res.data);
    //     if (res.data.status == 1) {
    //       stageSetter(res.data.result.application_stage);
    //       paymentFormSetter(res.data.result.processing_fee_status);
    //       formloaderSetter(false);
    //     }
    //     formloaderSetter(false);
    //   });
  };
  console.log(paymentData);
  let data = {
    ...paymentData,
    onSuccess: async function (response) {
      // function callback when payment is successful
      try {
        const res = await confirmApplicantPayment(
          rootid,
          response?.transactionId,
          response?.paymentReference
        );
        console.log(res);
        onClickPaymentButton();
      } catch (err) {
        onClickPaymentButton();
        console.log(err);
      }
    },
    onError: function (response) {
      // function callback when payment fails
      console.log("callback Error Response", response);
      setError("Payment Error");
    },
    onClose: function () {
      // function callback when payment modal is closed
      console.log("closed");
      // onClickPaymentButton();
      setError("Payment Aborted");
      // formloaderSetter(true);
      // stageSetter(1);
      // // paymentPageSetter(1);
      // formloaderSetter(false);
    },
  };

  ///////////////////////////

  const [selected, setselected] = useState();

  const countries = [
    { id: "1", name: "Registration/Processiing" },
    { id: "2", name: "Licensing of Special Activities" },
  ];

  const states = [
    {
      id: "1",
      countryId: "1",
      name: "EnterPrise",
      registration: "25 USD",
      renewal: "5USD",
    },
    {
      id: "2",
      countryId: "1",
      name: "Zones and Sub-Zones",
      registration: "25 USD",
      renewal: "5USD",
    },
    {
      id: "3",
      countryId: "1",
      name: "Free Zone Developer(s)",
      registration: "25 USD",
      renewal: "5USD",
    },
    {
      id: "4",
      countryId: "1",
      name: "Extension",
      registration: "25 USD",
      renewal: "5USD",
    },
    {
      id: "5",
      countryId: "1",
      name: "75% Duty Rebate Licence",
      registration: "25 USD",
      renewal: "5USD",
    },
    {
      id: "6",
      countryId: "2",
      name: "Enterprises",
      registration: "25 USD",
      renewal: "5USD",
    },
    {
      id: "7",
      countryId: "2",
      name: "Refineries",
      registration: "25 USD",
      renewal: "5USD",
    },
    {
      id: "8",
      countryId: "2",
      name: "Petrochemicals",
      registration: "25 USD",
      renewal: "5USD",
    },
    {
      id: "9",
      countryId: "2",
      name: "Assembly Plants",
      registration: "25 USD",
      renewal: "5USD",
    },
    {
      id: "10",
      countryId: "2",
      name: "Independent Power Plants",
      registration: "25 USD",
      renewal: "5USD",
    },
    {
      id: "11",
      countryId: "2",
      name: "Fertilizer Plant",
      registration: "25 USD",
      renewal: "5USD",
    },
    {
      id: "13",
      countryId: "2",
      name: "Tank Farm",
      registration: "25 USD",
      renewal: "5USD",
    },
    {
      id: "14",
      countryId: "2",
      name: "Any Other special Activity",
      registration: "25 USD",
      renewal: "5USD",
    },
  ];

  const cities = [
    { id: "1", stateId: "1", name: "Faridabad" },
    { id: "2", stateId: "1", name: "Palwal" },
    { id: "3", stateId: "2", name: "Mandi House" },
    { id: "4", stateId: "2", name: "kalka Ji" },
    { id: "1", stateId: "3", name: "Houston" },
    { id: "2", stateId: "3", name: "Austin" },
    { id: "3", stateId: "4", name: "Los Angeles" },
    { id: "4", stateId: "4", name: "Son Diego" },
  ];

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);

  useEffect(() => {
    setCountry(countries);
  }, []);

  const handleCountry = (id) => {
    const dt = states.filter((x) => x.countryId === id);
    setState(dt);
  };

  const handleState = (id) => {
    const selectedType = states.filter((state) => state.id === id);
    console.log(selectedType);
    setselected(selectedType[0]);
    console.log(id);
    const dt = cities.filter((x) => x.stateId === id);
    console.log(dt);
    setCity(dt);
  };

  return (
    <div className="App">
      <div className="container">
        <div style={{ fontSize: "17px", fontWeight: "700", color: "white" }}>
          For your application to be processed, you have to pay a sum of{" "}
          {applicatDetails?.ammount}
        </div>
        <p style={{ color: "white" }}>Pay with remita example</p>
        <div style={{ color: "red" }}>
          <b>{error}</b>{" "}
        </div>

      

        <input
          type="text"
          value={`${applicatDetails?.first_name}`}
          disabled
          placeholder="firstname"
          onChange={(e) =>
            setpaymentData({ ...paymentData, firstName: e.target.value })
          }
        />
        <input
          disabled
          type="text"
          value={`${applicatDetails?.last_name}`}
          placeholder="lastname"
          onChange={(e) =>
            setpaymentData({ ...paymentData, lastName: e.target.value })
          }
        />
        <input
          disabled
          type="text"
          value={`${applicatDetails?.email}`}
          placeholder="email"
          onChange={(e) => {
            if (
              /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                e.target.value
              )
            ) {
              setError("Enter A Valid Mail");
            } else {
              setpaymentData({ ...paymentData, email: e.target.value });
            }
          }}
        />
        <input
          disabled
          type="number"
          value={`${applicatDetails?.ammount}`}
          placeholder="amount"
          onChange={(e) =>
            setpaymentData({ ...paymentData, amount: e.target.value })
          }
        />
        <input
          disabled
          type="text"
          value="application processing fee"
          placeholder="description(optional)"
          onChange={(e) =>
            setpaymentData({ ...paymentData, narration: e.target.value })
          }
        />
        <RemitaPayment
          remitaData={data}
          className="btn" // class to style the button
          text="Proceed Payment with Remita" //text to show on button
          // add a 'live' prop to use the live urls/keys
        />
      </div>
    </div>
  );
}
