import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ClearIcon from "@material-ui/icons/Clear";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
// import Approvalbuttons from '../../Approvalbuttons';
import Approvalbuttons from "../../components/Button/Approvalbuttons";
import Listskeleton from "../../components/Listskeleton";
import Typography from "@material-ui/core/Typography";

function generate(element) {
  return [0, 1, 2, 3, 3, 3, 3].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  );
}

function Applicantlist(props) {
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  //const [dense, setDense] = React.useState(false);

  useEffect(() => {
    // user this to pass new value
    props.getApplicantListInit();
  }, [props.dense2]);
  const getStatus = (value) => {
    if (value.approval_stage == 0 && value.approval_stage_countter == 34) {
      return (
        <Typography
          style={{ color: "#060454", paddingBottom: 30, fontSize: 10 }}
          variant="h6"
        >
          Awaiting REGISTRY Approval
        </Typography>
      );
    } else if (value.approval_stage == 1 && value.approval_stage_decline == 0) {
      return (
        <Typography
          style={{ color: "pink", paddingBottom: 30, fontSize: 10 }}
          variant="h6"
        >
          Awaiting Registry HOD Approval
        </Typography>
      );
    } else if (value.approval_stage == 2 && value.approval_stage_decline == 0) {
      return (
        <Typography
          style={{ color: "purple", paddingBottom: 30, fontSize: 10 }}
          variant="h6"
        >
          Awaiting CEO Approval
        </Typography>
      );
    } else if (value.approval_stage == 3 && value.approval_stage_decline == 0) {
      return (
        <Typography
          style={{ color: "skyblue", paddingBottom: 30, fontSize: 10 }}
          variant="h6"
        >
          Awaiting Invoicing Unit Approval
        </Typography>
      );
    } else if (value.approval_stage == 4 && value.approval_stage_decline == 0) {
      return (
        <Typography
          style={{ color: "yellow", paddingBottom: 30, fontSize: 10 }}
          variant="h6"
        >
          Awaiting issue of Licence
        </Typography>
      );
    } else if (value.approval_stage == 5 && value.approval_stage_decline == 0) {
      return (
        <Typography
          style={{ color: "green", paddingBottom: 30, fontSize: 10 }}
          variant="h6"
        >
          APPROVED
        </Typography>
      );
    } else if (value.approval_stage_decline > 0) {
      return (
        <Typography
          style={{ color: "red", paddingBottom: 30, fontSize: 10 }}
          variant="h6"
        >
          DECLINED
        </Typography>
      );
    } else if (
      value.approval_stage == 0 &&
      value.approval_stage_countter > 0 &&
      value.approval_stage_countter != 34
    ) {
      return (
        <Typography
          style={{ color: "orange", paddingBottom: 30, fontSize: 10 }}
          variant="h6"
        >
          SCREENNING in progress
        </Typography>
      );
    } else if (
      value.approval_stage == 0 &&
      value.approval_stage_countter > 0 &&
      value.approval_stage_countter != 34
    ) {
      return (
        <Typography
          style={{ color: "orange", paddingBottom: 30, fontSize: 10 }}
          variant="h6"
        >
          SCREENNING in progress
        </Typography>
      );
    } else if (
      value.approval_stage == 0 &&
      value.approval_stage_countter == 0 &&
      value.approval_stage_countter != 34
    ) {
      return (
        <Typography
          style={{ color: "orange", paddingBottom: 30, fontSize: 10 }}
          variant="h6"
        >
          AWAITING SCREENING
        </Typography>
      );
    } else {
      return (
        <Typography
          style={{ color: "orange", paddingBottom: 30, fontSize: 10 }}
          variant="h6"
        >
          APPROVED
        </Typography>
      );
    }
  };

  console.log(props.applicantlist, "djsdjjksjks");

  const renderSwitch = (value) => {
    switch (value) {
      case 1:
        return (
          <List dense={dense}>
            {props.applicantlist?.applicant
              ? props?.applicantlist?.applicant?.map((value, index) => (
                  <ListItem
                    style={{ cursor: "pointer" }}
                    key={index}
                    onClick={() => {
                      props.getApplicantDetails(value.id);
                    }}
                  >
                    {/* <ListItemAvatar>
                      <PlayCircleOutlineIcon style={{ color: "#0F0E83" }} />
                    </ListItemAvatar> */}
                    <div
                      style={{
                        fontSize: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "10px",
                        paddingRight: "5px",
                      }}
                    >
                      {value?.application_type}
                    </div>
                    <ListItemText
                      primary={value.companyname}
                      secondary={getStatus(value)}
                    />
                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={props.applicantlist.priv}
                        value={value}
                        declineCounter={value.approval_stage_countter_decline}
                        approvalDecline={value.approval_stage_decline}
                        active={props.active}
                        getApplicantDetails={props.getApplicantDetails}
                        approve={props.approve}
                        decline={props.decline}
                        display={1}
                        report={props.report}
                        reportonchange={props.reportonchange}
                        openreport={props.openreport}
                        rootid={props.rootid}
                        declinemodal={props.declinemodal}
                        handleClosedeclinemodal={props.handleClosedeclinemodal}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
              : null}
          </List>
          // <List dense={dense}>
          //   {props.applicantlist?.applicant
          //     ? props?.applicantlist?.applicant?.map((value, index) => (
          //         <>
          //           {console.log(value, "lllllllll")}

          //           {value?.root_applicant ? (
          //             <>
          //               {" "}
          //               <ListItem
          //                 style={{ cursor: "pointer" }}
          //                 key={index}
          //                 onClick={() => {
          //                   props.getApplicantDetails(
          //                     value?.root_applicant?.id
          //                   );
          //                 }}
          //               >
          //                 <ListItemAvatar>
          //                   <PlayCircleOutlineIcon
          //                     style={{ color: "#0F0E83" }}
          //                   />
          //                 </ListItemAvatar>
          //                 <ListItemText
          //                   primary={value?.companyname}
          //                   secondary={getStatus(value)}
          //                 />
          //                 <ListItemSecondaryAction>
          //                   <Approvalbuttons
          //                     priv={props.applicantlist.priv}
          //                     value={value}
          //                     declineCounter={
          //                       value?.root_applicant
          //                         ?.approval_stage_countter_decline
          //                     }
          //                     approvalDecline={
          //                       value?.root_applicant?.approval_stage_decline
          //                     }
          //                     active={props.active}
          //                     getApplicantDetails={props.getApplicantDetails}
          //                     approve={props.approve}
          //                     decline={props.decline}
          //                     display={1}
          //                     report={props.report}
          //                     reportonchange={props.reportonchange}
          //                     openreport={props.openreport}
          //                     rootid={props.rootid}
          //                     declinemodal={props.declinemodal}
          //                     handleClosedeclinemodal={
          //                       props.handleClosedeclinemodal
          //                     }
          //                   />
          //                 </ListItemSecondaryAction>
          //               </ListItem>
          //             </>
          //           ) : (
          //             <List dense={dense}>
          //               {props.applicantlist?.applicant
          //                 ? props?.applicantlist?.applicant?.map(
          //                     (value, index) => (
          //                       <ListItem
          //                         style={{ cursor: "pointer" }}
          //                         key={index}
          //                         onClick={() => {
          //                           props.getApplicantDetails(value.id);
          //                         }}
          //                       >
          //                         {/* <ListItemAvatar>
          //                           <PlayCircleOutlineIcon
          //                             style={{ color: "#0F0E83" }}
          //                           />
          //                         </ListItemAvatar> */}
          //                         {/* <ListItemText > */}
          //                           <div style={{fontSize:'10px', display:'flex', alignItems:'center', justifyContent:'center', marginRight:'10px', paddingRight:'5px'}}>
          //                              {value?.application_type}
          //                           </div>

          //                         {/* </ListItemText> */}
          //                         <ListItemText
          //                           primary={value.companyname}
          //                           secondary={getStatus(value)}
          //                         />
          //                         <ListItemSecondaryAction>
          //                           <Approvalbuttons
          //                             priv={props.applicantlist.priv}
          //                             value={value}
          //                             declineCounter={
          //                               value.approval_stage_countter_decline
          //                             }
          //                             approvalDecline={
          //                               value.approval_stage_decline
          //                             }
          //                             active={props.active}
          //                             getApplicantDetails={
          //                               props.getApplicantDetails
          //                             }
          //                             approve={props.approve}
          //                             decline={props.decline}
          //                             display={1}
          //                             report={props.report}
          //                             reportonchange={props.reportonchange}
          //                             openreport={props.openreport}
          //                             rootid={props.rootid}
          //                             declinemodal={props.declinemodal}
          //                             handleClosedeclinemodal={
          //                               props.handleClosedeclinemodal
          //                             }
          //                           />
          //                         </ListItemSecondaryAction>
          //                       </ListItem>
          //                     )
          //                   )
          //                 : null}
          //             </List>
          //           )}

          //         </>
          //       ))
          //     : null}
          // </List>
        );
      case 2:
        return <h3> No Applicant</h3>;

      default:
        return <Listskeleton />;
    }
  };

  return <React.Fragment>{renderSwitch(props.listloader)}</React.Fragment>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundColor: "#fff",
  },
  emailcontainer: {
    border: 1,
  },
  multilineColor: {
    color: "#fff",
  },
  floatingLabelFocusStyle: {
    color: "#fff",
  },
  image: {
    backgroundImage: "url(../../img/loginillustration.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.type === "light" ? "#fff" : "#fff",
    backgroundSize: "67%",
    backgroundPosition: "center",
  },

  signinFormGrid: {
    boxShadow: "none",
    backgroundColor: "#0F0E83",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#0043ce",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formHolder: {
    width: "60%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#fff",
    borderRadius: "0.4rem",
    color: "#0F0E83",
    width: "100%",
  },
  textfield: {
    borderRadius: "2rem",
  },
  email: {
    width: "60%",
    borderWidth: 2,
  },
}));

export default Applicantlist;
