import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LockOutlineIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import oilandgaslogo from "../../assets/oilandgaslogo.png";
import Emailstep1 from "../../components/Emailstep1";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Menutab from "../../components/Tabs/Menutab";

import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getFormStage1,
  submitFormStage1,
} from "../../Services/apiServices/Form";
import { Modal } from "@material-ui/core";
import FormStagePreview from "../../components/preview/FormStagePreview.jsx";

document.body.style = "background: #f5f5f5;";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "13px",
      },
    },
  },
})(TextField);

function Applicantform1(props) {
  const {
    email,
    snakBarSetter,
    stageSetter,
    errorSetter,
    formloaderSetter,
    rootid,
    formloader,
    whichapplication,
  } = props;
  const [serverData, setserverData] = useState(false);
  const [previousFIlledData, setpreviousFIlledData] = useState();
  const [companyname, setCompanyname] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [fax, setFax] = useState("");
  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState();
  const [contactname, setContactname] = useState("");
  const [phonenolocal, setPhonenolocal] = useState("");
  const [faxlocal, setFaxlocal] = useState("");
  const [websitelocal, setWebsitelocal] = useState("");
  const [addresslocal, setAddresslocal] = useState("");
  const [contactnamelocal, setContactnamelocal] = useState("");
  const [registrationlocation, setRegistrationlocation] = useState(
    "Company Registered In Nigeria"
  );
  const [registerinnigeria, setRegisterinnigeria] = useState("Yes");

  //validators
  const [companyregisteredvalidator, setCompanyregisteredvalidator] =
    useState(true);
  const [companynamevalidator, setCompanynamevalidator] = useState(true);
  const [headofficeaddressvalidator, setHeadofficeaddressvalidator] =
    useState(true);
  const [headofficetellvalidator, setHeadofficetellvalidator] = useState(true);
  const [headofficefaxvalidator, setHeadofficefaxvalidator] = useState(true);
  const [headofficewebsitevalidator, setHeadofficewebsitevalidator] =
    useState(true);
  const [headofficecontactnamevalidator, setHeadofficecontactnamevalidator] =
    useState(true);

  const [ngofficeaddressvalidator, setNgofficeaddressvalidator] =
    useState(true);
  const [ngofficetellvalidator, setNgofficetellvalidator] = useState(true);
  const [ngofficefaxvalidator, setNgofficefaxvalidator] = useState(true);
  const [ngofficewebsitevalidator, setNgofficewebsitevalidator] =
    useState(true);
  const [ngofficecontactnamevalidator, setNgofficecontactnamevalidator] =
    useState(true);

  const [
    intendtoregistercompanyregisteredvalidator,
    setIntendtoregistercompanyregisteredvalidator,
  ] = useState(true);

  const [showPreviewForm, setshowPreviewForm] = useState(false);
  const [stageData, setstageData] = useState();
  const [formEdit, setformEdit] = useState(false)

  const [loading, setloading] = useState(false);
  const companynameOnchange = (e) => {
    setCompanyname(e.target.value);
  };

  const phonenoOnchange = (e) => {
    setPhoneno(e.target.value);
  };

  const faxOnchange = (e) => {
    setFax(e.target.value);
  };
  const websiteOnchange = (e) => {
    setWebsite(e.target.value);
  };

  const formPreviewSetter = (value) => {
    setshowPreviewForm(value);
  };

  const addressOnchange = (e) => {
    setAddress(e.target.value);
  };

  const contactnameOnchange = (e) => {
    setContactname(e.target.value);
  };

  const phonenolocalOnchange = (e) => {
    setPhonenolocal(e.target.value);
  };
  const faxlocalOnchange = (e) => {
    setFaxlocal(e.target.value);
  };

  const websitelocalOnchange = (e) => {
    setWebsitelocal(e.target.value);
  };

  const addresslocalOnchange = (e) => {
    setAddresslocal(e.target.value);
  };

  const contactnamelocalOnchange = (e) => {
    setContactnamelocal(e.target.value);
  };

  const registrationlocationOnchange = (e) => {
    setRegistrationlocation(e.target.value);
  };

  const registerinnigeriaOnchange = (e) => {
    setRegisterinnigeria(e.target.value);
  };

  useEffect(() => {
    async function fetchFormData() {
      setloading(true);
      const response = await getFormStage1(rootid);
      setloading(false);
      if (response?.data?.result?.copanyname  && !formEdit) {
        setpreviousFIlledData(response?.data?.result);

        console.log(response, "djjkdjd");
        setserverData(true);
      }
    }
    fetchFormData();
  }, [serverData]);

  const previewStageData = async () => {
    formloaderSetter(false);
    const companyDetails = [
      // { name: "root_id", value: `${rootid}` },
      { name: "Company name", value: `${companyname}` },
      { name: "Phone no", value: `${phoneno}` },
      { name: "Fax", value: `${fax}` },
      { name: "Website", value: `${website}` },
      { name: "Address", value: `${address}` },
      { name: "Contact name", value: `${contactname}` },
      { name: "Local phone number", value: `${phonenolocal}` },
      { name: "Fax local", value: `${faxlocal}` },
      { name: "Website local", value: `${websitelocal}` },
      { name: "Address local", value: `${addresslocal}` },
      { name: "Contact name local", value: `${contactnamelocal}` },
      { name: "Registration location", value: `${registrationlocation}` },
      { name: "If not registered in Nigeria , is it your intention to register?", value: `${registerinnigeria}` },
    ];

    // formloaderSetter(true);
    if (companyname.length < 1) {
      setCompanynamevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Company Name");
      return false;
    }

    if (phoneno.length !== 11 || isNaN(phoneno) == true) {
      setHeadofficetellvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Phone number");
      return false;
    }

    if (website.length < 1) {
      setHeadofficewebsitevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter a website");
      return false;
    }
    if (address.length < 1) {
      setHeadofficeaddressvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Office Address");
      return false;
    }
    if (contactname.length < 1) {
      setHeadofficecontactnamevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Contact Name");
      return false;
    }
    if (phonenolocal.length !== 11 || isNaN(phonenolocal) == true) {
      setNgofficetellvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Phone number");
      return false;
    }

    if (websitelocal.length < 1) {
      setNgofficewebsitevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Website");
      return false;
    }
    if (addresslocal.length < 1) {
      setNgofficeaddressvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Address In Nigeria");
      return false;
    }
    if (contactnamelocal.lenght < 1) {
      setNgofficecontactnamevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please EnterNigeria Contact Person Name");
      return false;
    }
    if (registrationlocation.length < 1) {
      setCompanyregisteredvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Select Country of Registration");
      return false;
    }
    if (registerinnigeria.length < 1) {
      setIntendtoregistercompanyregisteredvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter(
        "Please Let Us Know If You Would Like To Register In Nigeria"
      );
      return false;
    }

    setstageData(companyDetails);
    setshowPreviewForm(true);
  };

  const onSubmitCompanyDetails = async () => {
    console.log("NAMMMM");
    const companyDetails = {
      root_id: rootid,
      company_name: companyname,
      phone_no: phoneno,
      fax: fax,
      website: website,
      address: address,
      contact_name: contactname,
      phone_no_local: phonenolocal,
      fax_local: faxlocal,
      website_local: websitelocal,
      address_local: addresslocal,
      contact_name_local: contactnamelocal,
      registration_location: registrationlocation,
      register_in_nigeria: registerinnigeria,
    };

    formloaderSetter(true);
    if (companyname.length < 1) {
      setCompanynamevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Company Name");
      return false;
    }

    if (phoneno.length !== 11 || isNaN(phoneno) == true) {
      setHeadofficetellvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Phone number");
      return false;
    }

    if (website.length < 1) {
      setHeadofficewebsitevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter a website");
      return false;
    }
    if (address.length < 1) {
      setHeadofficeaddressvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Office Address");
      return false;
    }
    if (contactname.length < 1) {
      setHeadofficecontactnamevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Contact Name");
      return false;
    }
    if (phonenolocal.length !== 11 || isNaN(phonenolocal) == true) {
      setNgofficetellvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Phone number");
      return false;
    }

    if (websitelocal.length < 1) {
      setNgofficewebsitevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Website");
      return false;
    }
    if (addresslocal.length < 1) {
      setNgofficeaddressvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Address In Nigeria");
      return false;
    }
    if (contactnamelocal.lenght < 1) {
      setNgofficecontactnamevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please EnterNigeria Contact Person Name");
      return false;
    }
    if (registrationlocation.length < 1) {
      setCompanyregisteredvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Select Country of Registration");
      return false;
    }
    if (registerinnigeria.length < 1) {
      setIntendtoregistercompanyregisteredvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter(
        "Please Let Us Know If You Would Like To Register In Nigeria"
      );
      return false;
    }

    // setshowPreviewForm(true)
    setstageData(companyDetails);

    await submitFormStage1(companyDetails).then((res) => {
      if (res.data.status == 1) {
        formloaderSetter(false);
        stageSetter(res.data.result.application_stage);
        console.log("contact_details", companyDetails);
      }
      formloaderSetter(false);
    });
  };

  const onSelectSetter = async (value) => {
    setloading(true);
    console.log("clicked", "form111");
    stageSetter(value);
    const response = await getFormStage1(rootid);
    setloading(false);
    if (response?.data?.result?.companyname && !formEdit) {
      setpreviousFIlledData(response?.data?.result);

      console.log(response, "djjkdjd");
      setserverData(true);
    }

    // setserverData(true);
    //load
    // if data set visibilitiy to true and change display
  };

  const classes = useStyles();
  return (
    <React.Fragment>
      <AppBar position="absolute" color="#ffffff" className={classes.appBar}>
        <Toolbar className={classes.layout}>
          <img
            src={oilandgaslogo}
            alt="Logo"
            style={{
              width: "80px",
              height: "60px",
              textAlign: "center",
              alignSelf: "center",
            }}
          />
        </Toolbar>
      </AppBar>

      <Modal
        open={showPreviewForm}
        onClose={() => setshowPreviewForm(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
          overflow: "scroll",
          border: "2px solid blue",
          height: "100vh",
          marginTop: "10px",
          // overflowX: 'visible',
          // backgroundColor:'white'
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            height: "maxContent",
            width: window.innerWidth - 50,
            padding: "1rem 2rem",
            border: "2px solid orange",
          }}
        >
          <FormStagePreview
            data={[stageData]}
            formloaderSetter={formloaderSetter}
            formPreviewSetter={formPreviewSetter}
          />
          <Button
            style={{ marginRight: "1rem" }}
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => {
              setshowPreviewForm(false);
              if (!formloader) {
                return onSubmitCompanyDetails();
              } else {
                return null;
              }
            }}
          >
            {" "}
            Confirm Save and Next{" "}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              formloaderSetter(false);
              setshowPreviewForm(false);
            }}
          >
            {" "}
            No{" "}
          </Button>
        </div>
      </Modal>

      <Grid container component="main" className={classes.layout}>
        <Grid item xs={12}>
          {/* {returnMenuTab()} */}
          <Menutab
            stage={props.stage}
            whichapplication={whichapplication}
            onSelectSetter={onSelectSetter}
          />

          <h5>
            {" "}
            Please tell us how you would be incorporated, tick the appropriate
            box
          </h5>
        </Grid>
        {loading && serverData && (
          <div>
            <div class="lds-dual-ring"></div>
          </div>
        )}
        {!serverData ? (
          <div>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <Box component="span">
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="gender1"
                    value={props.registrationlocation}
                    onChange={registrationlocationOnchange}
                  >
                    <FormControlLabel
                      value="Company Registered Oversea"
                      control={
                        <Radio
                          style={{ color: "#060454", borderRadius: "80px" }}
                        />
                      }
                      label="Company Registered Oversea"
                      classes={{ label: classes.checkboxLabel }}
                    />
                    <FormControlLabel
                      value="Company Registered In Nigeria"
                      control={
                        <Radio
                          style={{ color: "#060454", borderRadius: "80px" }}
                        />
                      }
                      label="Company Registered In Nigeria"
                      classes={{ label: classes.checkboxLabel }}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <h4> 1. Company Details</h4>
            </Grid>
            <Grid
              container
              xs={12}
              spacing={1}
              style={{ backgroundColor: "#fff", marginTop: 25, padding: 30 }}
            >
              <Grid item xs={12}>
                <TextField
                  id="outlined-full-width"
                  label="Company Registered Name :"
                  value={props.companyname}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    className: !companynamevalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !companynamevalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline: !companynamevalidator
                        ? classes.errorstyle
                        : null,
                    },
                  }}
                  onChange={companynameOnchange}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="outlined-full-width"
                  label="Office Registerd Address :"
                  value={props.address}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    className: !headofficeaddressvalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !headofficeaddressvalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline: !headofficeaddressvalidator
                        ? classes.errorstyle
                        : null,
                    },
                  }}
                  onChange={addressOnchange}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  id="outlined-full-width"
                  label="Telephone :"
                  value={props.phoneno}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    className: !headofficetellvalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !headofficetellvalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline: !headofficetellvalidator
                        ? classes.errorstyle
                        : null,
                    },
                  }}
                  onChange={phonenoOnchange}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  id="outlined-full-width"
                  label="Fax :"
                  value={props.fax}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={faxOnchange}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  disabled
                  id="outlined-full-width"
                  label="E-mail :"
                  value={props.email}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  id="outlined-full-width"
                  label="Website :"
                  value={props.website}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    className: !headofficewebsitevalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !headofficewebsitevalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline: !headofficewebsitevalidator
                        ? classes.errorstyle
                        : null,
                    },
                  }}
                  onChange={websiteOnchange}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="outlined-full-width"
                  label="Contact Name :"
                  value={props.contactname}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    className: !headofficecontactnamevalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !headofficecontactnamevalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline: !headofficecontactnamevalidator
                        ? classes.errorstyle
                        : null,
                    },
                  }}
                  onChange={contactnameOnchange}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              spacing={1}
              style={{ backgroundColor: "#fff", marginTop: 35, padding: 30 }}
            >
              <Grid item xs={12}>
                <TextField
                  id="outlined-full-width"
                  label="Office Address in Nigeria"
                  value={props.addresslocal}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    className: !ngofficeaddressvalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !ngofficeaddressvalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline: !ngofficeaddressvalidator
                        ? classes.errorstyle
                        : null,
                    },
                  }}
                  onChange={addresslocalOnchange}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  id="outlined-full-width"
                  label="Telephone"
                  value={props.phonenolocal}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    className: !ngofficetellvalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !ngofficetellvalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline: !ngofficetellvalidator
                        ? classes.errorstyle
                        : null,
                    },
                  }}
                  onChange={phonenolocalOnchange}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  id="outlined-full-width"
                  label="Fax"
                  value={props.faxlocal}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={faxlocalOnchange}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  disabled
                  id="outlined-full-width"
                  label="E-mail"
                  value={props.email}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  id="outlined-full-width"
                  label="Website"
                  value={props.websitelocal}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    className: !ngofficewebsitevalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !ngofficewebsitevalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline: !ngofficewebsitevalidator
                        ? classes.errorstyle
                        : null,
                    },
                  }}
                  onChange={websitelocalOnchange}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="outlined-full-width"
                  label="Contact Name"
                  value={props.contactnamelocal}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    className: !ngofficecontactnamevalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !ngofficecontactnamevalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline: !ngofficecontactnamevalidator
                        ? classes.errorstyle
                        : null,
                    },
                  }}
                  onChange={contactnamelocalOnchange}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ padding: 30 }}>
              <Box component="span" m={1}>
                <Typography
                  variant="h6"
                  component="span"
                  style={{ color: "#060454", fontSize: "14px" }}
                >
                  If not registered in Nigeria , is it your intention to
                  register :
                </Typography>
              </Box>
              <Box component="span">
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="gender1"
                    value={props.registerinnigeria}
                    onChange={registerinnigeriaOnchange}
                  >
                    <FormControlLabel
                      classes={{ label: classes.checkboxLabel }}
                      value="Yes"
                      control={
                        <Radio
                          style={{ color: "#060454", borderRadius: "80px" }}
                        />
                      }
                      label="Yes"
                      style={{ height: "14px" }}
                    />
                    <FormControlLabel
                      classes={{ label: classes.checkboxLabel }}
                      value="No"
                      control={
                        <Radio
                          style={{ color: "#060454", borderRadius: "80px" }}
                        />
                      }
                      label="No"
                      style={{ height: "14px" }}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>

            <Grid
              container
              xs={12}
              justify="flex-end"
              style={{ marginBottom: "100px" }}
            >
              <Box component="span">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={!formloader ? previewStageData : null}
                  style={{ backgroundColor: "#060454", height: "30px" }}
                  className={classes.button}
                  endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                >
                  Preview & Continue
                  {props.formloader ? <CircularProgress size={24} /> : null}
                </Button>
              </Box>
              {/* <Box component="span">
            <Button
              variant="contained"
              color="primary"
              onClick={!formloader ? onSubmitCompanyDetails : null}
              style={{ backgroundColor: "#060454", height: "30px" }}
              className={classes.button}
              endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
            >
              Save & Continue{" "}
              {props.formloader ? <CircularProgress size={24} /> : null}
            </Button>
          </Box> */}
            </Grid>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "#fff",
              marginTop: 25,
              padding: 30,
              marginBottom: "7rem",
            }}
          >
            <Grid container style={{display:'flex', columnGap:'10px'}}>
              {previousFIlledData.copanyname && (
                <Grid xs={4}>
                  {previousFIlledData.copanyname.status === 0 && (
                    <>
                      <label>Company Registered Name</label>
                      <input
                        disabled
                        value={previousFIlledData.copanyname.value}
                      />
                    </>
                  )}
                </Grid>
              )}
              {previousFIlledData.companyint && (
                <Grid xs={4}>
                  {previousFIlledData.companyint.status === 0 && (
                    <>
                      <label>FAX</label>
                      <input
                        disabled
                        value={previousFIlledData.companyint.value}
                      />
                    </>
                  )}
                </Grid>
              )}
              {previousFIlledData.companyintaddress && (
                <Grid xs={4}>
                  {previousFIlledData.companyintaddress.status === 0 && (
                    <>
                      <label>Office Registered Address</label>
                      <input
                        disabled
                        value={previousFIlledData.companyintaddress.value}
                      />
                    </>
                  )}
                </Grid>
              )}
              {previousFIlledData.companyintcontactperson && (
                <Grid xs={4}>
                  {previousFIlledData.companyintcontactperson.status === 0 && (
                    <>
                      <label>Contact Name :</label>
                      <input
                        disabled
                        value={previousFIlledData.companyintcontactperson.value}
                      />
                    </>
                  )}
                </Grid>
              )}
              {previousFIlledData.companyintphone && (
                <Grid xs={4}>
                  {previousFIlledData.companyintphone.status === 0 && (
                    <>
                      <label>Telephone :</label>
                      <input
                        disabled
                        value={previousFIlledData.companyintphone.value}
                      />
                    </>
                  )}
                </Grid>
              )}
              {previousFIlledData.companyintwebsite && (
                <Grid xs={4}>
                  {previousFIlledData.companyintwebsite.status === 0 && (
                    <>
                      <label>Company Website</label>
                      <input
                        disabled
                        value={previousFIlledData.companyintwebsite.value}
                      />
                    </>
                  )}
                </Grid>
              )}
              {previousFIlledData.companylocaddress && (
                <Grid xs={4}>
                  {previousFIlledData.companylocaddress.status === 0 && (
                    <>
                      <label>Office Address in Nigeria :</label>
                      <input
                        disabled
                        value={previousFIlledData.companylocaddress.value}
                      />
                    </>
                  )}
                </Grid>
              )}
              {previousFIlledData.companyloccontactperson && (
                <Grid xs={4}>
                  {previousFIlledData.companyloccontactperson.status === 0 && (
                    <>
                      <label>Contact Name :</label>
                      <input
                        disabled
                        value={previousFIlledData.companyloccontactperson.value}
                      />
                    </>
                  )}
                </Grid>
              )}
              {previousFIlledData.companylocfax && (
                <Grid xs={4}>
                  {previousFIlledData.companylocfax.status === 0 && (
                    <>
                      <label>FAX</label>
                      <input
                        disabled
                        value={previousFIlledData.companylocfax.value}
                      />
                    </>
                  )}
                </Grid>
              )}
              {previousFIlledData.companylocphone && (
                <Grid xs={4}>
                  {previousFIlledData.companylocphone.status === 0 && (
                    <>
                      <label>Telephone</label>
                      <input
                        disabled
                        value={previousFIlledData.companylocphone.value}
                      />
                    </>
                  )}
                </Grid>
              )}
              {previousFIlledData.companylocwebsite && (
                <Grid xs={4}>
                  {previousFIlledData.companylocwebsite.status === 0 && (
                    <>
                      <label>Nigeria Website</label>
                      <input
                        disabled
                        value={previousFIlledData.companylocwebsite.value}
                      />
                    </>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                // onClick={!formloader ? previewStageData : null}
                style={{ backgroundColor: "#060454", height: "30px" }}
                className={classes.button}
                endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                onClick={() => {setformEdit(true);
                              setserverData(false)}
                            }
              >
                Refill Form
              </Button>
            </Grid>
          </div>
        )}
      </Grid>
      <AppBar position="fixed" className={classes.appBarButom} color="#060454">
        <Typography
          variant="span"
          color="inherit"
          noWrap
          style={{ textAlign: "center", color: "#fff", alignItems: "center" }}
          justify="center"
        >
          Copyright Ⓒ 2022 <b>Oil and Gas Free Zones Authority</b>. All Rights Reserved.
        </Typography>
      </AppBar>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    position: "relative",
  },
  checkboxLabel: {
    fontSize: 14,
  },
  appBarButom: {
    top: "auto",
    bottom: 0,
    height: "40px",
    backgroundColor: "#060454",
    paddingTop: "5px",
    paddingButtom: "5px",
    alignItems: "center",
    justifyContent: "center",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "60%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  errorstyle: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
  errorstylelabel: {
    color: "red",
  },
}));

export default Applicantform1;
