//208.75.151.205 ns1851606760.a2dns.com ns185160676.a2dns.com
import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LockOutlineIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import oilandgaslogo from "../../assets/oilandgaslogo.png";
import Emailstep1 from "../../components/Emailstep1";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InputBase from "@material-ui/core/InputBase";
import Menutab from "../../components/Tabs/Menutab";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import {
  getFormStage7,
  submitFormStage7,
} from "../../Services/apiServices/Form";
import FormStagePreview from "../../components/preview/FormStagePreview";
import { Modal } from "@material-ui/core";
import txt from "../../txt.png";
import pdf from "../../pdf.png";
import { Document, Page, pdfjs } from "react-pdf";

document.body.style = "background: #f5f5f5;";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "13px",
      },
    },
  },
})(TextField);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

function Applicationform7(props) {
  const {
    snakBarSetter,
    stageSetter,
    errorSetter,
    formloaderSetter,
    rootid,
    formloader,
    stage,
    whichapplication,
  } = props;

  const [serverData, setserverData] = useState(false);
  const [formEdit, setformEdit] = useState(false);
  const [previousFIlledData, setpreviousFIlledData] = useState();
  const [imgtype, setImgtype] = React.useState("img");
  const [img, setImg] = React.useState("");
  const [openimg, setOpenimg] = React.useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  // document Upload

  const [cac, setCac] = useState("");
  const [memorandum, setMemorandum] = useState("");
  const [registationcountry, setRegistationcountry] = useState("");
  const [brochure, setBrochure] = useState("");
  const [businessplan, setBusinessplan] = useState("");
  const [financialprofile, setFinancialprofile] = useState("");
  const [pasonelprofile, setPasonelprofile] = useState("");
  const [audit, setAudit] = useState("");
  const [affidavit, setAffidavit] = useState("");

  // upload stage validator state
  const [cacvalidator, setCacvalidator] = useState(true);
  const [memorandumvalidator, setMemorandumvalidator] = useState(true);
  const [registationcountryvalidator, setRegistationcountryvalidator] =
    useState(true);
  const [brochurevalidator, setBrochurevalidator] = useState(true);
  const [businessplanvalidator, setBusinessplanvalidator] = useState(true);
  const [financialprofilevalidator, setFinancialprofilevalidator] =
    useState(true);
  const [pasonelprofilevalidator, setPasonelprofilevalidator] = useState(true);
  const [auditvalidator, setAuditvalidator] = useState(true);
  const [affidavitvalidator, setAffidavitvalidator] = useState(true);

  const [previewData, setpreviewData] = useState();
  const [showPreviewForm, setshowPreviewForm] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    // user this to pass new value
    getregistationcountry();
  });

  const formPreviewSetter = (value) => {
    setshowPreviewForm(value);
  };

  const getregistationcountry = async () => {
    const upladinitData = {
      root_id: rootid,
    };

    await submitFormStage7(upladinitData).then((res) => {
      // console.log(memorandum[0],'jjkck')
      if (res.data.status == 1) {
        setRegistationcountry(res.data.result.registrationcountry);
      }
    });
  };

  useEffect(() => {
    async function fetchFormData() {
      const response = await getFormStage7(rootid);

      if (response?.data?.result.documentupload.length !== 0 && !formEdit) {
        setpreviousFIlledData(response?.data?.result);
        setserverData(true);
      }
    }
    fetchFormData();
  }, [serverData]);

  const memorandumOnchange = (e) => {
    setMemorandum(e.target.files);
  };
  const brochureOnchange = (e) => {
    setBrochure(e.target.files);
  };
  const businessplanOnchange = (e) => {
    setBusinessplan(e.target.files);
  };
  const financialprofileOnchange = (e) => {
    setFinancialprofile(e.target.files);
  };

  //  console.log(financialprofile, 'lk')
  const pasonelprofileOnchange = (e) => {
    setPasonelprofile(e.target.files);
  };
  const auditOnchange = (e) => {
    setAudit(e.target.files);
  };
  const affidavitOnchange = (e) => {
    setAffidavit(e.target.files);
  };
  const cacOnchange = (e) => {
    setCac(e.target.files);
  };

  const onPreviewUploads = async (value) => {
    console.log(cac);
    formloaderSetter(true);
    if (cac[0] == undefined) {
      setCacvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload CAC Document");
      return false;
    } else {
      setCacvalidator(true);
    }

    if (memorandum[0] == undefined) {
      setMemorandumvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload Memorandum");
      return false;
    } else {
      setMemorandumvalidator(true);
    }

    if (brochure[0] == undefined) {
      setBrochurevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload company Brochure");
      return false;
    } else {
      setBrochurevalidator(true);
    }

    if (businessplan[0] == undefined) {
      setBusinessplanvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload Business Plan");
      return false;
    } else {
      setBusinessplanvalidator(true);
    }

    if (financialprofile[0] == undefined) {
      setFinancialprofilevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload Financial Profile");
      return false;
    } else {
      setFinancialprofilevalidator(true);
    }

    if (pasonelprofile[0] == undefined) {
      setPasonelprofilevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload Pasonel Profile");
      return false;
    } else {
      setPasonelprofilevalidator(true);
    }

    if (audit[0] == undefined) {
      setAuditvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload Company Audit Document");
      return false;
    } else {
      setAuditvalidator(true);
    }

    if (affidavit[0] == undefined) {
      setAffidavitvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter(
        "Please Upload Affidavit Issued by a Notary Public that the Entity is Oil & Gas related Company"
      );
      return false;
    } else {
      setAffidavitvalidator(true);
    }

    formloaderSetter(false);

    const stage7Data = [
      { name: "cac", value: `${cac[0].name}`, file: cac[0] },
      {
        name: "memorandum",
        value: `${memorandum[0].name}`,
        file: memorandum[0],
      },
      { name: "Brochure", value: `${brochure[0].name}`, file: brochure[0] },
      {
        name: "Business plan",
        value: `${businessplan[0].name}`,
        file: businessplan[0],
      },
      {
        name: "Financial profile",
        value: `${financialprofile[0].name}`,
        file: financialprofile[0],
      },
      {
        name: "Personel profile",
        value: `${pasonelprofile[0].name}`,
        file: pasonelprofile[0],
      },
      { name: "Audit", value: `${audit[0].name}`, file: audit[0] },
      { name: "Affidavit", value: `${affidavit[0].name}`, file: affidavit[0] },
    ];

    setpreviewData(stage7Data);
    setshowPreviewForm(true);
  };

  const onSubmitUploads = async (value) => {
    console.log(cac);
    formloaderSetter(true);
    if (cac[0] == undefined) {
      setCacvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload CAC Document");
      return false;
    } else {
      setCacvalidator(true);
    }

    if (memorandum[0] == undefined) {
      setMemorandumvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload Memorandum");
      return false;
    } else {
      setMemorandumvalidator(true);
    }

    if (brochure[0] == undefined) {
      setBrochurevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload company Brochure");
      return false;
    } else {
      setBrochurevalidator(true);
    }

    if (businessplan[0] == undefined) {
      setBusinessplanvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload Business Plan");
      return false;
    } else {
      setBusinessplanvalidator(true);
    }

    if (financialprofile[0] == undefined) {
      setFinancialprofilevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload Financial Profile");
      return false;
    } else {
      setFinancialprofilevalidator(true);
    }

    if (pasonelprofile[0] == undefined) {
      setPasonelprofilevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload Pasonel Profile");
      return false;
    } else {
      setPasonelprofilevalidator(true);
    }

    if (audit[0] == undefined) {
      setAuditvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload Company Audit Document");
      return false;
    } else {
      setAuditvalidator(true);
    }

    if (affidavit[0] == undefined) {
      setAffidavitvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter(
        "Please Upload Affidavit Issued by a Notary Public that the Entity is Oil & Gas related Company"
      );
      return false;
    } else {
      setAffidavitvalidator(true);
    }

    const stage7Data = new FormData();

    stage7Data.append("root_id", rootid);
    stage7Data.append("cac", cac[0]);
    stage7Data.append("memorandum", memorandum[0]);
    stage7Data.append("brochure", brochure[0]);
    stage7Data.append("businessplan", businessplan[0]);
    stage7Data.append("financialprofile", financialprofile[0]);
    stage7Data.append("pasonelprofile", pasonelprofile[0]);
    stage7Data.append("audit", audit[0]);
    stage7Data.append("affidavit", affidavit[0]);

    console.log(memorandum[0], affidavit[0], "jjkck");
    // console.log(stage7Data);
    for (var key of stage7Data.entries()) {
      console.log(key[0] + ", " + key[1]);
    }

    await axios
      .post(
        "https://ogfzaapi.skillzserver.com/aplicationformstage7/create",
        stage7Data
      )
      .then((res) => {
        if (res.data.status == 1) {
          stageSetter(res.data.result.application_stage);
          formloaderSetter(false);
        }
        formloaderSetter(false);
      })
      .catch((error) => {
        formloaderSetter(false);
        console.log(error);
      });
  };

  const onSelectSetter = async (value) => {
    console.log("clicked");
    stageSetter(value);
    const response = await getFormStage7(rootid);

    if (response?.data?.result.documentupload.length !== 0 && !formEdit) {
      setpreviousFIlledData(response?.data?.result);
      setserverData(true);
    }

    // setserverData(true);
    //load
    // if data set visibilitiy to true and change display
  };

  const handleCloseImg = () => {
    setImg("");
    setOpenimg(false);
  };
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const showimage = (img) => {
    console.log(img, "nsjssj");
    if (img.split(".").pop() == "pdf") {
      setImgtype("pdf");
      let newimg = "http://api.ogfza-registry.com/uploads/" + img;
      setImg(newimg);
      window.open(
        newimg,
        "sharer",
        "toolbar=no,status=yes,width=900,height=500"
      );

      return false;
    } else {
      setImgtype("img");
    }
    let newimg = "http://api.ogfza-registry.com/uploads/" + img;
    setImg(newimg);
    setOpenimg(true);
    console.log(newimg);
  };

  return (
    <React.Fragment>
      <Modal
        open={openimg}
        onClose={handleCloseImg}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "scroll",
        }}
      >
        <div className={classes.modalview}>
          {imgtype == "pdf" ? (
            <Document file={img} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
          ) : (
            <img src={img} />
          )}
        </div>
      </Modal>
      <Modal
        open={showPreviewForm}
        onClose={() => setshowPreviewForm(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // overflow: "auto",
          overflow: "scroll",
          border: "2px solid blue",
          height: "100vh",
          marginTop: "10px",
          // overflowX: 'visible',
          // backgroundColor:'white'
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            height: "maxContent",
            width: window.innerWidth - 50,
            padding: "1rem 2rem",
            border: "2px solid orange",
          }}
        >
          <FormStagePreview
            data={[previewData]}
            formloaderSetter={formloaderSetter}
            formPreviewSetter={formPreviewSetter}
          />
          <Button
            style={{ marginRight: "1rem" }}
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => {
              setshowPreviewForm(false);
              if (!formloader) {
                return onSubmitUploads();
              } else {
                return null;
              }
            }}
          >
            {" "}
            Confirm Save and Next{" "}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              formloaderSetter(false);
              setshowPreviewForm(false);
            }}
          >
            {" "}
            No{" "}
          </Button>
        </div>
      </Modal>
      <AppBar position="absolute" color="#ffffff" className={classes.appBar}>
        <Toolbar className={classes.layout}>
          <img
            src={oilandgaslogo}
            alt="Logo"
            style={{
              width: "80px",
              height: "60px",
              textAlign: "center",
              alignSelf: "center",
            }}
          />
        </Toolbar>
      </AppBar>

      <Grid container component="main" className={classes.layout}>
        <Menutab
          onSelectSetter={onSelectSetter}
          whichapplication={whichapplication}
          stage={stage}
        />
        {!serverData ? (
          <>
            <Grid item xs={12} style={{ marginBottom: 25 }}>
              <h4>
                {" "}
                Documentation Required (Please attach certified copies ):
              </h4>
            </Grid>
            <Grid
              container
              xs={12}
              spacing={1}
              style={{ backgroundColor: "#fff", marginTop: 25, padding: 30 }}
            >
              <Grid item xs={12}>
              <div className="upload_title">
                  <label>CAC</label>
                </div>
                <input
                  accept="image/*,application/pdf"
                  className={classes.input}
                  style={{ display: "none" }}
                  label="cac"
                  id="cacOnchange"
                  multiple
                  type="file"
                  onChange={cacOnchange}
                />
               
                <label htmlFor="cacOnchange">
                  <Button
                    fullWidth
                    style={{ backgroundColor: "#fff", minHeight: 60 }}
                    variant="outlined"
                    component="span"
                    className={
                      !cacvalidator
                        ? classes.errorstyle
                        : cac.length > 0
                        ? classes.successupload
                        : classes.uploads
                    }
                  >
                    {cac.length > 0
                      ? cac[0].name
                      : "Licence of Incorporation/Notorised Copy"}
                  </Button>
                </label>
              </Grid>

              <Grid item xs={12}>
                <input
                  accept="image/*,application/pdf"
                  className={classes.input}
                  style={{ display: "none" }}
                  id="memorandumOnchange"
                  multiple
                  type="file"
                  onChange={memorandumOnchange}
                />
                <div className="upload_title">
                  <label>Memorandum Of Understanding</label>
                </div>
                <label htmlFor="memorandumOnchange">
                  <Button
                    fullWidth
                    style={{ backgroundColor: "#fff", minHeight: 60 }}
                    variant="outlined"
                    component="span"
                    className={
                      !memorandumvalidator
                        ? classes.errorstyle
                        : memorandum.length > 0
                        ? classes.successupload
                        : classes.uploads
                    }
                  >
                    {memorandum.length > 0
                      ? memorandum[0].name
                      : "Memorandum and Articles of Association"}
                  </Button>
                </label>
              </Grid>

              <Grid item xs={12}>
                <input
                  accept="image/*,application/pdf"
                  className={classes.input}
                  style={{ display: "none" }}
                  id="brochureOnchange"
                  multiple
                  type="file"
                  onChange={brochureOnchange}
                />
                <div className="upload_title"> 
                  <label>Brochure</label>
                </div>
                <label htmlFor="brochureOnchange">
                  <Button
                    fullWidth
                    style={{ backgroundColor: "#fff", minHeight: 60 }}
                    variant="outlined"
                    component="span"
                    className={
                      !brochurevalidator
                        ? classes.errorstyle
                        : brochure.length > 0
                        ? classes.successupload
                        : classes.uploads
                    }
                  >
                    {brochure.length > 0
                      ? brochure[0].name
                      : "Company Brochure/Profile"}
                  </Button>
                </label>
              </Grid>

              <Grid item xs={12}>
                <input
                  accept="image/*,application/pdf"
                  className={classes.input}
                  style={{ display: "none" }}
                  id="businessplanOnchange"
                  multiple
                  type="file"
                  onChange={businessplanOnchange}
                />
                <div className="upload_title">
                  <label>Business Plan</label>
                </div>
                <label htmlFor="businessplanOnchange">
                  <Button
                    fullWidth
                    style={{ backgroundColor: "#fff", minHeight: 60 }}
                    variant="outlined"
                    component="span"
                    className={
                      !businessplanvalidator
                        ? classes.errorstyle
                        : businessplan.length > 0
                        ? classes.successupload
                        : classes.uploads
                    }
                  >
                    {businessplan.length > 0
                      ? businessplan[0].name
                      : "Report of the Feasibility Studies (Business Plan)"}
                  </Button>
                </label>
              </Grid>

              <Grid item xs={12}>
                <input
                  accept="image/*,application/pdf"
                  className={classes.input}
                  style={{ display: "none" }}
                  id="financialprofileOnchange"
                  multiple
                  type="file"
                  onChange={financialprofileOnchange}
                />
                <div className="upload_title">
                  <label>Financial Profile</label>
                </div>
                <label htmlFor="financialprofileOnchange">
                  <Button
                    fullWidth
                    style={{ backgroundColor: "#fff", minHeight: 60 }}
                    variant="outlined"
                    component="span"
                    className={
                      !financialprofilevalidator
                        ? classes.errorstyle
                        : financialprofile.length > 0
                        ? classes.successupload
                        : classes.uploads
                    }
                  >
                    {financialprofile.length > 0
                      ? financialprofile[0].name
                      : "Financial Profile"}
                  </Button>
                </label>
              </Grid>

              <Grid item xs={12}>
                <input
                  accept="image/*,application/pdf"
                  className={classes.input}
                  style={{ display: "none" }}
                  id="pasonelprofileOnchange "
                  multiple
                  type="file"
                  onChange={pasonelprofileOnchange}
                />
                <div className="upload_title">
                  <label>Personal Profile</label>
                </div>
                <label htmlFor="pasonelprofileOnchange ">
                  <Button
                    fullWidth
                    style={{ backgroundColor: "#fff", minHeight: 60 }}
                    variant="outlined"
                    component="span"
                    className={
                      !pasonelprofilevalidator
                        ? classes.errorstyle
                        : pasonelprofile.length > 0
                        ? classes.successupload
                        : classes.uploads
                    }
                  >
                    {pasonelprofile.length > 0
                      ? pasonelprofile[0].name
                      : "Personnel Profile"}
                  </Button>
                </label>
              </Grid>

              <Grid item xs={12}>
                <input
                  accept="image/*,application/pdf"
                  className={classes.input}
                  style={{ display: "none" }}
                  id="auditOnchange"
                  multiple
                  type="file"
                  onChange={auditOnchange}
                />
                <div className="upload_title">
                  <label>Audit</label>
                </div>
                <label htmlFor="auditOnchange">
                  <Button
                    fullWidth
                    style={{ backgroundColor: "#fff", minHeight: 60 }}
                    variant="outlined"
                    component="span"
                    className={
                      !auditvalidator
                        ? classes.errorstyle
                        : audit.length > 0
                        ? classes.successupload
                        : classes.uploads
                    }
                  >
                    {audit.length > 0
                      ? audit[0].name
                      : "Companies last three years Audited Account"}
                  </Button>
                </label>
              </Grid>

              <Grid item xs={12}>
                <input
                  accept="image/*,application/pdf"
                  className={classes.input}
                  style={{ display: "none" }}
                  id="affidavitOnchange"
                  multiple
                  type="file"
                  onChange={affidavitOnchange}
                />
                <div className="upload_title">
                  <label>Affidavit</label>
                </div>
                <label htmlFor="affidavitOnchange">
                  <Button
                    fullWidth
                    style={{ backgroundColor: "#fff", minHeight: 60 }}
                    variant="outlined"
                    component="span"
                    className={
                      !affidavitvalidator
                        ? classes.errorstyle
                        : affidavit.length > 0
                        ? classes.successupload
                        : classes.uploads
                    }
                  >
                    {affidavit.length > 0
                      ? affidavit[0].name
                      : "Affidavit issued by a Notary Public that the entity is Oil & Gas related Company"}
                  </Button>
                </label>
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              justify="flex-end"
              style={{ marginBottom: "100px", marginTop: 25 }}
            >
              <Box component="span">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={!formloader ? onPreviewUploads : null}
                  style={{ backgroundColor: "#060454", height: "30px" }}
                  className={classes.button}
                  endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                >
                  Preview & Continue{" "}
                  {formloader ? <CircularProgress size={24} /> : null}
                </Button>
              </Box>
            </Grid>
          </>
        ) : (
          <div
            style={{
              backgroundColor: "#fff",
              marginTop: 25,
              padding: 30,
              marginBottom: "7rem",
              width: "100%",
            }}
          >
            <Grid container>
              {/* {previousFIlledData?.document && ( */}
              <Grid xs={12} style={{ display: "flex", flexDirection: "row" }}>
                {previousFIlledData?.documentupload && (
                  <>
                    {previousFIlledData?.documentupload?.map((e, index) => (
                      <>
                        {e.status === 0 && (
                          <>
                            <div>
                              <div>
                                <b>{e.type}</b>
                              </div>
                              <div>
                                {" "}
                                <img
                                  src={
                                    e?.parth.split(".").pop() == "pdf"
                                      ? pdf
                                      : txt
                                  }
                                  style={{
                                    width: 100,
                                    height: 100,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    showimage(
                                      e?.parth
                                      // e?.signarure
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ))}
                  </>
                )}
              </Grid>
              {/* )} */}
            </Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                // onClick={!formloader ? previewStageData : null}
                style={{ backgroundColor: "#060454", height: "30px" }}
                className={classes.button}
                endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                onClick={() => {
                  setformEdit(true);
                  setserverData(false);
                }}
              >
                Refill Form
              </Button>
            </Grid>
          </div>
        )}
      </Grid>
      <AppBar position="fixed" className={classes.appBarButom} color="#060454">
        <Typography
          variant="span"
          color="inherit"
          noWrap
          style={{ textAlign: "center", color: "#fff", alignItems: "center" }}
          justify="center"
        >
          Copyright © 2022 <b>Oil and Gas Free Zones Authority</b>. All Rights Reserved.
        </Typography>
      </AppBar>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    position: "relative",
  },
  checkboxLabel: {
    fontSize: 14,
  },
  appBarButom: {
    top: "auto",
    bottom: 0,
    height: "40px",
    backgroundColor: "#060454",
    paddingTop: "5px",
    paddingButtom: "5px",
    alignItems: "center",
    justifyContent: "center",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "60%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  errorstyle: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
  errorstylelabel: {
    color: "red",
  },
  uploads: {
    border: "1px dashed #ccc",
  },
  successupload: {
    border: "1px dashed green",
  },
}));

export default Applicationform7;
