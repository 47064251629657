import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LockOutlineIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import oilandgaslogo from "../../assets/oilandgaslogo.png";
import Emailstep1 from "../../components/Emailstep1";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Menutab from "../../components/Tabs/Menutab";
import axios from "axios";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getCompanyName,
  getFormStage2,
  submitFormStage0,
} from "../../Services/apiServices/Form";
import { Modal } from "@material-ui/core";
import FormStagePreview from "../../components/preview/FormStagePreview";
import txt from "../../txt.png";
import pdf from "../../pdf.png";
import { Document, Page, pdfjs } from "react-pdf";

document.body.style = "background: #f5f5f5;";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "13px",
      },
    },
  },
})(TextField);

function Applicationform0(props) {
  const {
    // mousignature,
    // inputFields,
    formloaderSetter,
    errorSetter,
    snakBarSetter,
    stageSetter,
    rootid,
    stage,
    formloader,
    vendorApplicant,
    whichapplication,
    // moucompanyname,
  } = props;
  const [serverData, setserverData] = useState(false);
  const [moutotalshares, setMoutotalshares] = useState("");
  const [mounumberofshares, setMounumberofshares] = useState("");
  const [witnessesname, setWitnessesname] = useState("");
  const [witnessesaddress, setWitnessesaddress] = useState("");
  const [witnessesoccupation, setWitnessesoccupation] = useState("");
  const [witnessessignature, setWitnessessignature] = useState({});
  const [mouexpectedstartdate, setMouexpectedstartdate] = useState("");
  const [moulocation, setMoulocation] = useState("Onne Oil and Gas Free Zone");
  const [mousignature, setMousignature] = useState([]);
  const [moudescription, setMoudescription] = useState("");
  const [moudescriptionvalidator, setMoudescriptionvalidator] = useState(true);
  const [moutotalsharesvalidator, setMoutotalsharesvalidator] = useState(true);
  const [mounumberofsharesvalidator, setMounumberofsharesvalidator] =
    useState(true);

  const [witnessesnamevalidator, setWitnessesnamevalidator] = useState(true);
  const [witnessesaddressvalidator, setWitnessesaddressvalidator] =
    useState(true);
  const [witnessesoccupationvalidator, setWitnessesoccupationvalidator] =
    useState(true);
  const [mouexpectedstartdatevalidator, setMouexpectedstartdatevalidator] =
    useState(true);
  const [witnessessignaturevalidator, setWitnessessignaturevalidator] =
    useState(true);

  const [dynamicnameval, setDynamicnameval] = useState(0);
  const [dynamicaddressval, setDynamicaddressval] = useState(0);
  const [dynamicsharesval, setDynamicsharesval] = useState(0);
  const [dynamicsignatureval, setDynamicsignatureval] = useState(0);
  const [showPreviewForm, setshowPreviewForm] = useState(false);
  const [inputFields, setInputFields] = useState([
    {
      name: "",
      address: "",
      shares: "",
      signature: {},
    },
  ]);
  const [moucompanyname, setMoucompanyname] = useState("");
  const [previewData, setpreviewData] = useState();
  const [formEdit, setformEdit] = useState(false);
  const [previousFIlledData, setpreviousFIlledData] = useState();
  const [imgtype, setImgtype] = React.useState("img");
  const [img, setImg] = React.useState("");
  const [openimg, setOpenimg] = React.useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    // user this to pass new value
    getcompanyname();
  });

  console.log(vendorApplicant);

  useEffect(() => {
    async function fetchFormData() {
      const response = await getFormStage2(rootid);
      if (response?.data?.result.totalshares && !formEdit) {
        setpreviousFIlledData(response?.data?.result);

        console.log(response, "kinjdjds");
        setserverData(true);
      }
    }
    fetchFormData();
  }, [serverData]);

  const handleCloseImg = () => {
    setImg("");
    setOpenimg(false);
  };
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  // const onSubmitMou = () => {
  //   //e.preventDefault();
  //   onSubmitMou();
  // };

  // const handleInputChange = (index, event) => {
  //   // dynamicValueSetter(0)
  //     setDynamicnameval(0);
  //     setDynamicaddressval(0);
  //     setDynamicsharesval(0);
  //     setDynamicsignatureval(0);

  //   handleInputChange(index, event);
  // };

  const handleInputChange = (index, event) => {
    setDynamicnameval(0);
    setDynamicaddressval(0);
    setDynamicsharesval(0);
    setDynamicsignatureval(0);
    const values = [...inputFields];
    const valuefiles = [...mousignature];
    console.log(event.target.name);

    if (event.target.name == "name") {
      values[index].name = event.target.value;
    } else if (event.target.name == "address") {
      values[index].address = event.target.value;
    } else if (event.target.name == "shares") {
      values[index].shares = event.target.value;
    } else {
      // const stageTestmouData = new FormData();
      // stageTestmouData.append("test", event.target.files);
      values[index].signature = event.target.files;
      //valuefiles[index] = event.target.files;
      //values[index].signature = stageTestmouData;
      //console.log(values[index].signature.name);
    }

    setInputFields(values);
    console.log(inputFields);
  };

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({
      name: "",
      address: "",
      shares: "",
      signature: {},
    });
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    let size = Object.keys(values).length;
    if (size == 1) {
      alert("Please note you cant remove this as you must enter a value ");
      return false;
    }

    values.splice(index, 1);
    setInputFields(values);
  };

  const getcompanyname = async () => {
    const upladinitData = {
      root_id: rootid,
    };

    await getCompanyName(upladinitData).then((res) => {
      console.log(rootid, "lets see if rootid was paseed");
      console.log(res.data);
      if (res.data.status == 1) {
        setMoucompanyname(res.data.result.companyname);
      }
    });
    // await axios
    //   .post(
    //     "https://ogfzaapi.skillzserver.com/aplicationformstage0/getcompanyname",
    //     upladinitData
    //   )
    //   .then((res) => {
    //     console.log(res.data);
    //     if (res.data.status == 1) {
    //       setMoucompanyname(res.data.result.companyname);
    //     }
    //   });
  };

  const moulocationOnchange = (e) => {
    setMoulocation(e.target.value);
  };
  const moudescriptionOnchange = (e) => {
    setMoudescription(e.target.value);
  };

  const moutotalsharesOnchange = (e) => {
    console.log(1);
    setMoutotalshares(e.target.value);
  };

  const mounumberofsharesOnchange = (e) => {
    setMounumberofshares(e.target.value);
  };

  const mouexpectedstartdateOnchange = (e) => {
    console.log(2);
    setMouexpectedstartdate(e.target.value);
  };

  const witnessesnameOnchange = (e) => {
    setWitnessesname(e.target.value);
  };
  const witnessesaddressOnchange = (e) => {
    setWitnessesaddress(e.target.value);
  };
  const witnessesoccupationOnchange = (e) => {
    setWitnessesoccupation(e.target.value);
  };
  const witnessessignatureOnchange = (e) => {
    setWitnessessignature(e.target.files);
  };

  const onPreviewMou = async () => {
    console.log(moudescription.length);
    formloaderSetter(false);
    if (moudescription.length < 1) {
      setMoudescriptionvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Description");
      return false;
    } else {
      setMoudescriptionvalidator(true);
    }
    if (moutotalshares.length < 1) {
      setMoutotalsharesvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Total Shares");
      return false;
    } else {
      setMoutotalsharesvalidator(true);
    }
    if (mounumberofshares.length < 1) {
      setMounumberofsharesvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("please enter number of share holders");
      return false;
    } else {
      setMounumberofsharesvalidator(true);
    }
    if (witnessesname.length < 1) {
      setWitnessesnamevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Witness Name");
      return false;
    } else {
      setWitnessesnamevalidator(true);
    }
    if (witnessesaddress.length < 1) {
      setWitnessesaddressvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Witness Address");
      return false;
    } else {
      setWitnessesaddressvalidator(true);
    }
    if (witnessesoccupation.length < 1) {
      setWitnessesoccupationvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Witness Ocupation");
      return false;
    } else {
      setWitnessesoccupationvalidator(true);
    }
    if (mouexpectedstartdate.length < 1) {
      setMouexpectedstartdatevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Expected Start Date");
      return false;
    } else {
      setMouexpectedstartdatevalidator(true);
    }
    if (witnessessignature[0] === undefined) {
      setWitnessessignaturevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Select Signature");
      return false;
    } else {
      setWitnessessignaturevalidator(true);
    }

    let newChecker = null;
    for (const [index, inputField] of inputFields.entries()) {
      console.log(index);
      if (inputField.name.length == 0) {
        setDynamicnameval(index + 1);
        newChecker = false;
        formloaderSetter(false);
        break;
      }

      if (inputField.address.length == 0) {
        setDynamicaddressval(index + 1);
        newChecker = false;
        formloaderSetter(false);
        break;
      }

      if (inputField.shares.length == 0) {
        setDynamicsharesval(index + 1);
        newChecker = false;
        formloaderSetter(false);
        break;
      }

      if (inputField.signature[0] === undefined) {
        setDynamicsignatureval(index + 1);
        newChecker = false;
        formloaderSetter(false);
        break;
      }

      newChecker = true;
    }

    console.log(newChecker);
    if (newChecker) {
      // const stagemouData = new FormData();
      // inputFields.map(
      //   (inputField, index) => (
      //     console.log(inputField),
      //     stagemouData.append("dynamic[]", JSON.stringify(inputField)),
      //     stagemouData.append("dynamicFiles" + index, inputField.signature[0])
      //   )
      // );
      // stagemouData.append("root_id", rootid);
      // stagemouData.append("mousig", mousignature);

      // stagemouData.append("moulocation", moulocation);
      // stagemouData.append("moudescription", moudescription);
      // stagemouData.append("moutotalshares", moutotalshares);
      // stagemouData.append("mounumberofshares", mounumberofshares);
      // stagemouData.append("witnessesname", witnessesname);
      // stagemouData.append("witnessesaddress", witnessesaddress);
      // stagemouData.append("witnessesoccupation", witnessesoccupation);
      // stagemouData.append("mouexpectedstartdate", mouexpectedstartdate);
      // stagemouData.append("witnessessignature", witnessessignature[0]);

      console.log(inputFields);
      const stagemouData = [
        // { name: "root_id", value: `${rootid}` },
        {
          name: "mousig",
          value: `imgInputArra`,
          imgInputArra: true,
          imgInputArraData: inputFields,
        },
        { name: "MOU location", value: `${moulocation}` },
        { name: "MOU description", value: `${moudescription}` },
        { name: "MOU total shares", value: `${moutotalshares}` },
        { name: "Witnesses name", value: `${witnessesname}` },
        { name: "Witnesses address", value: `${witnessesaddress}` },
        { name: "Witnesses occupation", value: `${witnessesoccupation}` },
        { name: "MOU expected startdate", value: `${mouexpectedstartdate}` },
        {
          name: "witnesses signature",
          value: `${witnessessignature[0].name}`,
          file: witnessessignature[0],
        },
      ];
      console.log(witnessessignature[0].name);
      console.log(stagemouData);
      setpreviewData(stagemouData);
      setshowPreviewForm(true);
    }
  };

  const onSubmitMou = async () => {
    console.log(moudescription.length);
    formloaderSetter(true);
    if (moudescription.length < 1) {
      setMoudescriptionvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Description");
      return false;
    } else {
      setMoudescriptionvalidator(true);
    }
    if (moutotalshares.length < 1) {
      setMoutotalsharesvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Total Shares");
      return false;
    } else {
      setMoutotalsharesvalidator(true);
    }
    if (mounumberofshares.length < 1) {
      setMounumberofsharesvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("please enter number of share holders");
      return false;
    } else {
      setMounumberofsharesvalidator(true);
    }
    if (witnessesname.length < 1) {
      setWitnessesnamevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Witness Name");
      return false;
    } else {
      setWitnessesnamevalidator(true);
    }
    if (witnessesaddress.length < 1) {
      setWitnessesaddressvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Witness Address");
      return false;
    } else {
      setWitnessesaddressvalidator(true);
    }
    if (witnessesoccupation.length < 1) {
      setWitnessesoccupationvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Witness Ocupation");
      return false;
    } else {
      setWitnessesoccupationvalidator(true);
    }
    if (mouexpectedstartdate.length < 1) {
      setMouexpectedstartdatevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Expected Start Date");
      return false;
    } else {
      setMouexpectedstartdatevalidator(true);
    }
    if (witnessessignature[0] === undefined) {
      setWitnessessignaturevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Select Signature");
      return false;
    } else {
      setWitnessessignaturevalidator(true);
    }

    let newChecker = null;
    for (const [index, inputField] of inputFields.entries()) {
      console.log(index);
      if (inputField.name.length == 0) {
        setDynamicnameval(index + 1);
        newChecker = false;
        formloaderSetter(false);
        break;
      }

      if (inputField.address.length == 0) {
        setDynamicaddressval(index + 1);
        newChecker = false;
        formloaderSetter(false);
        break;
      }

      if (inputField.shares.length == 0) {
        setDynamicsharesval(index + 1);
        newChecker = false;
        formloaderSetter(false);
        break;
      }

      if (inputField.signature[0] === undefined) {
        setDynamicsignatureval(index + 1);
        newChecker = false;
        formloaderSetter(false);
        break;
      }

      newChecker = true;
    }

    console.log(newChecker);
    if (newChecker) {
      const stagemouData = new FormData();
      inputFields.map(
        (inputField, index) => (
          console.log(inputField),
          stagemouData.append("dynamic[]", JSON.stringify(inputField)),
          stagemouData.append("dynamicFiles" + index, inputField.signature[0])
        )
      );
      stagemouData.append("root_id", rootid);
      stagemouData.append("mousig", mousignature);

      stagemouData.append("moulocation", moulocation);
      stagemouData.append("moudescription", moudescription);
      stagemouData.append("moutotalshares", moutotalshares);
      stagemouData.append("mounumberofshares", mounumberofshares);
      stagemouData.append("witnessesname", witnessesname);
      stagemouData.append("witnessesaddress", witnessesaddress);
      stagemouData.append("witnessesoccupation", witnessesoccupation);
      stagemouData.append("mouexpectedstartdate", mouexpectedstartdate);
      stagemouData.append("witnessessignature", witnessessignature[0]);

      setpreviewData(stagemouData);
      await submitFormStage0(stagemouData)
        .then((res) => {
          console.log(res.data);
          if (res.data.status == 1) {
            formloaderSetter(false);
            if (vendorApplicant === 1) {
              stageSetter(9);
            } else {
              stageSetter(res.data.result.application_stage);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const showimage = (img) => {
    console.log(img, "nsjssj");
    if (img.split(".").pop() == "pdf") {
      setImgtype("pdf");
      let newimg = "http://api.ogfza-registry.com/uploads/" + img;
      setImg(newimg);
      window.open(
        newimg,
        "sharer",
        "toolbar=no,status=yes,width=900,height=500"
      );

      return false;
    } else {
      setImgtype("img");
    }
    let newimg = "http://api.ogfza-registry.com/uploads/" + img;
    setImg(newimg);
    setOpenimg(true);
    console.log(newimg);
  };



  const formPreviewSetter = (value) => {
    setshowPreviewForm(value);
  };

  const onSelectSetter = async (value) => {
    stageSetter(value);
    const response = await getFormStage2(rootid);
    console.log(response, "djkdskkjsjksd");
    if (response?.data?.result.totalshares && !formEdit) {
      setpreviousFIlledData(response?.data?.result);
      setserverData(true);
    }

    // setserverData(true);
    //load
    // if data set visibilitiy to true and change display
  };

  const classes = useStyles();
  return (
    <React.Fragment>
      <Modal
        open={openimg}
        onClose={handleCloseImg}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "scroll",
        }}
      >
        <div className={classes.modalview}>
          {imgtype == "pdf" ? (
            <Document file={img} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
          ) : (
            <img src={img} />
          )}
        </div>
      </Modal>
      <Modal
        open={showPreviewForm}
        onClose={() => setshowPreviewForm(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
          overflow: "scroll",
          border: "2px solid blue",
          height: "100vh",
          marginTop: "10px",
          // overflowX: 'visible',
          // backgroundColor:'white'
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            height: "maxContent",
            width: window.innerWidth - 50,
            padding: "1rem 2rem",
            border: "2px solid orange",
          }}
        >
          <FormStagePreview
            data={[previewData]}
            formloaderSetter={formloaderSetter}
            formPreviewSetter={formPreviewSetter}
          />
          <Button
            style={{ marginRight: "1rem" }}
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => {
              setshowPreviewForm(false);
              if (!formloader) {
                return onSubmitMou();
              } else {
                return null;
              }
            }}
          >
            {" "}
            Confirm Save and Next{" "}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              formloaderSetter(false);
              setshowPreviewForm(false);
            }}
          >
            {" "}
            No{" "}
          </Button>
        </div>
      </Modal>

      <AppBar position="absolute" color="#ffffff" className={classes.appBar}>
        <Toolbar className={classes.layout}>
          <img
            src={oilandgaslogo}
            alt="Logo"
            style={{
              width: "80px",
              height: "60px",
              textAlign: "center",
              alignSelf: "center",
            }}
          />
        </Toolbar>
      </AppBar>

      <Grid container component="main" className={classes.layout}>
        <Grid item xs={12}>
          <Menutab
            onSelectSetter={onSelectSetter}
            whichapplication={whichapplication}
            stage={stage}
          />
          <h5> Memorandum of Association </h5>
          <h7>
            {" "}
            In accordance with Sections 31, 32, 33 & 34 of the Oil and Gas
            Export Free Zones Regulation, 2019{" "}
          </h7>
        </Grid>

        {!serverData ? (
          <div>
            <Grid
              container
              xs={12}
              spacing={1}
              style={{ backgroundColor: "#fff", marginTop: 25, padding: 30 }}
            >
              <Grid item xs={12}>
                <TextField
                  id="outlined-full-width"
                  label="Company Registered Name :"
                  value={moucompanyname}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <h5>
                  The registered office of the company will be situated in{" "}
                </h5>
                <Box component="span">
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="location"
                      name="location"
                      value={moulocation}
                      onChange={moulocationOnchange}
                    >
                      <FormControlLabel
                        value="Onne Oil and Gas Free Zone"
                        control={
                          <Radio
                            style={{ color: "#060454", borderRadius: "80px" }}
                          />
                        }
                        label="Onne Oil and Gas Free Zone"
                        classes={{ label: classes.checkboxLabel }}
                      />
                      <FormControlLabel
                        value="Eko Support Oil and Gas Free Zone"
                        control={
                          <Radio
                            style={{ color: "#060454", borderRadius: "80px" }}
                          />
                        }
                        label="Eko Support Oil and Gas Free Zone"
                        classes={{ label: classes.checkboxLabel }}
                      />
                      <FormControlLabel
                        value="Warri Oil and Gas Free Zone"
                        control={
                          <Radio
                            style={{ color: "#060454", borderRadius: "80px" }}
                          />
                        }
                        label="Warri Oil and Gas Free Zone"
                        classes={{ label: classes.checkboxLabel }}
                      />
                      <FormControlLabel
                        value="Liberty Oil and Gas Free Zone"
                        control={
                          <Radio
                            style={{ color: "#060454", borderRadius: "80px" }}
                          />
                        }
                        label="Liberty Oil and Gas Free Zone"
                        classes={{ label: classes.checkboxLabel }}
                      />
                      <FormControlLabel
                        value="Brass Oil and Gas City"
                        control={
                          <Radio
                            style={{ color: "#060454", borderRadius: "80px" }}
                          />
                        }
                        label="Brass Oil and Gas City"
                        classes={{ label: classes.checkboxLabel }}
                      />
                      <FormControlLabel
                        value="Notore Industrial City"
                        control={
                          <Radio
                            style={{ color: "#060454", borderRadius: "80px" }}
                          />
                        }
                        label="Notore Industrial City"
                        classes={{ label: classes.checkboxLabel }}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <h4> The objects for which the company is established are</h4>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  value={moudescription}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    className: !moudescriptionvalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !moudescriptionvalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline: !moudescriptionvalidator
                        ? classes.errorstyle
                        : null,
                    },
                  }}
                  onChange={moudescriptionOnchange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <h4>1. The liability of the members is limited</h4>
              </Grid>
              <Grid item xs={6}>
                <h4>2. The company is a private company</h4>
              </Grid>

              <Grid item xs={5}>
                <h4>The share capital of the company shall consist of</h4>
              </Grid>

              <Grid item xs={3}>
                <TextField
                  value={moutotalshares}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    className: !moutotalsharesvalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !moutotalsharesvalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline: !moutotalsharesvalidator
                        ? classes.errorstyle
                        : null,
                    },
                  }}
                  placeholder="insert total authorized share capital"
                  onChange={moutotalsharesOnchange}
                />
              </Grid>

              <Grid item xs={1}>
                <h4>divided into </h4>
              </Grid>

              <Grid item xs={3}>
                <TextField
                  placeholder="insert the number of shares"
                  value={mounumberofshares}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    className: !mounumberofsharesvalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !mounumberofsharesvalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline: !mounumberofsharesvalidator
                        ? classes.errorstyle
                        : null,
                    },
                  }}
                  onChange={mounumberofsharesOnchange}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <h5>
                {" "}
                We the several persons, whose names and addresses appear
                hereunder, are desirous of being formed into a Free Zone
                Enterprise (FZE) in pursuance of this Memorandum of Association,
                and we respectively agree to take the number of shares in the
                capital of the company to set opposite our respective names.
              </h5>
            </Grid>

            <Grid container xs={12} spacing={1} style={{ padding: 30 }}>
              <Grid item xs={12}>
                <TableContainer>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ backgroundColor: "#fff" }}>
                          Name of subscriber
                        </TableCell>
                        <TableCell style={{ backgroundColor: "#fff" }}>
                          Address
                        </TableCell>
                        <TableCell style={{ backgroundColor: "#fff" }}>
                          Number of Shares
                        </TableCell>
                        <TableCell style={{ backgroundColor: "#fff" }}>
                          Signature
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody component={Paper}>
                      {inputFields.map((inputField, index) => (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <TextField
                              variant="outlined"
                              id="outlined-full-width"
                              placeholder="Name"
                              name="name"
                              margin="normal"
                              value={inputField.name}
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                              InputProps={{
                                classes: {
                                  focused:
                                    dynamicnameval == index + 1
                                      ? classes.errorstyle
                                      : null,
                                  notchedOutline:
                                    dynamicnameval == index + 1
                                      ? classes.errorstyle
                                      : null,
                                },
                              }}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              variant="outlined"
                              id="outlined-full-width"
                              placeholder="Address"
                              margin="normal"
                              name="address"
                              value={inputField.address}
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                              InputProps={{
                                classes: {
                                  focused:
                                    dynamicaddressval == index + 1
                                      ? classes.errorstyle
                                      : null,
                                  notchedOutline:
                                    dynamicaddressval == index + 1
                                      ? classes.errorstyle
                                      : null,
                                },
                              }}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              variant="outlined"
                              id="outlined-full-width"
                              placeholder="Shares"
                              name="shares"
                              value={inputField.shares}
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                              margin="normal"
                              InputProps={{
                                classes: {
                                  focused:
                                    dynamicsharesval == index + 1
                                      ? classes.errorstyle
                                      : null,
                                  notchedOutline:
                                    dynamicsharesval == index + 1
                                      ? classes.errorstyle
                                      : null,
                                },
                              }}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <input
                              accept="*"
                              className={
                                dynamicsignatureval == index + 1
                                  ? classes.errorstyle
                                  : classes.input
                              }
                              style={{ display: "none" }}
                              id={"raised-button-file" + index}
                              multiple
                              type="file"
                              //value={inputField.signature}
                              name="signature"
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                            />
                            <label htmlFor={"raised-button-file" + index}>
                              <Button
                                fullWidth
                                style={{
                                  backgroundColor: "#fff",
                                  minHeight: 60,
                                }}
                                variant="outlined"
                                component="span"
                                className={
                                  dynamicsignatureval == index + 1
                                    ? classes.errorstyle
                                    : inputFields[index].signature.length > 0
                                    ? classes.successupload
                                    : classes.uploads
                                }
                              >
                                {inputFields[index].signature.length > 0
                                  ? inputFields[index].signature[0].name
                                  : "Upload Signature"}
                              </Button>
                            </label>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid container xs={12} justify="flex-end">
                <Box component="span" mx={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#ccc",
                      height: "30px",
                      color: "black",
                    }}
                    className={classes.button}
                    onClick={handleRemoveFields}
                    startIcon={
                      <ArrowBackIcon
                        style={{ fontSize: "20px", color: "black" }}
                      />
                    }
                  >
                    Remove
                  </Button>
                </Box>
                <Box component="span">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddFields}
                    style={{ backgroundColor: "#060454", height: "30px" }}
                    className={classes.button}
                    endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                  >
                    add subscribers
                  </Button>
                </Box>
              </Grid>
            </Grid>

            <Grid container xs={12}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-full-width"
                  type="date"
                  value={mouexpectedstartdate}
                  label="Expected MOU Start Date"
                  placeholder="Placeholder"
                  onChange={mouexpectedstartdateOnchange}
                  fullWidth
                  style={{ backgroundColor: "#fff" }}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    className: !mouexpectedstartdatevalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !mouexpectedstartdatevalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline: !mouexpectedstartdatevalidator
                        ? classes.errorstyle
                        : null,
                    },
                  }}
                  variant="outlined"
                />
              </Grid>
              <h4>Particulars of witnesses to the above signatures.</h4>
            </Grid>

            <Grid container xs={12}>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  id="outlined-full-width"
                  placeholder="Name"
                  name="witnessesname"
                  value={witnessesname}
                  onChange={witnessesnameOnchange}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    className: !witnessesnamevalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !witnessesnamevalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline: !witnessesnamevalidator
                        ? classes.errorstyle
                        : null,
                    },
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  id="outlined-full-width"
                  placeholder="Address"
                  name="address"
                  value={witnessesaddress}
                  onChange={witnessesaddressOnchange}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    className: !witnessesaddressvalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !witnessesaddressvalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline: !witnessesaddressvalidator
                        ? classes.errorstyle
                        : null,
                    },
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  id="outlined-full-width"
                  placeholder="Occupation"
                  name="occupation"
                  value={witnessesoccupation}
                  onChange={witnessesoccupationOnchange}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    className: !witnessesoccupationvalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !witnessesoccupationvalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline: !witnessesoccupationvalidator
                        ? classes.errorstyle
                        : null,
                    },
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <input
                  accept="*"
                  className={classes.input}
                  style={{ display: "none" }}
                  id={"raised-button-files"}
                  multiple
                  type="file"
                  name="mousignature"
                  //value={witnessessignature}
                  onChange={witnessessignatureOnchange}
                />
                <label htmlFor={"raised-button-files"}>
                  <Button
                    fullWidth
                    style={{
                      backgroundColor: "#fff",
                      minHeight: 55,
                      marginTop: 15,
                    }}
                    variant="outlined"
                    component="span"
                    className={
                      !witnessessignaturevalidator
                        ? classes.errorstyle
                        : witnessessignature.length > 0
                        ? classes.successupload
                        : classes.uploads
                    }
                  >
                    {witnessessignature.length > 0
                      ? witnessessignature[0].name
                      : "Upload Signature"}
                  </Button>
                </label>
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              justify="flex-end"
              style={{ marginBottom: "100px" }}
            >
              <Box component="span">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={!formloader ? onPreviewMou : null}
                  style={{ backgroundColor: "#060454", height: "30px" }}
                  className={classes.button}
                  endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                >
                  Preview & Continue
                  {props.formloader ? <CircularProgress size={24} /> : null}
                </Button>
              </Box>
              {/* <Box component="span">
            <Button
              variant="contained"
              color="primary"
              onClick={!formloader ? onSubmitMou : null}
              style={{ backgroundColor: "#060454", height: "30px" }}
              className={classes.button}
              endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
            >
              Save & Continue{" "}
              {formloader ? <CircularProgress size={24} /> : null}
            </Button>
          </Box> */}
            </Grid>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "#fff",
              marginTop: 25,
              padding: 30,
              marginBottom: "7rem",
            }}
          >
            <Grid container style={{display:'flex', columnGap:'10px'}}>
              {previousFIlledData?.authorizedshare && (
                <Grid xs={4}>
                  {previousFIlledData?.authorizedshare?.status === 0 && (
                    <>
                      <label>Company Registered Name</label>
                      <input
                        disabled
                        value={previousFIlledData?.authorizedshare?.value}
                      />
                    </>
                  )}
                </Grid>
              )}
              {previousFIlledData?.moudate && (
                <Grid xs={4}>
                  {previousFIlledData?.moudate?.status === 0 && (
                    <>
                      <label>MOU Start Date</label>
                      <input
                        disabled
                        value={previousFIlledData?.moudate?.value}
                      />
                    </>
                  )}
                </Grid>
              )}
              {previousFIlledData?.objofstablished && (
                <Grid xs={4}>
                  {previousFIlledData?.objofstablished?.status === 0 && (
                    <>
                      <label>Company Registered Name</label>
                      <input
                        disabled
                        value={previousFIlledData?.objofstablished?.value}
                      />
                    </>
                  )}
                </Grid>
              )}
              {previousFIlledData?.totalshares && (
                <Grid xs={4}>
                  {previousFIlledData?.totalshares?.status === 0 && (
                    <>
                      <label>Company Registered Name</label>
                      <input
                        disabled
                        value={previousFIlledData?.totalshares?.value}
                      />
                    </>
                  )}
                </Grid>
              )}
              {previousFIlledData?.witnessaddress && (
                <Grid xs={4}>
                  {previousFIlledData?.witnessaddress?.status === 0 && (
                    <>
                      <label>Company Registered Name</label>
                      <input
                        disabled
                        value={previousFIlledData?.witnessaddress?.value}
                      />
                    </>
                  )}
                </Grid>
              )}
              {previousFIlledData?.witnessname && (
                <Grid xs={4}>
                  {previousFIlledData?.witnessname?.status === 0 && (
                    <>
                      <label>Company Registered Name</label>
                      <input
                        disabled
                        value={previousFIlledData?.witnessname?.value}
                      />
                    </>
                  )}
                </Grid>
              )}
              {previousFIlledData?.witnessoccupation && (
                <Grid xs={4}>
                  {previousFIlledData?.witnessoccupation?.status === 0 && (
                    <>
                      <label>Company Registered Name</label>
                      <input
                        disabled
                        value={previousFIlledData?.witnessoccupation?.value}
                      />
                    </>
                  )}
                </Grid>
              )}
              <Grid container>
                {previousFIlledData?.shareholders?.map((e, index) => (
                  <>
                    {" "}
                    <Grid xs={12}>
                      <b>Share holder {index + 1}</b>{" "}
                    </Grid>
                    {/* <label>Company Registered Name</label> */}
                    {e?.address && (
                      <Grid xs={4}>
                        <div>Address</div>
                        <input disabled value={e?.address} />
                      </Grid>
                    )}
                    {e?.name && (
                      <Grid xs={4}>
                        <div>Name</div>
                        <input disabled value={e?.name} />
                      </Grid>
                    )}
                    {e?.number && (
                      <Grid xs={4}>
                        <div>number</div>
                        <input disabled value={e?.number} />
                      </Grid>
                    )}
                    {e?.signarure && (
                      <div
                        style={{
                          marginRight: "1rem",
                          display: "flex",
                          flexDirection: "row",
                          columnGap: "1rem",
                          alignItems: "center",
                        }}
                      >
                        <b>Signature</b>
                        <img
                          src={
                            e?.signarure.split(".").pop() == "pdf" ? pdf : txt
                          }
                          style={{
                            width: 100,
                            height: 100,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            showimage(
                              e?.signarure
                              // e?.signarure
                            );
                          }}
                        />
                      </div>
                    )}
                  </>
                ))}
              </Grid>
              {previousFIlledData?.witnesssignature && (
                <Grid xs={4}>
                  {previousFIlledData?.witnesssignature?.status === 0 && (
                    <>
                       <b>Witness Signature</b>
                        <img
                          src={
                            previousFIlledData?.witnesssignature?.file_path.split(".").pop() == "pdf" ? pdf : txt
                          }
                          style={{
                            width: 100,
                            height: 100,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            showimage(
                              previousFIlledData?.witnesssignature?.file_path
                              // e?.signarure
                            );
                          }}
                        />
                    </>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                // onClick={!formloader ? previewStageData : null}
                style={{ backgroundColor: "#060454", height: "30px" }}
                className={classes.button}
                endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                onClick={() => {
                  setformEdit(true);
                  setserverData(false);
                }}
              >
                Refill Form
              </Button>
            </Grid>
          </div>
        )}
      </Grid>
      <AppBar position="fixed" className={classes.appBarButom} color="#060454">
        <Typography
          variant="span"
          color="inherit"
          noWrap
          style={{ textAlign: "center", color: "#fff", alignItems: "center" }}
          justify="center"
        >
          Copyright © 2022 <b>Oil and Gas Free Zones Authority</b>. All Rights Reserved.
        </Typography>
      </AppBar>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    position: "relative",
  },
  checkboxLabel: {
    fontSize: 14,
  },
  appBarButom: {
    top: "auto",
    bottom: 0,
    height: "40px",
    backgroundColor: "#060454",
    paddingTop: "5px",
    paddingButtom: "5px",
    alignItems: "center",
    justifyContent: "center",
  },
  errorstyle: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
  uploads: {
    border: "1px dashed #ccc",
  },
  successupload: {
    border: "1px dashed green",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "60%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

export default Applicationform0;
