import React, {useState,useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import LockOutlineIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles,withStyles, } from '@material-ui/core/styles';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import oilandgaslogo from '../../oilandgaslogo.png';
import axios from 'axios';
import { Redirect } from 'react-router';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import LockIcon from '@material-ui/icons/Lock';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';






const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '13px',
      },
    },
  },
})(TextField);


function Login(props) {


    useEffect( () => {
        // user this to pass new value
        if ("token" in localStorage) {
            window.location = '/dashboard';
        }


    },[]);

    const classes = useStyles();
    const [email,setEmail] = useState('');
    const [showtoast,setShowtoast] = useState(false);
    const [error,setError] = useState('');
    const [loader,setLoader] = useState(false);
    const [password,setPassword] = useState('');

    const onChangeEmail = (e) =>{
        setEmail(e.target.value);
    }

    const onChangePassword = (e) =>{
        setPassword(e.target.value);
    }
    const submitLogin = async () =>{
        const usercredentials = {
            username: email,
            password:password
        };
        setShowtoast(false)
        if(!email.length){
            setError("Username cant be blank")
            setShowtoast(true)

            return false

        }

        if( !password.length){

            setError("Password cant be blank")
            setShowtoast(true)
            return false

        }


    setLoader(true)
    await axios.post('https://ogfzaapi.skillzserver.com/site/login',  usercredentials )
      .then(res => {
          setLoader(false)
        if(res.data?.status == 1){
            localStorage.setItem('token', res?.data?.result.access_token);
            localStorage.setItem('priv', res?.data.result.priv);
            localStorage.setItem('username', res.data.result.username);
            window.location = '/dashboard';
        }else{
            setError("Username or Password is wrong")
            setShowtoast(true)
        }
      }).catch(error=>{
        console.log(error)
      })
    }

    return (
        <React.Fragment>

        <Grid container component="main" className={classes.root}>

        <CssBaseline />
        <Grid container spacing={0}  xs={false} sm={6} md={6} className={classes.image} alignItems="center"
       justify="center" component={Paper} square >


        <div style={{ width: '70%',textAlign:'center' }}>

            <Box component="div"  m={0} display="block">
                <img src={oilandgaslogo} alt="Logo"  style={{width:'160px',textAlign:"center",alignSelf:'center'}}/>
            </Box>
            <Box component="p"  m={0} display="block">
                <h3> Application For Free Zones Registration</h3>
                <Typography variant="body1" gutterBottom>
                    The <b>Oil and Gas Free Zones Authority</b> (OGFZA) is the national regulatory agency superintending over the operation of the oil and gas free trade zones in the country.
                </Typography>
            </Box>
        </div>

       </Grid>
        <Grid container spacing={0} xs={12} sm={6} md={6}  className={classes.signinFormGrid} alignItems="center" justify="center"  >

          <div className={classes.formHolder}>
          {showtoast == true ?
          <Alert severity="error" color="error">
            {error}
          </Alert>
          :null}
            <form className={classes.form} noValidate>



              <Grid style={{ border: "1px solid #fff" ,borderRadius:'8px',padding:'3px 3px 10px 25px'}}container spacing={1} alignItems="flex-end">
                <Grid item xs={2}>

                  <PersonOutlineIcon style={{ color: " #ccc",fontSize:'35px'}} />
                </Grid>
                <Grid item xs={10}>
                  <TextField
                      InputLabelProps={{className: classes.floatingLabelFocusStyle,}}
                      id=""
                      value={email}
                      onChange = {onChangeEmail}
                      label="Username"
                      fullWidth
                      InputProps={{
                          disableUnderline: true,
                          classes: {
                              input: classes.multilineColor
                          }
                      }
                  }
                  />
                </Grid>
              </Grid>

              <Grid style={{ border: "1px solid #fff" ,borderRadius:'8px',padding:'3px 3px 10px 25px',marginTop:10}} container spacing={1} alignItems="flex-end">
                <Grid item xs={2}>
                  <LockIcon style={{ color: " #ccc",fontSize:'35px'}} />
                </Grid>
                <Grid item xs={10}>
                  <TextField
                      InputLabelProps={{className: classes.floatingLabelFocusStyle,}}
                      id=""
                      type="password"
                      value={password}
                      onChange = {onChangePassword }
                      label="Password"
                      fullWidth
                      InputProps={{
                          disableUnderline: true,
                          classes: {
                              input: classes.multilineColor
                          }
                      }
                  }
                  />
                </Grid>
              </Grid>




              <Button
                type="button"

                variant="contained"
                color="primary"
                onClick = {submitLogin }
                className={classes.submit}
              >
                Login
                {loader == true ?
                <CircularProgress size={24}  />
                :null}
              </Button>


            </form>
          </div>
        </Grid>

      </Grid>
      </React.Fragment>
    );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    backgroundColor:'#fff',
  },
  emailcontainer: {
    border: 1,
  },
  multilineColor:{
        color:'#fff'
    },
    floatingLabelFocusStyle: {
        color: "#fff"
    },
  image: {
    backgroundImage: 'url(../../img/loginillustration.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? '#fff' : '#fff',
    backgroundSize: '67%',
    backgroundPosition: 'center',
  },

  signinFormGrid: {
    boxShadow:'none',
    background:`linear-gradient(${"rgba(255,255,255,0.5)"}, ${"transparent"})`,
    backgroundColor:"#060454"

  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor:'#0043ce',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formHolder: {
    width: '60%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#fff',
    borderRadius: '0.4rem',
    color:'#060454',
    width:'100%'
  },
  textfield: {
    borderRadius: '2rem',
  },
  email:{
      width:'60%',
      borderWidth:2,

  }
}));

export default Login;
