import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Skeleton from '@material-ui/lab/Skeleton';



function Skeletons() {
    const UseStyles = makeStyles((theme) => ({
      card: {
        width: "100%",
        margin: theme.spacing(2),
      },
      media: {
        height: 100,
      },
    }));
  const classes = UseStyles();

  return (
      <>
    <Card className={classes.card}>
      <CardHeader



        title={

            <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />

        }

      />

      <Skeleton animation="wave" variant="rect" className={classes.media} />



      <CardContent>

          <React.Fragment>
            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={13} width="80%" style={{ marginBottom: 6 }}/>
            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={16} style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={12} width="80%" style={{ marginBottom: 6 }}/>
            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
          </React.Fragment>

      </CardContent>
    </Card>

    <Card className={classes.card}>
      <CardHeader



        title={

            <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />

        }

      />

      <Skeleton animation="wave" variant="rect" className={classes.media} />



      <CardContent>

          <React.Fragment>
            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={13} width="80%" style={{ marginBottom: 6 }}/>
            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={16} style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={12} width="80%" style={{ marginBottom: 6 }}/>
            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
          </React.Fragment>

      </CardContent>
    </Card>
    </>
  );
}

export default Skeletons;
