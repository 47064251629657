import {
  Divider,
  Grid,
  Modal,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import pdf from "../../pdf.png";
import txt from "../../txt.png";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Viewer } from "@react-pdf-viewer/core";

export default function FormStagePreview(props) {
  const { data, formloaderSetter, formPreviewSetter } = props;

  const styles = useStyles();
  const [stageData, setstageData] = useState([]);

  const [imgtype, setImgtype] = React.useState("img");
  const [img, setImg] = React.useState("");
  const [openimg, setOpenimg] = React.useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    setstageData(data);
  }, []);

  console.log(data, stageData.address, "previewData");

  const handleCloseImg = () => {
    setImg("");
    setOpenimg(false);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const showimage = async (img, file) => {
    if (img.split(".").pop() == "pdf") {
      setImgtype("pdf");
      setImg(URL.createObjectURL(file));
      let newimg = "http://api.ogfza-registry.com/uploads/" + img;

      setOpenimg(true);

      return false;
    } else {
      const res = await getBase64(file);
      console.log(res);
      setImg(res);
      setOpenimg(true);

      console.log(file);
      setImgtype("img");
    }

    setOpenimg(true);
  };

  const classes = useStyles();
  return (
    <>
      <div
        style={{
          overflowY: "scroll",
          height: "80vh",
        }}
      >
        <Modal
          open={openimg}
          onClose={handleCloseImg}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // overflow: "scroll"
          }}
        >
          <div className={classes.modalview}>
            {imgtype == "pdf" ? (
              <Viewer fileUrl={img} />
            ) : (
              <img src={`${img}`} />
            )}
          </div>
        </Modal>
        <div style={{ marginBottom: "1rem", fontSize: "20px" }}>
          <b>Application Form Preview</b>{" "}
        </div>
        <Grid container item style={{ marginBottom: "2rem" }}>
          {data[0]?.map((element) => {
            if (element.value.split(".").pop() == "pdf") {
              return (
                <Grid xs={4}>
                  <div>
                    <div>
                      <b>{element.name}</b>{" "}
                    </div>
                    <img
                      src={pdf}
                      style={{ width: 100, height: 100, cursor: "pointer" }}
                      onClick={() => {
                        showimage(element.value, element.file);
                      }}
                    />
                  </div>
                </Grid>
              );
            } else if (
              element.value.split(".").pop() == "jpg" ||
              element.value.split(".").pop() == "jpeg" ||
              element.value.split(".").pop() == "png" ||
              element.value.split(".").pop() == "webp"
            ) {
              return (
                <Grid xs={4}>
                  <div style={{ marginBottom: "2rem" }}>
                    <div>
                      <b>{element.name}</b>{" "}
                    </div>
                    <img
                      src={txt}
                      style={{ width: 100, height: 100, cursor: "pointer" }}
                      onClick={() => {
                        showimage(element.value, element.file);
                      }}
                    />
                  </div>
                </Grid>
              );
            } else {
              if (element.nestedData) {
                return (
                  <div>
                    <div style={{ fontSize: "20px", color: "blue" }}>
                      {element.value}
                    </div>
                    {element.nestedData.map((e) => (
                      <>
                        {/* <Grid container spacing={1}> */}
                        <Grid xs={12} item style={{ marginRight: "1rem" }}>
                          <TableBody component={Paper}>
                            <TableRow>
                              <TableCell>
                                <div>
                                  <b>{e.name}</b>{" "}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  {e.data.map((value) => (
                                    <div style={{ marginRight: "1rem" }}>
                                      {value.name.split(" ")[0]}
                                      <input
                                        defaultValue={value.name.split(" ")[1]}
                                        disabled
                                      />
                                    </div>
                                  ))}
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Grid>
                        {/* </Grid> */}
                      </>
                    ))}
                  </div>
                );
              } else if (element.imgInputArra) {
                return (
                  <Grid container xs={12} style={{ backgroundColor: "" }}>
                    <div style={{ backgroundColor: "" }}>
                      {/* {(console.log(element), "sdhjshjs")} */}
                      {element.imgInputArraData?.map((datum, index) => (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {/* {(console.log(datum), "SDHSJS")} */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            {/* ///ASSUMED compulsory field && */}
                            {datum?.name && datum?.address && datum?.shares && (
                              <div
                                style={{
                                  marginRight: "1rem",
                                  display: "flex",
                                  flexDirection: "row",
                                  columnGap: "1rem",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <b>subscriber {index + 1}</b>{" "}
                                </div>

                                {datum?.name && (
                                  <div style={{ marginRight: "" }}>
                                    <div>{Object.keys({ ...datum })[0]}</div>
                                    <input
                                      defaultValue={datum?.name}
                                      disabled
                                    />
                                  </div>
                                )}
                                {datum.address && (
                                  <div style={{ marginRight: "1rem" }}>
                                    <div>{Object.keys({ ...datum })[1]}</div>
                                    <input
                                      defaultValue={datum?.address}
                                      disabled
                                    />
                                  </div>
                                )}
                                {datum.shares && (
                                  <div style={{ marginRight: "1rem" }}>
                                    <div>{Object.keys({ ...datum })[2]}</div>
                                    <input
                                      defaultValue={datum?.shares}
                                      disabled
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                            {(datum.activity || datum.approvedvendor) && (
                              <div>
                                <b>Nature of Businness</b>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  {datum.activity && (
                                    <div style={{ marginRight: "1rem" }}>
                                      {console.log(
                                        Object.keys({ ...datum }),
                                        "kdkjs"
                                      )}
                                      <div>{Object.keys({ ...datum })[0]}</div>
                                      <input
                                        defaultValue={datum?.activity}
                                        disabled
                                      />
                                    </div>
                                  )}

                                  {datum.approvedvendor && (
                                    <div style={{ marginRight: "1rem" }}>
                                      <div>{Object.keys({ ...datum })[1]}</div>
                                      <input
                                        defaultValue={datum?.approvedvendor}
                                        disabled
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}

                            {/* //// */}
                            {datum.value && (
                              <div>
                                <b>COMPANY INCORPORATION TYPE {index + 1}</b>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  {datum.value && (
                                    <div style={{ marginRight: "1rem" }}>
                                      {/* <div>{Object.keys({ ...datum })[0]}</div> */}
                                      <input
                                        style={{ width: "300px" }}
                                        defaultValue={datum?.value}
                                        disabled
                                      />
                                    </div>
                                  )}

                                  {/* {datum.approvedvendor && (
                                    <div style={{ marginRight: "1rem" }}>
                                      <div>{Object.keys({ ...datum })[1]}</div>
                                      <input
                                        defaultValue={datum?.approvedvendor}
                                        disabled
                                      />
                                    </div>
                                  )} */}
                                </div>
                              </div>
                            )}
                            {/* /// */}
                            {/* /// compulsory /// */}
                            {datum.email && datum.name && datum.phonenumber && (
                              <div>
                                <b>CONTACT INFORMATION {index + 1}</b>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  {datum.email && (
                                    <div style={{ marginRight: "1rem" }}>
                                      {console.log(
                                        Object.keys({ ...datum }),
                                        "kdkjs"
                                      )}
                                      <div>{Object.keys({ ...datum })[0]}</div>
                                      <input
                                        defaultValue={datum?.email}
                                        disabled
                                      />
                                    </div>
                                  )}

                                  {datum.name && (
                                    <div style={{ marginRight: "1rem" }}>
                                      <div>{Object.keys({ ...datum })[1]}</div>
                                      <input
                                        defaultValue={datum?.name}
                                        disabled
                                      />
                                    </div>
                                  )}
                                  {datum.phonenumber && (
                                    <div style={{ marginRight: "1rem" }}>
                                      <div>{Object.keys({ ...datum })[2]}</div>
                                      <input
                                        defaultValue={datum?.phonenumber}
                                        disabled
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}

                            {/* ////  Directors/Shareholder Information ///// */}
                            <Divider />
                            {datum.address &&
                              datum.dob &&
                              datum.name &&
                              datum.nationality &&
                              datum.occupation && (
                                <div>
                                  <b>
                                    Director Non Coorporate - Entity {index + 1}
                                  </b>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    {datum.name && (
                                      <div style={{ marginRight: "1rem" }}>
                                        <div>
                                          {Object.keys({ ...datum })[0]}
                                        </div>
                                        <input
                                          defaultValue={datum?.name}
                                          disabled
                                        />
                                      </div>
                                    )}
                                    {datum.dob && (
                                      <div style={{ marginRight: "1rem" }}>
                                        <div>
                                          {Object.keys({ ...datum })[1]}
                                        </div>
                                        <input
                                          defaultValue={datum?.dob}
                                          disabled
                                        />
                                      </div>
                                    )}
                                    {datum.occupation && (
                                      <div style={{ marginRight: "1rem" }}>
                                        <div>
                                          {Object.keys({ ...datum })[2]}
                                        </div>
                                        <input
                                          defaultValue={datum?.occupation}
                                          disabled
                                        />
                                      </div>
                                    )}
                                    {datum.address && (
                                      <div style={{ marginRight: "1rem" }}>
                                        {console.log(
                                          Object.keys({ ...datum }),
                                          "kdkjs"
                                        )}
                                        <div>
                                          {Object.keys({ ...datum })[3]}
                                        </div>
                                        <input
                                          defaultValue={datum?.address}
                                          disabled
                                        />
                                      </div>
                                    )}

                                    {datum.nationality && (
                                      <div style={{ marginRight: "1rem" }}>
                                        <div>
                                          {Object.keys({ ...datum })[4]}
                                        </div>
                                        <input
                                          defaultValue={datum?.nationality}
                                          disabled
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                            {/* ///// */}
                            {/* ////  Directors/Shareholder Information ///// */}
                            <Divider />
                            {datum.address &&
                              datum.country &&
                              datum.name &&
                              datum.share && (
                                <div>
                                  <b>Entity{index + 1}</b>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    {datum.name && (
                                      <div style={{ marginRight: "1rem" }}>
                                        <div>
                                          {Object.keys({ ...datum })[0]}
                                        </div>
                                        <input
                                          defaultValue={datum?.name}
                                          disabled
                                        />
                                      </div>
                                    )}
                                    {datum.address && (
                                      <div style={{ marginRight: "1rem" }}>
                                        {console.log(
                                          Object.keys({ ...datum }),
                                          "kdkjs"
                                        )}
                                        <div>
                                          {Object.keys({ ...datum })[1]}
                                        </div>
                                        <input
                                          defaultValue={datum?.address}
                                          disabled
                                        />
                                      </div>
                                    )}

                                    {datum.share && (
                                      <div style={{ marginRight: "1rem" }}>
                                        <div>
                                          {Object.keys({ ...datum })[2]}
                                        </div>
                                        <input
                                          defaultValue={datum?.share}
                                          disabled
                                        />
                                      </div>
                                    )}

                                    {datum.country && (
                                      <div style={{ marginRight: "1rem" }}>
                                        <div>
                                          {Object.keys({ ...datum })[3]}
                                        </div>
                                        <input
                                          defaultValue={datum?.country}
                                          disabled
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                            {/* ///// */}
                            {/* ////  Directors/Shareholder Information ///// */}
                            <div
                              style={{ borderBottom: "2px solid black" }}
                            ></div>

                            {datum.address &&
                              datum.date &&
                              datum.name &&
                              datum.document && (
                                <div>
                                  <b>
                                    Share Holder Corporate - Entity{index + 1}
                                  </b>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    {datum.name && (
                                      <div style={{ marginRight: "1rem" }}>
                                        <div>
                                          {Object.keys({ ...datum })[0]}
                                        </div>
                                        <input
                                          defaultValue={datum?.name}
                                          disabled
                                        />
                                      </div>
                                    )}
                                    {datum.address && (
                                      <div style={{ marginRight: "1rem" }}>
                                        {console.log(
                                          Object.keys({ ...datum }),
                                          "kdkjs"
                                        )}
                                        <div>
                                          {Object.keys({ ...datum })[1]}
                                        </div>
                                        <input
                                          defaultValue={datum?.address}
                                          disabled
                                        />
                                      </div>
                                    )}
                                    <Divider />
                                    {datum.date && (
                                      <div style={{ marginRight: "1rem" }}>
                                        <div>
                                          {Object.keys({ ...datum })[3]}
                                        </div>
                                        <input
                                          defaultValue={datum?.date}
                                          disabled
                                        />
                                      </div>
                                    )}

                                    {datum?.document && (
                                      <div
                                        style={{
                                          marginRight: "1rem",
                                          display: "flex",
                                          flexDirection: "row",
                                          columnGap: "1rem",
                                          alignItems: "center",
                                        }}
                                      >
                                        <b>document</b>
                                        <img
                                          src={txt}
                                          style={{
                                            width: 100,
                                            height: 100,
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            showimage(
                                              datum?.document[0].name,
                                              datum?.document[0]
                                            );
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                            {/* ////  Directors/Shareholder Information ///// */}
                            <Divider />
                            {datum.address &&
                              datum.numberofshare &&
                              datum.name &&
                              datum.nationality &&
                              datum.resident && (
                                <div>
                                  <b>Share Holder Details {index + 1}</b>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    {datum.name && (
                                      <div style={{ marginRight: "1rem" }}>
                                        <div>
                                          {Object.keys({ ...datum })[0]}
                                        </div>
                                        <input
                                          defaultValue={datum?.name}
                                          disabled
                                        />
                                      </div>
                                    )}
                                    {datum.nationality && (
                                      <div style={{ marginRight: "1rem" }}>
                                        <div>
                                          {Object.keys({ ...datum })[1]}
                                        </div>
                                        <input
                                          defaultValue={datum?.nationality}
                                          disabled
                                        />
                                      </div>
                                    )}

                                    {datum.numberofshare && (
                                      <div style={{ marginRight: "1rem" }}>
                                        {console.log(
                                          Object.keys({ ...datum }),
                                          "kdkjs"
                                        )}
                                        <div>
                                          {Object.keys({ ...datum })[2]}
                                        </div>
                                        <input
                                          defaultValue={datum?.numberofshare}
                                          disabled
                                        />
                                      </div>
                                    )}

                                    {datum.resident && (
                                      <div style={{ marginRight: "1rem" }}>
                                        <div>
                                          {Object.keys({ ...datum })[3]}
                                        </div>
                                        <input
                                          defaultValue={datum?.resident}
                                          disabled
                                        />
                                      </div>
                                    )}
                                    {datum.address && (
                                      <div style={{ marginRight: "1rem" }}>
                                        <div>
                                          {Object.keys({ ...datum })[4]}
                                        </div>
                                        <input
                                          defaultValue={datum?.address}
                                          disabled
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                            {/* /'''""""' */}
                            <Divider />

                            <div>
                              {(datum.addressvendor ||
                                datum.namevendor ||
                                datum.servicevendor) && (
                                <div>
                                  <div>
                                    <b>List of Vendors/ Service Providers</b>{" "}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    {datum.addressvendor && (
                                      <div style={{ marginRight: "1rem" }}>
                                        <div>
                                          {Object.keys({ ...datum })[0]}
                                        </div>
                                        <input
                                          defaultValue={datum?.addressvendor}
                                          disabled
                                        />
                                      </div>
                                    )}
                                    {datum.namevendor && (
                                      <div style={{ marginRight: "1rem" }}>
                                        <div>
                                          {Object.keys({ ...datum })[1]}
                                        </div>
                                        <input
                                          defaultValue={datum?.namevendor}
                                          disabled
                                        />
                                      </div>
                                    )}
                                    {datum.servicevendor && (
                                      <div style={{ marginRight: "1rem" }}>
                                        <div>
                                          {Object.keys({ ...datum })[2]}
                                        </div>
                                        <input
                                          defaultValue={datum?.servicevendor}
                                          disabled
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                            {(datum.exported ||
                              datum.valexport ||
                              datum.volexport) && (
                              <div>
                                <div>
                                  {" "}
                                  <b>EXPORTS</b>{" "}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  {datum.exported && (
                                    <div style={{ marginRight: "1rem" }}>
                                      <div>{Object.keys({ ...datum })[0]}</div>
                                      <input
                                        defaultValue={datum?.exported}
                                        disabled
                                      />
                                    </div>
                                  )}

                                  {datum.valexport && (
                                    <div style={{ marginRight: "1rem" }}>
                                      <div>{Object.keys({ ...datum })[1]}</div>
                                      <input
                                        defaultValue={datum?.valexport}
                                        disabled
                                      />
                                    </div>
                                  )}
                                  {datum.volexport && (
                                    <div style={{ marginRight: "1rem" }}>
                                      <div>{Object.keys({ ...datum })[2]}</div>
                                      <input
                                        defaultValue={datum?.volexport}
                                        disabled
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            {/* {datum.activity && (
                              <div style={{ marginRight: "1rem" }}>
                                <div>{Object.keys({ ...datum })[2]}</div>
                                <input
                                  defaultValue={datum?.activity}
                                  disabled
                                />
                              </div>
                            )}
                            {datum.approvedvendor && (
                              <div style={{ marginRight: "1rem" }}>
                                <div>{Object.keys({ ...datum })[2]}</div>
                                <input
                                  defaultValue={datum?.approvedvendor}
                                  disabled
                                />
                              </div>
                            )} */}
                            {/* //// */}
                            {(datum.valoversea ||
                              datum.voloversea ||
                              datum.valueimported ||
                              datum.valueimport) && (
                              <div>
                                <div>
                                  <b>IMPORTS</b>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  {datum.imported && (
                                    <div style={{ marginRight: "1rem" }}>
                                      <div>{Object.keys({ ...datum })[0]}</div>
                                      <input
                                        defaultValue={datum?.imported}
                                        disabled
                                      />
                                    </div>
                                  )}
                                  {datum.valoversea && (
                                    <div style={{ marginRight: "1rem" }}>
                                      <div>{Object.keys({ ...datum })[1]}</div>
                                      <input
                                        defaultValue={datum?.valoversea}
                                        disabled
                                      />
                                    </div>
                                  )}
                                  {datum.valueimport && (
                                    <div style={{ marginRight: "1rem" }}>
                                      <div>{Object.keys({ ...datum })[2]}</div>
                                      <input
                                        defaultValue={datum?.valueimport}
                                        disabled
                                      />
                                    </div>
                                  )}
                                  {datum.valueimported && (
                                    <div style={{ marginRight: "1rem" }}>
                                      <div>{Object.keys({ ...datum })[3]}</div>
                                      <input
                                        defaultValue={datum?.valueimported}
                                        disabled
                                      />
                                    </div>
                                  )}
                                  {datum.voloversea && (
                                    <div style={{ marginRight: "1rem" }}>
                                      <div>{Object.keys({ ...datum })[4]}</div>
                                      <input
                                        defaultValue={datum?.voloversea}
                                        disabled
                                      />
                                    </div>
                                  )}
                                  {datum.volumeimport && (
                                    <div style={{ marginRight: "1rem" }}>
                                      <div>{Object.keys({ ...datum })[5]}</div>
                                      <input
                                        defaultValue={datum?.volumeimport}
                                        disabled
                                      />
                                    </div>
                                  )}
                                  {datum.volumeimported && (
                                    <div style={{ marginRight: "1rem" }}>
                                      <div>{Object.keys({ ...datum })[6]}</div>
                                      <input
                                        defaultValue={datum?.volumeimported}
                                        disabled
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>

                          <div>
                            {/* {datum?.document && (
                              <div
                                style={{
                                  marginRight: "1rem",
                                  display: "flex",
                                  flexDirection: "row",
                                  columnGap: "1rem",
                                  alignItems: "center",
                                }}
                              >
                                <b>document</b>
                                <img
                                  src={txt}
                                  style={{
                                    width: 100,
                                    height: 100,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    showimage(
                                      datum?.document[0].name,
                                      datum?.document[0]
                                    );
                                  }}
                                />
                              </div>
                            )} */}
                          </div>

                          <div>
                            {datum?.signature && (
                              <div
                                style={{
                                  marginRight: "1rem",
                                  display: "flex",
                                  flexDirection: "row",
                                  columnGap: "1rem",
                                  alignItems: "center",
                                }}
                              >
                                <b>Signature</b>
                                <img
                                  src={txt}
                                  style={{
                                    width: 100,
                                    height: 100,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    showimage(
                                      datum?.signature[0].name,
                                      datum?.signature[0]
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </Grid>
                );
              } else {
                if (element?.arrayInputs) {
                  return (
                    <Grid container xs={12}>
                      <Grid xs={12} style={{ marginBottom: ".6rem" }}>
                        <div>
                          <b>
                            <i>{element?.name}</i>{" "}
                          </b>
                        </div>
                      </Grid>
                      {element?.arrayInputs?.map((e) => (
                        <Grid xs={4}>
                          {console.log(e,'cjckxx')}
                          {" "}
                          {/* <Grid xs={4}> */}{" "}
                          <div style={{ marginRight: "1rem" }}>
                            {" "}
                            <div>{e?.name || e?.type || e?.nameauthorised_shares_capital && "nameauthorised_shares_capital" || e?.dividend_into  && "dividend_into" || e?.dividend_ratio && "dividend_ratio" || e?.issued_share_capital && "issued_share_capital" || e?.paid_up_capital && "paid_up_capital"}</div>
                            <input
                              defaultValue={e?.value || e?.name || e?.nameauthorised_shares_capital || e?.dividend_into || e?.dividend_ratio || e?.issued_share_capital || e?.paid_up_capital}
                              disabled
                            />
                          </div>
                          {/* </Grid> */}
                        </Grid>
                      ))}
                    </Grid>
                  );
                } else {
                  return (
                    <Grid xs={4}>
                      <div style={{ marginRight: "1rem" }}>
                        <div>
                          <b>{element?.name}</b>
                        </div>
                        <input defaultValue={element?.value} disabled />
                      </div>
                    </Grid>
                  );
                }
              }
            }
          })}
        </Grid>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundColor: "#fff",
  },
  emailcontainer: {
    border: 1,
  },
  multilineColor: {
    color: "#fff",
  },
  floatingLabelFocusStyle: {
    color: "#fff",
  },
  image: {
    backgroundImage: "url(../../img/loginillustration.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.type === "light" ? "#fff" : "#fff",
    backgroundSize: "67%",
    backgroundPosition: "center",
  },

  signinFormGrid: {
    boxShadow: "none",
    backgroundColor: "#060454",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#0043ce",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formHolder: {
    width: "60%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#fff",
    borderRadius: "0.4rem",
    color: "#060454",
    width: "100%",
  },
  textfield: {
    borderRadius: "2rem",
  },
  email: {
    width: "60%",
    borderWidth: 2,
  },
  modalview: {
    position: "absolute",
    width: 800,
    height: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "scroll",
  },
}));
