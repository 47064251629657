import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";
// import { TabPanel } from '@mui/lab';
import React from "react";
import PropTypes from "prop-types";
import DocumentDetails from "../DocumentDetails";
import RenewalList from "../../Views/Renewal/RenewalList";

import "../../index.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function RegistryTab(props) {
  //   const classes = useStyles();
  const { data, showimage, ...other } = props;
  const [value, setValue] = React.useState(0);
  console.log(props);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: "white",
            },
          }}
        >
          <Tab label="Applicant Licence" {...a11yProps(0)} />
          <Tab label="Licence Renewal Request" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <DocumentDetails data={data} showimage={showimage} />
      </TabPanel>
      <TabPanel style={{ width: "100%" }} value={value} index={1}>
        <RenewalList data={data} showimage={showimage} />
      </TabPanel>
    </>
  );
}
