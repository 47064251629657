import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LockOutlineIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import oilandgaslogo from "../../assets/oilandgaslogo.png";
import MenuIcon from "@material-ui/icons/Menu";
import DescriptionIcon from "@material-ui/icons/Description";
import Applicantlist from "../Applicants/Applicantlist";
import Applicantdetails from "../Applicants/Applicantdetails";
import Skeletons from "../../components/skeleton";
import Dashboardcontent from "./Dashboardcontent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ClearIcon from "@material-ui/icons/Clear";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import HourglassFullOutlinedIcon from "@material-ui/icons/HourglassFullOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import CancelPresentationOutlinedIcon from "@material-ui/icons/CancelPresentationOutlined";
import axios from "axios";
import Modal from "@material-ui/core/Modal";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import { approvalValidation } from "../../Services/apiServices/Urls";

document.body.style = "background: #F6F4F5;";

function Dashboard(props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [listloader, setListloader] = useState("");
  const [dashboardloader, setDashboardloader] = useState("");
  const [active, setActive] = useState("");
  const [password, setPassword] = useState("");
  const [report, setReport] = useState("");
  const [userslistmodal, setUserslistmodal] = React.useState(false);
  const [createuserstate, setCreateuserstate] = React.useState(false);
  const [dense, setDense] = React.useState(false);
  const [sectionloader, setSectionloader] = React.useState("");
  const [secondary, setSecondary] = React.useState(false);
  const [displaydashboard, setDisplaydashboard] = React.useState(1);
  const [applicantlist, setApplicantlist] = React.useState([]);
  const [dense2, setDense2] = React.useState(false);
  const [dashboardcountinit, setDashboardcountinit] = React.useState(false);
  const [dashboardcount, setDashboardcount] = React.useState([]);
  const [applicantdetails, setApplicantdetails] = React.useState([]);
  const [openreport, setOpenreport] = React.useState(false);
  const [rootid, setRootid] = React.useState(false);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [role, setRole] = React.useState("general");
  const [username, setUsername] = React.useState("");
  const [useremail, setUseremail] = React.useState("");
  const [userpassword, setUserpassword] = React.useState("");
  const [userloader, setUserloader] = React.useState(false);
  const [opensnakbar, setOpensnakbar] = React.useState(false);
  const [error, setError] = React.useState("");
  const [userslist, setUserslist] = React.useState([]);
  const [applicantID, setapplicantID] = useState("");
  const TOKEN = localStorage.getItem("token");
  const PRIV = localStorage.getItem("priv");
  const USERNAME = localStorage.getItem("username");

  const declinemodal = (value) => {
    setRootid(value);
    setOpenreport(true);
  };
  const handleClosedeclinemodal = (value) => {
    setRootid(value);
    setOpenreport(false);
  };

  useEffect(() => {
    // user this to pass new value
    if (TOKEN === null) {
      window.location = "/login";
    }
  }, []);

  const reportonchange = (value) => {
    setReport(value);
  };



  const renderSwitch = (value) => {
    switch (value) {
      case 2:
        return (
          <Applicantdetails
            root_id={applicantID}
            rootid={rootid}
            applicantdetails={applicantdetails}
            approve={approve}
            approvesection={approvesection}
            decline={decline}
            declinesection={declinesection}
            closedetails={closedetails}
            priv={PRIV}
            sectionloader={sectionloader}
          />
        );
      case 3:
        return <Skeletons />;

      default:
        return (
          <Dashboardcontent
            dashboardcount={dashboardcount}
            getdashboardcountInit={getdashboardcountInit}
            dashboardcountinit={dashboardcountinit}
            getApplicantListbyroute={getApplicantListbyroute}
            priv={PRIV}
            dashboardloader={dashboardloader}
          />
        );
    }
  };

  const closedetails = (value) => {
    setActive("");
    setDisplaydashboard(value);
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const getApplicantListInit = () => {
    setListloader("");
    console.log("newtok", TOKEN);
    axios({
      method: "post",
      url: "https://ogfzaapi.skillzserver.com/site/applicantlist",
      headers: {
        "x-access-token": TOKEN,
        "Content-Type": "application/json",
      },
      //data:{ customer_id: global.id, payment_mode: paymentMode, address_id:this.props.address, expected_delivery_date:this.props.delivery_date, total:this.props.total, discount:this.props.discount, sub_total:this.props.sub_total, promo_id:this.props.promo_id, items: JSON.stringify(Object.values(this.props.items)) }
    }).then((res) => {
      if (res.data.status == 1) {
        console.log(res.data, "djjkksjs");
        setDense2(true);
        if (res?.data?.result?.applicant?.length > 0) {
          setListloader(1);
        } else {
          setListloader(2);
        }
        setApplicantlist(res?.data?.result);
      }
    });
  };

  const getApplicantListbyroute = (route) => {
    setListloader("");
    axios({
      method: "post",
      url: "https://ogfzaapi.skillzserver.com/site/dashboardroute?route=" + route,
      headers: {
        "x-access-token": TOKEN,
        "Content-Type": "application/json",
      },
      //data:{ customer_id: global.id, payment_mode: paymentMode, address_id:this.props.address, expected_delivery_date:this.props.delivery_date, total:this.props.total, discount:this.props.discount, sub_total:this.props.sub_total, promo_id:this.props.promo_id, items: JSON.stringify(Object.values(this.props.items)) }
    }).then((res) => {
      setapplicantID(res?.data?.result?.applicant[0]?.root_id);
      if (res.data.status == 1) {
        if (res?.data?.result?.applicant?.length > 0) {
          setListloader(1);
        } else {
          setListloader(2);
        }
        setApplicantlist(res?.data?.result);
      }
    });
  };

  const getdashboardcountInit = () => {
    setDashboardloader("");
    axios({
      method: "post",
      url: "https://ogfzaapi.skillzserver.com/site/dashboardcouter",
      headers: {
        "x-access-token": TOKEN,
        "Content-Type": "application/json",
      },
      //data:{ customer_id: global.id, payment_mode: paymentMode, address_id:this.props.address, expected_delivery_date:this.props.delivery_date, total:this.props.total, discount:this.props.discount, sub_total:this.props.sub_total, promo_id:this.props.promo_id, items: JSON.stringify(Object.values(this.props.items)) }
    }).then((res) => {
      console.log("applicantlist", res.data);

      if (res?.data?.status == 1) {
        setDashboardcount(res?.data?.result);
        setDashboardcountinit(true);
      }
      setDashboardloader(1);
    });
  };

  const approve = (id) => {
    axios({
      method: "get",
      url: "https://ogfzaapi.skillzserver.com/site/approve?id=" + id,
      headers: {
        "x-access-token": TOKEN,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      console.log("applicantlist", res.data);
      if (res.data.status == 1) {
        //setDense2(true)

        setApplicantlist(res.data.result.applicant_list);
        setApplicantdetails(res.data.result.applicant_details);
        //console.log('applicantlist',res.data.result)
      }
    });
  };

  const decline = (id) => {
    console.log("newtok", TOKEN);

    axios({
      method: "post",
      url: "https://ogfzaapi.skillzserver.com/site/decline?id=" + id,
      headers: {
        "x-access-token": TOKEN,
        "Content-Type": "application/json",
      },
      data: { reason: report },
    }).then((res) => {
      console.log("applicantlist", res.data);
      if (res.data.status == 1) {
        //setDense2(true)
        setReport("");
        setOpenreport(false);
        setApplicantlist(res.data.result.applicant_list);
        setApplicantdetails(res.data.result.applicant_details);
        //console.log('applicantlist',res.data.result)
      }
    });
  };


  const approvesection = (section, id) => {

    console.log(section, 'kslslslksk')
    setSectionloader(section + id);
    axios({
      method: "post",
      url: "https://ogfzaapi.skillzserver.com/site/approvesection?id=" + id,
      headers: {
        "x-access-token": TOKEN,
        "Content-Type": "application/json",
      },
      data: { section: section },
    }).then((res) => {
      setSectionloader("");
      if (res?.data?.status == 1) {
        setApplicantdetails(res?.data?.result);
      }
    });
  };


  const declinesection = (section, id) => {
    setSectionloader(section + id);
    console.log("newtok", TOKEN);
    axios({
      method: "post",
      url: "https://ogfzaapi.skillzserver.com/site/declinesection?id=" + id,
      headers: {
        "x-access-token": TOKEN,
        "Content-Type": "application/json",
      },
      data: { section: section },
    }).then((res) => {
      console.log("applicantlist", res.data);
      setSectionloader("");
      if (res?.data?.status == 1) {
        //setDense2(true)
        setApplicantdetails(res.data.result);
      }
    });
  };

  const getApplicantDetails = async (id) => {
    console.log(applicantID, "jdsssssssjssjks");
    setapplicantID(id);
    setDisplaydashboard(3);
    console.log("newtok", TOKEN);
    axios({
      method: "post",
      url: "https://ogfzaapi.skillzserver.com/site/applicantdetails?id=" + id,
      headers: {
        "x-access-token": TOKEN,
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      console.log("applicandekktails", res.data);
      if (res.data.status == 1) {
        setActive(id);
        setApplicantdetails(res.data.result);
        setDisplaydashboard(2);
      }
    });
  };

  const test = () => {
    alert(123);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const submitEmail = () => {};

  const openmenu1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const closemenu1 = () => {
    setAnchorEl1(null);
  };

  const openmenu2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const closemenu2 = () => {
    setAnchorEl2(null);
  };
  const logout = () => {
    localStorage.clear();
    window.location = "/login";
  };

  const closeuserslistmodal = () => {
    setUserslistmodal(false);
  };
  const closecreateuserstate = () => {
    setCreateuserstate(false);
  };

  const openuserslistmodal = () => {
    axios({
      method: "post",
      url: "https://ogfzaapi.skillzserver.com/site/getusers",
      headers: {
        "x-access-token": TOKEN,
        "Content-Type": "application/json",
      },

      data: {
        username: username,
        email: useremail,
        password: userpassword,
        role: role,
      },
    }).then(async (res) => {
      setUserloader(false);
      console.log("applicandetails", res.data);
      if (res.data.status == 1) {
        setUserslistmodal(true);
        setUserslist(res.data.result);
      } else {
        setOpensnakbar(true);
        setError("Could not get users.");
      }
      setCreateuserstate(false);
    });
  };
  const opencreateuserstate = () => {
    setCreateuserstate(true);
  };
  const handleChangeRole = (event) => {
    setRole(event.target.value);
  };
  const useremailOnchange = (event) => {
    setUseremail(event.target.value);
  };
  const usernameOnchange = (event) => {
    setUsername(event.target.value);
  };
  const userpasswordOnchange = (event) => {
    setUserpassword(event.target.value);
  };
  const closesnakbar = () => {
    setOpensnakbar(false);
  };

  const createUser = () => {
    setUserloader(true);
    axios({
      method: "post",
      url: "https://ogfzaapi.skillzserver.com/site/register",
      headers: {
        "x-access-token": TOKEN,
        "Content-Type": "application/json",
      },

      data: {
        username: username,
        email: useremail,
        password: userpassword,
        role: role,
      },
    }).then(async (res) => {
      setUserloader(false);
      console.log("applicandetails", res.data);
      if (res.data.status == 1) {
        setOpensnakbar(true);
        setError("User Was Creaded Successfuly");
      } else {
        setOpensnakbar(true);
        setError("Something went wrong please try again later.");
      }
      setCreateuserstate(false);
    });
  };

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={opensnakbar}
        onClose={closesnakbar}
        message={error}
        key={"topcenter"}
      />
      <Modal
        open={userslistmodal}
        onClose={closeuserslistmodal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className={classes.modalview}>
          <List style={{ maxHeight: 200, overflow: "auto" }}>
            {userslist.map((value, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={value.username}
                  secondary={value.email}
                />
              </ListItem>
            ))}
          </List>
        </div>
      </Modal>

      <Modal
        open={createuserstate}
        onClose={closecreateuserstate}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className={classes.modalview}>
          <Grid container xs={12}>
            <Grid item xs={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="role-outlined-label">Role</InputLabel>
                <Select
                  labelId="role-outlined-label"
                  id="role"
                  value={role}
                  onChange={handleChangeRole}
                  label="Role"
                  fullWidth
                >
                  <MenuItem value={"general"}>General User</MenuItem>
                  <MenuItem value={"spv"}>HOD REGISTRY</MenuItem>
                  <MenuItem value={"legal"}>Registry HOD</MenuItem>
                  <MenuItem value={"account"}>Invoicing Unit</MenuItem>
                  <MenuItem value={"ceo"}>CEO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Username"
                placeholder="Username"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={username}
                onChange={usernameOnchange}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Email"
                placeholder="Email"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={useremail}
                onChange={useremailOnchange}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Password"
                type="password"
                placeholder="Password"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={userpassword}
                onChange={userpasswordOnchange}
              />
            </Grid>

            <Grid item xs={12}>
              <Button onClick={createUser}>
                Create User
                {userloader == true ? <CircularProgress size={24} /> : null}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>

      <Grid container component="main">
        <CssBaseline />
        <Grid
          item
          style={{ minHeight: "100vh", background: "#fff" }}
          component="div"
          xs={4}
          sm={4}
          md={4}
        >
          <Grid
            container
            style={{ minHeight: "130px", paddingLeft: 20, paddingRight: 20 }}
            component={"div"}
            xs={12}
            sm={12}
            md={12}
          >
            <Grid item xs={4} sm={4} md={4}>
              <img
                src={oilandgaslogo}
                alt="Logo"
                style={{ width: "80px", height: "80px" }}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}></Grid>
            <Grid container xs={4} sm={4} md={4} justify="flex-end">
              <MenuIcon
                style={{ fontSize: 40, marginTop: 10, textAlign: "right" }}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={openmenu1}
              />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl1}
                keepMounted
                open={Boolean(anchorEl1)}
                onClose={closemenu1}
              >
                {PRIV == "4" ? (
                  <>
                    <MenuItem onClick={opencreateuserstate}>
                      Create User
                    </MenuItem>
                    <MenuItem onClick={openuserslistmodal}>All Users</MenuItem>
                  </>
                ) : (
                  <MenuItem>
                    You Dont Have Access To this Functionality
                  </MenuItem>
                )}
              </Menu>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            style={{
              minHeight: "50px",
              background: "#0F0E83",
              borderLeft: "solid 10px green",
              paddingLeft: 10,
            }}
            alignItems="center"
          >
            <h1 style={{ color: "#fff", fontSize: 20 }}>
              <DescriptionIcon style={{ color: "#fff" }} /> Application List
            </h1>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ minHeight: "130px", paddingLeft: 10, paddingRight: 5 }}
          >
            <Applicantlist
              applicantlist={applicantlist}
              getApplicantListInit={getApplicantListInit}
              dense2={dense2}
              getApplicantDetails={getApplicantDetails}
              approve={approve}
              decline={decline}
              active={active}
              listloader={listloader}
              report={report}
              reportonchange={reportonchange}
              openreport={openreport}
              rootid={rootid}
              declinemodal={declinemodal}
              handleClosedeclinemodal={handleClosedeclinemodal}
            />
          </Grid>
        </Grid>

        <Grid
          item
          layout="row"
          xs={8}
          sm={8}
          md={8}
          style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 20 }}
        >
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            justify="flex-end"
            alignItems="center"
          >
            <Box style={{ fontSize: 20, paddingLeft: 15, paddingRight: 15 }}>
              <NotificationsActiveOutlinedIcon />
            </Box>

            <Box
              style={{
                fontSize: 20,
                paddingLeft: 15,
                paddingRight: 15,
                borderLeft: "1px solid #ccc",
              }}
            >
              Hi {USERNAME}{" "}
              <KeyboardArrowDownIcon
                aria-controls="simple-menu2"
                aria-haspopup="true"
                onClick={openmenu2}
              />
              <Menu
                id="simple-menu2"
                anchorEl={anchorEl2}
                keepMounted
                open={Boolean(anchorEl2)}
                onClose={closemenu2}
              >
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </Box>
            <Box>
              <Avatar>
                <img
                  src={oilandgaslogo}
                  alt="Logo"
                  style={{
                    width: "80px",
                    height: "60px",
                    textAlign: "center",
                    alignSelf: "center",
                  }}
                />
              </Avatar>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} style={{ paddingTop: 30 }}>
            {renderSwitch(displaydashboard)}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
  },
  emailcontainer: {
    border: 1,
  },
  multilineColor: {
    color: "#fff",
  },
  floatingLabelFocusStyle: {
    color: "#fff",
  },
  image: {
    backgroundImage: "url(../../img/loginillustration.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.type === "light" ? "#fff" : "#fff",
    backgroundSize: "67%",
    backgroundPosition: "center",
  },

  signinFormGrid: {
    boxShadow: "none",
    backgroundColor: "#0F0E83",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#0043ce",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formHolder: {
    width: "60%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#fff",
    borderRadius: "0.4rem",
    color: "#0F0E83",
    width: "100%",
  },
  textfield: {
    borderRadius: "2rem",
  },
  email: {
    width: "60%",
    borderWidth: 2,
  },
  modalview: {
    position: "absolute",
    width: 800,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default Dashboard;
