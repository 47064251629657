// export const baseUrl = process.env.REACT_APP_BASE_URL;
export const baseUrl = "https://ogfzaapi.skillzserver.com";

//Form URLs
export const validateEmailUrl = `${baseUrl}/aplicationform/index`;
export const formStage1Url = `${baseUrl}/aplicationformstage1/create`;
export const getFormStage1Url = `${baseUrl}/aplicationformstage1/getdetails`;
export const getFormStage2Url = `${baseUrl}/aplicationformstage0`;
export const getFormStage3Url = `${baseUrl}/aplicationformstage2`;
export const getFormStage4Url = `${baseUrl}/aplicationformstage3`;
export const getFormStage5Url = `${baseUrl}/aplicationformstage4`;
export const getFormStage6Url = `${baseUrl}/aplicationformstage5`;
export const getFormStage7Url = `${baseUrl}/aplicationformstage7`;
export const getFormStage8Url = `${baseUrl}/aplicationformstage8`;
export const getRenewalFormStage1Url = `${baseUrl}/aplicationrenewalcompanyinfo`;

export const getRenewalFormStage2Url = `${baseUrl}/aplicationrenewaldebenture`;
export const getRenewalFormStage3Url = `${baseUrl}/aplicationrenewaldirectorshareholderdetails`;
export const getRenewalFormStage4Url = `${baseUrl}/aplicationrenewalbusinessactivity`;
export const getRenewalFormStage5Url = `${baseUrl}/aplicationrenewalrequireddocs`;

export const formStage2Url = `${baseUrl}/aplicationformstage2/create`;
export const formStage3Url = `${baseUrl}/aplicationformstage3/create`;
export const formStage4Url = `${baseUrl}/aplicationformstage4/create`;
export const formStage5Url = `${baseUrl}/aplicationformstage5/create`;
export const formStage6Url = `${baseUrl}/aplicationformstage6/create`;
export const formStage7Url = `${baseUrl}/aplicationformstage7/create`;
export const formStage0Url = `${baseUrl}/aplicationformstage0/create`;
export const getCompanyNameUrl = `${baseUrl}/aplicationformstage0/getcompanyname`;
export const getAllRenewalListUrl = `${baseUrl}/aplicationrenewalform/index`;
export const getCountriesUrl = `${baseUrl}/site/country`;
export const getApplicantDetailsUrl = `${baseUrl}/aplicationprocessingfee/companypaymentcontactdetails`;
export const confirmApplicantPaymentUrl = `${baseUrl}/aplicationprocessingfee/approvefinalpayment`;


export const finalApprovalCheckUrl = `${baseUrl}/site/approve`;
export const submitRequiredStageFormUrl = `${baseUrl}/aplicationrenewalrequireddocs/create`;
// confirmApplicantPaymentUrl
// getApplicantDetailsUrl
// submitRequiredStageFormUrl

export const getParticularYearRenewalUrl = `${baseUrl}/aplicationrenewalform/view`;
// export const getParticularYearRenewalUrl = `${baseUrl}/aplicationrenewalform/view`;
// export const getParticularYearRenewalUrl = `${baseUrl}/aplicationrenewalform/view`;
export const approvalValidation = `${baseUrl}/`;
