import { createStyles, LinearProgress, makeStyles } from '@material-ui/core';
import React,{useEffect, useState} from 'react'



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      // height: "100vh",
      alignItems: "center",
      justifyContent: "center",
    },
    margin: {
      margin: theme.spacing(3),
    },
    linearProgress: {
      width: theme.spacing(30),
    },
  })
);

export default function ProgressBar() {
    // const { data, showimage } = props;
    const classes = useStyles();
    const [level, setLevel] = useState(0);
  
    // const [expanded, setExpanded] = React.useState("panel1");
    // const handleChange = (panel) => (event, newExpanded) => {
    //   setExpanded(newExpanded ? panel : false);
    // };
  
    useEffect(() => {
      const timer = setInterval(() => {
        setLevel((newLevel) => (newLevel >= 100 ? 0 : newLevel + 10));
      }, 700);
      return () => {
        clearInterval(timer);
      };
    }, []);
  return (
    <div>

<div className={classes.root}>
          {/* <CircularProgress className={classes.margin} />
          <CircularProgress color="secondary" className={classes.margin} />
          <CircularProgress
            color="secondary"
            variant="determinate"
            value={level}
            className={classes.margin}
          />
          <LinearProgress
            className={[classes.linearProgress, classes.margin]}
          />
          <LinearProgress
            variant="determinate"
            value={level}
            className={[classes.linearProgress, classes.margin]}
          /> */}
          <LinearProgress
            variant="buffer"
            value={level}
            valueBuffer={level + 10}
            className={[classes.linearProgress, classes.margin]}
          />
        </div>
      
    </div>
  )
}
