import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';



function Dashboardskeleton() {
    const UseStyles = makeStyles((theme) => ({
      card: {
        width: "100%",
        margin: theme.spacing(2),
      },
      media: {
        height: 100,
      },
    }));
  const classes = UseStyles();

  return (
      <Grid container  xs={12} sm={12} md={12} spacing = {2}>
          <Grid item  xs={12} sm={3} md={3}>
              <Box component = "div" style = {{minHeight:100, borderTopLeftRadius:10,borderTopRightRadius:10,padding:5}}>
                  <Box  component = "div"  style={{backgroundColor:"#ccc",height:50,borderTopLeftRadius:10,borderTopRightRadius:10,display:"flex",alignItems:"center",justifyContent:"center",color:"#fff",fontSize:20,cursor:"pointer"}}>
                   <Skeleton animation="wave"  height={10} width="60%"  />
                  </Box>
                  <Box component = "div" style={{backgroundColor:"#eee",display:"flex",alignItems:"center",justifyContent:"center",color:"#000",paddingTop:20}}>
                        <Skeleton animation="wave" variant="rect" height={30} width={60}  />

                  </Box>
                  <Box component = "div" style={{backgroundColor:"#eee",display:"flex",alignItems:"center",justifyContent:"center",color:"#000",borderBottomLeftRadius:10,borderBottomRightRadius:10,paddingTop:10,paddingBottom:10,fontSize:15}}>
                      <Skeleton animation="wave" variant="circle" height={30} width={30}  />
                  </Box>
              </Box>
          </Grid>
          <Grid item  xs={12} sm={3} md={3}>
          <Box component = "div" style = {{minHeight:100, borderTopLeftRadius:10,borderTopRightRadius:10,padding:5}}>
              <Box  component = "div"  style={{backgroundColor:"#ccc",height:50,borderTopLeftRadius:10,borderTopRightRadius:10,display:"flex",alignItems:"center",justifyContent:"center",color:"#fff",fontSize:20,cursor:"pointer"}}>
               <Skeleton animation="wave"  height={10} width="60%"  />
              </Box>
              <Box component = "div" style={{backgroundColor:"#eee",display:"flex",alignItems:"center",justifyContent:"center",color:"#000",paddingTop:20}}>
                    <Skeleton animation="wave" variant="rect" height={30} width={60}  />

              </Box>
              <Box component = "div" style={{backgroundColor:"#eee",display:"flex",alignItems:"center",justifyContent:"center",color:"#000",borderBottomLeftRadius:10,borderBottomRightRadius:10,paddingTop:10,paddingBottom:10,fontSize:15}}>
                  <Skeleton animation="wave" variant="circle" height={30} width={30}  />
              </Box>
          </Box>
          </Grid>
          <Grid item  xs={12} sm={3} md={3}>
          <Box component = "div" style = {{minHeight:100, borderTopLeftRadius:10,borderTopRightRadius:10,padding:5}}>
              <Box  component = "div"  style={{backgroundColor:"#ccc",height:50,borderTopLeftRadius:10,borderTopRightRadius:10,display:"flex",alignItems:"center",justifyContent:"center",color:"#fff",fontSize:20,cursor:"pointer"}}>
               <Skeleton animation="wave"  height={10} width="60%"  />
              </Box>
              <Box component = "div" style={{backgroundColor:"#eee",display:"flex",alignItems:"center",justifyContent:"center",color:"#000",paddingTop:20}}>
                    <Skeleton animation="wave" variant="rect" height={30} width={60}  />

              </Box>
              <Box component = "div" style={{backgroundColor:"#eee",display:"flex",alignItems:"center",justifyContent:"center",color:"#000",borderBottomLeftRadius:10,borderBottomRightRadius:10,paddingTop:10,paddingBottom:10,fontSize:15}}>
                  <Skeleton animation="wave" variant="circle" height={30} width={30}  />
              </Box>
          </Box>
          </Grid>
          <Grid item  xs={12} sm={3} md={3}>
          <Box component = "div" style = {{minHeight:100, borderTopLeftRadius:10,borderTopRightRadius:10,padding:5}}>
              <Box  component = "div"  style={{backgroundColor:"#ccc",height:50,borderTopLeftRadius:10,borderTopRightRadius:10,display:"flex",alignItems:"center",justifyContent:"center",color:"#fff",fontSize:20,cursor:"pointer"}}>
               <Skeleton animation="wave"  height={10} width="60%"  />
              </Box>
              <Box component = "div" style={{backgroundColor:"#eee",display:"flex",alignItems:"center",justifyContent:"center",color:"#000",paddingTop:20}}>
                    <Skeleton animation="wave" variant="rect" height={30} width={60}  />

              </Box>
              <Box component = "div" style={{backgroundColor:"#eee",display:"flex",alignItems:"center",justifyContent:"center",color:"#000",borderBottomLeftRadius:10,borderBottomRightRadius:10,paddingTop:10,paddingBottom:10,fontSize:15}}>
                  <Skeleton animation="wave" variant="circle" height={30} width={30}  />
              </Box>
          </Box>
          </Grid>

          <Grid  container  xs={12} sm={12} md={12}>
           <Skeleton animation="wave"  height={10} width="30%"  />
          </Grid>

          <Grid container  xs={12} sm={12} md={12}>
          <Grid item  xs={12} sm={3} md={3}>
              <Box component = "div" style = {{minHeight:100, borderTopLeftRadius:10,borderTopRightRadius:10,padding:5}}>
                  <Box  component = "div"  style={{backgroundColor:"#ccc",height:50,borderTopLeftRadius:10,borderTopRightRadius:10,display:"flex",alignItems:"center",justifyContent:"center",color:"#fff",fontSize:20,cursor:"pointer"}}>
                   <Skeleton animation="wave"  height={10} width="60%"  />
                  </Box>
                  <Box component = "div" style={{backgroundColor:"#eee",display:"flex",alignItems:"center",justifyContent:"center",color:"#000",paddingTop:20}}>
                        <Skeleton animation="wave" variant="rect" height={30} width={60}  />

                  </Box>
                  <Box component = "div" style={{backgroundColor:"#eee",display:"flex",alignItems:"center",justifyContent:"center",color:"#000",borderBottomLeftRadius:10,borderBottomRightRadius:10,paddingTop:10,paddingBottom:10,fontSize:15}}>
                      <Skeleton animation="wave" variant="circle" height={30} width={30}  />
                  </Box>
              </Box>
          </Grid>
          <Grid item  xs={12} sm={3} md={3}>
          <Box component = "div" style = {{minHeight:100, borderTopLeftRadius:10,borderTopRightRadius:10,padding:5}}>
              <Box  component = "div"  style={{backgroundColor:"#ccc",height:50,borderTopLeftRadius:10,borderTopRightRadius:10,display:"flex",alignItems:"center",justifyContent:"center",color:"#fff",fontSize:20,cursor:"pointer"}}>
               <Skeleton animation="wave"  height={10} width="60%"  />
              </Box>
              <Box component = "div" style={{backgroundColor:"#eee",display:"flex",alignItems:"center",justifyContent:"center",color:"#000",paddingTop:20}}>
                    <Skeleton animation="wave" variant="rect" height={30} width={60}  />

              </Box>
              <Box component = "div" style={{backgroundColor:"#eee",display:"flex",alignItems:"center",justifyContent:"center",color:"#000",borderBottomLeftRadius:10,borderBottomRightRadius:10,paddingTop:10,paddingBottom:10,fontSize:15}}>
                  <Skeleton animation="wave" variant="circle" height={30} width={30}  />
              </Box>
          </Box>
          </Grid>
          <Grid item  xs={12} sm={3} md={3}>
          <Box component = "div" style = {{minHeight:100, borderTopLeftRadius:10,borderTopRightRadius:10,padding:5}}>
              <Box  component = "div"  style={{backgroundColor:"#ccc",height:50,borderTopLeftRadius:10,borderTopRightRadius:10,display:"flex",alignItems:"center",justifyContent:"center",color:"#fff",fontSize:20,cursor:"pointer"}}>
               <Skeleton animation="wave"  height={10} width="60%"  />
              </Box>
              <Box component = "div" style={{backgroundColor:"#eee",display:"flex",alignItems:"center",justifyContent:"center",color:"#000",paddingTop:20}}>
                    <Skeleton animation="wave" variant="rect" height={30} width={60}  />

              </Box>
              <Box component = "div" style={{backgroundColor:"#eee",display:"flex",alignItems:"center",justifyContent:"center",color:"#000",borderBottomLeftRadius:10,borderBottomRightRadius:10,paddingTop:10,paddingBottom:10,fontSize:15}}>
                  <Skeleton animation="wave" variant="circle" height={30} width={30}  />
              </Box>
          </Box>
          </Grid>
          <Grid item  xs={12} sm={3} md={3}>
          <Box component = "div" style = {{minHeight:100, borderTopLeftRadius:10,borderTopRightRadius:10,padding:5}}>
              <Box  component = "div"  style={{backgroundColor:"#ccc",height:50,borderTopLeftRadius:10,borderTopRightRadius:10,display:"flex",alignItems:"center",justifyContent:"center",color:"#fff",fontSize:20,cursor:"pointer"}}>
               <Skeleton animation="wave"  height={10} width="60%"  />
              </Box>
              <Box component = "div" style={{backgroundColor:"#eee",display:"flex",alignItems:"center",justifyContent:"center",color:"#000",paddingTop:20}}>
                    <Skeleton animation="wave" variant="rect" height={30} width={60}  />

              </Box>
              <Box component = "div" style={{backgroundColor:"#eee",display:"flex",alignItems:"center",justifyContent:"center",color:"#000",borderBottomLeftRadius:10,borderBottomRightRadius:10,paddingTop:10,paddingBottom:10,fontSize:15}}>
                  <Skeleton animation="wave" variant="circle" height={30} width={30}  />
              </Box>
          </Box>
          </Grid>

          </Grid>
          </Grid>
  );
}




    const useStyles = makeStyles((theme) => ({
      root: {
        height: '100vh',
        backgroundColor:'#fff',
      },
      emailcontainer: {
        border: 1,
      },
      multilineColor:{
            color:'#fff'
        },
        floatingLabelFocusStyle: {
            color: "#fff"
        },
      image: {
        backgroundImage: 'url(../../img/loginillustration.png)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
          theme.palette.type === 'light' ? '#fff' : '#fff',
        backgroundSize: '67%',
        backgroundPosition: 'center',
      },

      signinFormGrid: {
        boxShadow:'none',
        backgroundColor: '#060454',

      },
      paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor:'#0043ce',
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      formHolder: {
        width: '60%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#fff',
        borderRadius: '0.4rem',
        color:'#060454',
        width:'100%'
      },
      textfield: {
        borderRadius: '2rem',
      },
      email:{
          width:'60%',
          borderWidth:2,

      }
    }));

export default Dashboardskeleton;
