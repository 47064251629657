import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Divider from "@material-ui/core/Divider";
import InboxIcon from "@material-ui/icons/Inbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import DocumentDetails from "../../components/DocumentDetails";
import {
  getAllRenewalList,
  getParticularYearRenewal,
} from "../../Services/apiServices/Form";
import axios from "axios";
// import moment from "moment";
import ShowDocumentDetails from "../../components/ShowDocumentDetails ";
const TOKEN = localStorage.getItem("token");

// import DocumentDetails from "../../DocumentDetails";

const userRenewalList = [
  {
    id: "1",
    name: "Licence Renewal Request for 2022",
    status: "Pending",
  },
  {
    id: "2",
    name: "Licence Renewal Request for 2021",
    status: "Declined",
  },
  {
    id: "3",
    name: "Licence Renewal Request for 2020",
    status: "Renewed",
  },
  {
    id: "4",
    name: "Licence Renewal Request for 2019",
    status: "Pending",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
  },
  crumb: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    marginTop: "0rem",
    marginBottom: "2rem",
    width: "100%",
    padding: ".5rem",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    backgroundColor: theme.palette.background.paper,
  },
}));
function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function RenewalList(props) {
  const { data, showimage, root_id } = props;

  console.log(props, "sdnsskjjs");

  console.log(showimage, data, "dksdkkjss");
  const classes = useStyles();
  const [showForm, setshowForm] = useState(false);
  const [PageTitle, setPageTitle] = useState("");
  const [allApplicantRenewals, setAllApplicantRenewals] = useState();
  const [renewalID, setrenewalID] = useState();
  const [loading, setloading] = useState(false);
  const [applicantDetails, setapplicantDetails] = useState();

  useEffect(() => {
    var route = "totalRenewal";
    getCurrentRenewalApplicant();
  }, []);

  const getCurrentRenewalApplicant = async () => {
    const response = await getAllRenewalList(data?.root_id);
    console.log(response?.data?.result, "jdsjdsjisjs");
      setAllApplicantRenewals(response?.data?.result);
  };

  const getSingleYearRenewal = async (id) => {
    setloading(true);

    try {
      const res = await getParticularYearRenewal(id);
      console.log(res.data.result);
      setapplicantDetails(res?.data?.result);
      setloading(false);
      setshowForm(true);
    } catch (err) {
      setloading(false);
      console.log(err);
    }

    // setshowForm(true);
  };

  useEffect(() => {}, [PageTitle]);

  const getAllYearsRenewal = () => {};

  return (
    <>
      <div style={{ width: "100%" }}>
        {showForm ? (
          <>
            <div className={classes.crumb}>
              <h
                onClick={() => {
                  setshowForm(false);
                  setPageTitle("");
                }}
                style={{ color: "rgb(15, 14, 131)" }}
              >
                Licence / &nbsp;{" "}
              </h>{" "}
              {showForm && `${PageTitle}`}
            </div>
            {!loading && (
              <ShowDocumentDetails
                applicantDetails={applicantDetails}
                data={data}
                showimage={showimage}
              />
            )}
          </>
        ) : (
          <>
            <div className={classes.crumb}>
              {" "}
              <h
                onClick={() => {
                  setshowForm(false);
                  setPageTitle("");
                }}
                style={{ color: "rgb(15, 14, 131)" }}
              >
                Licence / &nbsp;{" "}
              </h>{" "}
              {showForm && `${PageTitle}`}
            </div>
            {loading && (
              <div class="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
            {/* {!loading } */}
            <div style={{ backgroundColor: "" }} className={classes.root}>
              <List
                style={{ width: "100%" }}
                // component="nav" aria-label="main mailbox folders"
              >
                {allApplicantRenewals &&
                  allApplicantRenewals.map((e) => (
                    <ListItem
                      className="renewal__list"
                      button
                      onClick={() => {
                        // var id = 22
                        getSingleYearRenewal(e?.id);
                        // getSingleYearRenewal(id);
                        // setPageTitle(
                        //   `Licence Renewal Request for ${moment(
                        //     e?.date_created
                        //   ).format("YYYY")}`
                        // );
                        setPageTitle(e?.date_created);
                      }}
                    >
                      <ListItemText
                        primary={`Licence Renewal Request for ${e?.date_created}`}
                      />
                      {e.status === 1 && (
                        <div style={{ color: "green", marginLeft: "17rem" }}>
                          Renewed
                        </div>
                      )}
                      {e?.status === 2 && (
                        <div style={{ color: "red" }}>Declined</div>
                      )}
                      {e?.status === 0 && (
                        <div style={{ color: "yellow" }}>Pending</div>
                      )}
                    </ListItem>
                  ))}
                {/* {userRenewalList &&
                userRenewalList.map((e) => (
                  <>
                    <ListItem
                      className="renewal__list"
                      button
                      onClick={() => {
                        setPageTitle(e.name);
                        setshowForm(true);
                      }}
                    >
                      <ListItemText primary={`${e.name}`} />
                      {e.status === "Renewed" && (
                        <div style={{ color: "green", marginLeft: "17rem" }}>
                          Renewed
                        </div>
                      )}
                      {e.status === "Declined" && (
                        <div style={{ color: "red" }}>Declined</div>
                      )}
                      {e.status === "Pending" && (
                        <div style={{ color: "yellow" }}>Pending</div>
                      )}
                    </ListItem>
                  </>
                ))} */}
              </List>
            </div>
          </>
        )}
      </div>
    </>
  );
}
