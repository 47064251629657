import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LockOutlineIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import oilandgaslogo from "../../assets/oilandgaslogo.png";
import Emailstep1 from "../../components/Emailstep1";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Menutab from "../../components/Tabs/Menutab";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getFormStage5,
  submitFormStage4,
} from "../../Services/apiServices/Form";
import { Modal } from "@material-ui/core";
import FormStagePreview from "../../components/preview/FormStagePreview";

document.body.style = "background: #f5f5f5;";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "13px",
      },
    },
  },
})(TextField);

function Applicationform4(props) {
  const {
    snakBarSetter,
    stageSetter,
    errorSetter,
    formloaderSetter,
    rootid,
    formloader,
    stage,
    whichapplication,
  } = props;

  const [serverData, setserverData] = useState(false);
  const [previewData, setpreviewData] = useState();
  const [showPreviewForm, setshowPreviewForm] = useState(false);
  const [formEdit, setformEdit] = useState(false);
  const [previousFIlledData, setpreviousFIlledData] = useState();
  // stage 4 validator state
  const [businessactivityvalidator, setBusinessactivityvalidator] =
    useState(true);
  const [expectedstartdatevalidator, setExpectedstartdatevalidator] =
    useState(true);

  const [businessactivity, setBusinessactivity] = useState("");
  const [expectedstartdate, setExpectedstartdate] = useState("");
  const [undevelopedland, setUndevelopedland] = useState({
    type: "undeveloped land",
    value: null,
    mearsurment: "sq. mtrs",
  });

  const [concretestackingarea, setConcretestackingarea] = useState({
    type: "concrete stacking area",
    value: null,
    mearsurment: "sq. mtrs",
  });

  const [developedland, setDevelopedland] = useState({
    type: "developed land",
    value: null,
    mearsurment: "sq. mtrs",
  });

  const [warehousespace, setWarehousespace] = useState({
    type: "warehouse space",
    value: null,
    mearsurment: "sq. mtrs",
  });
  const [factorypremises, setFactorypremises] = useState({
    type: "factory premises",
    value: null,
    mearsurment: "sq. mtrs",
  });
  const [officeaccommodation, setOfficeaccommodation] = useState({
    type: "office accommodation",
    value: null,
    mearsurment: "sq. mtrs",
  });
  const [equipmentrequirement, setEquipmentrequirement] = useState({
    type: "equipment requirement",
    value: null,
    mearsurment: "sq. mtrs",
  });
  const [residentialaccommodation, setResidentialaccommodation] = useState({
    type: "residential accommodation",
    value: null,
    mearsurment: "No. of persons",
  });

  useEffect(() => {
    async function fetchFormData() {
      const response = await getFormStage5(rootid);
      if (response?.data?.result?.expectedstartdate && !formEdit) {
        setpreviousFIlledData(response?.data?.result);
        setserverData(true);
      }
    }
    fetchFormData();
  }, [serverData]);

  const classes = useStyles();
  const businessactivityOnchange = (e) => {
    setBusinessactivity(e.target.value);
  };
  const expectedstartdateOnchange = (e) => {
    setExpectedstartdate(e.target.value);
  };
  const undevelopedlandOnchange = (e) => {
    setUndevelopedland({
      type: "undeveloped land",
      value: e.target.value,
      mearsurment: "sq. mtrs",
    });
  };

  const developedlandOnchange = (e) => {
    setDevelopedland({
      type: "developed land",
      value: e.target.value,
      mearsurment: "sq. mtrs",
    });
  };

  const concretestackingareaOnchange = (e) => {
    setConcretestackingarea({
      type: "concrete stacking area",
      value: e.target.value,
      mearsurment: "sq. mtrs",
    });
  };
  const warehousespaceOnchange = (e) => {
    setWarehousespace({
      type: "warehouse space",
      value: e.target.value,
      mearsurment: "sq. mtrs",
    });
  };
  const factorypremisesOnchange = (e) => {
    setFactorypremises({
      type: "factory premises",
      value: e.target.value,
      mearsurment: "sq. mtrs",
    });
  };
  const officeaccommodationOnchange = (e) => {
    setOfficeaccommodation({
      type: "office accommodation",
      value: e.target.value,
      mearsurment: "sq. mtrs",
    });
  };
  const equipmentrequirementOnchange = (e) => {
    setEquipmentrequirement({
      type: "equipment requirement",
      value: e.target.value,
      mearsurment: "sq. mtrs",
    });
  };
  const residentialaccommodationOnchange = (e) => {
    setResidentialaccommodation({
      type: "residential accommodation",
      value: e.target.value,
      mearsurment: "No. of persons",
    });
  };
  // const submitStage4 = () =>{
  //     props.submitStage4()
  // }

  const previewStage4 = async () => {
    let holdMeasurments = [];
    holdMeasurments.push(
      undevelopedland,
      concretestackingarea,
      warehousespace,
      factorypremises,
      officeaccommodation,
      equipmentrequirement,
      residentialaccommodation
    );

    formloaderSetter(false);

    console.log(holdMeasurments);
    const stage4Data = [
      {
        name: "MEASUREMENTS",
        value: `${holdMeasurments}`,
        arrayInputs: holdMeasurments,
      },
      { name: "Business Activity", value: `${businessactivity}` },
      { name: "Expected start date", value: `${expectedstartdate}` },
    ];

    if (businessactivity.length < 1) {
      setBusinessactivityvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Business Activity cant be empty");
      return false;
    } else {
      setBusinessactivityvalidator(true);
    }

    if (expectedstartdate.length < 1) {
      setExpectedstartdatevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Expected Start Date cant be empty");
      return false;
    } else {
      setExpectedstartdatevalidator(true);
    }

    setpreviewData(stage4Data);
    setshowPreviewForm(true);
  };

  const submitStage4 = async () => {
    let holdMeasurments = [];
    holdMeasurments.push(
      undevelopedland,
      concretestackingarea,
      warehousespace,
      factorypremises,
      officeaccommodation,
      equipmentrequirement,
      residentialaccommodation
    );
    const stage4Data = {
      measurment: holdMeasurments,
      root_id: rootid,
      businessactivity: businessactivity,
      expectedstartdate: expectedstartdate,
    };
    formloaderSetter(true);
    if (businessactivity.length < 1) {
      setBusinessactivityvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Business Activity cant be empty");
      return false;
    } else {
      setBusinessactivityvalidator(true);
    }

    if (expectedstartdate.length < 1) {
      setExpectedstartdatevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Expected Start Date cant be empty");
      return false;
    } else {
      setExpectedstartdatevalidator(true);
    }

    await submitFormStage4(stage4Data).then((res) => {
      if (res.data.status == 1) {
        formloaderSetter(false);
        stageSetter(res.data.result.application_stage);
      }
      formloaderSetter(false);
    });
  };

  const formPreviewSetter = (value) => {
    setshowPreviewForm(value);
  };

  const onSelectSetter = async (value) => {
    console.log("clicked");
    stageSetter(value);
    const response = await getFormStage5(rootid);
    if (response?.data?.result.expectedstartdate && !formEdit) {
      setpreviousFIlledData(response?.data?.result);
      setserverData(true);
    }
    //load
    // if data set visibilitiy to true and change display
  };

  return (
    <React.Fragment>
      <Modal
        open={showPreviewForm}
        onClose={() => setshowPreviewForm(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
          overflow: "scroll",
          border: "2px solid blue",
          height: "100vh",
          marginTop: "10px",
          // overflowX: 'visible',
          // backgroundColor:'white'
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            height: "maxContent",
            width: window.innerWidth - 50,
            padding: "1rem 2rem",
            border: "2px solid orange",
          }}
        >
          <FormStagePreview
            data={[previewData]}
            formloaderSetter={formloaderSetter}
            formPreviewSetter={formPreviewSetter}
          />
          <Button
            style={{ marginRight: "1rem" }}
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => {
              setshowPreviewForm(false);
              if (!formloader) {
                return submitStage4();
              } else {
                return null;
              }
            }}
          >
            {" "}
            Confirm Save and Next{" "}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              formloaderSetter(false);
              setshowPreviewForm(false);
            }}
          >
            {" "}
            No{" "}
          </Button>
        </div>
      </Modal>
      <AppBar position="absolute" color="#ffffff" className={classes.appBar}>
        <Toolbar className={classes.layout}>
          <img
            src={oilandgaslogo}
            alt="Logo"
            style={{
              width: "80px",
              height: "60px",
              textAlign: "center",
              alignSelf: "center",
            }}
          />
        </Toolbar>
      </AppBar>

      <Grid container component="main" className={classes.layout}>
        <Menutab
          onSelectSetter={onSelectSetter}
          whichapplication={whichapplication}
          stage={stage}
        />
        {!serverData ? (
          <>
            <Grid item xs={12}>
              <h4>
                {" "}
                6. Proposed Business Activity and location of the Free Zone:
              </h4>
            </Grid>

            <Grid container xs={12} spacing={1}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-full-width"
                  placeholder="Please give a brief outline of the main operation of the proposed activity of the Company in the space provided below."
                  fullWidth
                  multiline
                  value={businessactivity}
                  onChange={businessactivityOnchange}
                  margin="normal"
                  style={{ backgroundColor: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                    className: !businessactivityvalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !businessactivityvalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline: !businessactivityvalidator
                        ? classes.errorstyle
                        : null,
                    },
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <h4> 7. List of Required Facilities</h4>
            </Grid>

            <Grid
              container
              xs={12}
              spacing={1}
              style={{ backgroundColor: "#fff", padding: 30 }}
            >
              <Grid item xs={3}>
                <TextField
                  id="outlined-full-width"
                  label="Undeveloped Land:"
                  placeholder="sq. mtrs."
                  fullWidth
                  onChange={undevelopedlandOnchange}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  id="outlined-full-width"
                  label="Developed Land:"
                  onChange={developedlandOnchange}
                  placeholder="sq. mtrs."
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  id="outlined-full-width"
                  label="Concrete Stacking Area:"
                  placeholder="sq. mtrs."
                  fullWidth
                  onChange={concretestackingareaOnchange}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  id="outlined-full-width"
                  label="Warehouse Space:"
                  placeholder="sq. mtrs."
                  onChange={warehousespaceOnchange}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  id="outlined-full-width"
                  label="Factory Premises:"
                  placeholder="sq. mtrs."
                  onChange={factorypremisesOnchange}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  id="outlined-full-width"
                  label="Office Accommodation:"
                  placeholder="sq. mtrs."
                  onChange={officeaccommodationOnchange}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  id="outlined-full-width"
                  label="Equipment Requirement:"
                  placeholder="sq. mtrs."
                  onChange={equipmentrequirementOnchange}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  id="outlined-full-width"
                  label="Residential Accommodation:"
                  placeholder="No. of persons"
                  fullWidth
                  onChange={residentialaccommodationOnchange}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <h4> 8. Expected start date:</h4>
            </Grid>

            <Grid container xs={12} spacing={1}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-full-width"
                  type="date"
                  value={expectedstartdate}
                  placeholder="Placeholder"
                  label="Expected start date"
                  onChange={expectedstartdateOnchange}
                  fullWidth
                  style={{ backgroundColor: "#fff" }}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    className: !expectedstartdatevalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !expectedstartdatevalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline: !expectedstartdatevalidator
                        ? classes.errorstyle
                        : null,
                    },
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              justify="flex-end"
              style={{ marginBottom: "100px" }}
            >
              <Box component="span">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={!formloader ? previewStage4 : null}
                  style={{ backgroundColor: "#060454", height: "30px" }}
                  className={classes.button}
                  endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                >
                  Preview & Continue{" "}
                  {formloader ? <CircularProgress size={24} /> : null}
                </Button>
              </Box>
              {/* <Box component="span">
            <Button
              variant="contained"
              color="primary"
              onClick={!formloader ? submitStage4 : null}
              style={{ backgroundColor: "#060454", height: "30px" }}
              className={classes.button}
              endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
            >
              Save & Continue{" "}
              {formloader ? <CircularProgress size={24} /> : null}
            </Button>
          </Box> */}
            </Grid>
          </>
        ) : (
          <div
            style={{
              backgroundColor: "#fff",
              marginTop: 25,
              padding: 30,
              marginBottom: "7rem",
            }}
          >
            {previousFIlledData?.expectedstartdate && (
              <Grid xs={4}>
                {previousFIlledData?.expectedstartdate?.status === 0 && (
                  <>
                    <label>Expected Start Date</label>
                    <input
                      disabled
                      value={previousFIlledData?.expectedstartdate?.start_date}
                    />
                  </>
                )}
              </Grid>
            )}
            <Grid container>
              {previousFIlledData?.requiredfacilities?.map((e, index) => (
                <>
                  {e.status === 0 && (
                    <Grid xs={4}>
                      <div>{e.type}</div>

                      <input disabled value={e?.quantity} />
                    </Grid>
                  )}
                  {e?.name && (
                    <Grid xs={4}>
                      <div>Name</div>
                      <input disabled value={e?.name} />
                    </Grid>
                  )}
                  {e?.number && (
                    <Grid xs={4}>
                      <div>number</div>
                      <input disabled value={e?.number} />
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
            {previousFIlledData?.ogfzabusinessactivities && (
              <Grid xs={4}>
                {previousFIlledData?.ogfzabusinessactivities?.status === 0 && (
                  <>
                    <label>Business Activity</label>
                    <input
                      disabled
                      value={
                        previousFIlledData?.ogfzabusinessactivities?.description
                      }
                    />
                  </>
                )}
              </Grid>
            )}
            <Grid>
              <Button
                variant="contained"
                color="primary"
                // onClick={!formloader ? previewStageData : null}
                style={{ backgroundColor: "#060454", height: "30px" }}
                className={classes.button}
                endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                onClick={() => {
                  setformEdit(true);
                  setserverData(false);
                }}
              >
                Refill Form
              </Button>
            </Grid>
          </div>
        )}
      </Grid>
      <AppBar position="fixed" className={classes.appBarButom} color="#060454">
        <Typography
          variant="span"
          color="inherit"
          noWrap
          style={{ textAlign: "center", color: "#fff", alignItems: "center" }}
          justify="center"
        >
          Copyright © 2022 <b>Oil and Gas Free Zones Authority</b>. All Rights Reserved.
        </Typography>
      </AppBar>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    position: "relative",
  },
  checkboxLabel: {
    fontSize: 14,
  },
  errorstyle: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
  appBarButom: {
    top: "auto",
    bottom: 0,
    height: "40px",
    backgroundColor: "#060454",
    paddingTop: "5px",
    paddingButtom: "5px",
    alignItems: "center",
    justifyContent: "center",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "60%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

export default Applicationform4;
