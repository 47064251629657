import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ClearIcon from "@material-ui/icons/Clear";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import HourglassFullOutlinedIcon from "@material-ui/icons/HourglassFullOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import CancelPresentationOutlinedIcon from "@material-ui/icons/CancelPresentationOutlined";
import Box from "@material-ui/core/Box";
import Dashboardspv from "./Dashboardspv";
import Dashboardlegal from "./Dashboardlegal";
import Dashboardceo from "./Dashboardceo";
import Dashboardgeneral from "./Dashboardgeneral";
import Dashboardskeleton from "./Dashboardskeleton";
import { VpnLock } from "@material-ui/icons";

function Dashboardcontent(props) {
  useEffect(() => {
    // user this to pass new value
    props.getdashboardcountInit();
  }, [props.dashboardcountinit]);

  const DASHBOARDPRIVCONTENT = (value) => {
    console.log(value);
    switch (value) {
      case "2":
        return (
          <Dashboardspv
            dashboardcount={props.dashboardcount}
            getApplicantListbyroute={props.getApplicantListbyroute}
          />
        );
      case "3":
        return (
          <Dashboardlegal
            dashboardcount={props.dashboardcount}
            getApplicantListbyroute={props.getApplicantListbyroute}
          />
        );
      case "4":
        return (
          <Dashboardceo
            dashboardcount={props.dashboardcount}
            getApplicantListbyroute={props.getApplicantListbyroute}
          />
        );

      default:
        return (
          <Dashboardgeneral
            dashboardcount={props.dashboardcount}
            getApplicantListbyroute={props.getApplicantListbyroute}
          />
        );
    }
  };

  const DASHBOARDPRIVCONTENTTITLE = (value) => {
    switch (value) {
      case "2":
        return "REGISTRY";
      case "3":
        return "Registry HOD";
      case "4":
        return "CEO";

      default:
        return "GENERAL";
    }
  };

  const renderSwitch = (value) => {
    switch (value) {
      case 1:
        return (
          <Grid container xs={12} sm={12} md={12} spacing={2}>
            <Grid item xs={12} sm={3} md={3}>
              <Box
                component="div"
                style={{
                  minHeight: 100,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  padding: 5,
                }}
              >
                <Box
                  onClick={() => {
                    props.getApplicantListbyroute("totalapplicant");
                  }}
                  component="div"
                  style={{
                    backgroundColor: "#060454",
                    height: 50,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                    fontSize: 20,
                    cursor: "pointer",
                  }}
                >
                  Total Applicant
                </Box>
                <Box
                  component="div"
                  style={{
                    backgroundColor: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#000",
                    paddingTop: 20,
                  }}
                >
                  <DescriptionOutlinedIcon
                    style={{ fontSize: 50, color: "#060454" }}
                  />
                </Box>
                <Box
                  component="div"
                  style={{
                    backgroundColor: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#000",
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    paddingTop: 10,
                    paddingBottom: 10,
                    fontSize: 15,
                  }}
                >
                  {props?.dashboardcount?.gen?.all}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Box
                component="div"
                style={{
                  minHeight: 100,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  padding: 5,
                }}
              >
                <Box
                  onClick={() => {
                    props.getApplicantListbyroute("totalapproved");
                  }}
                  component="div"
                  style={{
                    backgroundColor: "#060454",
                    height: 50,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                    fontSize: 18,
                    cursor: "pointer",
                  }}
                >
                  Total Approved
                </Box>
                <Box
                  component="div"
                  style={{
                    backgroundColor: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#000",
                    paddingTop: 20,
                  }}
                >
                  <HourglassFullOutlinedIcon
                    style={{ fontSize: 50, color: "#060454" }}
                  />
                </Box>
                <Box
                  component="div"
                  style={{
                    backgroundColor: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#000",
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    paddingTop: 10,
                    paddingBottom: 10,
                    fontSize: 15,
                  }}
                >
                  {props?.dashboardcount?.gen?.Approve}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Box
                component="div"
                style={{
                  minHeight: 100,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  padding: 5,
                }}
              >
                <Box
                  onClick={() => {
                    props.getApplicantListbyroute("totalpaid");
                  }}
                  component="div"
                  style={{
                    backgroundColor: "#060454",
                    height: 50,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                    fontSize: 20,
                    cursor: "pointer",
                  }}
                >
                  Total Paid
                </Box>
                <Box
                  component="div"
                  style={{
                    backgroundColor: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#000",
                    paddingTop: 20,
                  }}
                >
                  <MonetizationOnOutlinedIcon
                    style={{ fontSize: 50, color: "#060454" }}
                  />
                </Box>
                <Box
                  component="div"
                  style={{
                    backgroundColor: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#000",
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    paddingTop: 10,
                    paddingBottom: 10,
                    fontSize: 20,
                  }}
                >
                  {props?.dashboardcount?.gen?.Paid}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Box
                component="div"
                style={{
                  minHeight: 100,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  padding: 5,
                }}
              >
                <Box
                  onClick={() => {
                    props.getApplicantListbyroute("totaldeclined");
                  }}
                  component="div"
                  style={{
                    backgroundColor: "#060454",
                    height: 50,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                    fontSize: 18,
                    cursor: "pointer",
                  }}
                >
                  Total Rejected
                </Box>
                <Box
                  component="div"
                  style={{
                    backgroundColor: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#000",
                    paddingTop: 20,
                  }}
                >
                  <CancelPresentationOutlinedIcon
                    style={{ fontSize: 50, color: "#060454" }}
                  />
                </Box>
                <Box
                  component="div"
                  style={{
                    backgroundColor: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#000",
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    paddingTop: 10,
                    paddingBottom: 10,
                    fontSize: 15,
                  }}
                >
                  {props?.dashboardcount?.gen?.Decline}
                </Box>
              </Box>
            </Grid>

            <Grid container xs={12} sm={12} md={12}>
              <h1 style={{ color: "#060454" }}>
                {" "}
                {DASHBOARDPRIVCONTENTTITLE(props.priv)}{" "}
              </h1>
            </Grid>

            <Grid container xs={12} sm={12} md={12}>
              {DASHBOARDPRIVCONTENT(props.priv)}
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Box
                component="div"
                style={{
                  minHeight: 100,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  padding: 5,
                }}
              >
                <Box
                  component="div"
                  style={{
                    backgroundColor: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#000",
                    paddingTop: 20,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <HourglassFullOutlinedIcon
                    style={{ fontSize: 50, color: "#060454" }}
                  />
                </Box>

                <Box
                  component="div"
                  style={{
                    minHeight: 80,
                    backgroundColor: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#000",
                    paddingTop: 10,
                    paddingBottom: 10,
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  {props?.dashboardcount?.spv?.Renewal} NUMBER OF PENDING LICENCE
                  RENEWAL
                </Box>

                <Box
                  onClick={() => {
                    props.getApplicantListbyroute("totalRenewal");
                  }}
                  component="div"
                  style={{
                    backgroundColor: "#060454",
                    height: 50,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                    fontSize: 18,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    cursor: "pointer",
                  }}
                >
                  View
                </Box>
              </Box>
            </Grid>
            
          </Grid>
        );

      default:
        return <Dashboardskeleton />;
    }
  };

  return <React.Fragment>{renderSwitch(props.dashboardloader)}</React.Fragment>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundColor: "#fff",
  },
  emailcontainer: {
    border: 1,
  },
  multilineColor: {
    color: "#fff",
  },
  floatingLabelFocusStyle: {
    color: "#fff",
  },
  image: {
    backgroundImage: "url(../../img/loginillustration.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.type === "light" ? "#fff" : "#fff",
    backgroundSize: "67%",
    backgroundPosition: "center",
  },

  signinFormGrid: {
    boxShadow: "none",
    backgroundColor: "#060454",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#0043ce",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formHolder: {
    width: "60%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#fff",
    borderRadius: "0.4rem",
    color: "#060454",
    width: "100%",
  },
  textfield: {
    borderRadius: "2rem",
  },
  email: {
    width: "60%",
    borderWidth: 2,
  },
}));

export default Dashboardcontent;
