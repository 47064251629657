import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ClearIcon from "@material-ui/icons/Clear";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";

function Approvalbuttons(props) {
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  const styles = useStyles();
  const classes = useStyles();
  const [confirmModal, setconfirmModal] = useState(false);
  const [approvalAction, setApprovalAction] = useState();
  //const [dense, setDense] = React.useState(false);
  console.log(props);

  const declinemodal = (value) => {
    props.declinemodal(value);
  };
  const reportonchange = (e) => {
    props.reportonchange(e.target.value);
  };

  return (
    <React.Fragment>
      <Modal
        open={confirmModal}
        onClose={() => setconfirmModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className={classes.modalview}>
          Are you sure you want to {approvalAction}?
          <Button
            onClick={() => {
              setconfirmModal(false);
              if (approvalAction === "approve") {
                return props.approvesection(props.section, props.value.id);
              } else {
                return props.declinesection(props.section, props.value.id);
              }
            }}
          >
            {" "}
            confirm{" "}
          </Button>
          <Button
            onClick={() => {
              setconfirmModal(false);
            }}
          >
            {" "}
            No{" "}
          </Button>
        </div>
      </Modal>
      <Modal
        open={props.openreport}
        onClose={props.handleClosedeclinemodal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",

          justifyContent: "center",
        }}
      >
        <div className={classes.modalview}>
          <TextField
            id="outlined-full-width"
            label="Reason"
            placeholder="Reason"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={props.report}
            onChange={reportonchange}
          />
          <Button
            onClick={() => {
              props.decline(props.rootid);
            }}
          >
            {" "}
            Decline{" "}
          </Button>
        </div>
      </Modal>
      {props.display == 1 ? (
        <Box>
          {(props.priv == 1 && props.value.approval_stage_countter < 34) ||
          (props.priv == 2 && props.value.approval_stage_countter < 34) ? (
            <Button
              style={{
                backgroundColor:
                  props.active == props.value.id ? "#0F0E83" : "#ccc",
                color: props.active == props.value.id ? "#fff" : "#000",
              }}
              onClick={() => {
                props.getApplicantDetails(props.value.id);
              }}
            >
              {" "}
              Screen{" "}
            </Button>
          ) : null}
          {props.priv == 5 &&
          props.value.approval_stage_countter == 34 &&
          props.value.approval_stage == 3 &&
          props.value.approval_stage_decline == 0 ? (
            <div>
              <Button
                onClick={() => {
                  props.approve(props.value.id);
                }}
                style={{ backgroundColor: "green", color: "#fff" }}
              >
                {" "}
                Approve{" "}
              </Button>
            </div>
          ) : null}

          {props.priv == 4 &&
          props.value.approval_stage_countter == 34 &&
          props.value.approval_stage == 4 &&
          props.value.approval_stage_decline == 0 ? (
            <div>
              <Button
                onClick={() => {
                  props.approve(props.value.id);
                }}
                style={{ backgroundColor: "green", color: "#fff" }}
              >
                {" "}
                Issue{" "}
              </Button>
            </div>
          ) : null}

          {(props.priv > 2 && props.value.approval_stage_countter < 34) ||
          (props.priv > 2 && props.value.approval_stage < 1) ? (
            <Button
              style={{
                backgroundColor:
                  props.active == props.value.id ? "#0F0E83" : "#ccc",
                color: props.active == props.value.id ? "#fff" : "#000",
              }}
              onClick={() => {
                props.getApplicantDetails(props.value.id);
              }}
            >
              {" "}
              View{" "}
            </Button>
          ) : null}

          {(props.priv == 1 && props.value.approval_stage_countter == 34) ||
          (props.priv == 2 &&
            props.value.approval_stage_countter == 34 &&
            props.value.approval_stage > 0) ||
          (props.priv == 3 &&
            props.value.approval_stage_countter == 34 &&
            props.value.approval_stage > 1) ||
          (props.priv == 4 &&
            props.value.approval_stage_countter == 34 &&
            props.value.approval_stage == 3) ||
          (props.priv == 5 &&
            props.value.approval_stage_countter == 34 &&
            props.value.approval_stage > 3) ||
          (props.priv == 4 &&
            props.value.approval_stage_countter == 34 &&
            props.value.approval_stage > 4) ||
          props.value.approval_stage_decline > 0 ? (
            <Button
              style={{
                backgroundColor:
                  props.active == props.value.id ? "#0F0E83" : "#ccc",
                color: props.active == props.value.id ? "#fff" : "#000",
              }}
              onClick={() => {
                props.getApplicantDetails(props.value.id);
              }}
            >
              {" "}
              View{" "}
            </Button>
          ) : null}

          {(props.priv == 2 &&
            props.value.approval_stage_countter == 34 &&
            props.value.approval_stage == 0) ||
          (props.priv == 3 &&
            props.value.approval_stage_countter == 34 &&
            props.value.approval_stage == 1) ||
          (props.priv == 4 &&
            props.value.approval_stage_countter == 34 &&
            props.value.approval_stage == 2) ? (
            <div>
              {props.declineCounter == 0 && props.approvalDecline == 0 ? (
                <>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      props.approve(props.value.id);
                    }}
                    style={{ fontSize: 15 }}
                  >
                    <DoneAllIcon style={{ color: "green" }} />
                  </IconButton>
                </>
              ) : null}

              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  declinemodal(props.value.id);
                }}
                style={{ fontSize: 15 }}
              >
                <ClearIcon style={{ color: "red" }} />
              </IconButton>
            </div>
          ) : null}
        </Box>
      ) : null}

      {props.display == 2 ? (
        <Box>
          {props.priv <= 2 ? (
            <Box>
              {props?.value?.status == 0 ? (
                props.sectionloader == props.section + props.value.id ? (
                  // <div></div>
                  <CircularProgress size={24} />
                ) : (
                  <div>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        // props.approvesection(props.section, props.value.id);
                        setApprovalAction("approve");
                        setconfirmModal(true);
                      }}
                      style={{ fontSize: 15 }}
                    >
                      <DoneAllIcon style={{ color: "green" }} />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        setApprovalAction("delete");
                        setconfirmModal(true);
                      }}
                      style={{ fontSize: 15 }}
                    >
                      <ClearIcon style={{ color: "red" }} />
                    </IconButton>
                  </div>
                )
              ) : null}

              {props.value?.status == 1 ? (
                <div>
                  <Button style={{ border: "solid 1px green", color: "green" }}>
                    {" "}
                    Verified{" "}
                  </Button>
                </div>
              ) : null}

              {props.value?.status == 2 ? (
                <div>
                  <Button style={{ border: "solid 1px red", color: "red" }}>
                    {" "}
                    Declined{" "}
                  </Button>
                </div>
              ) : null}
            </Box>
          ) : null}

          {props.priv > 2 ? (
            <Box>
              {props.value?.status == 0 ? (
                <div>
                  <Button
                    style={{ border: "solid 1px orange", color: "orange" }}
                  >
                    {" "}
                    Pending{" "}
                  </Button>
                </div>
              ) : null}

              {props.value?.status == 1 ? (
                <div>
                  <Button style={{ border: "solid 1px green", color: "green" }}>
                    {" "}
                    Verified{" "}
                  </Button>
                </div>
              ) : null}

              {props.priv > 2 ? (
                <Box>
                  {/* {props.value?.status == 0 ? (
                    <div>
                      <Button
                        style={{ border: "solid 1px orange", color: "orange" }}
                      >
                        {" "}
                        Pending{" "}
                      </Button>
                    </div>
                  ) : null} */}

                  {/* {props.value?.status == 1 ? (
                    <div>
                      <Button
                        style={{ border: "solid 1px green", color: "green" }}
                      >
                        {" "}
                        Verified{" "}
                      </Button>
                    </div>
                  ) : null} */}

                  {props.value?.status == 2 ? (
                    <div>
                      <Button style={{ border: "solid 1px red", color: "red" }}>
                        {" "}
                        Declined{" "}
                      </Button>
                    </div>
                  ) : null}
                </Box>
              ) : null}
            </Box>
          ) : null}
        </Box>
      ) : null}
   
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundColor: "#fff",
  },
  emailcontainer: {
    border: 1,
  },
  multilineColor: {
    color: "#fff",
  },
  floatingLabelFocusStyle: {
    color: "#fff",
  },
  image: {
    backgroundImage: "url(../../img/loginillustration.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.type === "light" ? "#fff" : "#fff",
    backgroundSize: "67%",
    backgroundPosition: "center",
  },

  signinFormGrid: {
    boxShadow: "none",
    backgroundColor: "#0F0E83",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#0043ce",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formHolder: {
    width: "60%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#fff",
    borderRadius: "0.4rem",
    color: "#0F0E83",
    width: "100%",
  },
  textfield: {
    borderRadius: "2rem",
  },
  email: {
    width: "60%",
    borderWidth: 2,
  },
  pending: {
    border: "solid 1px orange",
    color: "orange",
  },
  decline: {
    border: "solid 1px red",
    color: "red",
  },
  aprove: {
    border: "solid 1px green",
    color: "green",
  },
  modalview: {
    position: "absolute",
    width: 800,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default Approvalbuttons;
