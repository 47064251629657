import React, { useRef, useState } from "react";
import { Fireworks } from "fireworks-js/dist/react";
import { Button } from "@material-ui/core";
import "./Presentation.css";
import oilandgaslogo from "../../oilandgaslogo.png";

export default function Presentation() {
  const [launchProject, setlaunchProject] = useState(false);
  const [showText, setshowText] = useState(false);
  const options = {
    speed: 3,
  };
  const style = {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    position: "fixed",
    background: "#000",
  };

  const setTextTimeOut = () => {
    setTimeout(() => {
      setshowText(true);
    }, 4000);
  };

  return (
    <>
      {!launchProject && (
        <>
          {" "}
          <div
            style={{
              // border: "1px solid red",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              flexDirection: "column",
              rowGap: "60px",
            }}
          >
            <div>
              <img
                src={oilandgaslogo}
                alt="Logo"
                style={{
                  width: "160px",
                  textAlign: "center",
                  alignSelf: "center",
                }}
              />
            </div>
            <div style={{ fontSize: "18px", fontWeight: "700" }}>
              OGFZA Registry Management Information System (RIMS)
            </div>

            <div
              onClick={() => {
                setlaunchProject(!launchProject);
                setTextTimeOut();
              }}
            >
              <button class="button-19" role="button">
                LAUNCH RIMS
              </button>
            </div>
          </div>
        </>
      )}

      {launchProject && (
        <>
          {showText && (
            <>
              <div
                className="text_body"
                style={{
                  color: "white",
                  backgroundColor: "",
                  position: "absolute",
                  zIndex: "200000",
                  // border: "2px",
                  // fontSize: "22px",
                  fontWeight: "800",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  rowGap: "0",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                  paddingTop: "-20rem",
                }}
              >
                <div id="shared-loader">
                  <div class="cube">
                    <div class="cube-face cube-face-front">0</div>
                    <div class="cube-face cube-face-back">R</div>
                    <div class="cube-face cube-face-right">R</div>
                    <div class="cube-face cube-face-left">I</div>
                    <div class="cube-face cube-face-top">M</div>
                    <div class="cube-face cube-face-bottom">S</div>
                  </div>
                </div>
                <div
                  style={{
                    color: "white",
                    marginTop: "-100px",
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    fontSize: "24px",
                  }}
                >
                  <div>
                    <h1 className=""> CONGRATULATIONS !!!</h1>
                  </div>

                  <div>
                    <h3>
                      THE MD / CEO, UMANA O UMANA AND THE ENTIRE OGFZA STAFF
                    </h3>
                  </div>

                  <div>
                    <h1> ON </h1>
                  </div>

                  <div>
                    <h3>
                      THE COMMISSIONING OF THE REGISTRY INFORMATION MANAGEMENT
                      SYSTEMS, <br /> (Online Registration Portal)
                    </h3>
                  </div>

                  <div>
                    <h3> BY</h3>
                  </div>

                  <div>
                    <h3>THE HON MINISTER OF INDUSTRY, TRADE AND INVESTMENT.</h3>
                  </div>

                  <div>
                    <h3> H.E. OTUNBA ADENIYI ADEBAYO</h3>
                  </div>
                </div>
              </div>
            </>
          )}

          <div style={{ marginTop: "-50px" }}>
            <Fireworks options={options} style={style} />
          </div>
        </>
      )}
    </>
  );
}
