import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LockOutlineIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import oilandgaslogo from "../../assets/oilandgaslogo.png";
// import Emailstep1 from './Emailstep1';
import Emailstep1 from "../../components/Emailstep1";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Menutab from "../../components/Tabs/Menutab";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import FormStagePreview from "../../components/preview/FormStagePreview";
import { Modal } from "@material-ui/core";
import { getRenewalFormStage1 } from "../../Services/apiServices/Form";

document.body.style = "background: #f5f5f5;";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "13px",
      },
    },
  },
})(TextField);

const companyIncoporation = [
  { value: "Incorporated in the Free (FZE)" },
  { value: "Incorporated in Nigeria (CAC)" },
  { value: "Offshore Incorporation" },
];

function Companybasicinformationpage(props) {
  const {
    snakBarSetter,
    stageSetter,
    errorSetter,
    formloaderSetter,
    rootid,
    formloader,
    stage,
    whichapplication,
  } = props;

  const [serverData, setserverData] = useState(false);
  const [formEdit, setformEdit] = useState(false);
  const [previousFIlledData, setpreviousFIlledData] = useState();

  //renewal company basic contact information  states
  const [renewalinputfields, setRenewalinputfields] = useState([
    {
      name: "",
      email: "",
      phonenumber: "",
    },
  ]);

  const [
    renewalcompanybasicinformationphone,
    setRenewalcompanybasicinformationphone,
  ] = useState("");
  const [
    renewalcompanybasicinformationcompanyname,
    setRenewalcompanybasicinformationcompanyname,
  ] = useState("");
  const [
    renewalcompanybasicinformationfileno,
    setRenewalcompanybasicinformationfileno,
  ] = useState("");
  const [
    renewalcompanybasicinformationtin,
    setRenewalcompanybasicinformationtin,
  ] = useState("");
  const [
    renewalcompanybasicinformationfreezoneaddress,
    setRenewalcompanybasicinformationfreezoneaddress,
  ] = useState("");
  const [
    renewalcompanybasicinformationwebsite,
    setRenewalcompanybasicinformationwebsite,
  ] = useState("");
  const [
    renewalcompanybasicinformationemail,
    setRenewalcompanybasicinformationemail,
  ] = useState("");
  const [renewalphonenumber, setRenewalphonenumber] = useState("");
  const [renewaldynamicnameval, setRenewaldynamicnameval] = useState("");
  const [renewaldynamicemailval, setRenewaldynamicemailval] = useState("");
  const [renewaldynamicphonenumberval, setRenewaldynamicphonenumberval] =
    useState("");

  const [
    renewalcompanybasicinformationplan,
    setRenewalcompanybasicinformationplan,
  ] = useState("");

  //validators
  const [
    renewalcompanybasicinformationcompanynamevalidator,
    setRenewalcompanybasicinformationcompanynamevalidator,
  ] = useState(true);
  const [
    renewalcompanybasicinformationfilenovalidator,
    setRenewalcompanybasicinformationfilenovalidator,
  ] = useState(true);
  const [
    renewalcompanybasicinformationtinvalidator,
    setRenewalcompanybasicinformationtinvalidator,
  ] = useState(true);
  const [
    renewalcompanybasicinformationfreezoneaddressvalidator,
    setRenewalcompanybasicinformationfreezoneaddressvalidator,
  ] = useState(true);
  const [
    renewalcompanybasicinformationwebsitevalidator,
    setRenewalcompanybasicinformationwebsitevalidator,
  ] = useState(true);
  const [
    renewalcompanybasicinformationemailvalidator,
    setRenewalcompanybasicinformationemailvalidator,
  ] = useState(true);

  const [renewalwestafricancountrystage3, setRenewalwestafricancountrystage3] =
    useState();
  const [
    renewalcompanybasicinformationphonevalidator,
    setRenewalcompanybasicinformationphonevalidator,
  ] = useState(true);

  const [
    renewalcompanybasicinformationplanvalidator,
    setRenewalcompanybasicinformationplanvalidator,
  ] = useState(true);

  const classes = useStyles();

  const renewalregistrationlocationOnchange = (e) => {
    props.renewalregistrationlocationOnchange(e.target.value);
  };
  const [previewData, setpreviewData] = useState();
  const [showPreviewForm, setshowPreviewForm] = useState(false);

  const renewalwestAfricanCountryStage3 = (value) => {
    setRenewalwestafricancountrystage3(value);
  };

  const renewalcompanynameOnchange = (e) => {
    setRenewalcompanybasicinformationcompanyname(e.target.value);
  };

  const renewalphonenumberoneOnchange = (e) => {
    setRenewalcompanybasicinformationphone(e.target.value);
  };
  const renewalfilenoOnchange = (e) => {
    setRenewalcompanybasicinformationfileno(e.target.value);
  };

  const renewaltinOnchange = (e) => {
    setRenewalcompanybasicinformationtin(e.target.value);
  };

  const renewalfreezoneaddressOnchange = (e) => {
    setRenewalcompanybasicinformationfreezoneaddress(e.target.value);
  };
  const renewalwebsiteOnchange = (e) => {
    setRenewalcompanybasicinformationwebsite(e.target.value);
  };

  const renewalemailOnchange = (e) => {
    setRenewalcompanybasicinformationemail(e.target.value);
  };

  const renewalPlan = (e) => {
    setRenewalcompanybasicinformationplan(e.target.value);
  };

  const renewalPhoneNumberOne = (e) => {
    setRenewalphonenumber(e.target.value);
  };

  const formPreviewSetter = (value) => {
    setshowPreviewForm(value);
  };

  const renewalhandleInputChange = (index, event) => {
    const values = [...renewalinputfields];
    console.log(event.target.name);

    setRenewaldynamicnameval(0);
    setRenewaldynamicemailval(0);
    setRenewaldynamicphonenumberval(0);
    if (event.target.name == "name") {
      values[index].name = event.target.value;
    } else if (event.target.name == "email") {
      values[index].email = event.target.value;
    } else if (event.target.name == "phonenumber") {
      values[index].phonenumber = event.target.value;
    } else {
      // const stageTestmouData = new FormData();
      // stageTestmouData.append("test", event.target.files);
      // values[index].signature = event.target.files;
      //valuefiles[index] = event.target.files;
      //values[index].signature = stageTestmouData;
      //console.log(values[index].signature.name);
    }

    setRenewalinputfields(values);
    console.log(renewalinputfields);
  };

  useEffect(() => {
    async function fetchFormData() {
      const response = await getRenewalFormStage1(rootid);

      if (response?.data?.result.renewalcompanyinfo && !formEdit) {
        setpreviousFIlledData(response?.data?.result);
        setserverData(true);
      }
    }
    fetchFormData();
  }, [serverData]);

  const renewalhandleAddFields = () => {
    const values = [...renewalinputfields];
    values.push({
      name: "",
      email: "",
      phonenumber: "",
    });
    setRenewalinputfields(values);
  };

  const renewalhandleRemoveFields = (index) => {
    const values = [...renewalinputfields];
    let size = Object.keys(values).length;
    if (size == 1) {
      alert("Please note you cant remove this as you must enter a value ");
      return false;
    }

    values.splice(index, 1);
    setRenewalinputfields(values);
  };

  const renewalsCompanybasicPreview = async () => {
    formloaderSetter(false);
    if (renewalcompanybasicinformationcompanyname.length < 1) {
      setRenewalcompanybasicinformationcompanynamevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Witness Company Name");
      return false;
    } else {
      setRenewalcompanybasicinformationcompanynamevalidator(true);
    }
    if (renewalcompanybasicinformationfileno.length < 1) {
      setRenewalcompanybasicinformationfilenovalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter F.Z.E. License No.");
      return false;
    } else {
      setRenewalcompanybasicinformationfilenovalidator(true);
    }
    if (renewalcompanybasicinformationtin.length < 1) {
      setRenewalcompanybasicinformationtinvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter TIN");
      return false;
    } else {
      setRenewalcompanybasicinformationtinvalidator(true);
    }

    if (renewalcompanybasicinformationfreezoneaddress.length < 1) {
      setRenewalcompanybasicinformationfreezoneaddressvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter free zone office address");
      return false;
    } else {
      setRenewalcompanybasicinformationfreezoneaddressvalidator(true);
    }

    if (renewalcompanybasicinformationwebsite.length < 1) {
      setRenewalcompanybasicinformationwebsitevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter website");
      return false;
    } else {
      setRenewalcompanybasicinformationwebsitevalidator(true);
    }
    if (renewalcompanybasicinformationemail.length < 1) {
      setRenewalcompanybasicinformationemailvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter email");
      return false;
    } else {
      setRenewalcompanybasicinformationemailvalidator(true);
    }
    if (renewalcompanybasicinformationphone.length < 1) {
      setRenewalcompanybasicinformationphonevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter plan");
      return false;
    } else {
      setRenewalcompanybasicinformationphonevalidator(true);
    }

    // const renewalCompanyInfoData = {
    //   renewal_id: rootid,
    //   companyname: renewalcompanybasicinformationcompanyname,
    //   fze_no: renewalcompanybasicinformationfileno,
    //   tin: renewalcompanybasicinformationtin,
    //   address: renewalcompanybasicinformationfreezoneaddress,
    //   website: renewalcompanybasicinformationwebsite,
    //   email: renewalcompanybasicinformationemail,
    //   phone: renewalcompanybasicinformationphone,
    //   incoporationtype: renewalwestafricancountrystage3,
    //   contact_person: renewalinputfields,
    // };

    formloaderSetter(false);
    const renewalCompanyInfoData = [
      // { name: "root_id", value: `${rootid}` },
      {
        name: "company name",
        value: `${renewalcompanybasicinformationcompanyname}`,
      },
      { name: "fze_no", value: `${renewalcompanybasicinformationfileno}` },
      { name: "tin", value: `${renewalcompanybasicinformationtin}` },
      {
        name: "address",
        value: `${renewalcompanybasicinformationfreezoneaddress}`,
      },
      { name: "website", value: `${renewalcompanybasicinformationwebsite}` },
      { name: "email", value: `${renewalcompanybasicinformationemail}` },
      { name: "phone", value: `${renewalcompanybasicinformationphone}` },
      {
        name: "incoporationtype",
        value: `imgInputArra`,
        imgInputArra: true,
        imgInputArraData: renewalwestafricancountrystage3,
      },
      {
        name: "contact_person",
        value: `imgInputArra`,
        imgInputArra: true,
        imgInputArraData: renewalinputfields,
      },
    ];

    console.log(renewalinputfields);
    console.log(renewalCompanyInfoData);
    console.log(renewalwestafricancountrystage3);
    setshowPreviewForm(false);
    setpreviewData(renewalCompanyInfoData);
    setshowPreviewForm(true);
    // await axios
    //   .post(
    //     " https://ogfzaapi.skillzserver.com/site/aplicationrenewalcompanyinfo/create",
    //     renewalCompanyInfoData
    //   )
    //   .then((res) => {
    //     formloaderSetter(false);
    //     if (res.data.status == 1) {
    //       stageSetter(res.data.result.application_stage);
    //     }
    //     console.log(res.data);
    //   });
  };

  const renewalsCompanybasicSubmit = async () => {
    formloaderSetter(true);
    if (renewalcompanybasicinformationcompanyname.length < 1) {
      setRenewalcompanybasicinformationcompanynamevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Witness Company Name");
      return false;
    } else {
      setRenewalcompanybasicinformationcompanynamevalidator(true);
    }
    if (renewalcompanybasicinformationfileno.length < 1) {
      setRenewalcompanybasicinformationfilenovalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter F.Z.E. License No.");
      return false;
    } else {
      setRenewalcompanybasicinformationfilenovalidator(true);
    }
    if (renewalcompanybasicinformationtin.length < 1) {
      setRenewalcompanybasicinformationtinvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter tin");
      return false;
    } else {
      setRenewalcompanybasicinformationtinvalidator(true);
    }

    if (renewalcompanybasicinformationfreezoneaddress.length < 1) {
      setRenewalcompanybasicinformationfreezoneaddressvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter free zone office address");
      return false;
    } else {
      setRenewalcompanybasicinformationfreezoneaddressvalidator(true);
    }

    if (renewalcompanybasicinformationwebsite.length < 1) {
      setRenewalcompanybasicinformationwebsitevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter website");
      return false;
    } else {
      setRenewalcompanybasicinformationwebsitevalidator(true);
    }
    if (renewalcompanybasicinformationemail.length < 1) {
      setRenewalcompanybasicinformationemailvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter email");
      return false;
    } else {
      setRenewalcompanybasicinformationemailvalidator(true);
    }
    if (renewalcompanybasicinformationphone.length < 1) {
      setRenewalcompanybasicinformationphonevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter plan");
      return false;
    } else {
      setRenewalcompanybasicinformationphonevalidator(true);
    }

    const renewalCompanyInfoData = {
      renewal_id: rootid,
      company_name: renewalcompanybasicinformationcompanyname,
      fze_no: renewalcompanybasicinformationfileno,
      tin: renewalcompanybasicinformationtin,
      address: renewalcompanybasicinformationfreezoneaddress,
      website: renewalcompanybasicinformationwebsite,
      email: renewalcompanybasicinformationemail,
      phone: renewalcompanybasicinformationphone,
      incoporationtype: renewalwestafricancountrystage3,
      contact_person: renewalinputfields,
    };

    await axios
      .post(
        "https://ogfzaapi.skillzserver.com/aplicationrenewalcompanyinfo/create",
        renewalCompanyInfoData
      )
      .then((res) => {
        formloaderSetter(false);
        if (res.data.status == 1) {
          stageSetter(res.data.result.application_stage);
        }
        console.log(res.data);
      });
  };

  const onSelectSetter = async (value) => {
    console.log("clicked");

    console.log("clicked", "form111");
    stageSetter(value);
    const response = await getRenewalFormStage1(rootid);

    if (response?.data?.result.renewalcompanyinfo && !formEdit) {
      setpreviousFIlledData(response?.data?.result);
      setserverData(true);
    }
    // console.log(response, "djjkdjd");

    // const response = await getFormStage1(rootid);
    // console.log(response, 'djjkdjd')
    // setserverData(true);
    //load
    // if data set visibilitiy to true and change display
  };
  return (
    <React.Fragment>
      <Modal
        open={showPreviewForm}
        onClose={() => setshowPreviewForm(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
          overflow: "scroll",
          border: "2px solid blue",
          height: "100vh",
          marginTop: "10px",
          // overflowX: 'visible',
          // backgroundColor:'white'
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            height: "maxContent",
            width: window.innerWidth - 50,
            padding: "1rem 2rem",
            border: "2px solid orange",
          }}
        >
          <FormStagePreview
            data={[previewData]}
            formloaderSetter={formloaderSetter}
            formPreviewSetter={formPreviewSetter}
          />
          <Button
            style={{ marginRight: "1rem" }}
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => {
              setshowPreviewForm(false);
              if (!formloader) {
                return renewalsCompanybasicSubmit();
              } else {
                return null;
              }
            }}
          >
            {" "}
            Confirm Save and Next{" "}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              formloaderSetter(false);
              setshowPreviewForm(false);
            }}
          >
            {" "}
            No{" "}
          </Button>
        </div>
      </Modal>
      <AppBar position="absolute" color="#ffffff" className={classes.appBar}>
        <Toolbar className={classes.layout}>
          <img
            src={oilandgaslogo}
            alt="Logo"
            style={{
              width: "80px",
              height: "60px",
              textAlign: "center",
              alignSelf: "center",
            }}
          />
        </Toolbar>
      </AppBar>

      <Grid container component="main" className={classes.layout}>
        <Menutab
          onSelectSetter={onSelectSetter}
          whichapplication={whichapplication}
          stage={stage}
        />
        {!serverData ? (
          <>
            <Grid item xs={12}>
              {/* <Menutab stage={stage} /> */}

              <h4> Company Basic Details:</h4>
            </Grid>

            <Grid
              container
              xs={12}
              spacing={1}
              style={{ backgroundColor: "#fff", padding: 30 }}
            >
              <h4> Company Incorporation Type:</h4>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  onChange={(event, newValue) => {
                    console.log(event, newValue);
                    renewalwestAfricanCountryStage3(newValue);
                  }}
                  id="tags-outlined"
                  options={companyIncoporation}
                  getOptionLabel={(option) => option.value}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      label="select company incorporation type"
                    
                      {...params}
                      variant="outlined"
                      style={{ backgroundColor: "#fff" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-full-width"
                  placeholder="Company Name"
                  name="companyname"
                  fullWidth
                  multiline
                  value={renewalcompanybasicinformationcompanyname}
                  onChange={renewalcompanynameOnchange}
                  margin="normal"
                  style={{ backgroundColor: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                    className:
                      !renewalcompanybasicinformationcompanynamevalidator
                        ? classes.errorstylelabel
                        : null,
                  }}
                  InputProps={{
                    classes: {
                      focused:
                        !renewalcompanybasicinformationcompanynamevalidator
                          ? classes.errorstyle
                          : null,
                      notchedOutline:
                        !renewalcompanybasicinformationcompanynamevalidator
                          ? classes.errorstyle
                          : null,
                    },
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="outlined-full-width"
                  placeholder="F.Z.E. License No."
                  name="fileno"
                  fullWidth
                  multiline
                  value={renewalcompanybasicinformationfileno}
                  onChange={renewalfilenoOnchange}
                  margin="normal"
                  style={{ backgroundColor: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                    className: !renewalcompanybasicinformationfilenovalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !renewalcompanybasicinformationfilenovalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline:
                        !renewalcompanybasicinformationfilenovalidator
                          ? classes.errorstyle
                          : null,
                    },
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-full-width"
                  placeholder="Tax Identification Number(TIN)"
                  name="tin"
                  fullWidth
                  multiline
                  value={renewalcompanybasicinformationtin}
                  onChange={renewaltinOnchange}
                  margin="normal"
                  style={{ backgroundColor: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                    className: !renewalcompanybasicinformationtinvalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !renewalcompanybasicinformationtinvalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline:
                        !renewalcompanybasicinformationtinvalidator
                          ? classes.errorstyle
                          : null,
                    },
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <h4> Company Contact Information </h4>
            </Grid>

            <Grid
              container
              xs={12}
              spacing={1}
              style={{ backgroundColor: "#fff", padding: 30 }}
            >
              <Grid item xs={12}>
                <TextField
                  id="outlined-full-width"
                  placeholder="Free Zone Office Address"
                  name="freezoneaddress"
                  fullWidth
                  multiline
                  value={renewalcompanybasicinformationfreezoneaddress}
                  onChange={renewalfreezoneaddressOnchange}
                  margin="normal"
                  style={{ backgroundColor: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                    className:
                      !renewalcompanybasicinformationfreezoneaddressvalidator
                        ? classes.errorstylelabel
                        : null,
                  }}
                  InputProps={{
                    classes: {
                      focused:
                        !renewalcompanybasicinformationfreezoneaddressvalidator
                          ? classes.errorstyle
                          : null,
                      notchedOutline:
                        !renewalcompanybasicinformationfreezoneaddressvalidator
                          ? classes.errorstyle
                          : null,
                    },
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  id="outlined-full-width"
                  placeholder="Website."
                  name="website"
                  fullWidth
                  multiline
                  value={renewalcompanybasicinformationwebsite}
                  onChange={renewalwebsiteOnchange}
                  margin="normal"
                  style={{ backgroundColor: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                    className: !renewalcompanybasicinformationwebsitevalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !renewalcompanybasicinformationwebsitevalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline:
                        !renewalcompanybasicinformationwebsitevalidator
                          ? classes.errorstyle
                          : null,
                    },
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-full-width"
                  placeholder="Email"
                  name="email"
                  fullWidth
                  multiline
                  value={renewalcompanybasicinformationemail}
                  onChange={renewalemailOnchange}
                  margin="normal"
                  style={{ backgroundColor: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                    className: !renewalcompanybasicinformationemailvalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !renewalcompanybasicinformationemailvalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline:
                        !renewalcompanybasicinformationemailvalidator
                          ? classes.errorstyle
                          : null,
                    },
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  id="outlined-full-width"
                  placeholder="Phone Number"
                  name="plan"
                  fullWidth
                  multiline
                  value={renewalcompanybasicinformationphone}
                  onChange={renewalphonenumberoneOnchange}
                  margin="normal"
                  style={{ backgroundColor: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                    className: !renewalcompanybasicinformationphonevalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !renewalcompanybasicinformationphonevalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline:
                        !renewalcompanybasicinformationphonevalidator
                          ? classes.errorstyle
                          : null,
                    },
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <h4>Primary Contact Information</h4>
              </Grid>

              {renewalinputfields.map((renewalinputField, index) => (
                <>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      id="outlined-full-width"
                      placeholder="Name"
                      name="name"
                      margin="normal"
                      value={renewalinputField.name}
                      onChange={(event) =>
                        renewalhandleInputChange(index, event)
                      }
                      InputProps={{
                        classes: {
                          focused:
                            props.renewaldynamicnameval == index + 1
                              ? classes.errorstyle
                              : null,
                          notchedOutline:
                            props.renewaldynamicnameval == index + 1
                              ? classes.errorstyle
                              : null,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      id="outlined-full-width"
                      placeholder="email"
                      name="email"
                      margin="normal"
                      value={renewalinputField.email}
                      onChange={(event) =>
                        renewalhandleInputChange(index, event)
                      }
                      InputProps={{
                        classes: {
                          focused:
                            props.renewaldynamicemailval == index + 1
                              ? classes.errorstyle
                              : null,
                          notchedOutline:
                            props.renewaldynamicemailval == index + 1
                              ? classes.errorstyle
                              : null,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      id="outlined-full-width"
                      placeholder="Phone Number"
                      name="phonenumber"
                      margin="normal"
                      value={renewalinputField.phonenumber}
                      onChange={(event) =>
                        renewalhandleInputChange(index, event)
                      }
                      InputProps={{
                        classes: {
                          focused:
                            props.renewaldynamicphonenumberval == index + 1
                              ? classes.errorstyle
                              : null,
                          notchedOutline:
                            props.renewaldynamicphonenumberval == index + 1
                              ? classes.errorstyle
                              : null,
                        },
                      }}
                    />
                  </Grid>
                </>
              ))}

              <Grid
                container
                xs={12}
                justify="flex-end"
                style={{ marginBottom: "30px" }}
              >
                <Box component="span" mx={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#ccc",
                      height: "30px",
                      color: "black",
                    }}
                    className={classes.button}
                    onClick={renewalhandleRemoveFields}
                    startIcon={
                      <ArrowBackIcon
                        style={{ fontSize: "20px", color: "black" }}
                      />
                    }
                  >
                    REMOVE ADD
                  </Button>
                </Box>

                <Box component="span">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: "#060454", height: "30px" }}
                    className={classes.button}
                    onClick={renewalhandleAddFields}
                    endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                  >
                    ADD MORE
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              justify="flex-end"
              style={{ marginBottom: "50px" }}
            >
              <Box component="span">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={!formloader ? renewalsCompanybasicPreview : null}
                  style={{
                    backgroundColor: "#060454",
                    height: "30px",
                    marginTop: "15px",
                    marginRight: "255px",
                  }}
                  className={classes.button}
                  endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                >
                  Preview & Continue{" "}
                  {formloader ? <CircularProgress size={24} /> : null}
                </Button>
              </Box>
            </Grid>
          </>
        ) : (
          <div
            style={{
              backgroundColor: "#fff",
              marginTop: 25,
              padding: 30,
              marginBottom: "7rem",
              width: "100%",
            }}
          >
            {" "}
            <Grid
              container
              style={{ display: "", flexDirection: "", columnGap: "" }}
            >
              {/* {previousFIlledData?.document && ( */}

              {previousFIlledData?.renewalcompanyinfo.status === 0 && (
                <>
                  <Grid
                    xs={5}
                    style={{
                      display: "",
                      flexDirection: "",
                      marginRight: "1rem",
                    }}
                  >
                    <div style={{ marginBottom: "1rem" }}>
                      <b>Company Information</b>
                    </div>
                    <div style={{ display: "" }}>
                      {" "}
                      <div>Company Name</div>
                      <input
                        disabled
                        value={
                          previousFIlledData?.renewalcompanyinfo?.company_name
                        }
                      />
                    </div>
                    <div style={{ display: "" }}>
                      {" "}
                      <div>Free Zone Number</div>
                      <input
                        disabled
                        value={previousFIlledData?.renewalcompanyinfo?.fze_no}
                      />
                    </div>
                    <div style={{ display: "" }}>
                      {" "}
                      <div>TIN</div>
                      <input
                        disabled
                        value={previousFIlledData?.renewalcompanyinfo?.tin}
                      />
                    </div>
                  </Grid>
                </>
              )}
              {/* {previousFIlledData?.registeredDebenture.status === 0 && (
                <>
                  <Grid
                    xs={5}
                    style={{
                      display: "",
                      flexDirection: "",
                      marginRight: "1rem",
                    }}
                  >
                      
                    <div style={{ display: "flex" }}>
                    <div><b>Registered Debenture</b></div>
                      <input
                        disabled
                        value={
                          previousFIlledData?.registeredDebenture?.details
                        }
                      />
                    </div>
                   
                 
                  </Grid>
                </>
              )} */}
              {previousFIlledData?.renewalcompanycontactdetails && (
                <>
                  {/* Company Contact Details */}
                  {previousFIlledData?.renewalcompanycontactdetails?.map(
                    (e) => (
                      <>
                        {e.status === 0 && (
                          <>
                            <Grid
                              xs={5}
                              style={{
                                display: "",
                                flexDirection: "",
                                marginRight: "1rem",
                              }}
                            >
                              <div style={{ marginBottom: "1rem" }}>
                                <b>Company Contact Details</b>
                              </div>
                              <div style={{ display: "" }}>
                                {" "}
                                <div>Company address</div>
                                <input disabled value={e?.address} />
                              </div>
                              <div style={{ display: "" }}>
                                {" "}
                                <div>Email</div>
                                <input disabled value={e?.email} />
                              </div>
                              <div style={{ display: "" }}>
                                {" "}
                                <div>phone</div>
                                <input disabled value={e?.phone} />
                              </div>
                            </Grid>
                          </>
                        )}{" "}
                      </>
                    )
                  )}
                </>
              )}
              {previousFIlledData?.renewalCompanyInfoincoporation && (
                <>
                  {/* Company Contact Details */}
                  {previousFIlledData?.renewalCompanyInfoincoporation?.map(
                    (e) => (
                      <>
                        {e.status === 0 && (
                          <>
                            <Grid
                              xs={5}
                              style={{
                                display: "",
                                flexDirection: "",
                                marginRight: "1rem",
                              }}
                            >
                              <div style={{ marginTop: "1rem" }}>
                                <b>Company Incorporation Information</b>
                              </div>

                              <div style={{ display: "" }}>
                                {" "}
                                {/* <div>phone</div> */}
                                <input disabled value={e?.value} />
                              </div>
                            </Grid>
                          </>
                        )}{" "}
                      </>
                    )
                  )}
                </>
              )}
              {previousFIlledData?.renewalCompanycontactPerson && (
                <>
                  {/* Company Contact Details */}
                  {previousFIlledData?.renewalCompanycontactPerson?.map((e) => (
                    <>
                      {e.status === 0 && (
                        <>
                          <Grid
                            xs={5}
                            style={{
                              display: "",
                              flexDirection: "",
                              marginRight: "1rem",
                            }}
                          >
                            <div style={{ marginTop: "1rem" }}>
                              <b>Company Contact Person(s)</b>
                            </div>
                            <div style={{ display: "" }}>
                              {" "}
                              <div>Name</div>
                              <input disabled value={e?.name} />
                            </div>
                            <div style={{ display: "" }}>
                              {" "}
                              <div>Email</div>
                              <input disabled value={e?.email} />
                            </div>
                            <div style={{ display: "" }}>
                              {" "}
                              <div>Phone Number</div>
                              <input disabled value={e?.phone} />
                            </div>
                          </Grid>
                        </>
                      )}{" "}
                    </>
                  ))}
                </>
              )}
            </Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                // onClick={!formloader ? previewStageData : null}
                style={{ backgroundColor: "#060454", height: "30px" }}
                className={classes.button}
                endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                onClick={() => {
                  setformEdit(true);
                  setserverData(false);
                }}
              >
                Refill Form
              </Button>
            </Grid>
          </div>
        )}
      </Grid>

      {/* <Grid container xs={12} spacing={1} style={{backgroundColor:'#fff',padding:30}}>

        <Grid item xs={3} >
        <TextField
          id="outlined-full-width"
          label="Undeveloped Land:"
          placeholder="sq. mtrs."
          fullWidth
          onChange = {undevelopedlandOnchange}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
        />
            </Grid >

            <Grid item xs={3} >
            <TextField
              id="outlined-full-width"
              label="Developed Land:"
              onChange = {developedlandOnchange}
              placeholder="sq. mtrs."
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
                </Grid >

                <Grid item xs={3}  >
                <TextField
                  id="outlined-full-width"
                  label="Concrete Stacking Area:"
                  placeholder="sq. mtrs."
                  fullWidth
                  onChange = {concretestackingareaOnchange}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
                    </Grid >

            <Grid item xs={3}  >
            <TextField
              id="outlined-full-width"
              label="Warehouse Space:"
              placeholder="sq. mtrs."
              onChange = {warehousespaceOnchange}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
                </Grid >

                <Grid item xs={3}  >
                <TextField
                  id="outlined-full-width"
                  label="Factory Premises:"
                  placeholder="sq. mtrs."
                  onChange = {factorypremisesOnchange}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
                    </Grid >


                    <Grid item xs={3} >
                    <TextField
                      id="outlined-full-width"
                      label="Office Accommodation:"
                      placeholder="sq. mtrs."
                      onChange = {officeaccommodationOnchange}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                        </Grid >

                        <Grid item xs={3} >
                        <TextField
                          id="outlined-full-width"
                          label="Equipment Requirement:"
                          placeholder="sq. mtrs."
                          onChange = {equipmentrequirementOnchange}
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                            </Grid >

                            <Grid item xs={3}  >
                            <TextField
                              id="outlined-full-width"
                              label="Residential Accommodation:"
                              placeholder="No. of persons"
                              fullWidth
                              onChange = {residentialaccommodationOnchange}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                            />
                                </Grid >

                        </Grid >
 */}

      {/* 
                <Grid item xs={12} >

                <h4> 8. Expected start date:</h4>
                </Grid>

                <Grid container xs={12} spacing={1} >
                <Grid item xs={12} >
                <TextField
                id="outlined-full-width"
                type="date"
                value = {expectedstartdate}
                placeholder="Placeholder"
                onChange = {expectedstartdateOnchange}
                fullWidth
                style={{backgroundColor:"#fff"}}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  className: !expectedstartdatevalidator ? classes.errorstylelabel:null
                }}
                InputProps={{
                  classes: {

                    focused: !expectedstartdatevalidator ? classes.errorstyle:null,
                    notchedOutline:!expectedstartdatevalidator ? classes.errorstyle:null,
                  }
                }}
                variant="outlined"
                />
                </Grid >

</Grid > */}

      <AppBar position="fixed" className={classes.appBarButom} color="#060454">
        <Typography
          variant="span"
          color="inherit"
          noWrap
          style={{ textAlign: "center", color: "#fff", alignItems: "center" }}
          justify="center"
        >
          Copyright © 2022 <b>Oil and Gas Free Zones Authority</b>. All Rights Reserved.
        </Typography>
      </AppBar>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    position: "relative",
  },
  checkboxLabel: {
    fontSize: 14,
  },
  errorstyle: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
  appBarButom: {
    top: "auto",
    bottom: 0,
    height: "40px",
    backgroundColor: "#060454",
    paddingTop: "5px",
    paddingButtom: "5px",
    alignItems: "center",
    justifyContent: "center",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "60%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

export default Companybasicinformationpage;
