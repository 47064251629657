import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LockOutlineIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import oilandgaslogo from "../../assets/oilandgaslogo.png";
import Emailstep1 from "../../components/Emailstep1";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import Menutab from '../../Menutab';
import Menutab from "../../components/Tabs/Menutab";
import CircularProgress from "@material-ui/core/CircularProgress";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import axios from "axios";
import { Modal } from "@material-ui/core";
import FormStagePreview from "../../components/preview/FormStagePreview";
import { getRenewalFormStage4 } from "../../Services/apiServices/Form";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    height: "34px",
    marginTop: "16px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

document.body.style = "background: #f5f5f5;";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "13px",
      },
    },
  },
})(TextField);

function Renewalbusinesscorporate(props) {
  const {
    snakBarSetter,
    stageSetter,
    errorSetter,
    formloaderSetter,
    rootid,
    formloader,
    stage,
    whichapplication,
  } = props;
  const classes = useStyles();

  const [serverData, setserverData] = useState(false);
  const [formEdit, setformEdit] = useState(false);
  const [previousFIlledData, setpreviousFIlledData] = useState();

  //renewal business corporation state declaration
  const [renewalbizcorpinputField, setRenewalbizcorpinputField] = useState([
    {
      activity: "",
      approvedvendor: "",
    },
  ]);
  const [renewalbizVendorServiceField, setRenewalbizVendorServiceField] =
    useState([
      {
        namevendor: "",
        addressvendor: "",
        servicevendor: "",
      },
    ]);
  const [renewalBizImportField, setRenewalBizImportField] = useState([
    {
      imported: "",
      volumeimported: "",
      valueimported: "",
      volumeimport: "",
      valueimport: "",
      voloversea: "",
      valoversea: "",
    },
  ]);
  const [renewalBizExportField, setRenewalBizExportField] = useState([
    {
      exported: "",
      volexport: "",
      valexport: "",
    },
  ]);

  const [
    renewalbizcorpdynamicactivityval,
    setRenewalbizcorpdynamicactivityval,
  ] = useState("");
  const [
    renewalbizcorpdynamicapprovedvendorval,
    setRenewalbizcorpdynamicapprovedvendorval,
  ] = useState("");

  const [
    renewalbizcorpdynamicnamevendorval,
    setRenewalbizcorpdynamicnamevendorval,
  ] = useState("");
  const [
    renewalbizcorpdynamicaddressvendorval,
    setRenewalbizcorpdynamicaddressvendorval,
  ] = useState("");
  const [
    renewalbizcorpdynamicservicevendorval,
    setRenewalbizcorpdynamicservicevendorval,
  ] = useState("");

  const [
    renewalbizcorpdynamicimportedval,
    setRenewalbizcorpdynamicimportedval,
  ] = useState("");
  const [
    renewalbizcorpdynamicvolumeimportedval,
    setRenewalbizcorpdynamicvolumeimportedval,
  ] = useState("");
  const [
    renewalbizcorpdynamicvalueimportedval,
    setRenewalbizcorpdynamicvalueimportedval,
  ] = useState("");
  const [
    renewalbizcorpdynamicvolumeimportval,
    setRenewalbizcorpdynamicvolumeimportval,
  ] = useState("");
  const [
    renewalbizcorpdynamicvalueimportval,
    setRenewalbizcorpdynamicvalueimportval,
  ] = useState("");
  const [
    renewalbizcorpdynamicvoloverseaval,
    setRenewalbizcorpdynamicvoloverseaval,
  ] = useState("");
  const [
    renewalbizcorpdynamicvaloverseaval,
    setRenewalbizcorpdynamicvaloverseaval,
  ] = useState("");

  const [
    renewalbizcorpdynamicexportedval,
    setRenewalbizcorpdynamicexportedval,
  ] = useState("");
  const [
    renewalbizcorpdynamicvolexportval,
    setRenewalbizcorpdynamicvolexportval,
  ] = useState("");
  const [
    renewalbizcorpdynamicvalexportval,
    setRenewalbizcorpdynamicvalexportval,
  ] = useState("");
  const renewalbizcorpinputChange = (e) => {
    renewalbizcorpinputChange(e.target.value);
  };
  const renewalbizcorpInputChange = (e) => {
    renewalbizcorpInputChange(e.target.value);
  };
  const renewalbizCorpInputChange = (e) => {
    renewalbizCorpInputChange(e.target.value);
  };
  const renewalBizCorpInputChange = (e) => {
    renewalBizCorpInputChange(e.target.value);
  };

  const [previewData, setpreviewData] = useState();
  const [showPreviewForm, setshowPreviewForm] = useState(false);
  //   const companyDirectSubmit = ()=>{
  //     companydirectSubmit()
  // }

  /// renewalbusinesscorporate

  useEffect(() => {
    async function fetchFormData() {
      const response = await getRenewalFormStage4(rootid);

      if (response?.data?.result.businessactivities && !formEdit) {
        setpreviousFIlledData(response?.data?.result);
        setserverData(true);
      }
    }
    fetchFormData();
  }, [serverData]);

  const formPreviewSetter = (value) => {
    setshowPreviewForm(value);
  };
  const renewalbizcorpinputRemoveFields = () => {
    const values = [...renewalbizcorpinputField];
    let size = Object.keys(values).length;
    if (size == 1) {
      alert("Please note you cant remove this as you must enter a value ");
      return false;
    }

    values.splice(size - 1, 1);
    setRenewalbizcorpinputField(values);
  };
  const renewalbizcorpinputAddField = () => {
    const values = [...renewalbizcorpinputField];
    values.push({
      activity: "",
      approvedvendor: "",
    });
    setRenewalbizcorpinputField(values);
  };

  const renewalbizVendorServiceRemoveFields = () => {
    const values = [...renewalbizVendorServiceField];
    let size = Object.keys(values).length;
    if (size == 1) {
      alert("Please note you cant remove this as you must enter a value ");
      return false;
    }

    values.splice(size - 1, 1);
    setRenewalbizVendorServiceField(values);
  };

  const renewalbizVendorServiceAddFields = () => {
    const values = [...renewalbizVendorServiceField];
    values.push({
      namevendor: "",
      addressvendor: "",
      servicevendor: "",
    });
    setRenewalbizVendorServiceField(values);
  };
  const renewalBizImportRemoveFields = () => {
    const values = [...renewalBizImportField];
    let size = Object.keys(values).length;
    if (size == 1) {
      alert("Please note you cant remove this as you must enter a value ");
      return false;
    }

    values.splice(size - 1, 1);
    setRenewalBizImportField(values);
  };
  const renewalBizImportAddFields = () => {
    const values = [...renewalBizImportField];
    values.push({
      imported: "",
      volumeimported: "",
      valueimported: "",
      volumeimport: "",
      valueimport: "",
      voloversea: "",
      valoversea: "",
    });
    setRenewalBizImportField(values);
  };
  const renewalBizExportRemoveFields = () => {
    const values = [...renewalBizExportField];
    let size = Object.keys(values).length;
    if (size == 1) {
      alert("Please note you cant remove this as you must enter a value ");
      return false;
    }

    values.splice(size - 1, 1);
    setRenewalBizExportField(values);
  };
  const renewalBizExportAddFields = () => {
    const values = [...renewalBizExportField];
    values.push({
      exported: "",
      volexport: "",
      valexport: "",
    });
    setRenewalBizExportField(values);
  };

  const renewalbizcorpinputFieldChange = (index, event) => {
    const values = [...renewalbizcorpinputField];
    if (event.target.name == "activity") {
      values[index].activity = event.target.value;
    } else {
      values[index].approvedvendor = event.target.value;
    }

    setRenewalbizcorpinputField(values);
  };

  const renewalbizVendorServiceFieldChange = (index, event) => {
    const values = [...renewalbizVendorServiceField];
    if (event.target.name == "namevendor") {
      values[index].namevendor = event.target.value;
    } else if (event.target.name == "addressvendor") {
      values[index].addressvendor = event.target.value;
    } else {
      values[index].servicevendor = event.target.value;
    }

    setRenewalbizVendorServiceField(values);
  };

  const renewalBizImportFieldChange = (index, event) => {
    const values = [...renewalBizImportField];
    if (event.target.name == "imported") {
      values[index].imported = event.target.value;
    } else if (event.target.name == "volumeimported") {
      values[index].volumeimported = event.target.value;
    } else if (event.target.name == "valueimported") {
      values[index].valueimported = event.target.value;
    } else if (event.target.name == "valueimport") {
      values[index].valueimport = event.target.value;
    } else if (event.target.name == "volumeimport") {
      values[index].volumeimport = event.target.value;
    } else if (event.target.name == "voloversea") {
      values[index].voloversea = event.target.value;
    } else {
      values[index].valoversea = event.target.value;
    }

    setRenewalBizImportField(values);
  };

  const renewalBizExportFieldChange = (index, event) => {
    const values = [...renewalBizExportField];
    if (event.target.name == "exported") {
      values[index].exported = event.target.value;
    } else if (event.target.name == "volexport") {
      values[index].volexport = event.target.value;
    } else {
      values[index].valexport = event.target.value;
    }

    setRenewalBizExportField(values);
  };

  const renewalbusinesscorporatePreview = async () => {
    formloaderSetter(false);
    // const value = {
    //   renewal_id: rootid,
    //   renewalBusinessActivities: renewalbizcorpinputField,
    //   renewalVendorService: renewalbizVendorServiceField,
    //   renewalExport: renewalBizExportField,
    //   renewalImport: renewalBizImportField,
    // };

    const value = [
      // { name: "root_id", value: `${rootid}` },
      {
        name: "mousig",
        value: `imgInputArra`,
        imgInputArra: true,
        imgInputArraData: renewalbizcorpinputField,
      },
      {
        name: "mousig",
        value: `imgInputArra`,
        imgInputArra: true,
        imgInputArraData: renewalbizVendorServiceField,
      },
      {
        name: "mousig",
        value: `imgInputArra`,
        imgInputArra: true,
        imgInputArraData: renewalBizExportField,
      },
      {
        name: "mousig",
        value: `imgInputArra`,
        imgInputArra: true,
        imgInputArraData: renewalBizImportField,
      },
      // { name: "moulocation", value: `${moulocation}` },
      // { name: "moudescription", value: `${moudescription}` },
      // { name: "Mou total shares", value: `${moutotalshares}` },
      // { name: "witnesses name", value: `${witnessesname}` },
      // { name: "witnesses address", value: `${witnessesaddress}` },
      // { name: "witnesses occupation", value: `${witnessesoccupation}` },
      // { name: "mouexpected startdate", value: `${mouexpectedstartdate}` },
      // { name: "witnesses signature", value: `${witnessessignature[0].name}`, file: witnessessignature[0] },
    ];
    setpreviewData(value);
    setshowPreviewForm(true);
    console.log(value);
  };

  const renewalbusinesscorporateSubmit = async () => {
    formloaderSetter(true);
    const value = {
      renewal_id: rootid,
      renewalBusinessActivities: renewalbizcorpinputField,
      renewalVendorService: renewalbizVendorServiceField,
      renewalExport: renewalBizExportField,
      renewalImport: renewalBizImportField,
    };

    await axios
      .post(
        "https://ogfzaapi.skillzserver.com/aplicationrenewalbusinessactivity/create",
        value
      )
      .then((res) => {
        formloaderSetter(false);
        if (res.data.status == 1) {
          stageSetter(res.data.result.application_stage);
        }
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
        formloaderSetter(false);
      });

    console.log(value);
  };

  const renewalbizcorpremoveFields = () => {
    renewalbizcorpremoveFields();
  };

  const renewalbizcorpaddFields = (index) => {
    renewalbizcorpaddFields(index);
  };

  const renewalbizcorpRemoveFields = () => {
    renewalbizcorpRemoveFields();
  };

  const renewalbizcorpAddFields = (index) => {
    renewalbizcorpAddFields(index);
  };

  const renewalbizCorpRemoveFields = () => {
    renewalbizCorpRemoveFields();
  };

  const renewalbizCorpAddFields = (index) => {
    renewalbizCorpAddFields(index);
  };

  const renewalBizCorpRemoveFields = () => {
    renewalBizCorpRemoveFields();
  };

  const renewalBizCorpAddFields = (index) => {
    renewalBizCorpAddFields(index);
  };

  const onSelectSetter = async (value) => {
    console.log("clicked");
    stageSetter(value);

    const response = await getRenewalFormStage4(rootid);
    if (response?.data?.result.businessactivities && !formEdit) {
      setpreviousFIlledData(response?.data?.result);
      setserverData(true);
    }
  };

  /////////////ANTHEM//////////////

  const [selected, setselected] = useState();

  const countries = [
    { id: "1", name: "Registration/Processiing" },
    { id: "2", name: "Licensing of Special Activities" },
  ];

  const states = [
    {
      id: "1",
      countryId: "1",
      name: "EnterPrise",
      registration: "$25, 000.00",
      renewal: "$25, 000.00",
    },
    {
      id: "2",
      countryId: "1",
      name: "Zones and Sub-Zones",
      registration: "$250, 000.00",
      renewal: "$50, 000.00",
    },
    {
      id: "3",
      countryId: "1",
      name: "Free Zone Developer(s)",
      registration: "$200, 000.00",
      renewal: "$50, 000.00",
    },
    {
      id: "4",
      countryId: "1",
      name: "Extension",
      registration: "$100, 000.00",
      renewal: "$50, 000.00",
    },
    {
      id: "5",
      countryId: "1",
      name: "75% Duty Rebate Licence",
      registration: "$15, 000.00",
      renewal: "$15, 000.00",
    },
    {
      id: "6",
      countryId: "2",
      name: "Enterprises",
      registration: "$100, 000.00",
      renewal: "$40, 000.00",
    },
    {
      id: "7",
      countryId: "2",
      name: "Refineries",
      registration: "$250, 000.00",
      renewal: "$50, 000.00",
    },
    {
      id: "8",
      countryId: "2",
      name: "Petrochemicals",
      registration: "$100, 000.00",
      renewal: "$40, 000.00",
    },
    {
      id: "9",
      countryId: "2",
      name: "Assembly Plants",
      registration: "$100, 000.00",
      renewal: "$40, 000.00",
    },
    {
      id: "10",
      countryId: "2",
      name: "Independent Power Plants",
      registration: "$100, 000.00",
      renewal: "$40, 000.00",
    },
    {
      id: "11",
      countryId: "2",
      name: "Fertilizer Plant",
      registration: "$100, 000.00",
      renewal: "$40, 000.00",
    },
    {
      id: "13",
      countryId: "2",
      name: "Tank Farm",
      registration: "$200, 000.00",
      renewal: "$40, 000.00",
    },
    {
      id: "14",
      countryId: "2",
      name: "Any Other special Activity",
      registration: "$100, 000.00",
      renewal: "$40, 000.00",
    },
  ];

  const cities = [
    { id: "1", stateId: "1", name: "Faridabad" },
    { id: "2", stateId: "1", name: "Palwal" },
    { id: "3", stateId: "2", name: "Mandi House" },
    { id: "4", stateId: "2", name: "kalka Ji" },
    { id: "1", stateId: "3", name: "Houston" },
    { id: "2", stateId: "3", name: "Austin" },
    { id: "3", stateId: "4", name: "Los Angeles" },
    { id: "4", stateId: "4", name: "Son Diego" },
  ];

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);

  useEffect(() => {
    setCountry(countries);
  }, []);

  const handleCountry = (id) => {
    const dt = states.filter((x) => x.countryId === id);
    setState(dt);
  };

  const handleState = (id) => {
    const selectedType = states.filter((state) => state.id === id);
    console.log(selectedType);
    setselected(selectedType[0]);
    console.log(id);
    const dt = cities.filter((x) => x.stateId === id);
    console.log(dt);
    setCity(dt);
  };

  return (
    <React.Fragment>
      <Modal
        open={showPreviewForm}
        onClose={() => setshowPreviewForm(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
          overflow: "scroll",
          border: "2px solid blue",
          height: "100vh",
          marginTop: "10px",
          // overflowX: 'visible',
          // backgroundColor:'white'
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            height: "maxContent",
            width: window.innerWidth - 50,
            padding: "1rem 2rem",
            border: "2px solid orange",
          }}
        >
          <FormStagePreview
            data={[previewData]}
            formloaderSetter={formloaderSetter}
            formPreviewSetter={formPreviewSetter}
          />
          <Button
            style={{ marginRight: "1rem" }}
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => {
              setshowPreviewForm(false);
              if (!formloader) {
                return renewalbusinesscorporateSubmit();
              } else {
                return null;
              }
            }}
          >
            {" "}
            Confirm Save and Next{" "}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              formloaderSetter(false);
              setshowPreviewForm(false);
            }}
          >
            {" "}
            No{" "}
          </Button>
        </div>
      </Modal>
      <AppBar position="absolute" color="#ffffff" className={classes.appBar}>
        <Toolbar className={classes.layout}>
          <img
            src={oilandgaslogo}
            alt="Logo"
            style={{
              width: "80px",
              height: "60px",
              textAlign: "center",
              alignSelf: "center",
            }}
          />
        </Toolbar>
      </AppBar>

      <Grid container component="main" className={classes.layout}>
        <Menutab
          onSelectSetter={onSelectSetter}
          whichapplication={whichapplication}
          stage={stage}
        />
        {!serverData ? (
          <>
            <Grid
              container
              xs={12}
              spacing={0}
              style={{
                backgroundColor: "#fff",
                padding: 30,
                marginTop: "50px",
              }}
            >
              <Grid container xs={12} spacing={1}>
                <Grid item xs={12}>
                  <h4 style={{ position: "absolute", top: "200px" }}>
                    {" "}
                    Nature of Business
                  </h4>
                </Grid>
                {renewalbizcorpinputField.map(
                  (renewalbizcorpinputField, index) => (
                    <>
                      <Grid item xs={6}>
                        <TextField
                          id="outlined-full-width"
                          placeholder="Activity"
                          name="activity"
                          fullWidth
                          multiline
                          margin="normal"
                          value={renewalbizcorpinputField.activity}
                          onChange={(event) =>
                            renewalbizcorpinputFieldChange(index, event)
                          }
                          InputProps={{
                            classes: {
                              focused:
                                renewalbizcorpdynamicactivityval == index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewalbizcorpdynamicactivityval == index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <NativeSelect
                          id="demo-customized-select-native"
                          value={
                            renewalbizcorpinputField.approvedvendor.length == 0
                              ? "Please Select activity Status"
                              : renewalbizcorpinputField.approvedvendor
                          }
                          variant="outlined"
                          name="approvedvendor"
                          input={<BootstrapInput />}
                          fullWidth
                          margin="normal"
                          onChange={(event) =>
                            renewalbizcorpinputFieldChange(index, event)
                          }
                          InputProps={{
                            classes: {
                              focused:
                                renewalbizcorpdynamicapprovedvendorval ==
                                index + 1
                                  ? classes.errorstyle
                                  : null,
                              // notchedOutline:
                              //   renewabizcorpdynamicapprovedvendorval ==
                              //   index + 1
                              //     ? classes.errorstyle
                              //     : null,
                            },
                          }}
                        >
                          <option value="">
                            {" "}
                            Please Select activity Status{" "}
                          </option>
                          <option value={"Approved Activity"}>
                            Approved Activity
                          </option>
                          <option value={"New Activity"}>New Activity</option>
                        </NativeSelect>
                      </Grid>
                    </>
                  )
                )}
              </Grid>
              <Grid container xs={12} spacing={1}>
                <Grid item xs={12}>
                  <h4 style={{ position: "", top: "" }}> FreeZone Type</h4>
                </Grid>
                <div>
                  <div className="payment_registration_type">
                    <div>
                      {" "}
                      <div>Type</div>
                      <select
                        style={{ height: "", marginBottom: "" }}
                        id="ddlCountry"
                        className="form-control select-class"
                        onChange={(e) => handleCountry(e.target.value)}
                      >
                        <option value="0">Select Type</option>
                        {country && country !== undefined
                          ? country.map((ctr, index) => {
                              return (
                                <option key={index} value={ctr.id}>
                                  {ctr.name}
                                </option>
                              );
                            })
                          : "No Country"}
                      </select>
                    </div>

                    <br></br>
                    <div>
                      <div>Options</div>
                      <select
                        id="ddlStates"
                        className="form-control select-class"
                        onChange={(e) => handleState(e.target.value)}
                      >
                        <option value="0">Select Option</option>
                        {state && state !== undefined
                          ? state.map((ctr, index) => {
                              return (
                                <option key={index} value={ctr.id}>
                                  {ctr.name}
                                </option>
                              );
                            })
                          : "No State"}
                      </select>
                    </div>

                    {/* <br></br>
                    <select id="ddlCity" className="form-control select-class">
                      <option value="0">Select City</option>
                      {city && city !== undefined
                        ? city.map((ctr, index) => {
                            return (
                              <option key={index} value={ctr.id}>
                                {ctr.name}
                              </option>
                            );
                          })
                        : "No City"}
                    </select> */}
                  </div>
                  <div>
                    {selected && (
                      <>
                        <div>
                          Type: <b>{selected?.name}</b>{" "}
                        </div>
                        {/* <div>
                          Registration Fees: <b>{selected?.registration}</b>{" "}
                        </div> */}
                        <div>
                          Renewal Fees: <b>{selected?.renewal}</b>{" "}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Grid>

              <Grid
                container
                xs={12}
                justify="flex-end"
                style={{ marginBottom: "30px" }}
              >
                <Box component="span" mx={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#ccc",
                      height: "30px",
                      color: "black",
                    }}
                    className={classes.button}
                    onClick={renewalbizcorpinputRemoveFields}
                    startIcon={
                      <ArrowBackIcon
                        style={{ fontSize: "20px", color: "black" }}
                      />
                    }
                  >
                    REMOVE ADD
                  </Button>
                </Box>

                <Box component="span">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: "#060454", height: "30px" }}
                    className={classes.button}
                    onClick={renewalbizcorpinputAddField}
                    endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                  >
                    ADD MORE
                  </Button>
                </Box>
              </Grid>

              <Grid container xs={12} spacing={1}>
                <Grid item xs={12}>
                  <h4> List of Vendors/ Service Providers</h4>
                </Grid>
                {renewalbizVendorServiceField.map(
                  (renewalbizcorpInputField, index) => (
                    <>
                      <Grid item xs={4}>
                        <TextField
                          id="outlined-full-width"
                          placeholder="Name of Vendor"
                          name="namevendor"
                          fullWidth
                          multiline
                          margin="normal"
                          value={renewalbizcorpInputField.namevendor}
                          onChange={(event) =>
                            renewalbizVendorServiceFieldChange(index, event)
                          }
                          InputProps={{
                            classes: {
                              focused:
                                renewalbizcorpdynamicnamevendorval == index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewalbizcorpdynamicnamevendorval == index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <TextField
                          variant="outlined"
                          id="outlined-full-width"
                          placeholder="Address"
                          name="addressvendor"
                          fullWidth
                          multiline
                          margin="normal"
                          value={renewalbizcorpInputField.addressvendor}
                          onChange={(event) =>
                            renewalbizVendorServiceFieldChange(index, event)
                          }
                          InputProps={{
                            classes: {
                              focused:
                                renewalbizcorpdynamicaddressvendorval ==
                                index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewalbizcorpdynamicaddressvendorval ==
                                index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          variant="outlined"
                          id="outlined-full-width"
                          placeholder="Service"
                          name="servicevendor"
                          fullWidth
                          multiline
                          margin="normal"
                          value={renewalbizcorpInputField.dob}
                          onChange={(event) =>
                            renewalbizVendorServiceFieldChange(index, event)
                          }
                          InputProps={{
                            classes: {
                              focused:
                                renewalbizcorpdynamicservicevendorval ==
                                index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewalbizcorpdynamicservicevendorval ==
                                index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                        />
                      </Grid>
                    </>
                  )
                )}
              </Grid>

              <Grid
                container
                xs={12}
                justify="flex-end"
                style={{ marginBottom: "30px" }}
              >
                <Box component="span" mx={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#ccc",
                      height: "30px",
                      color: "black",
                    }}
                    className={classes.button}
                    onClick={renewalbizVendorServiceRemoveFields}
                    startIcon={
                      <ArrowBackIcon
                        style={{ fontSize: "20px", color: "black" }}
                      />
                    }
                  >
                    REMOVE ADD
                  </Button>
                </Box>

                <Box component="span">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: "#060454", height: "30px" }}
                    className={classes.button}
                    onClick={renewalbizVendorServiceAddFields}
                    endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                  >
                    ADD MORE
                  </Button>
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              spacing={0}
              style={{
                backgroundColor: "#fff",
                padding: 30,
                marginTop: "50px",
              }}
            >
              <Grid container xs={12} spacing={1}>
                <Grid item xs={12}>
                  <h4> Import</h4>
                </Grid>
                {renewalBizImportField.map(
                  (renewalbizCorpInputField, index) => (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-full-width"
                          placeholder="Nature of Imported Goods"
                          name="imported"
                          fullWidth
                          multiline
                          margin="normal"
                          value={renewalbizCorpInputField.name}
                          onChange={(event) =>
                            renewalBizImportFieldChange(index, event)
                          }
                          InputProps={{
                            classes: {
                              focused:
                                renewalbizcorpdynamicimportedval == index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewalbizcorpdynamicimportedval == index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          id="outlined-full-width"
                          placeholder="Volume of Imported Goods"
                          name="volumeimported"
                          fullWidth
                          multiline
                          margin="normal"
                          value={renewalbizCorpInputField.dob}
                          onChange={(event) =>
                            renewalBizImportFieldChange(index, event)
                          }
                          InputProps={{
                            classes: {
                              focused:
                                renewalbizcorpdynamicvolumeimportedval ==
                                index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewalbizcorpdynamicvolumeimportedval ==
                                index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          id="outlined-full-width"
                          placeholder="Value of Imported Goods"
                          name="valueimported"
                          fullWidth
                          multiline
                          margin="normal"
                          value={renewalbizCorpInputField.valueimported}
                          onChange={(event) =>
                            renewalBizImportFieldChange(index, event)
                          }
                          InputProps={{
                            classes: {
                              focused:
                                renewalbizcorpdynamicvalueimportedval ==
                                index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewalbizcorpdynamicvalueimportedval ==
                                index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          id="outlined-full-width"
                          placeholder="Volume of imported goods from Nigeria"
                          name="volumeimport"
                          fullWidth
                          multiline
                          margin="normal"
                          value={renewalbizCorpInputField.volumeimport}
                          onChange={(event) =>
                            renewalBizImportFieldChange(index, event)
                          }
                          InputProps={{
                            classes: {
                              focused:
                                renewalbizcorpdynamicvolumeimportval ==
                                index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewalbizcorpdynamicvolumeimportval ==
                                index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          id="outlined-full-width"
                          placeholder="Value of imported goods from Nigeria"
                          name="valueimport"
                          fullWidth
                          multiline
                          margin="normal"
                          value={renewalbizCorpInputField.valueimport}
                          onChange={(event) =>
                            renewalBizImportFieldChange(index, event)
                          }
                          InputProps={{
                            classes: {
                              focused:
                                renewalbizcorpdynamicvalueimportval == index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewalbizcorpdynamicvalueimportval == index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          id="outlined-full-width"
                          placeholder="Volume of imported goods from Oversea"
                          name="voloversea"
                          fullWidth
                          multiline
                          margin="normal"
                          value={renewalbizCorpInputField.voloversea}
                          onChange={(event) =>
                            renewalBizImportFieldChange(index, event)
                          }
                          InputProps={{
                            classes: {
                              focused:
                                renewalbizcorpdynamicvoloverseaval == index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewalbizcorpdynamicvoloverseaval == index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          id="outlined-full-width"
                          placeholder="Value of imported goods from Oversea"
                          name="valoversea"
                          fullWidth
                          multiline
                          margin="normal"
                          value={renewalbizCorpInputField.valoversea}
                          onChange={(event) =>
                            renewalBizImportFieldChange(index, event)
                          }
                          InputProps={{
                            classes: {
                              focused:
                                renewalbizcorpdynamicvaloverseaval == index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewalbizcorpdynamicvaloverseaval == index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                        />
                      </Grid>
                    </>
                  )
                )}
              </Grid>

              <Grid
                container
                xs={12}
                justify="flex-end"
                style={{ marginBottom: "30px" }}
              >
                <Box component="span" mx={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#ccc",
                      height: "30px",
                      color: "black",
                    }}
                    className={classes.button}
                    onClick={renewalBizImportRemoveFields}
                    startIcon={
                      <ArrowBackIcon
                        style={{ fontSize: "20px", color: "black" }}
                      />
                    }
                  >
                    REMOVE ADD
                  </Button>
                </Box>

                <Box component="span">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: "#060454", height: "30px" }}
                    className={classes.button}
                    onClick={renewalBizImportAddFields}
                    endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                  >
                    ADD MORE
                  </Button>
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              spacing={0}
              style={{ backgroundColor: "#fff", padding: 30 }}
            >
              {/* <h4> Directors Particulars:</h4> */}

              <Grid container xs={12} spacing={1}>
                <Grid item xs={12}>
                  <h4> Export</h4>
                </Grid>
                {renewalBizExportField.map(
                  (renewalBizCorpInputField, index) => (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-full-width"
                          placeholder="Nature of Exported Goods"
                          name="exported"
                          fullWidth
                          multiline
                          margin="normal"
                          value={renewalBizCorpInputField.exported}
                          onChange={(event) =>
                            renewalBizExportFieldChange(index, event)
                          }
                          InputProps={{
                            classes: {
                              focused:
                                renewalbizcorpdynamicexportedval == index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewalbizcorpdynamicexportedval == index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          id="outlined-full-width"
                          placeholder="Volume of Exported Goods"
                          name="volexport"
                          fullWidth
                          multiline
                          margin="normal"
                          value={renewalBizCorpInputField.dob}
                          onChange={(event) =>
                            renewalBizExportFieldChange(index, event)
                          }
                          InputProps={{
                            classes: {
                              focused:
                                renewalbizcorpdynamicvolexportval == index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewalbizcorpdynamicvolexportval == index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          id="outlined-full-width"
                          placeholder="Value of Exported Goods"
                          name="valexport"
                          fullWidth
                          multiline
                          margin="normal"
                          value={renewalBizCorpInputField.dob}
                          onChange={(event) =>
                            renewalBizExportFieldChange(index, event)
                          }
                          InputProps={{
                            classes: {
                              focused:
                                renewalbizcorpdynamicvalexportval == index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewalbizcorpdynamicvalexportval == index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                        />
                      </Grid>
                    </>
                  )
                )}
              </Grid>

              <Grid
                container
                xs={12}
                justify="flex-end"
                style={{ marginBottom: "30px" }}
              >
                <Box component="span" mx={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#ccc",
                      height: "30px",
                      color: "black",
                    }}
                    className={classes.button}
                    onClick={renewalBizExportRemoveFields}
                    startIcon={
                      <ArrowBackIcon
                        style={{ fontSize: "20px", color: "black" }}
                      />
                    }
                  >
                    REMOVE ADD
                  </Button>
                </Box>

                <Box component="span">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: "#060454", height: "30px" }}
                    className={classes.button}
                    onClick={renewalBizExportAddFields}
                    endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                  >
                    ADD MORE
                  </Button>
                </Box>
              </Grid>
              <Grid
                container
                xs={12}
                justify="flex-end"
                style={{ marginBottom: "50px" }}
              >
                <Box component="span">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={
                      !formloader ? renewalbusinesscorporatePreview : null
                    }
                    style={{
                      backgroundColor: "#060454",
                      height: "30px",
                      marginTop: "15px",
                      marginRight: "255px",
                    }}
                    className={classes.button}
                    endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                  >
                    Preview & Continue{" "}
                    {formloader ? <CircularProgress size={24} /> : null}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          <div
            style={{
              backgroundColor: "#fff",
              marginTop: 25,
              padding: 30,
              marginBottom: "7rem",
              width: "100%",
            }}
          >
            <Grid
              container
              style={{ display: "", flexDirection: "", columnGap: "" }}
            >
              {/* {previousFIlledData?.document && ( */}

              {previousFIlledData?.businessactivities.status === 0 && (
                <>
                  <Grid
                    xs={5}
                    style={{
                      display: "",
                      flexDirection: "",
                      marginRight: "1rem",
                    }}
                  >
                    <div>
                      <b>Business Activity</b>
                    </div>
                    <div style={{ display: "flex" }}>
                      {" "}
                      <input
                        disabled
                        value={previousFIlledData?.businessactivities?.activity}
                      />
                    </div>
                  </Grid>
                </>
              )}

              {previousFIlledData?.vendorservice && (
                <>
                  {/* Company Contact Details */}
                  {previousFIlledData?.vendorservice?.map((e) => (
                    <>
                      {e.status === 0 && (
                        <>
                          <Grid
                            xs={5}
                            style={{
                              display: "",
                              flexDirection: "",
                              marginRight: "1rem",
                            }}
                          >
                            <div>
                              <b>Vendor Service(s)</b>
                            </div>
                            <div style={{ display: "flex" }}>
                              {" "}
                              <div>Name</div>
                              <input disabled value={e?.name} />
                            </div>
                            <div style={{ display: "flex" }}>
                              {" "}
                              <div>address</div>
                              <input disabled value={e?.address} />
                            </div>
                            <div style={{ display: "flex" }}>
                              {" "}
                              <div>service</div>
                              <input disabled value={e?.service} />
                            </div>
                          </Grid>
                        </>
                      )}{" "}
                    </>
                  ))}
                </>
              )}
              {previousFIlledData?.export && (
                <>
                  {/* Company Contact Details */}
                  <Grid xs={12}>
                    <div
                      style={{
                        marginTop: "1rem",
                        borderTop: "1px solid #ccc",
                        padding: "1rem 0",
                      }}
                    >
                      <b>Export</b>
                    </div>
                  </Grid>

                  {previousFIlledData?.export?.map((e, index) => (
                    <>
                      {e.status === 0 && (
                        <>
                          <Grid
                            xs={5}
                            style={{
                              display: "",
                              flexDirection: "",
                              marginRight: "1rem",
                            }}
                          >
                            <div style={{ margin: "1rem 0" }}>
                              Export {index + 1}
                            </div>
                            <div style={{ display: "flex" }}>
                              {" "}
                              <div>nature of export</div>
                              <input disabled value={e?.nature_of_export} />
                            </div>
                            <div style={{ display: "flex" }}>
                              {" "}
                              <div>volume</div>
                              <input disabled value={e?.volume} />
                            </div>
                            <div style={{ display: "flex" }}>
                              {" "}
                              <div>value</div>
                              <input disabled value={e?.value} />
                            </div>
                          </Grid>
                        </>
                      )}{" "}
                    </>
                  ))}
                </>
              )}
              {previousFIlledData?.import && (
                <>
                  {/* Company Contact Details */}
                  <Grid xs={12}>
                    <div
                      style={{
                        marginTop: "1rem",
                        borderTop: "1px solid #ccc",
                        padding: "1rem 0",
                      }}
                    >
                      <b>Import</b>
                    </div>
                  </Grid>
                  {previousFIlledData?.import?.map((e, index) => (
                    <>
                      {e.status === 0 && (
                        <>
                          <Grid
                            xs={5}
                            style={{
                              display: "",
                              flexDirection: "",
                              marginRight: "1rem",
                            }}
                          >
                            <div style={{ margin: "1rem 0" }}>
                              Import {index + 1}
                            </div>
                            <div style={{ display: "flex" }}>
                              <div>nature of import</div>
                              <input disabled value={e?.nature_of_import} />
                            </div>
                            <div style={{ display: "flex" }}>
                              {" "}
                              <div>volume</div>
                              <input disabled value={e?.volume} />
                            </div>
                            <div style={{ display: "flex" }}>
                              <div>value</div>
                              <input disabled value={e?.value} />
                            </div>
                            <div style={{ display: "flex" }}>
                              <div>volume (Nigeria)</div>
                              <input disabled value={e?.volum_ng} />
                            </div>
                            <div style={{ display: "flex" }}>
                              <div>value (Nigeria)</div>
                              <input disabled value={e?.value_ng} />
                            </div>
                            <div style={{ display: "flex" }}>
                              <div>volum oversea</div>
                              <input disabled value={e?.volum_oversea} />
                            </div>
                            <div style={{ display: "flex" }}>
                              <div>value oversea</div>
                              <input disabled value={e?.value_oversea} />
                            </div>
                          </Grid>
                        </>
                      )}{" "}
                    </>
                  ))}
                </>
              )}
            </Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                // onClick={!formloader ? previewStageData : null}
                style={{ backgroundColor: "#060454", height: "30px" }}
                className={classes.button}
                endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                onClick={() => {
                  setformEdit(true);
                  setserverData(false);
                }}
              >
                Refill Form
              </Button>
            </Grid>
          </div>
        )}
      </Grid>

      <AppBar position="fixed" className={classes.appBarButom} color="#060454">
        <Typography
          variant="span"
          color="inherit"
          noWrap
          style={{ textAlign: "center", color: "#fff", alignItems: "center" }}
          justify="center"
        >
          Copyright © 2022 <b>Oil and Gas Free Zones Authority</b>. All Rights Reserved.
        </Typography>
      </AppBar>
    </React.Fragment>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    position: "relative",
  },
  checkboxLabel: {
    fontSize: 14,
  },
  errorstyle: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
  appBarButom: {
    top: "auto",
    bottom: 0,
    height: "40px",
    backgroundColor: "#060454",
    paddingTop: "5px",
    paddingButtom: "5px",
    alignItems: "center",
    justifyContent: "center",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "60%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

export default Renewalbusinesscorporate;
