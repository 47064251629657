import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ClearIcon from "@material-ui/icons/Clear";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import HourglassFullOutlinedIcon from "@material-ui/icons/HourglassFullOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import CancelPresentationOutlinedIcon from "@material-ui/icons/CancelPresentationOutlined";
import Box from "@material-ui/core/Box";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// use to display list
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import Divider from "@material-ui/core/Divider";
import oilandgaslogo from "../oilandgaslogo.png";
import pdf from "../pdf.png";
import txt from "../txt.png";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Approvalbuttons from "./Approvalbuttons";
import Approvalbuttons from "./Button/Approvalbuttons";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Snackbar from "@material-ui/core/Snackbar";
import { Document, Page, pdfjs } from "react-pdf";
import RegistryTab from "./Tabs/RegistryTab";
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ShowDocumentDetails(props) {
  const { data, showimage, applicantDetails } = props;
  console.log(applicantDetails, "dskkjkjsjksj");
  console.log(data, "dskkjkjsjksj");
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <>
      <Typography style={{ color: "#000", paddingBottom: 30 }} variant="h6">
        Preview Of Company Filled Informations And Uploaded Documents{" "}
      </Typography>
      <Grid item xs={12} sm={12} md={12}>
        {/* ///////////Next line //////////////// */}

        <div>
          <Accordion
            square
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel3d-header"
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              style={{ backgroundColor: "#060454" }}
            >
              <Typography style={{ color: "#fff" }}>company Info</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container>
                <Grid xs={4}>
                  <label>Company Name</label>
                  <input
                    value={
                      applicantDetails.applicant
                        ? applicantDetails.applicant[0]?.companyInfo
                            ?.company_name
                        : null
                    }
                    disabled
                  />
                </Grid>
                <Grid xs={4}>
                  <label>Free zone Number</label>
                  <input
                    disabled
                    value={
                      applicantDetails.applicant
                        ? applicantDetails.applicant[0]?.companyInfo?.fze_no
                        : null
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <label>Tax Identification Number</label>
                  <input
                    disabled
                    value={
                      applicantDetails.applicant
                        ? applicantDetails.applicant[0]?.companyInfo?.tin
                        : null
                    }
                  />
                </Grid>
                <Grid>
                  <Approvalbuttons
                    priv={applicantDetails.priv}
                    value={applicantDetails.applicant[0]?.companyInfo}
                    approvesection={data.approvesection}
                    declinesection={data.declinesection}
                    display={2}
                    section={"companyInfo"}
                    sectionloader={data.sectionloader}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>

        <div>
          <Accordion
            square
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel3d-header"
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              style={{ backgroundColor: "#060454" }}
            >
              <Typography style={{ color: "#fff" }}>
                Company Contact Details
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container>
                <Grid xs={4}>
                  <label>Company Address</label>
                  <input
                    disabled
                    value={
                      applicantDetails.applicant
                        ? applicantDetails.applicant[0]?.companyContactDetails
                            ?.address
                        : null
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <label>Company Email</label>
                  <input
                    disabled
                    value={
                      applicantDetails.applicant
                        ? applicantDetails.applicant[0]?.companyContactDetails
                            ?.email
                        : null
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <label>Company Phone Number</label>
                  <input
                    disabled
                    value={
                      applicantDetails.applicant
                        ? applicantDetails.applicant[0]?.companyContactDetails
                            ?.phone
                        : null
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <label>Company website</label>
                  <input
                    disabled
                    value={
                      applicantDetails.applicant
                        ? applicantDetails.applicant[0]?.companyContactDetails
                            ?.website
                        : null
                    }
                  />
                </Grid>

                <Grid>
                  <Approvalbuttons
                    priv={applicantDetails.priv}
                    value={applicantDetails.applicant[0]?.companyContactDetails}
                    approvesection={data.approvesection}
                    declinesection={data.declinesection}
                    display={2}
                    section={"companyContactDetails"}
                    sectionloader={data.sectionloader}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>

        <div>
          <Accordion
            square
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel3d-header"
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              style={{ backgroundColor: "#060454" }}
            >
              <Typography style={{ color: "#fff" }}>Document Upload</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container>
                {applicantDetails.applicant[0]?.documentUpload.map(
                  (value, index) => (
                    <Grid xs={4}>
                      <div>
                        {" "}
                        <label>{value.type_name}</label>
                        <img
                          src={
                            value.file_path.split(".").pop() == "pdf"
                              ? pdf
                              : txt
                          }
                          style={{
                            width: 100,
                            height: 100,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            showimage(value.file_path);
                          }}
                        />
                      </div>
                    </Grid>
                  )
                )}

                <Grid xs={12}>
                  <Approvalbuttons
                    priv={applicantDetails.priv}
                    value={applicantDetails.applicant[0]?.companyContactDetails}
                    approvesection={data.approvesection}
                    declinesection={data.declinesection}
                    display={2}
                    section={"companyContactDetails"}
                    sectionloader={data.sectionloader}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>

        <div>
          <Accordion
            square
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel3d-header"
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              style={{ backgroundColor: "#060454" }}
            >
              <Typography style={{ color: "#fff" }}>vendor Service</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container>
                {applicantDetails.applicant[0]?.vendorService?.map(
                  (value, index) => (
                    <>
                      {" "}
                      <Grid xs={12}>
                        <b>service {index + 1}</b>
                      </Grid>
                      {value.name && (
                        <Grid xs={4}>
                          <div>
                            <label>Name</label>
                            <input
                              disabled
                              value={
                                applicantDetails.applicant ? value.name : null
                              }
                            />
                          </div>
                        </Grid>
                      )}
                      {value.service && (
                        <Grid xs={4}>
                          <div>
                            <label>service</label>
                            <input
                              disabled
                              value={
                                applicantDetails.applicant
                                  ? value.service
                                  : null
                              }
                            />
                          </div>
                        </Grid>
                      )}
                      {value.address && (
                        <Grid xs={4}>
                          <div>
                            <label>Address</label>
                            <input
                              disabled
                              value={
                                applicantDetails.applicant
                                  ? value.address
                                  : null
                              }
                            />
                          </div>
                        </Grid>
                      )}
                      <Grid xs={4}>
                        <Approvalbuttons
                          priv={applicantDetails.priv}
                          value={value}
                          approvesection={data.approvesection}
                          declinesection={data.declinesection}
                          display={2}
                          section={"vendorService"}
                          sectionloader={data.sectionloader}
                        />
                      </Grid>
                    </>
                  )
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
        <div>
          <Accordion
            square
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel3d-header"
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              style={{ backgroundColor: "#060454" }}
            >
              <Typography style={{ color: "#fff" }}>
                Registered Debenture
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container>
                <Grid xs={4}>
                  <label>Details</label>
                  <input
                    disabled
                    value={
                      applicantDetails.applicant
                        ? applicantDetails.applicant[0]?.registeredDebenture
                            ?.details
                        : null
                    }
                  />
                </Grid>
                <Grid>
                  <Approvalbuttons
                    priv={applicantDetails.priv}
                    value={applicantDetails.applicant[0]?.registeredDebenture}
                    approvesection={data.approvesection}
                    declinesection={data.declinesection}
                    display={2}
                    section={"companyContactDetails"}
                    sectionloader={data.sectionloader}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
        <div>
          <Accordion
            square
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel3d-header"
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              style={{ backgroundColor: "#060454" }}
            >
              <Typography style={{ color: "#fff" }}>
                Business Activity
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container>
                <Grid xs={4}>
                  <label>Business Activity</label>
                  <input
                    value={
                      applicantDetails.applicant
                        ? applicantDetails.applicant[0]?.businessactivity
                            ?.activity
                        : null
                    }
                    disabled
                  />
                </Grid>

                <Grid>
                  <Approvalbuttons
                    priv={applicantDetails.priv}
                    value={applicantDetails.applicant[0]?.businessactivity}
                    approvesection={data.approvesection}
                    declinesection={data.declinesection}
                    display={2}
                    section={"companyInfo"}
                    sectionloader={data.sectionloader}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
        {/* //////////// */}

        <div>
          <Accordion
            square
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel3d-header"
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              style={{ backgroundColor: "#060454" }}
            >
              <Typography style={{ color: "#fff" }}>
                ShareHolders Details
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container>
                {applicantDetails.applicant[0]?.shareHoldersDetails?.map(
                  (value, index) => (
                    <>
                      {" "}
                      <Grid xs={12}>
                        <b>ShareHolder {index + 1}</b>
                      </Grid>
                      {value.name && (
                        <Grid xs={4}>
                          <div>
                            <label>Name</label>
                            <input
                              disabled
                              value={
                                applicantDetails.applicant ? value.name : null
                              }
                            />
                          </div>
                        </Grid>
                      )}
                      {value.nationality && (
                        <Grid xs={4}>
                          <div>
                            <label>Nationality</label>
                            <input
                              disabled
                              value={
                                applicantDetails.applicant
                                  ? value.nationality
                                  : null
                              }
                            />
                          </div>
                        </Grid>
                      )}
                      {value && (
                        <Grid xs={4}>
                          <div>
                            <label>number_of_shares</label>
                            <input
                              disabled
                              value={
                                value.number_of_shares
                              }
                            />
                          </div>
                        </Grid>
                      )}
                    
                      {value.ocupation && (
                        <Grid xs={4}>
                          <div>
                            <label>ocupation</label>
                            <input
                              disabled
                              value={
                                applicantDetails.applicant
                                  ? value.ocupation
                                  : null
                              }
                            />
                          </div>
                        </Grid>
                      )}
                      {value.resident && (
                        <Grid xs={4}>
                          <div>
                            <label>resident</label>
                            <input
                              disabled
                              value={
                                applicantDetails.applicant
                                  ? value.resident
                                  : null
                              }
                            />
                          </div>
                        </Grid>
                      )}
                      {value.address && (
                        <Grid xs={4}>
                          <div>
                            <label>address</label>
                            <input
                              disabled
                              value={
                                applicantDetails.applicant
                                  ? value.address
                                  : null
                              }
                            />
                          </div>
                        </Grid>
                      )}
                      <Grid xs={4}>
                        <Approvalbuttons
                          priv={applicantDetails.priv}
                          value={value}
                          approvesection={data.approvesection}
                          declinesection={data.declinesection}
                          display={2}
                          section={"vendorService"}
                          sectionloader={data.sectionloader}
                        />
                      </Grid>
                    </>
                  )
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
        <div>
          <Accordion
            square
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel3d-header"
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              style={{ backgroundColor: "#060454" }}
            >
              <Typography style={{ color: "#fff" }}>Import</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container>
                {applicantDetails.applicant[0]?.import?.map((value, index) => (
                  <>
                    {" "}
                    <Grid xs={12}>
                      <b>Import {index + 1}</b>
                    </Grid>
                    {value?.nature_of_import && (
                      <Grid xs={4}>
                        <div>
                          <label>nature of import</label>
                          <input
                            disabled
                            value={
                              applicantDetails.applicant
                                ? value.nature_of_import
                                : null
                            }
                          />
                        </div>
                      </Grid>
                    )}
                    {value.volume && (
                      <Grid xs={4}>
                        <div>
                          <label>volume</label>
                          <input
                            disabled
                            value={
                              applicantDetails.applicant ? value.volume : null
                            }
                          />
                        </div>
                      </Grid>
                    )}
                    {value.value && (
                      <Grid xs={4}>
                        <div>
                          <label>value</label>
                          <input
                            disabled
                            value={
                              applicantDetails.applicant ? value.value : null
                            }
                          />
                        </div>
                      </Grid>
                    )}
                    {value.volum_ng && (
                      <Grid xs={4}>
                        <div>
                          <label>volume (Nigeria)</label>
                          <input
                            disabled
                            value={
                              applicantDetails.applicant ? value.volum_ng : null
                            }
                          />
                        </div>
                      </Grid>
                    )}
                    {value?.value_ng && (
                      <Grid xs={4}>
                        <div>
                          <label>value (Nigeria)</label>
                          <input
                            disabled
                            value={
                              applicantDetails.applicant ? value.value_ng : null
                            }
                          />
                        </div>
                      </Grid>
                    )}
                    {value.volum_oversea && (
                      <Grid xs={4}>
                        <div>
                          <label>volume (oversea)</label>
                          <input
                            disabled
                            value={
                              applicantDetails.applicant
                                ? value.volum_oversea
                                : null
                            }
                          />
                        </div>
                      </Grid>
                    )}
                    {value.value_oversea && (
                      <Grid xs={4}>
                        <div>
                          <label>value (oversea)</label>
                          <input
                            disabled
                            value={
                              applicantDetails.applicant
                                ? value.value_oversea
                                : null
                            }
                          />
                        </div>
                      </Grid>
                    )}
                    <Grid xs={4}>
                      <Approvalbuttons
                        priv={applicantDetails.priv}
                        value={value}
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"vendorService"}
                        sectionloader={data.sectionloader}
                      />
                    </Grid>
                  </>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
        <div>
          <Accordion
            square
            expanded={expanded === "panel9"}
            onChange={handleChange("panel9")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel3d-header"
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              style={{ backgroundColor: "#060454" }}
            >
              <Typography style={{ color: "#fff" }}>Export</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container>
                {applicantDetails.applicant[0]?.export?.map((value, index) => (
                  <>
                    {" "}
                    <Grid xs={12}>
                      <b>Export {index + 1}</b>
                    </Grid>
                    {value?.nature_of_export && (
                      <Grid xs={4}>
                        <div>
                          <label>Nature of export</label>
                          <input
                            disabled
                            value={
                              applicantDetails.applicant
                                ? value.nature_of_export
                                : null
                            }
                          />
                        </div>
                      </Grid>
                    )}
                    {value.volume && (
                      <Grid xs={4}>
                        <div>
                          <label>volume</label>
                          <input
                            disabled
                            value={
                              applicantDetails.applicant ? value.volume : null
                            }
                          />
                        </div>
                      </Grid>
                    )}
                    {value.value && (
                      <Grid xs={4}>
                        <div>
                          <label>value</label>
                          <input
                            disabled
                            value={
                              applicantDetails.applicant ? value.value : null
                            }
                          />
                        </div>
                      </Grid>
                    )}
                    <Grid xs={4}>
                      <Approvalbuttons
                        priv={applicantDetails.priv}
                        value={value}
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"vendorService"}
                        sectionloader={data.sectionloader}
                      />
                    </Grid>
                  </>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
        <div>
          <Accordion
            square
            expanded={expanded === "panel10"}
            onChange={handleChange("panel10")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel3d-header"
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              style={{ backgroundColor: "#060454" }}
            >
              <Typography style={{ color: "#fff" }}>
                Director Particulars
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container>
                {applicantDetails.applicant[0]?.directorParticulars?.map(
                  (value, index) => (
                    <>
                      {" "}
                      <Grid xs={12}>
                        <b>Director Particulars {index + 1}</b>
                      </Grid>
                      {value.name && (
                        <Grid xs={4}>
                          <div>
                            <label>Name</label>
                            <input
                              disabled
                              value={
                                applicantDetails.applicant ? value.name : null
                              }
                            />
                          </div>
                        </Grid>
                      )}
                      {value.dob && (
                        <Grid xs={4}>
                          <div>
                            <label>Date of Birth</label>
                            <input
                              disabled
                              value={
                                applicantDetails.applicant ? value.dob : null
                              }
                            />
                          </div>
                        </Grid>
                      )}
                      {value.ocupation && (
                        <Grid xs={4}>
                          <div>
                            <label>ocupation</label>
                            <input
                              disabled
                              value={
                                applicantDetails.applicant
                                  ? value.ocupation
                                  : null
                              }
                            />
                          </div>
                        </Grid>
                      )}
                      {value.address && (
                        <Grid xs={4}>
                          <div>
                            <label>address</label>
                            <input
                              disabled
                              value={
                                applicantDetails.applicant
                                  ? value.address
                                  : null
                              }
                            />
                          </div>
                        </Grid>
                      )}
                      {value.nationality && (
                        <Grid xs={4}>
                          <div>
                            <label>nationality</label>
                            <input
                              disabled
                              value={
                                applicantDetails.applicant
                                  ? value.nationality
                                  : null
                              }
                            />
                          </div>
                        </Grid>
                      )}
                      <Grid xs={4}>
                        <Approvalbuttons
                          priv={applicantDetails.priv}
                          value={value}
                          approvesection={data.approvesection}
                          declinesection={data.declinesection}
                          display={2}
                          section={"vendorService"}
                          sectionloader={data.sectionloader}
                        />
                      </Grid>
                    </>
                  )
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
        <div>
          <Accordion
            square
            expanded={expanded === "panel11"}
            onChange={handleChange("panel11")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel3d-header"
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              style={{ backgroundColor: "#060454" }}
            >
              <Typography style={{ color: "#fff" }}>
                Company Incoporation Information
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container>
                {applicantDetails.applicant[0]?.companyInfoIncoporation?.map(
                  (value, index) => (
                    <>
                      {" "}
                      <Grid xs={12}>
                        <b>Company Incoporation Information {index + 1}</b>
                      </Grid>
                      {value.value && (
                        <Grid xs={6}>
                          <div>
                            <label>value</label>
                            <input
                              disabled
                              value={
                                applicantDetails.applicant ? value.value : null
                              }
                            />
                          </div>
                        </Grid>
                      )}
                      <Grid xs={4}>
                        <Approvalbuttons
                          priv={applicantDetails.priv}
                          value={value}
                          approvesection={data.approvesection}
                          declinesection={data.declinesection}
                          display={2}
                          section={"vendorService"}
                          sectionloader={data.sectionloader}
                        />
                      </Grid>
                    </>
                  )
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      </Grid>
    </>
  );
}
