import axios from "axios";
import * as urls from "./Urls";

const TOKEN = localStorage.getItem("token");
// const token = sessionStorage.getItem("token");
axios.defaults.headers["Authorization"] = `Bearer ${TOKEN}`;
axios.defaults.headers["x-access-token"] = `${TOKEN}`;

export const validateFormEmail = (data) =>
  axios({
    method: "post",
    url: urls.validateEmailUrl,
    data,
  });

export const submitFormStage1 = (data) =>
  axios({
    method: "post",
    url: urls.formStage1Url,
    data,
  });

export const submitFormStage2 = (data) =>
  axios({
    method: "post",
    url: urls.formStage2Url,
    data,
  });

export const submitFormStage3 = (data) =>
  axios({
    method: "post",
    url: urls.formStage3Url,
    data,
  });

export const submitFormStage4 = (data) =>
  axios({
    method: "post",
    url: urls.formStage4Url,
    data,
  });

export const submitFormStage5 = (data) =>
  axios({
    method: "post",
    url: urls.formStage5Url,
    data,
  });

export const submitFormStage6 = (data) =>
  axios({
    method: "post",
    url: urls.formStage6Url,
    data,
  });

export const submitFormStage7 = (data) =>
  axios({
    method: "post",
    url: urls.formStage7Url,
    data,
  });

export const submitFormStage0 = (data) =>
  axios({
    method: "post",
    url: urls.formStage0Url,
    data,
  });

export const getCompanyName = (data) =>
  axios({
    method: "post",
    url: urls.getCompanyNameUrl,
    data,
  });

export const approvalValidation = (data) => {
  return axios({
    method: "post",
    url: urls.approvalValidation,
    data,
  });
};

// export const getAllRenewalList = (data) =>
//   axios({
//     method: "post",
//     url: urls.getAllRenewalListUrl,
//     data,
//   });

export const getFormStage1 = (id) =>
  axios({
    method: "get",
    url: urls.getFormStage1Url,
    params: {
      id,
    },
  });

export const getFormStage2 = (id) =>
  axios({
    method: "get",
    url: urls.getFormStage2Url,
    params: {
      id,
    },
  });
export const getFormStage3 = (id) =>
  axios({
    method: "get",
    url: urls.getFormStage3Url,
    params: {
      id,
    },
  });
export const getFormStage4 = (id) =>
  axios({
    method: "get",
    url: urls.getFormStage4Url,
    params: {
      id,
    },
  });
export const getFormStage5 = (id) =>
  axios({
    method: "get",
    url: urls.getFormStage5Url,
    params: {
      id,
    },
  });
export const getFormStage6 = (id) =>
  axios({
    method: "get",
    url: urls.getFormStage6Url,
    params: {
      id,
    },
  });
export const getFormStage7 = (id) =>
  axios({
    method: "get",
    url: urls.getFormStage7Url,
    params: {
      id,
    },
  });
export const getFormStage8 = (id) =>
  axios({
    method: "get",
    url: urls.getFormStage8Url,
    params: {
      id,
    },
  });

export const getRenewalFormStage1 = (id) =>
  axios({
    method: "get",
    url: urls.getRenewalFormStage1Url,
    params: {
      id,
    },
  });

export const getRenewalFormStage2 = (id) =>
  axios({
    method: "get",
    url: urls.getRenewalFormStage2Url,
    params: {
      id,
    },
  });

export const getRenewalFormStage3 = (id) =>
  axios({
    method: "get",
    url: urls.getRenewalFormStage3Url,
    params: {
      id,
    },
  });

export const getRenewalFormStage4 = (id) =>
  axios({
    method: "get",
    url: urls.getRenewalFormStage4Url,
    params: {
      id,
    },
  });


  export const submitRequiredStageForm = (data) =>
  axios({
    method: "post",
    url: urls.submitRequiredStageFormUrl,
    data,
  });

export const getRenewalFormStage5 = (id) =>
  axios({
    method: "get",
    url: urls.getRenewalFormStage5Url,
    params: {
      id,
    },
  });

export const getCountries = (id) =>
  axios({
    method: "get",
    url: urls.getCountriesUrl,
    // params: {
    //   id
    // }
  });

export const getAllRenewalList = (id) =>
  axios({
    method: "get",
    url: urls.getAllRenewalListUrl,
    params: {
      id,
    },
  });

export const getParticularYearRenewal = (id) =>
  axios({
    method: "get",
    url: urls.getParticularYearRenewalUrl,
    params: {
      id,
    },
  });

export const getApplicantDetailsPayment = (id) =>
  axios({
    method: "get",
    url: urls.getApplicantDetailsUrl,
    params: {
      id,
    },
  });

export const confirmApplicantPayment = (root_id, payment_ref) =>
  axios({
    method: "post",
    url: urls.confirmApplicantPaymentUrl,
    data: {
      root_id,
      payment_ref,
    },
  });

// export const confirmApplicantPayment = (data) =>
// axios({
//   method: "post",
//   url: urls.formStage6Url,
//   data,
// });

export const approvalValidationStages = (id, stageNo) => {
  return axios({
    method: "get",
    url: `https://ogfzaapi.skillzserver.com/status/checkstatusap${stageNo}`,
    params: {
      id,
    },
  });
};



export const finalApprovalCheck = (id) =>
  axios({
    method: "get",
    url: urls.finalApprovalCheckUrl,
    params: {
    id
    },
  })
