import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LockOutlineIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import oilandgaslogo from "../../assets/oilandgaslogo.png";
// import Emailstep1 from './Emailstep1';
import Emailstep1 from "../../components/Emailstep1";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import Menutab from "../../Menutab";
import Menutab from "../../components/Tabs/Menutab";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { Modal } from "@material-ui/core";
import FormStagePreview from "../../components/preview/FormStagePreview";
import { CloudUpload, CloudUploadRounded } from "@material-ui/icons";
import { getRenewalFormStage2 } from "../../Services/apiServices/Form";
import txt from "../../txt.png";
import pdf from "../../pdf.png";
import { Document, Page, pdfjs } from "react-pdf";
document.body.style = "background: #f5f5f5;";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "13px",
      },
    },
  },
})(TextField);

function Renewalcompanyregistrationpage(props) {
  const {
    snakBarSetter,
    stageSetter,
    errorSetter,
    formloaderSetter,
    rootid,
    formloader,
    stage,
    whichapplication,
  } = props;

  const classes = useStyles();
  const [showPreviewForm, setshowPreviewForm] = useState(false);
  const [stageData, setstageData] = useState();
  const [serverData, setserverData] = useState(false);
  const [formEdit, setformEdit] = useState(false);
  const [previousFIlledData, setpreviousFIlledData] = useState();
  const [imgtype, setImgtype] = React.useState("img");
  const [img, setImg] = React.useState("");
  const [openimg, setOpenimg] = React.useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);

  // company registration page state declaration
  const [
    renewalcompanyregistrationdebenture,
    setRenewalcompanyregistrationdebenture,
  ] = useState("");
  const [
    renewalcompanyregistrationincome,
    setRenewalcompanyregistrationincome,
  ] = useState("");
  const [
    renewalcompanyregistrationliability,
    setRenewalcompanyregistrationliability,
  ] = useState("");
  const [
    renewalcompanyregistrationexternal,
    setRenewalcompanyregistrationexternal,
  ] = useState("");
  const [
    renewalcompanyregistrationotherincome,
    setRenewalcompanyregistrationotherincome,
  ] = useState("");
  // company registration page validator state declaration
  const [
    renewalcompanyregistrationdebenturevalidator,
    setRenewalcompanyregistrationdebenturevalidator,
  ] = useState(true);
  const [
    renewalcompanyregistrationincomevalidator,
    setRenewalcompanyregistrationincomevalidator,
  ] = useState(true);
  const [
    renewalcompanyregistrationliabilityvalidator,
    setRenewalcompanyregistrationliabilityvalidator,
  ] = useState(true);
  const [
    renewalcompanyregistrationexternalvalidator,
    setRenewalcompanyregistrationexternalvalidator,
  ] = useState(true);
  const [
    renewalcompanyregistrationotherincomevalidator,
    setRenewalcompanyregistrationotherincomevalidator,
  ] = useState(true);

  // process renewal company registration data here
  const renewalcompanyregistrationdebentureOnchange = (e) => {
    setRenewalcompanyregistrationdebenture(e.target.value);
  };

  const renewalcompanyregistrationincomeOnchange = (e) => {
    setRenewalcompanyregistrationincome(e.target.files);
  };

  const renewalcompanyregistrationexternalOnchange = (e) => {
    setRenewalcompanyregistrationexternal(e.target.files);
  };

  const renewalcompanyregistrationotherincomeOnchange = (e) => {
    setRenewalcompanyregistrationotherincome(e.target.files);
  };

  const renewalcompanyregistrationliabilityOnchange = (e) => {
    setRenewalcompanyregistrationliability(e.target.files);
  };

  const formPreviewSetter = (value) => {
    setshowPreviewForm(value);
  };

  useEffect(() => {
    async function fetchFormData() {
      const response = await getRenewalFormStage2(rootid);

      if (
        response?.data?.result.renewaldetailsofregistereddebenture &&
        !formEdit
      ) {
        setpreviousFIlledData(response?.data?.result);
        setserverData(true);
      }
    }
    fetchFormData();
  }, [serverData]);

  const renewalcompanyregistrationPreview = async (value) => {
    formloaderSetter(true);
    console.log("name");
    if (renewalcompanyregistrationdebenture < 1) {
      setRenewalcompanyregistrationdebenturevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Designation cant be empty");
      return false;
    } else {
      setRenewalcompanyregistrationdebenturevalidator(true);
    }

    if (renewalcompanyregistrationincome[0] == undefined) {
      setRenewalcompanyregistrationincomevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload Income");
      return false;
    } else {
      setRenewalcompanyregistrationincomevalidator(true);
    }

    if (renewalcompanyregistrationliability[0] == undefined) {
      setRenewalcompanyregistrationliabilityvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload Liability");
      return false;
    } else {
      setRenewalcompanyregistrationliabilityvalidator(true);
    }

    if (renewalcompanyregistrationexternal[0] == undefined) {
      setRenewalcompanyregistrationexternalvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload External");
      return false;
    } else {
      setRenewalcompanyregistrationexternalvalidator(true);
    }

    if (renewalcompanyregistrationotherincome[0] == undefined) {
      setRenewalcompanyregistrationotherincomevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload Other Income");
      return false;
    } else {
      setRenewalcompanyregistrationotherincomevalidator(true);
    }

    const documentfile = ["Income", "Liability", "External", "Otherincome"];
    // const debentureData = new FormData();
    // debentureData.append("debenture", renewalcompanyregistrationdebenture);
    // debentureData.append("renewal_id", rootid);
    // debentureData.append("document[]", documentfile);
    // debentureData.append("documentfile0", renewalcompanyregistrationincome[0]);
    // debentureData.append(
    //   "documentfile1",
    //   renewalcompanyregistrationliability[0]
    // );
    // debentureData.append(
    //   "documentfile2",
    //   renewalcompanyregistrationexternal[0]
    // );
    // debentureData.append(
    //   "documentfile3",
    //   renewalcompanyregistrationotherincome[0]
    // );

    formloaderSetter(false);
    const debentureData = [
      // { name: "root_id", value: `${rootid}` },
      { name: "debenture", value: `${renewalcompanyregistrationdebenture}` },
      // { name: "documentfile0", value: `${moudescription}` },
      {
        name: "INCOME",
        value: `${renewalcompanyregistrationincome[0].name}`,
        file: renewalcompanyregistrationincome[0],
      },
      {
        name: "LIABILITY",
        value: `${renewalcompanyregistrationliability[0].name}`,
        file: renewalcompanyregistrationliability[0],
      },
      {
        name: "EXTERNAL",
        value: `${renewalcompanyregistrationexternal[0].name}`,
        file: renewalcompanyregistrationexternal[0],
      },
      {
        name: "OTHER INCOME",
        value: `${renewalcompanyregistrationotherincome[0].name}`,
        file: renewalcompanyregistrationotherincome[0],
      },
    ];

    setshowPreviewForm(true);
    setstageData(debentureData);

    // await axios
    //   .post(
    //     " https://ogfzaapi.skillzserver.com/site/aplicationrenewaldebenture/create",
    //     debentureData
    //   )
    //   .then((res) => {
    //     formloaderSetter(false);
    //     if (res.data.status == 1) {
    //       stageSetter(res.data.result.application_stage);
    //     }
    //     console.log(res.data);
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //     formloaderSetter(false);
    //   });
  };

  const renewalcompanyregistration = async (value) => {
    formloaderSetter(true);
    if (renewalcompanyregistrationdebenture < 1) {
      setRenewalcompanyregistrationdebenturevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Designation cant be empty");
      return false;
    } else {
      setRenewalcompanyregistrationdebenturevalidator(true);
    }

    if (renewalcompanyregistrationincome[0] == undefined) {
      setRenewalcompanyregistrationincomevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload Income");
      return false;
    } else {
      setRenewalcompanyregistrationincomevalidator(true);
    }

    if (renewalcompanyregistrationliability[0] == undefined) {
      setRenewalcompanyregistrationliabilityvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload Liability");
      return false;
    } else {
      setRenewalcompanyregistrationliabilityvalidator(true);
    }

    if (renewalcompanyregistrationexternal[0] == undefined) {
      setRenewalcompanyregistrationexternalvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload External");
      return false;
    } else {
      setRenewalcompanyregistrationexternalvalidator(true);
    }

    if (renewalcompanyregistrationotherincome[0] == undefined) {
      setRenewalcompanyregistrationotherincomevalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload Other Income");
      return false;
    } else {
      setRenewalcompanyregistrationotherincomevalidator(true);
    }

    const documentfile = ["Income", "Liability", "External", "Otherincome"];
    const debentureData = new FormData();
    debentureData.append("debenture", renewalcompanyregistrationdebenture);
    debentureData.append("renewal_id", rootid);
    debentureData.append("document[]", documentfile);
    debentureData.append("documentfile0", renewalcompanyregistrationincome[0]);
    debentureData.append(
      "documentfile1",
      renewalcompanyregistrationliability[0]
    );
    debentureData.append(
      "documentfile2",
      renewalcompanyregistrationexternal[0]
    );
    debentureData.append(
      "documentfile3",
      renewalcompanyregistrationotherincome[0]
    );

    await axios
      .post(
        " https://ogfzaapi.skillzserver.com/aplicationrenewaldebenture/create",
        debentureData
      )
      .then((res) => {
        formloaderSetter(false);
        if (res.data.status == 1) {
          stageSetter(res.data.result.application_stage);
        }
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
        formloaderSetter(false);
      });
  };

  const showimage = (img) => {
    console.log(img, "nsjssj");
    if (img.split(".").pop() == "pdf") {
      setImgtype("pdf");
      let newimg = "http://api.ogfza-registry.com/uploads/" + img;
      setImg(newimg);
      window.open(
        newimg,
        "sharer",
        "toolbar=no,status=yes,width=900,height=500"
      );

      return false;
    } else {
      setImgtype("img");
    }
    let newimg = "http://api.ogfza-registry.com/uploads/" + img;
    setImg(newimg);
    setOpenimg(true);
    console.log(newimg);
  };

  const handleCloseImg = () => {
    setImg("");
    setOpenimg(false);
  };
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const onSelectSetter = async (value) => {
    console.log("clicked");
    stageSetter(value);

    const response = await getRenewalFormStage2(rootid);
    if (
      response?.data?.result.renewaldetailsofregistereddebenture &&
      !formEdit
    ) {
      setpreviousFIlledData(response?.data?.result);
      setserverData(true);
    }
    // const response = await getFormStage1(rootid);
    // console.log(response, 'djjkdjd')
    // setserverData(true);
    //load
    // if data set visibilitiy to true and change display
  };
  return (
    <React.Fragment>
      <Modal
        open={openimg}
        onClose={handleCloseImg}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "scroll",
        }}
      >
        <div className={classes.modalview}>
          {imgtype == "pdf" ? (
            <Document file={img} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
          ) : (
            <img src={img} />
          )}
        </div>
      </Modal>
      <Modal
        open={showPreviewForm}
        onClose={() => setshowPreviewForm(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
          overflow: "scroll",
          border: "2px solid blue",
          height: "100vh",
          marginTop: "10px",
          // overflowX: 'visible',
          // backgroundColor:'white'
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            height: "maxContent",
            width: window.innerWidth - 50,
            padding: "1rem 2rem",
            border: "2px solid orange",
          }}
        >
          <FormStagePreview
            data={[stageData]}
            formloaderSetter={formloaderSetter}
            formPreviewSetter={formPreviewSetter}
          />
          <Button
            style={{ marginRight: "1rem" }}
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => {
              setshowPreviewForm(false);
              if (!formloader) {
                return renewalcompanyregistration();
              } else {
                return null;
              }
            }}
          >
            {" "}
            Confirm Save and Next{" "}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              formloaderSetter(false);
              setshowPreviewForm(false);
            }}
          >
            {" "}
            No{" "}
          </Button>
        </div>
      </Modal>
      <AppBar position="absolute" color="#ffffff" className={classes.appBar}>
        <Toolbar className={classes.layout}>
          <img
            src={oilandgaslogo}
            alt="Logo"
            style={{
              width: "80px",
              height: "60px",
              textAlign: "center",
              alignSelf: "center",
            }}
          />
        </Toolbar>
      </AppBar>

      <Grid container component="main" className={classes.layout}>
        {/* <Menutab stage={stage} /> */}
        <Menutab
          onSelectSetter={onSelectSetter}
          whichapplication={whichapplication}
          stage={stage}
        />

        {!serverData ? (
          <>
            <Grid
              container
              xs={12}
              spacing={0}
              style={{
                backgroundColor: "#fff",
                padding: 20,
                marginTop: "50px",
              }}
            >
              <h4> Details of Registered Debenture/Mortgage/Charges</h4>
              <Grid item xs={12}>
                <TextField
                  id="outlined-full-width"
                  placeholder=""
                  name=""
                  fullWidth
                  multiline
                  value={renewalcompanyregistrationdebenture}
                  onChange={renewalcompanyregistrationdebentureOnchange}
                  margin="normal"
                  style={{ backgroundColor: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                    className: !renewalcompanyregistrationdebenturevalidator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !renewalcompanyregistrationdebenturevalidator
                        ? classes.errorstyle
                        : null,
                      notchedOutline:
                        !renewalcompanyregistrationdebenturevalidator
                          ? classes.errorstyle
                          : null,
                    },
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid item xs={12}></Grid>

            <Grid
              container
              xs={12}
              spacing={0}
              style={{ backgroundColor: "#fff", padding: 20 }}
            >
              <Grid container xs={12} spacing={1}>
                <Grid item xs={12}>
                  <h4> Upload Document Below</h4>
                </Grid>
                <Grid item xs={6}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    style={{ display: "none" }}
                    id={"raised-button-files"}
                    multiple
                    type="file"
                    name="renewalcompanyregistrationincome"
                    //value={witnessessignature}
                    onChange={renewalcompanyregistrationincomeOnchange}
                  />
                  <label htmlFor={"raised-button-files"}>
                    {renewalcompanyregistrationincome.length > 0 && (
                      <div>
                        <b>INCOME</b>{" "}
                      </div>
                    )}
                    <Button
                      fullWidth
                      style={{
                        backgroundColor: "#fff",
                        minHeight: 55,
                        marginTop: 15,
                      }}
                      variant="outlined"
                      component="span"
                      className={
                        !renewalcompanyregistrationincomevalidator
                          ? classes.errorstyle
                          : renewalcompanyregistrationincome.length > 0
                          ? classes.successupload
                          : classes.uploads
                      }
                    >
                      <div style={{ marginRight: "8px" }}>
                        <CloudUpload />
                      </div>

                      {renewalcompanyregistrationincome.length > 0
                        ? renewalcompanyregistrationincome[0].name
                        : "Income"}
                    </Button>
                  </label>
                </Grid>

                <Grid item xs={6}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    style={{ display: "none" }}
                    id={"renewal-company-registration-liability"}
                    multiple
                    type="file"
                    name="renewalcompanyregistrationliability"
                    //value={witnessessignature}
                    onChange={renewalcompanyregistrationliabilityOnchange}
                  />
                  <label htmlFor={"renewal-company-registration-liability"}>
                    {renewalcompanyregistrationliability.length > 0 && (
                      <div>
                        <b>LIABILITY</b>{" "}
                      </div>
                    )}
                    <Button
                      fullWidth
                      style={{
                        backgroundColor: "#fff",
                        minHeight: 55,
                        marginTop: 15,
                      }}
                      variant="outlined"
                      component="span"
                      className={
                        !renewalcompanyregistrationliabilityvalidator
                          ? classes.errorstyle
                          : renewalcompanyregistrationliability.length > 0
                          ? classes.successupload
                          : classes.uploads
                      }
                    >
                      <div style={{ marginRight: "8px" }}>
                        <CloudUpload />
                      </div>
                      {renewalcompanyregistrationliability.length > 0
                        ? renewalcompanyregistrationliability[0].name
                        : "Liability"}
                    </Button>
                  </label>
                </Grid>

                <Grid item xs={6}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    style={{ display: "none" }}
                    id={"renewal-company-registration-external"}
                    multiple
                    type="file"
                    name="renewalcompanyregistrationexternal"
                    //value={witnessessignature}
                    onChange={renewalcompanyregistrationexternalOnchange}
                  />
                  <label htmlFor={"renewal-company-registration-external"}>
                    {renewalcompanyregistrationexternal.length > 0 && (
                      <div>
                        <b>EXTERNAL</b>{" "}
                      </div>
                    )}
                    <Button
                      fullWidth
                      style={{
                        backgroundColor: "#fff",
                        minHeight: 55,
                        marginTop: 15,
                      }}
                      variant="outlined"
                      component="span"
                      className={
                        !renewalcompanyregistrationexternalvalidator
                          ? classes.errorstyle
                          : renewalcompanyregistrationexternal.length > 0
                          ? classes.successupload
                          : classes.uploads
                      }
                    >
                      <div style={{ marginRight: "8px" }}>
                        <CloudUpload />
                      </div>
                      {renewalcompanyregistrationexternal.length > 0
                        ? renewalcompanyregistrationexternal[0].name
                        : "External"}
                    </Button>
                  </label>
                </Grid>

                <Grid item xs={6}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    style={{ display: "none" }}
                    id={"renewal-company-registration-otherincome"}
                    multiple
                    type="file"
                    name="renewalcompanyregistrationotherincome"
                    //value={witnessessignature}
                    onChange={renewalcompanyregistrationotherincomeOnchange}
                  />
                  <label htmlFor={"renewal-company-registration-otherincome"}>
                    {renewalcompanyregistrationotherincome.length > 0 && (
                      <div>
                        <b>OTHER INCOME</b>{" "}
                      </div>
                    )}
                    <Button
                      fullWidth
                      style={{
                        backgroundColor: "#fff",
                        minHeight: 55,
                        marginTop: 15,
                      }}
                      variant="outlined"
                      component="span"
                      className={
                        !renewalcompanyregistrationotherincomevalidator
                          ? classes.errorstyle
                          : renewalcompanyregistrationotherincome.length > 0
                          ? classes.successupload
                          : classes.uploads
                      }
                    >
                      <div style={{ marginRight: "8px" }}>
                        <CloudUpload />
                      </div>
                      {renewalcompanyregistrationotherincome.length > 0
                        ? renewalcompanyregistrationotherincome[0].name
                        : "other income"}
                    </Button>
                  </label>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              justify="flex-end"
              style={{ marginBottom: "50px" }}
            >
              <Box component="span">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={
                    !formloader ? renewalcompanyregistrationPreview : null
                  }
                  style={{
                    backgroundColor: "#060454",
                    height: "30px",
                    marginTop: "15px",
                    marginRight: "255px",
                  }}
                  className={classes.button}
                  endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                >
                  Preview & Continue{" "}
                  {formloader ? <CircularProgress size={24} /> : null}
                </Button>
              </Box>
            </Grid>
          </>
        ) : (
          <div
            style={{
              backgroundColor: "#fff",
              marginTop: 25,
              padding: 30,
              marginBottom: "7rem",
              width: "100%",
            }}
          >
            <Grid container   style={{ display: "", flexDirection: "", columnGap:'' }}>
              {/* {previousFIlledData?.document && ( */}

              {previousFIlledData?.renewaldetailsofregistereddebenture
                .status === 0 && (
                <>
                  <Grid
                    xs={4}
                    style={{ display: "", flexDirection: "", marginRight:'1rem' }}
                  >
                    <div>Details of Registered Debenture</div>
                    <div>
                      {" "}
                      <input
                        disabled
                        value={
                          previousFIlledData
                            ?.renewaldetailsofregistereddebenture?.details
                        }
                      />
                    </div>
                  </Grid>
                </>
              )}

              {previousFIlledData?.income.status === 0 && (
                <>
                  <Grid
                    xs={4}
                    // style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div>{previousFIlledData?.income?.type_name}</div>
                    <img
                      src={
                        previousFIlledData?.income?.file_path
                          .split(".")
                          .pop() == "pdf"
                          ? pdf
                          : txt
                      }
                      style={{
                        width: 100,
                        height: 100,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        showimage(
                          previousFIlledData?.income?.file_path
                          // e?.signarure
                        );
                      }}
                    />
                  </Grid>
                </>
              )}
              {previousFIlledData?.liability.status === 0 && (
                <>
                  <Grid
                    xs={4}
                    // style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div>{previousFIlledData?.liability?.type_name}</div>
                    <img
                      src={
                        previousFIlledData?.liability?.file_path
                          .split(".")
                          .pop() == "pdf"
                          ? pdf
                          : txt
                      }
                      style={{
                        width: 100,
                        height: 100,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        showimage(
                          previousFIlledData?.liability?.file_path
                          // e?.signarure
                        );
                      }}
                    />
                  </Grid>
                </>
              )}
              {previousFIlledData?.external.status === 0 && (
                <>
                  <Grid
                    xs={4}
                    // style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div>{previousFIlledData?.external?.type_name}</div>
                    <img
                      src={
                        previousFIlledData?.external?.file_path
                          .split(".")
                          .pop() == "pdf"
                          ? pdf
                          : txt
                      }
                      style={{
                        width: 100,
                        height: 100,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        showimage(
                          previousFIlledData?.external?.file_path
                          // e?.signarure
                        );
                      }}
                    />
                  </Grid>
                </>
              )}
              {previousFIlledData?.otherincome.status === 0 && (
                <>
                  <Grid
                    xs={4}
                    // style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div>{previousFIlledData?.otherincome?.type_name}</div>
                    <img
                      src={
                        previousFIlledData?.otherincome?.file_path
                          .split(".")
                          .pop() == "pdf"
                          ? pdf
                          : txt
                      }
                      style={{
                        width: 100,
                        height: 100,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        showimage(
                          previousFIlledData?.otherincome?.file_path
                          // e?.signarure
                        );
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                // onClick={!formloader ? previewStageData : null}
                style={{ backgroundColor: "#060454", height: "30px" }}
                className={classes.button}
                endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                onClick={() => {
                  setformEdit(true);
                  setserverData(false);
                }}
              >
                Refill Form
              </Button>
            </Grid>
          </div>
        )}
      </Grid>

      <AppBar position="fixed" className={classes.appBarButom} color="#060454">
        <Typography
          variant="span"
          color="inherit"
          noWrap
          style={{ textAlign: "center", color: "#fff", alignItems: "center" }}
          justify="center"
        >
          Copyright © 2022 <b>Oil and Gas Free Zones Authority</b>. All Rights Reserved.
        </Typography>
      </AppBar>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    position: "relative",
  },
  checkboxLabel: {
    fontSize: 14,
  },
  errorstyle: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
  appBarButom: {
    top: "auto",
    bottom: 0,
    height: "40px",
    backgroundColor: "#060454",
    paddingTop: "5px",
    paddingButtom: "5px",
    alignItems: "center",
    justifyContent: "center",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "60%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

export default Renewalcompanyregistrationpage;
