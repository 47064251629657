import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ClearIcon from "@material-ui/icons/Clear";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import HourglassFullOutlinedIcon from "@material-ui/icons/HourglassFullOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import CancelPresentationOutlinedIcon from "@material-ui/icons/CancelPresentationOutlined";
import Box from "@material-ui/core/Box";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// use to display list
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import Divider from "@material-ui/core/Divider";
import oilandgaslogo from "../oilandgaslogo.png";
import pdf from "../pdf.png";
import txt from "../txt.png";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Approvalbuttons from "./Approvalbuttons";
import Approvalbuttons from "./Button/Approvalbuttons";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Snackbar from "@material-ui/core/Snackbar";
import { Document, Page, pdfjs } from "react-pdf";
import RegistryTab from "./Tabs/RegistryTab";
import {
  approvalValidationStages,
  finalApprovalCheck,
} from "../Services/apiServices/Form";
import {
  CircularProgress,
  createStyles,
  LinearProgress,
} from "@material-ui/core";
import ProgressBar from "./ProgressBar";
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      // height: "100vh",
      alignItems: "center",
      justifyContent: "center",
    },
    margin: {
      margin: theme.spacing(3),
    },
    linearProgress: {
      width: theme.spacing(30),
    },
  })
);

export default function DocumentDetails(props) {
  const classes = useStyles();

  console.log(props, "sjkdjskj");

  const { data, showimage } = props;
  const [level, setLevel] = useState(0);
  const [approvalLoader, setapprovalLoader] = useState(false);
  const [approvalMessage, setapprovalMessage] = useState();

  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const approvalVerification = async () => {
    setapprovalMessage("");
    setapprovalLoader(true);

    try {
      for (let i = 1; i < 4; i++) {
        const res = await approvalValidationStages(i, i);
        setLevel(level + 10);
        console.log(res?.data, "djdjdj");
        if (res?.data?.error_code) {
          setapprovalLoader(false);
          console.log("yeas");
          setapprovalMessage(res?.data?.message);
        }
      }
      await finalApprovalCheck(data?.root_id);
      setapprovalLoader(false);
      setapprovalMessage("Approval Successful");
      console.log("done");
    } catch (err) {
      setapprovalLoader(false);
      setapprovalMessage("Approval Failed");
      console.log(err, "error");
    }
  };

  // useEffect(() => {
  //   console.log("updatedd");
  //   setapprovalLoader(!approvalLoader);
  // }, []);
  return (
    <>
      <Typography style={{ color: "#000", paddingBottom: 30 }} variant="h6">
        Preview Of Company Filled Informations And Uploaded Documents{" "}
      </Typography>
   
      {data?.applicantdetails?.applicant[0]?.companyname ? (
        <>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion
              square
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                style={{ backgroundColor: "#060454" }}
              >
                <Typography style={{ color: "#fff" }}>Memorandum</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <List>
                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="Company Name"
                      secondary={
                        data.applicantdetails.applicant
                          ? data?.applicantdetails?.applicant[0]?.companyname
                              ?.value
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={
                          data?.applicantdetails?.applicant[0]?.companyname
                        }
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"companyname"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="The registered office of the company would be situated in :"
                      secondary={
                        data.applicantdetails.applicant
                          ? data?.applicantdetails?.applicant[0]?.moureglocation
                              ?.value
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={
                          data?.applicantdetails?.applicant[0]?.moureglocation
                        }
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"moureglocation"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="The objective for which the company was established :"
                      secondary={
                        data.applicantdetails.applicant
                          ? data?.applicantdetails?.applicant[0]
                              ?.objofestablishment?.description
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={
                          data.applicantdetails?.applicant[0]
                            ?.objofestablishment
                        }
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"objofestablishment"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="Authorized number of Shares:"
                      secondary={
                        data.applicantdetails.applicant
                          ? data.applicantdetails.applicant[0]?.shares?.value
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={data.applicantdetails.applicant[0]?.shares}
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"shares"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="number of Shares:"
                      secondary={
                        data.applicantdetails.applicant
                          ? data.applicantdetails.applicant[0]
                              ?.mounumberofshares?.value
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={
                          data.applicantdetails.applicant[0]?.mounumberofshares
                        }
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"mounumberofshares"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    <ListItemText>
                      <TableContainer>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Name: </TableCell>
                              <TableCell>Address</TableCell>
                              <TableCell>Shares</TableCell>
                              <TableCell>Signature</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.applicantdetails.applicant
                              ? data.applicantdetails.applicant[0]?.shareholders?.map(
                                  (value, index) => (
                                    <TableRow>
                                      <TableCell align="right">
                                        {value.name}
                                      </TableCell>
                                      <TableCell align="right">
                                        {value.address}
                                      </TableCell>
                                      <TableCell align="right">
                                        {value.number}
                                      </TableCell>
                                      <TableCell align="right">
                                        <img
                                          src={
                                            value.signarure.split(".").pop() ==
                                            "pdf"
                                              ? pdf
                                              : txt
                                          }
                                          style={{
                                            width: 100,
                                            height: 100,
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            showimage(value.signarure);
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell align="right">
                                        <Approvalbuttons
                                          priv={data.applicantdetails.priv}
                                          value={value}
                                          approvesection={data.approvesection}
                                          declinesection={data.declinesection}
                                          display={2}
                                          section={"shareholders"}
                                          sectionloader={data.sectionloader}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  )
                                )
                              : null}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </ListItemText>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="Date:"
                      secondary={
                        data.applicantdetails.applicant
                          ? data.applicantdetails.applicant[0]?.mouapprovaldate
                              ?.value
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={
                          data.applicantdetails.applicant[0]?.mouapprovaldate
                        }
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"mouapprovaldate"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="Witnesses Name:"
                      secondary={
                        data.applicantdetails.applicant
                          ? data.applicantdetails.applicant[0]?.witnessname
                              ?.value
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={data.applicantdetails.applicant[0]?.witnessname}
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"witnessname"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="Witnesses Address:"
                      secondary={
                        data.applicantdetails.applicant
                          ? data.applicantdetails.applicant[0]?.witnessaddress
                              ?.value
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={
                          data.applicantdetails.applicant[0]?.witnessaddress
                        }
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"witnessaddress"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="Witnesses Occupation:"
                      secondary={
                        data.applicantdetails.applicant
                          ? data.applicantdetails.applicant[0]
                              ?.witnessoccupation?.value
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={
                          data?.applicantdetails?.applicant[0]
                            ?.witnessoccupation
                        }
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"witnessoccupation"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    {
                      <ListItemAvatar>
                        <img
                          src={
                            data?.applicantdetails?.applicant[0]?.witnesssignature?.file_path
                              .split(".")
                              .pop() == "pdf"
                              ? pdf
                              : txt
                          }
                          style={{ width: 100, height: 100, cursor: "pointer" }}
                          onClick={() => {
                            showimage(
                              data?.applicantdetails?.applicant[0]
                                ?.witnesssignature?.file_path
                            );
                          }}
                        />
                      </ListItemAvatar>
                    }

                    <ListItemText primary="Witnesses Signature:" />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={
                          data?.applicantdetails?.applicant[0]?.witnesssignature
                        }
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"witnesssignature"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>

            <Accordion
              square
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel3d-header"
                expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                style={{ backgroundColor: "#060454" }}
              >
                <Typography style={{ color: "#fff" }}>
                  Company Details
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <List>
                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="Company Name"
                      secondary={
                        data?.applicantdetails?.applicant
                          ? data?.applicantdetails?.applicant[0]?.companyname
                              ?.value
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={
                          data?.applicantdetails?.applicant[0]?.companyname
                        }
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"companyname"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="Company Registered In:"
                      secondary={
                        data?.applicantdetails?.applicant
                          ? data?.applicantdetails?.applicant[0]
                              ?.companycountryofregistration?.value
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={
                          data?.applicantdetails?.applicant[0]
                            ?.companycountryofregistration
                        }
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"companycountryofregistration"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="Company Head Office Address:"
                      secondary={
                        data.applicantdetails.applicant
                          ? data.applicantdetails.applicant[0]?.companyaddress
                              ?.value
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={
                          data.applicantdetails.applicant[0]?.companyaddress
                        }
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"companyaddress"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="Company Tel:"
                      secondary={
                        data.applicantdetails.applicant
                          ? data.applicantdetails.applicant[0]?.companytel
                              ?.value
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={data.applicantdetails.applicant[0]?.companytel}
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"companytel"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="Company Fax:"
                      secondary={
                        data.applicantdetails.applicant
                          ? data.applicantdetails.applicant[0]?.companyfax
                              ?.value
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={data.applicantdetails.applicant[0]?.companyfax}
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"companyfax"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="Company International Website:"
                      secondary={
                        data.applicantdetails.applicant
                          ? data.applicantdetails.applicant[0]?.companywebsite
                              ?.value
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={
                          data.applicantdetails.applicant[0]?.companywebsite
                        }
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"companywebsite"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="Company International Contact Person:"
                      secondary={
                        data.applicantdetails.applicant
                          ? data.applicantdetails.applicant[0]?.contactname
                              ?.value
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={data.applicantdetails.applicant[0]?.contactname}
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"contactname"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="Company Local Address/Branch:"
                      secondary={
                        data.applicantdetails.applicant
                          ? data.applicantdetails.applicant[0]
                              ?.companylocaladdress?.value
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={
                          data.applicantdetails.applicant[0]
                            ?.companylocaladdress
                        }
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"companylocaladdress"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="Company Local Tel:"
                      secondary={
                        data.applicantdetails.applicant
                          ? data.applicantdetails.applicant[0]?.companylocaltel
                              ?.value
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={
                          data.applicantdetails.applicant[0]?.companylocaltel
                        }
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"companylocaltel"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="Company Local Fax:"
                      secondary={
                        data.applicantdetails.applicant
                          ? data.applicantdetails.applicant[0]?.companylocalfax
                              ?.value
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={
                          data.applicantdetails.applicant[0]?.companylocalfax
                        }
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"companylocalfax"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="Company Local Website:"
                      secondary={
                        data.applicantdetails.applicant
                          ? data.applicantdetails.applicant[0]
                              ?.companylocalwebsite?.value
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={
                          data.applicantdetails.applicant[0]
                            ?.companylocalwebsite
                        }
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"companylocalwebsite"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="Company Local Contact Name:"
                      secondary={
                        data.applicantdetails.applicant
                          ? data.applicantdetails.applicant[0]?.contactlocalname
                              ?.value
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={
                          data.applicantdetails.applicant[0]?.contactlocalname
                        }
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"contactlocalname"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>

                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="If Not Registered In Nigeria, Is It Your Intention To Register:"
                      secondary={
                        data.applicantdetails.applicant
                          ? data.applicantdetails.applicant[0]?.reginnigeria
                              ?.value
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={data.applicantdetails.applicant[0]?.reginnigeria}
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"reginnigeria"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                    <Divider variant="inset" component="li" />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>

            {data.applicantdetails?.applicant[0]?.application_type !==
              "vendor" && (
              <Accordion
                square
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                  expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                  style={{ backgroundColor: "#060454" }}
                >
                  <Typography style={{ color: "#fff" }}>
                    Nature of Present Business Operation
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <List>
                    <ListItem>
                      {
                        <ListItemAvatar>
                          {
                            //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                          }
                          <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                        </ListItemAvatar>
                      }

                      <ListItemText
                        primary="Nature Of Business Operation"
                        secondary={
                          data.applicantdetails.applicant
                            ? data.applicantdetails.applicant[0]
                                .natureofbusinessoperation?.description
                            : null
                        }
                      />

                      <ListItemSecondaryAction>
                        <Approvalbuttons
                          priv={data.applicantdetails.priv}
                          value={
                            data.applicantdetails.applicant[0]
                              ?.natureofbusinessoperation
                          }
                          approvesection={data.approvesection}
                          declinesection={data.declinesection}
                          display={2}
                          section={"natureofbusinessoperation"}
                          sectionloader={data.sectionloader}
                        />
                      </ListItemSecondaryAction>
                      <Divider variant="inset" component="li" />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            )}

            <Accordion
              square
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                aria-controls="panel4d-content"
                id="panel4d-header"
                expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                style={{ backgroundColor: "#060454" }}
              >
                <Typography style={{ color: "#fff" }}>
                  Description Of Activities / Countries With Other Operations in
                  West Africa
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <List>
                  <ListItem>
                    {
                      <ListItemAvatar>
                        {
                          //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                        }
                        <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                      </ListItemAvatar>
                    }

                    <ListItemText
                      primary="Description Of Activities"
                      secondary={
                        data.applicantdetails.applicant
                          ? data.applicantdetails.applicant[0]
                              ?.descriptionofactivity?.description
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={
                          data.applicantdetails.applicant[0]
                            ?.descriptionofactivity
                        }
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"descriptionofactivity"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  {data.applicantdetails ? (
                    data.applicantdetails.applicant[0]?.countryinoperation
                      ?.length > 0 ? (
                      <ListItem>
                        <ListItemText>
                          <TableContainer>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>SN: </TableCell>
                                  <TableCell>Country Name</TableCell>
                                  <TableCell>Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {data.applicantdetails.applicant
                                  ? data.applicantdetails.applicant[0]?.countryinoperation?.map(
                                      (value, index) => (
                                        <TableRow>
                                          <TableCell align="right">
                                            {index + 1}
                                          </TableCell>
                                          <TableCell align="right">
                                            {value.country_id}
                                          </TableCell>
                                          <TableCell align="right">
                                            <Approvalbuttons
                                              priv={data.applicantdetails.priv}
                                              value={value}
                                              approvesection={
                                                data.approvesection
                                              }
                                              declinesection={
                                                data.declinesection
                                              }
                                              display={2}
                                              section={"countryinoperation"}
                                              sectionloader={data.sectionloader}
                                            />
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )
                                  : null}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </ListItemText>
                        <Divider variant="inset" component="li" />
                      </ListItem>
                    ) : (
                      <ListItem>
                        {
                          <ListItemAvatar>
                            {
                              //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                            }
                            <PlayCircleOutlineIcon
                              style={{ color: "#060454" }}
                            />
                          </ListItemAvatar>
                        }

                        <ListItemText
                          primary="Countries With Other Operations in West Africa"
                          secondary="Not operational in any other west africa country"
                        />

                        <ListItemSecondaryAction>
                          <Approvalbuttons
                            priv={data.applicantdetails.priv}
                            value={{ id: 0, status: 0 }}
                            approvesection={data.approvesection}
                            declinesection={data.declinesection}
                            display={2}
                            section={"countryinoperation"}
                            sectionloader={data.sectionloader}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    )
                  ) : null}
                </List>
              </AccordionDetails>
            </Accordion>

            {data.applicantdetails?.applicant[0]?.application_type !==
              "vendor" && (
              <Accordion
                square
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  aria-controls="panel5d-content"
                  id="panel5d-header"
                  expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                  style={{ backgroundColor: "#060454" }}
                >
                  <Typography style={{ color: "#fff" }}>
                    Business Activities / Required Facilities / Expected Start
                    Date
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <List>
                    <Typography component="div">Business Activities</Typography>
                    <ListItem>
                      {
                        <ListItemAvatar>
                          {
                            //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                          }
                          <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                        </ListItemAvatar>
                      }

                      <ListItemText
                        primary="Business Activities"
                        secondary={
                          data.applicantdetails.applicant
                            ? data.applicantdetails.applicant[0]
                                .ogfzabusinessactivities?.description
                            : null
                        }
                      />

                      <ListItemSecondaryAction>
                        <Approvalbuttons
                          priv={data.applicantdetails.priv}
                          value={
                            data.applicantdetails.applicant[0]
                              ?.ogfzabusinessactivities
                          }
                          approvesection={data.approvesection}
                          declinesection={data.declinesection}
                          display={2}
                          section={"ogfzabusinessactivities"}
                          sectionloader={data.sectionloader}
                        />
                      </ListItemSecondaryAction>
                      <Divider variant="inset" component="li" />
                    </ListItem>

                    <Typography component="div">Required Facilities</Typography>

                    {data.applicantdetails.applicant[0]?.requiredfacilities?.map(
                      (value, index) => (
                        <ListItem>
                          {
                            <ListItemAvatar>
                              {
                                //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                              }
                              <PlayCircleOutlineIcon
                                style={{ color: "#060454" }}
                              />
                            </ListItemAvatar>
                          }

                          <ListItemText
                            primary={value.type}
                            secondary={
                              value.quantity + " " + value.quantity_measurement
                            }
                          />

                          <ListItemSecondaryAction>
                            <Approvalbuttons
                              priv={data.applicantdetails.priv}
                              value={value}
                              approvesection={data.approvesection}
                              declinesection={data.declinesection}
                              display={2}
                              section={"requiredfacilities"}
                              sectionloader={data.sectionloader}
                            />
                          </ListItemSecondaryAction>
                          <Divider variant="inset" component="li" />
                        </ListItem>
                      )
                    )}

                    <Typography component="div">Expected Start Date</Typography>
                    <ListItem>
                      {
                        <ListItemAvatar>
                          {
                            //<img src={oilandgaslogo} style={{width:100,height:100}}/>
                          }
                          <PlayCircleOutlineIcon style={{ color: "#060454" }} />
                        </ListItemAvatar>
                      }

                      <ListItemText
                        primary="Expected Start Date:"
                        secondary={
                          data.applicantdetails.applicant[0]?.expectedstartdate
                            ?.start_date
                        }
                      />

                      <ListItemSecondaryAction>
                        <Approvalbuttons
                          priv={data.applicantdetails.priv}
                          value={
                            data.applicantdetails.applicant[0]
                              ?.expectedstartdate
                          }
                          approvesection={data.approvesection}
                          declinesection={data.declinesection}
                          display={2}
                          section={"expectedstartdate"}
                          sectionloader={data.sectionloader}
                        />
                      </ListItemSecondaryAction>
                      <Divider variant="inset" component="li" />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            )}

            {data.applicantdetails?.applicant[0]?.application_type !==
              "vendor" && (
              <Accordion
                square
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
              >
                <AccordionSummary
                  aria-controls="panel6d-content"
                  id="panel6d-header"
                  expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                  style={{ backgroundColor: "#060454" }}
                >
                  <Typography style={{ color: "#fff" }}>
                    Import / Export Movement
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <List>
                    <Typography>Import Movement</Typography>
                    <ListItem>
                      <ListItemText>
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Route: </TableCell>
                                <TableCell>Cargo</TableCell>
                                <TableCell>Tons</TableCell>
                                <TableCell>Container</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.applicantdetails.applicant
                                ? data.applicantdetails.applicant[0]?.importmovement?.map(
                                    (value, index) => (
                                      <TableRow>
                                        <TableCell align="right">
                                          {value.route_type}
                                        </TableCell>
                                        <TableCell align="right">
                                          {value.cargo_type}
                                        </TableCell>
                                        <TableCell align="right">
                                          {value.tons}
                                        </TableCell>
                                        <TableCell align="right">
                                          {value.container}
                                        </TableCell>
                                        <TableCell align="right">
                                          {value.value}
                                        </TableCell>
                                        <TableCell align="right">
                                          <Approvalbuttons
                                            priv={data.applicantdetails.priv}
                                            value={value}
                                            approvesection={data.approvesection}
                                            declinesection={data.declinesection}
                                            display={2}
                                            section={"importmovement"}
                                            sectionloader={data.sectionloader}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )
                                : null}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </ListItemText>
                      <Divider variant="inset" component="li" />
                    </ListItem>

                    <Typography>Export Movement</Typography>
                    <ListItem>
                      <ListItemText>
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Route: </TableCell>
                                <TableCell>Cargo</TableCell>
                                <TableCell>Tons</TableCell>
                                <TableCell>Container</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.applicantdetails.applicant
                                ? data.applicantdetails.applicant[0]?.exportmovement?.map(
                                    (value, index) => (
                                      <TableRow>
                                        <TableCell align="right">
                                          {value.route_type}
                                        </TableCell>
                                        <TableCell align="right">
                                          {value.cargo_type}
                                        </TableCell>
                                        <TableCell align="right">
                                          {value.tons}
                                        </TableCell>
                                        <TableCell align="right">
                                          {value.container}
                                        </TableCell>
                                        <TableCell align="right">
                                          {value.value}
                                        </TableCell>
                                        <TableCell align="right">
                                          <Approvalbuttons
                                            priv={data.applicantdetails.priv}
                                            value={value}
                                            approvesection={data.approvesection}
                                            declinesection={data.declinesection}
                                            display={2}
                                            section={"exportmovement"}
                                            sectionloader={data.sectionloader}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )
                                : null}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </ListItemText>
                      <Divider variant="inset" component="li" />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            )}

            <Accordion
              square
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                aria-controls="panel7d-content"
                id="panel7d-header"
                expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                style={{ backgroundColor: "#060454" }}
              >
                <Typography style={{ color: "#fff" }}>
                  Company Understanding
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <List>
                  <ListItem>
                    <ListItemText>
                      <TableContainer style={{ width: "100%" }}>
                        <Table
                          aria-label="simple table"
                          style={{ width: "100%" }}
                        >
                          <TableBody style={{ width: "100%" }}>
                            <TableRow>
                              <TableCell align="right">Name</TableCell>
                              <TableCell align="right">
                                {
                                  data.applicantdetails.applicant[0]
                                    ?.companyunderstanding?.name
                                }
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="right">Designation</TableCell>
                              <TableCell align="right">
                                {
                                  data.applicantdetails.applicant[0]
                                    .companyunderstanding?.designation
                                }
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="right">Signature</TableCell>
                              <TableCell align="right">
                                <img
                                  src={
                                    data.applicantdetails.applicant[0]?.companyunderstanding?.signature
                                      .split(".")
                                      .pop() == "pdf"
                                      ? pdf
                                      : txt
                                  }
                                  style={{
                                    width: 100,
                                    height: 100,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    showimage(
                                      data.applicantdetails.applicant[0]
                                        .companyunderstanding?.signature
                                    );
                                  }}
                                />
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="right">Date</TableCell>
                              <TableCell align="right">
                                {
                                  data.applicantdetails.applicant[0]
                                    .companyunderstanding?.date_created
                                }
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="right">Company Stamp</TableCell>
                              <TableCell align="right">
                                <img
                                  src={
                                    data.applicantdetails.applicant[0]?.companyunderstanding?.company_stamp
                                      .split(".")
                                      .pop() == "pdf"
                                      ? pdf
                                      : txt
                                  }
                                  style={{
                                    width: 100,
                                    height: 100,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    showimage(
                                      data.applicantdetails.applicant[0]
                                        .companyunderstanding?.company_stamp
                                    );
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </ListItemText>

                    <ListItemSecondaryAction>
                      <Approvalbuttons
                        priv={data.applicantdetails.priv}
                        value={
                          data.applicantdetails.applicant[0]
                            ?.companyunderstanding
                        }
                        approvesection={data.approvesection}
                        declinesection={data.declinesection}
                        display={2}
                        section={"companyunderstanding"}
                        sectionloader={data.sectionloader}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
            <Typography
              style={{ color: "#060454", paddingBottom: 30 }}
              variant="h4"
            >
              Uploaded Document
            </Typography>
            <Grid container>
              {data.applicantdetails.applicant[0]?.documentupload?.map(
                (value, index) => (
                  <Grid item xs={12} sm={3} md={3}>
                    <Box
                      component="div"
                      style={{
                        minHeight: 100,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        padding: 5,
                      }}
                    >
                      <Box
                        component="div"
                        style={{
                          backgroundColor: "#fff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#000",
                          paddingTop: 20,
                          borderTopLeftRadius: 10,
                          borderTopRightRadius: 10,
                        }}
                      >
                        {console.log(value, "jsajkkkkkkkkaakjajka")}
                        <img
                          src={
                            value.parth.split(".").pop() == "pdf" ? pdf : txt
                          }
                          style={{ width: 100, height: 100, cursor: "pointer" }}
                          onClick={() => {
                            showimage(value.parth);
                          }}
                        />
                      </Box>

                      <Box
                        component="div"
                        style={{
                          minHeight: 60,
                          backgroundColor: "#fff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#000",
                          paddingTop: 10,
                          paddingBottom: 10,
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        {value.type}
                      </Box>

                      <Box
                        component="div"
                        style={{
                          backgroundColor: "#ccc",
                          height: 50,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#fff",
                          fontSize: 18,
                          borderBottomLeftRadius: 10,
                          borderBottomRightRadius: 10,
                          cursor: "pointer",
                        }}
                      >
                        <Approvalbuttons
                          priv={data.applicantdetails.priv}
                          value={value}
                          approvesection={data.approvesection}
                          declinesection={data.declinesection}
                          display={2}
                          section={"documentupload"}
                          sectionloader={data.sectionloader}
                        />
                      </Box>
                    </Box>
                  </Grid>
                )
              )}
            </Grid>

            {approvalLoader && <ProgressBar />}
            {approvalMessage}

            <Grid container>
              <Grid xs={6}>
                <Button
                  onClick={approvalVerification}
                  style={{
                    border: "solid 1px green",
                    color: "green",
                    marginTop: "2rem",
                  }}
                >
                  APPROVE
                </Button>
              </Grid>
              <Grid xs={6}>
                <Button
                  style={{
                    border: "solid 1px red",
                    color: "red",
                    marginTop: "2rem",
                  }}
                >
                  DECLINE
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      ): "No data"}
    </>
  );
}
