import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import LockOutlineIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles,withStyles, } from '@material-ui/core/styles';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import oilandgaslogo from '../oilandgaslogo.png';
import CircularProgress from '@material-ui/core/CircularProgress';




const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '13px',
      },
    },
  },
})(TextField);


function Emailstep1(props) {
    const classes = useStyles();

    const onChangeEmail = (e) =>{
        props.onChangeEmailParent(e.target.value);
    }
    const submitEmail = () =>{
        props.submitEmailParent();
    }

    return (
        <React.Fragment>
           <Grid container spacing={0} xs={12}   alignItems="center" justify="center"  >
          <div className={classes.formHolder}>

            <form className={classes.form} noValidate>



              <Grid className={props.emailstagevalidation ?classes.emailborder:classes.emailbordererror} style={{borderRadius:'8px',padding:'3px 3px 10px 25px'}} container spacing={1} alignItems="flex-end">
                <Grid item xs={2}>
                  <MailOutlinedIcon style={{ color: " #ccc",fontSize:'35px'}} />
                </Grid>
                <Grid item xs={10}>
                  <TextField

                      InputLabelProps={{className: props.emailstagevalidation ? classes.floatingLabelFocusStyle:classes.floatingLabelFocusStyleError}}
                      id=""
                      value={props.email}
                      onChange = {onChangeEmail}
                      label="Email Address"
                      fullWidth

                      InputProps={{
                          disableUnderline: true,
                          classes: {
                              input: classes.multilineColor
                          }
                      }
                  }
                  />
                </Grid>
              </Grid>




              <Button
                type="button"

                variant="contained"
                color="primary"
                onClick = {submitEmail}
                className={classes.submit}
              >
                Proceed {props.formloader?<CircularProgress size={24}  />:null}
              </Button>


            </form>
          </div>
        </Grid>
      </React.Fragment>
    );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    backgroundColor:'#fff',
  },
  emailcontainer: {
    border: 1,
  },
  multilineColor:{
        color:'#fff'
    },
    floatingLabelFocusStyle: {
        color: "#fff"
    },
    floatingLabelFocusStyleError: {
        color: "red",

    },
    emailbordererror:{
        border:"solid 1px red",
    },
    emailborder:{
        border:"solid 1px #fff",
    },
  image: {
    backgroundImage: 'url(../../img/loginillustration.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? '#fff' : '#fff',
    backgroundSize: '67%',
    backgroundPosition: 'center',
  },

  signinFormGrid: {
    boxShadow:'none',
    background:`linear-gradient(${"rgba(255,255,255,0.5)"}, ${"transparent"})`,
    backgroundColor:"#060454"

  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor:'#0043ce',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formHolder: {
    width: '60%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#fff',
    borderRadius: '0.4rem',
    color:'#060454',
    width:'100%'
  },
  textfield: {
    borderRadius: '2rem',
  },
  email:{
      width:'60%',
      borderWidth:2,

  }
}));

export default Emailstep1;
