import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LockOutlineIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import oilandgaslogo from "../../assets/oilandgaslogo.png";
import Emailstep1 from "../../components/Emailstep1";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InputBase from "@material-ui/core/InputBase";
// import Menutab from '../../Menutab';
import Menutab from "../../components/Tabs/Menutab";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { Modal } from "@material-ui/core";

document.body.style = "background: #f5f5f5;";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "13px",
      },
    },
  },
})(TextField);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

function Paymentproof(props) {
  const {
    snakBarSetter,
    stageSetter,
    errorSetter,
    formloaderSetter,
    rootid,
    formloader,
    stage,
    paymentPageSetter,
  } = props;

  const classes = useStyles();

  // payment proof states
  const [proof, setProof] = useState("");
  // payment proof validator state
  const [proofvalidator, setProofvalidator] = useState(true);
  const [showPreviewForm, setshowPreviewForm] = useState(false);
  const [imageUrl, setimageUrl] = useState();
  const [showPreviewBtn, setshowPreviewBtn] = useState(false)

  // const onClickPaymentButton = () => {
  //   onClickPaymentButton();
  // };

  const proofOnchang = (e) => {

    setshowPreviewBtn(true)
    setProof(e.target.files);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const showPreview = async () => {
    const res = await getBase64(proof);
    console.log(res);
    setimageUrl(res);
    setshowPreviewForm(true)
    // setPreviewUrl(res || file.preview);

    fetch(`${proof[0].name}`)
      .then(function (response) {
        return response.blob();
      })
      .then(function (blob) {
        console.log(blob);
        // here the image is a blob
      });
    console.log(proof);
  };

  const onClickPaymentButton = (value) => {
    formloaderSetter(true);
    if (proof[0] == undefined) {
      setProofvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Upload Proof Of Payment");
      return false;
    } else {
      setProofvalidator(true);
    }

    // send payment data to the server
    const proofData = new FormData();
    proofData.append("proof", proof[0]);
    proofData.append("root_id", rootid);

    //axios.post('https://ogfzaapi.skillzserver.com/aplicationformstage7/create',  proofData )
    axios
      .post("https://ogfzaapi.skillzserver.com/aplicationprocessingfee/create", proofData)
      .then((res) => {
        console.log(res.data);
        if (res.data.status == 1) {
          stageSetter(res.data.result.application_stage);
          paymentPageSetter(res.data.result.processing_fee_status);
          formloaderSetter(false);
        }
        formloaderSetter(false);
      });
  };

  return (
    <React.Fragment>
      <AppBar position="absolute" color="#ffffff" className={classes.appBar}>
        <Toolbar className={classes.layout}>
          <img
            src={oilandgaslogo}
            alt="Logo"
            style={{
              width: "80px",
              height: "60px",
              textAlign: "center",
              alignSelf: "center",
            }}
          />
        </Toolbar>
      </AppBar>

      <Modal
        open={showPreviewForm}
        onClose={() => setshowPreviewForm(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // backgroundColor:'white'
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            height: "100vh",
            width: window.innerWidth - 400,
          }}
        >
   
          <img style={{height: '600px', width:'1000px'}} src={`${imageUrl}`} />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              setshowPreviewForm(false);
              if(!formloader ){
                return onClickPaymentButton()
              }else{
                return null
              }
            }}
          >
            {" "}
            Save and Next{" "}
          </Button>
          <Button
            variant="contained"
              color="primary"
              className={classes.button}
            onClick={() => {
              setshowPreviewForm(false);
            }}
          >
            {" "}
            No{" "}
          </Button>
        </div>
      </Modal>

      <Grid container component="main" className={classes.layout}>
        <Grid item xs={12} style={{ marginBottom: 25 }}>
          <h4> Proof of Payment Reqiured (Please attach proof of payment ):</h4>
        </Grid>
        <Grid
          container
          xs={12}
          spacing={1}
          style={{ backgroundColor: "#fff", marginTop: 25, padding: 30 }}
        >
          <Grid item xs={12}>
            <input
              accept="image/*,application/pdf"
              className={classes.input}
              style={{ display: "none" }}
              id="cacOnchange"
              multiple
              type="file"
              onChange={proofOnchang}
            />
            <label htmlFor="cacOnchange">
              <Button
                fullWidth
                style={{ backgroundColor: "#fff", minHeight: 60 }}
                variant="outlined"
                component="span"
                className={
                  !proofvalidator
                    ? classes.errorstyle
                    : proof.length > 0
                    ? classes.successupload
                    : classes.uploads
                }
              >
                {proof.length > 0 ? proof[0].name : "Copy of Proof of Payment"}
              </Button>
            </label>
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          justify="flex-end"
          style={{ marginBottom: "100px", marginTop: 25 }}
        >
          {showPreviewBtn &&    <Box component="span">
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              // onClick={!formloader ? onClickPaymentButton : null}
              onClick={() => showPreview()}
              style={{ backgroundColor: "#060454", height: "30px" }}
             
              endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
            >
              Preview {formloader ? <CircularProgress size={24} /> : null}
            </Button>
          </Box> }
       
          <Box component="span">
            <Button
              variant="contained"
              color="primary"
              onClick={!formloader ? onClickPaymentButton : null}
              style={{ backgroundColor: "#060454", height: "30px" }}
              className={classes.button}
              endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
            >
              Save & Continue{" "}
              {formloader ? <CircularProgress size={24} /> : null}
            </Button>
          </Box>
        </Grid>
      </Grid>

      <AppBar position="fixed" className={classes.appBarButom} color="#060454">
        <Typography
          variant="span"
          color="inherit"
          noWrap
          style={{ textAlign: "center", color: "#fff", alignItems: "center" }}
          justify="center"
        >
          Copyright © 2022 <b>Oil and Gas Free Zones Authority</b>. All Rights Reserved.
        </Typography>
      </AppBar>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    position: "relative",
  },
  checkboxLabel: {
    fontSize: 14,
  },
  appBarButom: {
    top: "auto",
    bottom: 0,
    height: "40px",
    backgroundColor: "#060454",
    paddingTop: "5px",
    paddingButtom: "5px",
    alignItems: "center",
    justifyContent: "center",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "60%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  errorstyle: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
  errorstylelabel: {
    color: "red",
  },
  uploads: {
    border: "1px dashed #ccc",
  },
  successupload: {
    border: "1px dashed green",
  },
}));

export default Paymentproof;
