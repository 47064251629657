import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LockOutlineIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import oilandgaslogo from "../../assets/oilandgaslogo.png";
import Emailstep1 from "../Emailstep1";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Blue01 from "../stepsbuttons/Blue01.png";
import Blue02 from "../stepsbuttons/Blue02.png";
import Blue03 from "../stepsbuttons/Blue03.png";
import Blue04 from "../stepsbuttons/Blue04.png";
import Blue05 from "../stepsbuttons/Blue05.png";
import Blue06 from "../stepsbuttons/Blue06.png";
import Blue07 from "../stepsbuttons/Blue07.png";
import Blue08 from "../stepsbuttons/Blue08.png";

import Grey01 from "../stepsbuttons/Grey01.png";
import Grey02 from "../stepsbuttons/Grey02.png";
import Grey03 from "../stepsbuttons/Grey03.png";
import Grey04 from "../stepsbuttons/Grey04.png";
import Grey05 from "../stepsbuttons/Grey05.png";
import Grey06 from "../stepsbuttons/Grey06.png";
import Grey07 from "../stepsbuttons/Grey07.png";
import Grey08 from "../stepsbuttons/Grey08.png";

document.body.style = "background: #f5f5f5;";

function Menutab(props) {
  const { onSelectSetter, whichapplication } = props;
  const classes = useStyles();

  console.log(whichapplication, 'jffffffffffffffffffffffffffffff')

  const returnMenuTab = () => {
    if (whichapplication === 1) {
      return (
        <>
          <Grid container component="main" style={{ marginTop: 20 }}>
            <Box>
              <Button
                onClick={() => onSelectSetter(1)}
                className={
                  props.stage == 1 ? classes.step1blue : classes.step1grey
                }
              />
            </Box>
            <Box>
              <Button
                onClick={() => onSelectSetter(2)}
                className={
                  props.stage == 2 ? classes.step2blue : classes.step2grey
                }
              />
            </Box>
            <Box>
              <Button
                onClick={() => onSelectSetter(3)}
                className={
                  props.stage == 3 ? classes.step3blue : classes.step3grey
                }
              />
            </Box>
            <Box>
              <Button
                onClick={() => onSelectSetter(4)}
                className={
                  props.stage == 4 ? classes.step4blue : classes.step4grey
                }
              />
            </Box>
            <Box>
              <Button
                onClick={() => onSelectSetter(5)}
                className={
                  props.stage == 5 ? classes.step5blue : classes.step5grey
                }
              />
            </Box>
            <Box>
              <Button
                onClick={() => onSelectSetter(6)}
                className={
                  props.stage == 6 ? classes.step6blue : classes.step6grey
                }
              />
            </Box>
            <Box>
              <Button
                onClick={() => onSelectSetter(7)}
                className={
                  props.stage == 7 ? classes.step7blue : classes.step7grey
                }
              />
            </Box>
            <Box>
              <Button
                onClick={() => onSelectSetter(8)}
                className={
                  props.stage == 8 ? classes.step8blue : classes.step8grey
                }
              />
            </Box>
          </Grid>
        </>
      );
    } else if (whichapplication === 2) {
      return (
        <>
          <Grid container component="main" style={{ marginTop: 20 }}>
            <Box>
              <Button
                onClick={() => onSelectSetter(1)}
                className={
                  props.stage == 1 ? classes.step1blue : classes.step1grey
                }
              />
            </Box>
            <Box>
              <Button
                onClick={() => onSelectSetter(2)}
                className={
                  props.stage == 2 ? classes.step2blue : classes.step2grey
                }
              />
            </Box>
            <Box>
              <Button
                onClick={() => onSelectSetter(3)}
                className={
                  props.stage == 3 ? classes.step3blue : classes.step3grey
                }
              />
            </Box>
            <Box>
              <Button
                onClick={() => onSelectSetter(4)}
                className={
                  props.stage == 4 ? classes.step4blue : classes.step4grey
                }
              />
            </Box>
            <Box>
              <Button
                onClick={() => onSelectSetter(5)}
                className={
                  props.stage == 5 ? classes.step5blue : classes.step5grey
                }
              />
            </Box>
            {/* <Box>
              <Button
                className={
                  props.stage == 6 ? classes.step6blue : classes.step6grey
                }
              />
            </Box>
            <Box>
              <Button
                className={
                  props.stage == 7 ? classes.step7blue : classes.step7grey
                }
              />
            </Box>
            <Box>
              <Button
                className={
                  props.stage == 8 ? classes.step8blue : classes.step8grey
                }
              />
            </Box> */}
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid container component="main" style={{ marginTop: 20 }}>
            <Box>
              <Button
                onClick={() => onSelectSetter(1)}
                className={
                  props.stage == 1 ? classes.step1blue : classes.step1grey
                }
              />
            </Box>
            <Box>
              <Button
                onClick={() => onSelectSetter(2)}
                className={
                  props.stage == 2 ? classes.step2blue : classes.step2grey
                }
              />
            </Box>
            {/* <Box>
              <Button
                className={
                  props.stage == 3 ? classes.step3blue : classes.step3grey
                }
              />
            </Box>
            <Box>
              <Button
                className={
                  props.stage == 4 ? classes.step4blue : classes.step4grey
                }
              />
            </Box>
            <Box>
              <Button
                className={
                  props.stage == 5 ? classes.step5blue : classes.step5grey
                }
              />
            </Box>
            <Box>
              <Button
                className={
                  props.stage == 6 ? classes.step6blue : classes.step6grey
                }
              />
            </Box>
            <Box>
              <Button
                className={
                  props.stage == 7 ? classes.step7blue : classes.step7grey
                }
              />
            </Box>
            <Box>
              <Button
                className={
                  props.stage == 8 ? classes.step8blue : classes.step8grey
                }
              />
            </Box> */}
          </Grid>
        </>
      );
    }
  };

  return <React.Fragment>{returnMenuTab()}</React.Fragment>;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  step1blue: {
    width: "100px",
    backgroundImage: `url(${Blue01})`,
    backgroundSize: "contain",
    height: "50px",
    backgroundRepeat: "no-repeat",
  },
  step2blue: {
    width: "100px",
    backgroundImage: `url(${Blue02})`,
    backgroundSize: "contain",
    height: "50px",
    backgroundRepeat: "no-repeat",
  },
  step3blue: {
    width: "100px",
    backgroundImage: `url(${Blue03})`,
    backgroundSize: "contain",
    height: "50px",
    backgroundRepeat: "no-repeat",
  },
  step4blue: {
    width: "100px",
    backgroundImage: `url(${Blue04})`,
    backgroundSize: "contain",
    height: "50px",
    backgroundRepeat: "no-repeat",
  },
  step5blue: {
    width: "100px",
    backgroundImage: `url(${Blue05})`,
    backgroundSize: "contain",
    height: "50px",
    backgroundRepeat: "no-repeat",
  },
  step6blue: {
    width: "100px",
    backgroundImage: `url(${Blue06})`,
    backgroundSize: "contain",
    height: "50px",
    backgroundRepeat: "no-repeat",
  },
  step7blue: {
    width: "100px",
    backgroundImage: `url(${Blue07})`,
    backgroundSize: "contain",
    height: "50px",
    backgroundRepeat: "no-repeat",
  },
  step8blue: {
    width: "100px",
    backgroundImage: `url(${Blue08})`,
    backgroundSize: "contain",
    height: "50px",
    backgroundRepeat: "no-repeat",
  },
  step1grey: {
    width: "100px",
    backgroundImage: `url(${Grey01})`,
    backgroundSize: "contain",
    height: "50px",
    backgroundRepeat: "no-repeat",
  },
  step2grey: {
    width: "100px",
    backgroundImage: `url(${Grey02})`,
    backgroundSize: "contain",
    height: "50px",
    backgroundRepeat: "no-repeat",
  },
  step3grey: {
    width: "100px",
    backgroundImage: `url(${Grey03})`,
    backgroundSize: "contain",
    height: "50px",
    backgroundRepeat: "no-repeat",
  },
  step4grey: {
    width: "100px",
    backgroundImage: `url(${Grey04})`,
    backgroundSize: "contain",
    height: "50px",
    backgroundRepeat: "no-repeat",
  },
  step5grey: {
    width: "100px",
    backgroundImage: `url(${Grey05})`,
    backgroundSize: "contain",
    height: "50px",
    backgroundRepeat: "no-repeat",
  },
  step6grey: {
    width: "100px",
    backgroundImage: `url(${Grey06})`,
    backgroundSize: "contain",
    height: "50px",
    backgroundRepeat: "no-repeat",
  },
  step7grey: {
    width: "100px",
    backgroundImage: `url(${Grey07})`,
    backgroundSize: "contain",
    height: "50px",
    backgroundRepeat: "no-repeat",
  },
  step8grey: {
    width: "100px",
    backgroundImage: `url(${Grey08})`,
    backgroundSize: "contain",
    height: "50px",
    backgroundRepeat: "no-repeat",
  },
  appBar: {
    position: "relative",
  },
  checkboxLabel: {
    fontSize: 14,
  },
  appBarButom: {
    top: "auto",
    bottom: 0,
    height: "40px",
    backgroundColor: "blue",
    paddingTop: "5px",
    paddingButtom: "5px",
    alignItems: "center",
    justifyContent: "center",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "60%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

export default Menutab;
