import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Skeleton from '@material-ui/lab/Skeleton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';



function Listskeleton() {
    const UseStyles = makeStyles((theme) => ({
      card: {
        width: "100%",
        margin: theme.spacing(2),
      },
      media: {
        height: 100,
      },
    }));
  const classes = UseStyles();

  return (
      <>
      <List >
        <ListItem key = {1}>
            <ListItemAvatar>

                <Skeleton animation="wave" variant="circle" height={30} width={30} style={{ marginBottom: 6 }} />

            </ListItemAvatar>
            <ListItemText
              primary={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />}
              secondary={<Skeleton animation="wave" height={10} width="60%" style={{ marginBottom: 6 }} />}
            />
            <ListItemSecondaryAction>
                <Skeleton animation="wave" variant="rect" height={30} width={50} style={{ marginBottom: 6 }} />

            </ListItemSecondaryAction>

            </ListItem>

            <ListItem key = {2}>
                <ListItemAvatar>

                  <Skeleton animation="wave" variant="circle" height={30} width={30} style={{ marginBottom: 6 }} />

                </ListItemAvatar>
                <ListItemText
                primary={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />}
                secondary={<Skeleton animation="wave" height={10} width="60%" style={{ marginBottom: 6 }} />}
                />
                <ListItemSecondaryAction>
                  <Skeleton animation="wave" variant="rect" height={30} width={50} style={{ marginBottom: 6 }} />

                </ListItemSecondaryAction>

            </ListItem>

            <ListItem key = {3}>
                <ListItemAvatar>

                    <Skeleton animation="wave" variant="circle" height={30} width={30} style={{ marginBottom: 6 }} />

                </ListItemAvatar>
                <ListItemText
                  primary={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />}
                  secondary={<Skeleton animation="wave" height={10} width="60%" style={{ marginBottom: 6 }} />}
                />
                <ListItemSecondaryAction>
                    <Skeleton animation="wave" variant="rect" height={30} width={50} style={{ marginBottom: 6 }} />

                </ListItemSecondaryAction>

            </ListItem>

        </List>

    </>
  );
}

export default Listskeleton;
