import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LockOutlineIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import oilandgaslogo from "../../assets/oilandgaslogo.png";
import Emailstep1 from "../../components/Emailstep1";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Menutab from "../../components/Tabs/Menutab";
import CircularProgress from "@material-ui/core/CircularProgress";
import txt from "../../txt.png";
import pdf from "../../pdf.png";
import {
  getFormStage2,
  getFormStage3,
  submitFormStage2,
} from "../../Services/apiServices/Form";
import { Modal } from "@material-ui/core";
import FormStagePreview from "../../components/preview/FormStagePreview";
import { Document, Page, pdfjs } from "react-pdf";
document.body.style = "background: #f5f5f5;";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "13px",
      },
    },
  },
})(TextField);

function Applicationform2(props) {
  const {
    snakBarSetter,
    errorSetter,
    formloaderSetter,
    stageSetter,
    rootid,
    stage,
    formloader,
    whichapplication,
  } = props;

  const [serverData, setserverData] = useState(false);
  const [fileinputestage2, setFileinputestage2] = useState({});
  const [descriptionstage2, setDescriptionstage2] = useState("");
  const [fileinputestage2validator, setFileinputestage2validator] =
    useState(true);
  const [descriptionstage2validator, setDescriptionstage2validator] =
    useState(true);
  const [previewData, setpreviewData] = useState();
  const [showPreviewForm, setshowPreviewForm] = useState(false);
  const [formEdit, setformEdit] = useState(false);
  const [previousFIlledData, setpreviousFIlledData] = useState();
  const [imgtype, setImgtype] = React.useState("img");
  const [img, setImg] = React.useState("");
  const [openimg, setOpenimg] = React.useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);

  const classes = useStyles();
  // const onSUbmitStage2 = () =>{
  //     props.onSUbmitStage2()
  // }
  const descriptionOnchangeStage2 = (e) => {
    setDescriptionstage2(e.target.value);
  };
  const fileOnchangeStage2 = (e) => {
    setFileinputestage2(e.target.files);
  };

  const formPreviewSetter = (value) => {
    setshowPreviewForm(value);
  };

  useEffect(() => {
    async function fetchFormData() {
      const response = await getFormStage3(rootid);
      console.log(response, "djjkdjd");
      if (response?.data?.result.document && !formEdit) {
        setpreviousFIlledData(response?.data?.result);

        console.log(response, "kinjdjds");
        setserverData(true);
      }
    }
    fetchFormData();
  }, [serverData]);

  const onPreviewStage2 = async () => {
    formloaderSetter(false);

    const stage2Data = [
      {
        name: "document",
        value: `${fileinputestage2[0].name}`,
        file: fileinputestage2[0],
      },
      { name: "description", value: `${descriptionstage2}` },
    ];

    // setpreviewData(stage2Data)
    if (fileinputestage2[0] === undefined) {
      setFileinputestage2validator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please select a file");
      return false;
    } else {
      setFileinputestage2validator(true);
    }

    if (descriptionstage2.length < 1) {
      setDescriptionstage2validator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Description");
      return false;
    } else {
      setDescriptionstage2validator(true);
    }

    // setshowPreviewForm(true)
    setpreviewData(stage2Data);
    setshowPreviewForm(true);
  };

  const onSUbmitStage2 = async () => {
    formloaderSetter(true);
    const stage2Data = new FormData();

    stage2Data.append("document", fileinputestage2[0]);
    stage2Data.append("root_id", rootid);
    stage2Data.append("description", descriptionstage2);

    if (fileinputestage2[0] === undefined) {
      setFileinputestage2validator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please select a file");
      return false;
    } else {
      setFileinputestage2validator(true);
    }

    if (descriptionstage2.length < 1) {
      setDescriptionstage2validator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Enter Description");
      return false;
    } else {
      setDescriptionstage2validator(true);
    }
    setpreviewData(stage2Data);
    await submitFormStage2(stage2Data).then((res) => {
      if (res.data.status == 1) {
        formloaderSetter(false);
        stageSetter(res.data.result.application_stage);
        console.log("contact_details", stage2Data);
      }
      formloaderSetter(false);
    });
  };

  const onSelectSetter = async (value) => {
    console.log("clicked");
    stageSetter(value);
    const response = await getFormStage3(rootid);
    if (response?.data?.result.totalshares && !formEdit) {
      setpreviousFIlledData(response?.data?.result);

      console.log(response, "kinjdjds");
      setserverData(true);
    }
    //load
    // if data set visibilitiy to true and change display
  };

  const handleCloseImg = () => {
    setImg("");
    setOpenimg(false);
  };
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const showimage = (img) => {
    console.log(img, "nsjssj");
    if (img.split(".").pop() == "pdf") {
      setImgtype("pdf");
      let newimg = "http://api.ogfza-registry.com/uploads/" + img;
      setImg(newimg);
      window.open(
        newimg,
        "sharer",
        "toolbar=no,status=yes,width=900,height=500"
      );

      return false;
    } else {
      setImgtype("img");
    }
    let newimg = "http://api.ogfza-registry.com/uploads/" + img;
    setImg(newimg);
    setOpenimg(true);
    console.log(newimg);
  };

  return (
    <React.Fragment>
      <Modal
        open={openimg}
        onClose={handleCloseImg}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "scroll",
        }}
      >
        <div className={classes.modalview}>
          {imgtype == "pdf" ? (
            <Document file={img} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
          ) : (
            <img src={img} />
          )}
        </div>
      </Modal>
      <Modal
        open={showPreviewForm}
        onClose={() => setshowPreviewForm(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
          overflow: "scroll",
          border: "2px solid blue",
          height: "100vh",
          marginTop: "10px",
          // overflowX: 'visible',
          // backgroundColor:'white'
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            height: "maxContent",
            width: window.innerWidth - 50,
            padding: "1rem 2rem",
            border: "2px solid orange",
          }}
        >
          <FormStagePreview
            data={[previewData]}
            formloaderSetter={formloaderSetter}
            formPreviewSetter={formPreviewSetter}
          />
          <Button
            style={{ marginRight: "1rem" }}
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => {
              setshowPreviewForm(false);
              if (!formloader) {
                return onSUbmitStage2();
              } else {
                return null;
              }
            }}
          >
            {" "}
            Confirm Save and Next{" "}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              formloaderSetter(false);
              setshowPreviewForm(false);
            }}
          >
            {" "}
            No{" "}
          </Button>
        </div>
      </Modal>
      <AppBar position="absolute" color="#ffffff" className={classes.appBar}>
        <Toolbar className={classes.layout}>
          <img
            src={oilandgaslogo}
            alt="Logo"
            style={{
              width: "80px",
              height: "60px",
              textAlign: "center",
              alignSelf: "center",
            }}
          />
        </Toolbar>
      </AppBar>

      <Grid container component="main" className={classes.layout}>
        <Menutab
          onSelectSetter={onSelectSetter}
          whichapplication={whichapplication}
          stage={stage}
        />
        {!serverData ? (
          <>
            <Grid container xs={12} spacing={1} style={{ marginTop: 25 }}>
              <h4> 2. Nature of present business operations:</h4>
              <Grid item xs={12}>
                <TextField
                  id="outlined-full-width"
                  placeholder=""
                  fullWidth
                  value={descriptionstage2}
                  onChange={descriptionOnchangeStage2}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    className: !descriptionstage2validator
                      ? classes.errorstylelabel
                      : null,
                  }}
                  InputProps={{
                    classes: {
                      focused: !descriptionstage2validator
                        ? classes.errorstyle
                        : null,
                      notchedOutline: !descriptionstage2validator
                        ? classes.errorstyle
                        : null,
                    },
                  }}
                  variant="outlined"
                  multiline
                  style={{ backgroundColor: "#fff" }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              spacing={1}
              style={{ marginBottom: "10px" }}
            >
              <h4> 3. DPR Licence / Notorised copy overseas incorporation:</h4>
              <Grid item xs={12} style={{ borderWidth: 1 }}>
                <input
                  accept="image/*,application/pdf"
                  name="document"
                  className={classes.input}
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  onChange={fileOnchangeStage2}
                />
                <label htmlFor="raised-button-file">
                  <Button
                    fullWidth
                    style={{ backgroundColor: "#fff", minHeight: 60 }}
                    variant="outlined"
                    component="span"
                    className={
                      !fileinputestage2validator
                        ? classes.errorstyle
                        : fileinputestage2.length > 0
                        ? classes.successupload
                        : classes.uploads
                    }
                  >
                    {fileinputestage2.length > 0
                      ? fileinputestage2[0].name
                      : "Upload"}
                  </Button>
                </label>
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              justify="flex-end"
              style={{ marginBottom: "100px" }}
            >
              <Box component="span">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={!formloader ? onPreviewStage2 : null}
                  style={{ backgroundColor: "#060454", height: "30px" }}
                  className={classes.button}
                  endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                >
                  Preview & Continue{" "}
                  {formloader ? <CircularProgress size={24} /> : null}
                </Button>
              </Box>
              {/* <Box component="span">
            <Button
              variant="contained"
              color="primary"
              onClick={!formloader ? onSUbmitStage2 : null}
              style={{ backgroundColor: "#060454", height: "30px" }}
              className={classes.button}
              endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
            >
              Save & Continue{" "}
              {formloader ? <CircularProgress size={24} /> : null}
            </Button>
          </Box> */}
            </Grid>
          </>
        ) : (
          <div
            style={{
              backgroundColor: "#fff",
              marginTop: 25,
              padding: 30,
              marginBottom: "7rem",
            }}
          >
            <Grid container>
              {previousFIlledData?.document && (
                <Grid xs={6}>
                  {previousFIlledData?.document?.status === 0 && (
                    <>
                    <div><b>Document</b></div>
                      
                      <img
                        src={
                          previousFIlledData?.document?.parth
                            .split(".")
                            .pop() == "pdf"
                            ? pdf
                            : txt
                        }
                        style={{
                          width: 100,
                          height: 100,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          showimage(
                            previousFIlledData?.document?.parth
                            // e?.signarure
                          );
                        }}
                      />
                    </>
                  )}
                </Grid>
              )}
              {previousFIlledData?.natureofbusiness && (
                <Grid xs={6}>
                  {previousFIlledData?.natureofbusiness?.status === 0 && (
                    <>
                      <label>Company Registered Name</label>
                      <input
                        disabled
                        value={
                          previousFIlledData?.natureofbusiness?.description
                        }
                      />
                    </>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                // onClick={!formloader ? previewStageData : null}
                style={{ backgroundColor: "#060454", height: "30px" }}
                className={classes.button}
                endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                onClick={() => {
                  setformEdit(true);
                  setserverData(false);
                }}
              >
                Refill Form
              </Button>
            </Grid>
          </div>
        )}
      </Grid>
      <AppBar position="fixed" className={classes.appBarButom} color="#060454">
        <Typography
          variant="span"
          color="inherit"
          noWrap
          style={{ textAlign: "center", color: "#fff", alignItems: "center" }}
          justify="center"
        >
          Copyright © 2022 <b>Oil and Gas Free Zones Authority</b>. All Rights Reserved.
        </Typography>
      </AppBar>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    position: "relative",
  },
  checkboxLabel: {
    fontSize: 14,
  },
  appBarButom: {
    top: "auto",
    bottom: 0,
    height: "40px",
    backgroundColor: "#060454",
    paddingTop: "5px",
    paddingButtom: "5px",
    alignItems: "center",
    justifyContent: "center",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "60%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  errorstyle: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
  errorstylelabel: {
    color: "red",
  },
  uploads: {
    border: "1px dashed #ccc",
  },
  successupload: {
    border: "1px dashed green",
  },
}));

export default Applicationform2;
