import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LockOutlineIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import oilandgaslogo from "../../assets/oilandgaslogo.png";
// import Emailstep1 from './Emailstep1';
import Emailstep1 from "../../components/Emailstep1";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import Menutab from './Menutab';
// import Menutab from '../../Menutab';
import Menutab from "../../components/Tabs/Menutab";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import FormStagePreview from "../../components/preview/FormStagePreview";
import { Modal } from "@material-ui/core";
import { getRenewalFormStage3 } from "../../Services/apiServices/Form";

document.body.style = "background: #f5f5f5;";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "13px",
      },
    },
  },
})(TextField);

function Renewaldirectshareinfo(props) {
  const {
    snakBarSetter,
    stageSetter,
    errorSetter,
    formloaderSetter,
    rootid,
    formloader,
    stage,
    whichapplication,
  } = props;

  const classes = useStyles();

  const [stageData, setstageData] = useState();
  const [serverData, setserverData] = useState(false);
  const [formEdit, setformEdit] = useState(false);
  const [previousFIlledData, setpreviousFIlledData] = useState();
  const [renewaldirectInputFieldEntity, setRenewaldirectInputFieldEntity] =
    useState([
      {
        name: "",
        address: "",
        document: "",
        date: "",
      },
    ]);
  const [renewalShareInputFieldNonEntity, setRenewalShareInputFieldNonEntity] =
    useState([
      {
        name: "",
        nationality: "",
        numberofshare: "",
        resident: "",
        address: "",
      },
    ]);
  const [renewaldirectinputFieldNameVal, setRenewaldirectinputFieldNameVal] =
    useState("");
  const [renewaldirectinputFieldDobVal, setRenewaldirectinputFieldDobVal] =
    useState("");
  const [
    renewaldirectinputFieldOccupationVal,
    setRenewaldirectinputFieldOccupationVal,
  ] = useState("");
  const [
    renewaldirectinputFieldAddressVal,
    setRenewaldirectinputFieldAddressVal,
  ] = useState("");
  const [
    renewaldirectinputFielNationalityVal,
    setRenewaldirectinputFielNationalityVal,
  ] = useState("");
  const [
    renewaldirectinputFielEntityNameVal,
    setRenewaldirectinputFielEntityNameVal,
  ] = useState("");
  const [
    renewaldirectinputFielEntityAddressVal,
    setRenewaldirectinputFielEntityAddressVal,
  ] = useState("");
  const [
    renewaldirectinputFielEntityDocumentVal,
    setRenewaldirectinputFielEntityDocumentVal,
  ] = useState("");
  const [
    renewaldirectinputFielEntityDateVal,
    setRenewaldirectinputFielEntityDateVal,
  ] = useState("");

  const [
    renewalShareinputFielEntityNameVal,
    setRenewalShareinputFielEntityNameVal,
  ] = useState("");
  const [
    renewalShareinputFielEntityNationalityVal,
    setRenewalShareinputFielEntityNationalityVal,
  ] = useState("");
  const [
    renewalShareinputFielEntityShareVal,
    setRenewalShareinputFielEntityShareVal,
  ] = useState("");
  const [
    renewalShareinputFielEntityResidentVal,
    setRenewalShareinputFielEntityResidentVal,
  ] = useState("");
  const [
    renewalShareinputFielEntityAddressVal,
    setRenewalShareinputFielEntityAddressVal,
  ] = useState("");

  //Director shareholders information state declaration

  const [
    renewalShareCoporateNameValueval,
    setRenewalShareCoporateNameValueval,
  ] = useState();

  const [renewalShareAddressValueval, setRenewalShareAddressValueval] =
    useState();

  const [
    renewalShareCoporateShareValueval,
    setRenewalShareCoporateShareValueval,
  ] = useState();

  const [
    renewalShareCoporateCountryValueval,
    setRenewalShareCoporateCountryValueval,
  ] = useState();

  const [
    renewaldirectinputFieldNoneEntity,
    setRenewaldirectinputFieldNoneEntity,
  ] = useState([
    {
      name: "",
      dob: "",
      occupation: "",
      address: "",
      nationality: "",
    },
  ]);

  const [renewalShareCoporate, setRenewalShareCoporate] = useState([
    {
      name: "",
      address: "",
      share: "",
      country: "",
    },
  ]);

  const [renewaldirectinputField, setRenewaldirectinputField] = useState([
    {
      namea: "",
      address: "",
      document: "",
      date: "",
    },
  ]);

  const [renewalDirectInputField, setRenewalDirectInputField] = useState([
    {
      name: "",
      nationality: "",
      numberofshare: "",
      resident: "",
      address: "",
    },
  ]);

  const [
    renewaldirectauthorisedsharecapital,
    setRenewaldirectauthorisedsharecapital,
  ] = useState("");
  const [renewaldirectsharedividedinto, setRenewaldirectsharedividedinto] =
    useState("");
  const [renewaldirectsharedivisionratio, setRenewaldirectsharedivisionratio] =
    useState("");
  const [renewaldirectissuedsharecapital, setRenewaldirectissuedsharecapital] =
    useState("");
  const [renewaldirectpaidupcapital, setRenewaldirectpaidupcapital] =
    useState("");
  const [
    renewaldirectauthorisedsharecapitalvalidator,
    setRenewaldirectauthorisedsharecapitalvalidator,
  ] = useState(true);
  const [
    renewaldirectsharedividedintovalidator,
    setRenewaldirectsharedividedintovalidator,
  ] = useState(true);
  const [
    renewaldirectsharedivisionratiovalidator,
    setRenewaldirectsharedivisionratiovalidator,
  ] = useState(true);
  const [
    renewaldirectissuedsharecapitalvalidator,
    setRenewaldirectissuedsharecapitalvalidator,
  ] = useState(true);
  const [
    renewaldirectpaidupcapitalvalidator,
    setRenewaldirectpaidupcapitalvalidator,
  ] = useState(true);

  const [previewData, setpreviewData] = useState();
  const [showPreviewForm, setshowPreviewForm] = useState(false);
  const renewaldirecthandleInputChangeNoneEntity = (index, event) => {
    const values = [...renewaldirectinputFieldNoneEntity];

    if (event.target.name == "name") {
      values[index].name = event.target.value;
    } else if (event.target.name == "address") {
      values[index].address = event.target.value;
    } else if (event.target.name == "nationality") {
      values[index].nationality = event.target.value;
    } else if (event.target.name == "dob") {
      values[index].dob = event.target.value;
    } else {
      values[index].occupation = event.target.value;
    }

    setRenewaldirectinputFieldNoneEntity(values);
  };
  const renewaldirectauthorisedOnchange = (e) => {
    setRenewaldirectauthorisedsharecapital(e.target.value);
  };

  const renewaldirectsharedividedOnchange = (e) => {
    setRenewaldirectsharedividedinto(e.target.value);
  };

  const renewaldirectshareratioOnchange = (e) => {
    setRenewaldirectsharedivisionratio(e.target.value);
  };

  const renewaldirectissuedcapitalOnchange = (e) => {
    setRenewaldirectissuedsharecapital(e.target.value);
  };

  const renewaldirectpaidupcapitalOnchange = (e) => {
    setRenewaldirectpaidupcapital(e.target.value);
  };

  const renewalShareCoporateAddFields = () => {
    const values = [...renewalShareCoporate];
    values.push({
      name: "",
      address: "",
      share: "",
      country: "",
    });
    setRenewalShareCoporate(values);
  };

  const renewalShareCoporateRemoveFields = (index) => {
    const values = [...renewalShareCoporate];
    let size = Object.keys(values).length;
    if (size == 1) {
      alert("Please note you cant remove this as you must enter a value ");
      return false;
    }

    values.splice(index, 1);
    setRenewalShareCoporate(values);
  };

  const renewaldirecthandleRemoveFieldsNoneEntity = (index) => {
    const values = [...renewaldirectinputFieldNoneEntity];
    let size = Object.keys(values).length;
    if (size == 1) {
      alert("Please note you cant remove this as you must enter a value ");
      return false;
    }

    values.splice(index, 1);
    setRenewaldirectinputFieldNoneEntity(values);
  };
  const renewaldirecthandleAddFieldsNoneEntity = () => {
    const values = [...renewaldirectinputFieldNoneEntity];
    values.push({
      name: "",
      address: "",
      nationality: "",
      dob: "",
      occupation: "",
    });
    setRenewaldirectinputFieldNoneEntity(values);
  };
  const renewaldirectHandleInputChangeEntity = (index, event) => {
    // baseee
    const values = [...renewaldirectInputFieldEntity];
    console.log(index);
    if (event.target.name == "name") {
      values[index].name = event.target.value;
    } else if (event.target.name == "address") {
      values[index].address = event.target.value;
    } else if (event.target.name == "date") {
      values[index].date = event.target.value;
    } else {
      values[index].document = event.target.files;
    }

    setRenewaldirectInputFieldEntity(values);
  };

  const renewalShareCoporateInputChange = (index, event) => {
    const values = [...renewalShareCoporate];

    if (event.target.name == "name") {
      values[index].name = event.target.value;
    } else if (event.target.name == "share") {
      values[index].share = event.target.value;
    } else if (event.target.name == "address") {
      values[index].address = event.target.value;
    } else {
      values[index].country = event.target.value;
    }
    setRenewalShareCoporate(values);
  };

  const renewaldirectHandleRemoveFieldsEntity = (index) => {
    const values = [...renewaldirectInputFieldEntity];
    let size = Object.keys(values).length;
    if (size == 1) {
      alert("Please note you cant remove this as you must enter a value ");
      return false;
    }

    values.splice(size - 1, 1);
    setRenewaldirectInputFieldEntity(values);
  };

  const renewaldirectHandleAddFieldsEntity = () => {
    const values = [...renewaldirectInputFieldEntity];
    values.push({
      name: "",
      address: "",
      document: "",
      date: "",
    });
    setRenewaldirectInputFieldEntity(values);
  };

  const renewalShareInputFieldChangeNonEntity = (index, event) => {
    const values = [...renewalShareInputFieldNonEntity];
    if (event.target.name == "name") {
      values[index].name = event.target.value;
    } else if (event.target.name == "address") {
      values[index].address = event.target.value;
    } else if (event.target.name == "numberofshare") {
      values[index].numberofshare = event.target.value;
    } else if (event.target.name == "resident") {
      values[index].resident = event.target.value;
    } else {
      values[index].nationality = event.target.value;
    }

    setRenewalShareInputFieldNonEntity(values);
  };
  const renewalShareInputFieldNonEntityRemoveFields = (index) => {
    const values = [...renewalShareInputFieldNonEntity];
    let size = Object.keys(values).length;
    if (size == 1) {
      alert("Please note you cant remove this as you must enter a value ");
      return false;
    }

    values.splice(size - 1, 1);
    setRenewalShareInputFieldNonEntity(values);
  };

  const formPreviewSetter = (value) => {
    setshowPreviewForm(value);
  };

  useEffect(() => {
    async function fetchFormData() {
      const response = await getRenewalFormStage3(rootid);

      if (
        response?.data?.result.renewaldirectorparticulars?.length !== 0 &&
        !formEdit
      ) {
        setpreviousFIlledData(response?.data?.result);
        setserverData(true);
      }
    }
    fetchFormData();
  }, [serverData]);

  const renewalShareInputFieldNonEntitysAddFields = () => {
    const values = [...renewalShareInputFieldNonEntity];
    values.push({
      name: "",
      nationality: "",
      numberofshare: "",
      address: "",
      resident: "",
    });
    setRenewalShareInputFieldNonEntity(values);
  };

  const companyDirectPreview = async (value) => {
    //formloaderSetter(true)
    if (renewaldirectauthorisedsharecapital.length < 1) {
      setRenewaldirectauthorisedsharecapitalvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Designation cant be empty");
      return false;
    } else {
      setRenewaldirectauthorisedsharecapitalvalidator(true);
    }

    if (renewaldirectsharedividedinto.length < 1) {
      setRenewaldirectsharedividedintovalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Designation cant be empty");
      return false;
    } else {
      setRenewaldirectsharedividedintovalidator(true);
    }

    if (renewaldirectsharedivisionratio.length < 1) {
      setRenewaldirectsharedivisionratiovalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Designation cant be empty");
      return false;
    } else {
      setRenewaldirectsharedivisionratiovalidator(true);
    }

    if (renewaldirectissuedsharecapital.length < 1) {
      setRenewaldirectissuedsharecapitalvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Designation cant be empty");
      return false;
    } else {
      setRenewaldirectissuedsharecapitalvalidator(true);
    }

    if (renewaldirectpaidupcapital.length < 1) {
      setRenewaldirectpaidupcapitalvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Designation cant be empty");
      return false;
    } else {
      setRenewaldirectpaidupcapitalvalidator(true);
    }

    const directorShears = new FormData();

    const merge = [
      { nameauthorised_shares_capital: renewaldirectauthorisedsharecapital },
      { dividend_into: renewaldirectsharedividedinto },
      { dividend_ratio: renewaldirectsharedivisionratio },
      { issued_share_capital: renewaldirectissuedsharecapital },
      { paid_up_capital: renewaldirectpaidupcapital },
    ];

    directorShears.append("renewal_id", rootid);
    directorShears.append(
      "nonentity",
      JSON.stringify(renewaldirectinputFieldNoneEntity)
    );
    directorShears.append(
      "entity",
      JSON.stringify(renewaldirectInputFieldEntity)
    );
    directorShears.append("renewalShareDetails", JSON.stringify(merge));
    directorShears.append(
      "renewalShareholderDetails",
      JSON.stringify(renewalShareInputFieldNonEntity)
    );
    directorShears.append(
      "renewalShareholderDetailsCooporate",
      JSON.stringify(renewalShareCoporate)
    );

    if (
      renewaldirectInputFieldEntity.length > 0 &&
      renewaldirectInputFieldEntity[0].name.length > 0
    ) {
      renewaldirectInputFieldEntity.map((value, key) => {
        console.log(value.document[0]);
        directorShears.append("entitydocument" + key, value.document[0]);
      });
    }

    formloaderSetter(false);

    const directorShareData = [
      // { name: "phone", value: `${renewalcompanybasicinformationphone}` },
      {
        name: "nonentity",
        value: `imgInputArra`,
        imgInputArra: true,
        imgInputArraData: renewaldirectinputFieldNoneEntity,
      },

      { name: "renewalShareDetails", value: `${merge}`, arrayInputs: merge },
      {
        name: "renewalShareholderDetails",
        value: `imgInputArra`,
        imgInputArra: true,
        imgInputArraData: renewalShareInputFieldNonEntity,
      },
      {
        name: "renewalShareholderDetailsCooporate",
        value: `imgInputArra`,
        imgInputArra: true,
        imgInputArraData: renewalShareCoporate,
      },
      {
        name: "entitydocument",
        value: `imgInputArra`,
        imgInputArra: true,
        imgInputArraData: renewaldirectInputFieldEntity,
      },
    ];

    console.log(directorShareData, "directorShears");
    // await axios
    //   .post(
    //     "https://ogfzaapi.skillzserver.com/aplicationrenewaldirectorshareholderdetails/create",
    //     directorShears
    //   )
    //   .then((res) => {
    //     formloaderSetter(false);
    //     if (res.data.status == 1) {
    //       stageSetter(res.data.result.application_stage);
    //     }
    //     console.log(res.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     formloaderSetter(false);
    //   });

    setshowPreviewForm(true);
    setpreviewData(directorShareData);
  };

  const companyDirectSubmit = async (value) => {
    //formloaderSetter(true)
    if (renewaldirectauthorisedsharecapital.length < 1) {
      setRenewaldirectauthorisedsharecapitalvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Designation cant be empty");
      return false;
    } else {
      setRenewaldirectauthorisedsharecapitalvalidator(true);
    }

    if (renewaldirectsharedividedinto.length < 1) {
      setRenewaldirectsharedividedintovalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Designation cant be empty");
      return false;
    } else {
      setRenewaldirectsharedividedintovalidator(true);
    }

    if (renewaldirectsharedivisionratio.length < 1) {
      setRenewaldirectsharedivisionratiovalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Designation cant be empty");
      return false;
    } else {
      setRenewaldirectsharedivisionratiovalidator(true);
    }

    if (renewaldirectissuedsharecapital.length < 1) {
      setRenewaldirectissuedsharecapitalvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Designation cant be empty");
      return false;
    } else {
      setRenewaldirectissuedsharecapitalvalidator(true);
    }

    if (renewaldirectpaidupcapital.length < 1) {
      setRenewaldirectpaidupcapitalvalidator(false);
      formloaderSetter(false);
      window.scrollTo(0, 0);
      snakBarSetter(true);
      errorSetter("Please Designation cant be empty");
      return false;
    } else {
      setRenewaldirectpaidupcapitalvalidator(true);
    }

    const directorShears = new FormData();

    const merge = {
      nameauthorised_shares_capital: renewaldirectauthorisedsharecapital,
      dividend_into: renewaldirectsharedividedinto,
      dividend_ratio: renewaldirectsharedivisionratio,
      issued_share_capital: renewaldirectissuedsharecapital,
      paid_up_capital: renewaldirectpaidupcapital,
    };

    directorShears.append("renewal_id", rootid);
    directorShears.append(
      "nonentity",
      JSON.stringify(renewaldirectinputFieldNoneEntity)
    );
    directorShears.append(
      "entity",
      JSON.stringify(renewaldirectInputFieldEntity)
    );
    directorShears.append("renewalShareDetails", JSON.stringify(merge));
    directorShears.append(
      "renewalShareholderDetails",
      JSON.stringify(renewalShareInputFieldNonEntity)
    );
    directorShears.append(
      "renewalShareholderDetailsCooporate",
      JSON.stringify(renewalShareCoporate)
    );

    if (
      renewaldirectInputFieldEntity.length > 0 &&
      renewaldirectInputFieldEntity[0].name.length > 0
    ) {
      renewaldirectInputFieldEntity.map((value, key) => {
        console.log(value.document[0]);
        directorShears.append("entitydocument" + key, value.document[0]);
      });
    }

    await axios
      .post(
        "https://ogfzaapi.skillzserver.com/aplicationrenewaldirectorshareholderdetails/create",
        directorShears
      )
      .then((res) => {
        formloaderSetter(false);
        if (res.data.status == 1) {
          stageSetter(res.data.result.application_stage);
        }
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
        formloaderSetter(false);
      });
  };

  const onSelectSetter = async (value) => {
    console.log("clicked");
    stageSetter(value);
    const response = await getRenewalFormStage3(rootid);
    if (
      response?.data?.result.renewaldirectorparticulars?.length !== 0 &&
      !formEdit
    ) {
      setpreviousFIlledData(response?.data?.result);
      setserverData(true);
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={showPreviewForm}
        onClose={() => setshowPreviewForm(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
          overflow: "scroll",
          border: "2px solid blue",
          height: "100vh",
          marginTop: "10px",
          // overflowX: 'visible',
          // backgroundColor:'white'
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            height: "maxContent",
            width: window.innerWidth - 50,
            padding: "1rem 2rem",
            border: "2px solid orange",
          }}
        >
          <FormStagePreview
            data={[previewData]}
            formloaderSetter={formloaderSetter}
            formPreviewSetter={formPreviewSetter}
          />
          <Button
            style={{ marginRight: "1rem" }}
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => {
              setshowPreviewForm(false);
              if (!formloader) {
                return companyDirectSubmit();
              } else {
                return null;
              }
            }}
          >
            {" "}
            Confirm Save and Next{" "}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              formloaderSetter(false);
              setshowPreviewForm(false);
            }}
          >
            {" "}
            No{" "}
          </Button>
        </div>
      </Modal>
      <AppBar position="absolute" color="#ffffff" className={classes.appBar}>
        <Toolbar className={classes.layout}>
          <img
            src={oilandgaslogo}
            alt="Logo"
            style={{
              width: "80px",
              height: "60px",
              textAlign: "center",
              alignSelf: "center",
            }}
          />
        </Toolbar>
      </AppBar>

      <Grid container component="main" className={classes.layout}>
        {/* <Menutab stage={stage} /> */}
        <Menutab
          onSelectSetter={onSelectSetter}
          whichapplication={whichapplication}
          stage={stage}
        />

        {!serverData ? (
          <>
            <Grid item xs={12}>
              <h4> Directors/Shareholder Information</h4>
            </Grid>

            <Grid
              container
              xs={12}
              spacing={0}
              style={{
                backgroundColor: "#fff",
                padding: 30,
                marginTop: "50px",
              }}
            >
              <h4 style={{ position: "absolute", top: "265px", margin:'1rem 0' }}>
                {" "}
                DIRECTORS PARTICULARS:
              </h4>

              <Grid container xs={12} spacing={1}>
                <Grid item xs={12}>
                  <h4 style={{ marginTop: "2rem" }}>
                    {" "}
                    Director Non Coorporate - Entity
                  </h4>
                </Grid>
                {renewaldirectinputFieldNoneEntity.map(
                  (renewaldirectinputField, index) => (
                    <>
                      <Grid item xs={6}>
                        <TextField
                          id="outlined-full-width"
                          placeholder="Name"
                          name="name"
                          fullWidth
                          multiline
                          margin="normal"
                          value={renewaldirectinputField.name}
                          onChange={(event) =>
                            renewaldirecthandleInputChangeNoneEntity(
                              index,
                              event
                            )
                          }
                          InputProps={{
                           
                            classes: {
                              focused:
                                renewaldirectinputFieldNameVal == index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewaldirectinputFieldNameVal == index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        {/* <label>Date of Birth</label> */}
                        <TextField
                          id="outlined-full-width"
                          type="date"
                          name="dob"
                          label='Date of birth'
                          value={renewaldirectinputField.dob}
                          onChange={(event) =>
                            renewaldirecthandleInputChangeNoneEntity(
                              index,
                              event
                            )
                          }
                          fullWidth
                          style={{ backgroundColor: "#fff" }}
                          margin="normal"
                          // InputLabelProps={{ shrink: true }}
                          InputLabelProps={{
                            label:'Date of birth',
                            shrink: true,
                            className: !renewaldirectinputFieldDobVal
                              ? classes.errorstylelabel
                              : null,
                          }}
                          InputProps={{
                            placeholder: "Date of birth",
                            classes: {
                              focused:
                                renewaldirectinputFieldDobVal == index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewaldirectinputFieldDobVal == index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          id="outlined-full-width"
                          placeholder="Occupation"
                          name="occupation"
                          fullWidth
                          multiline
                          margin="normal"
                          value={renewaldirectinputField.occupation}
                          onChange={(event) =>
                            renewaldirecthandleInputChangeNoneEntity(
                              index,
                              event
                            )
                          }
                          InputProps={{
                            classes: {
                              focused:
                                renewaldirectinputFieldOccupationVal ==
                                index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewaldirectinputFieldOccupationVal ==
                                index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="outlined-full-width"
                          placeholder="Address"
                          name="address"
                          fullWidth
                          multiline
                          margin="normal"
                          value={renewaldirectinputField.address}
                          onChange={(event) =>
                            renewaldirecthandleInputChangeNoneEntity(
                              index,
                              event
                            )
                          }
                          InputProps={{
                            classes: {
                              focused:
                                renewaldirectinputFieldAddressVal == index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewaldirectinputFieldAddressVal == index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="outlined-full-width"
                          placeholder="Nationality"
                          name="nationality"
                          fullWidth
                          multiline
                          margin="normal"
                          value={renewaldirectinputField.nationality}
                          onChange={(event) =>
                            renewaldirecthandleInputChangeNoneEntity(
                              index,
                              event
                            )
                          }
                          InputProps={{
                            classes: {
                              focused:
                                renewaldirectinputFielNationalityVal ==
                                index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewaldirectinputFielNationalityVal ==
                                index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                          variant="outlined"
                        />
                      </Grid>
                    </>
                  )
                )}
              </Grid>

              <Grid
                container
                xs={12}
                justify="flex-end"
                style={{ marginBottom: "30px" }}
              >
                <Box component="span" mx={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#ccc",
                      height: "30px",
                      color: "black",
                    }}
                    className={classes.button}
                    onClick={renewaldirecthandleRemoveFieldsNoneEntity}
                    startIcon={
                      <ArrowBackIcon
                        style={{ fontSize: "20px", color: "black" }}
                      />
                    }
                  >
                    REMOVE ADD
                  </Button>
                </Box>

                <Box component="span">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: "#060454", height: "30px" }}
                    className={classes.button}
                    onClick={renewaldirecthandleAddFieldsNoneEntity}
                    endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                  >
                    ADD MORE
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <h4> Entity</h4>
              </Grid>
              <Grid container xs={12} spacing={1}>
                {renewaldirectInputFieldEntity.map(
                  (renewaldirectInputField, index) => (
                    <>
                      <Grid item xs={3}>
                        <TextField
                          id="outlined-full-width"
                          placeholder="Name"
                          name="name"
                          fullWidth
                          multiline
                          margin="normal"
                          value={renewaldirectInputField.name}
                          onChange={(event) =>
                            renewaldirectHandleInputChangeEntity(index, event)
                          }
                          InputProps={{
                            classes: {
                              focused:
                                renewaldirectinputFielEntityNameVal == index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewaldirectinputFielEntityNameVal == index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <TextField
                          variant="outlined"
                          id="outlined-full-width"
                          placeholder="Address"
                          name="address"
                          fullWidth
                          multiline
                          margin="normal"
                          value={renewaldirectInputField.address}
                          onChange={(event) =>
                            renewaldirectHandleInputChangeEntity(index, event)
                          }
                          InputProps={{
                            classes: {
                              focused:
                                renewaldirectinputFielEntityAddressVal ==
                                index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewaldirectinputFielEntityAddressVal ==
                                index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <input
                          // accept="image/*"
                          className={classes.input}
                          style={{ display: "none" }}
                          id={"document" + index}
                          multiple
                          type="file"
                          name="document"
                          onChange={(event) =>
                            renewaldirectHandleInputChangeEntity(index, event)
                          }
                        />
                        <label htmlFor={"document" + index}>
                          <Button
                            fullWidth
                            style={{
                              backgroundColor: "#fff",
                              minHeight: 55,
                              marginTop: 15,
                            }}
                            variant="outlined"
                            component="span"
                            className={
                              renewaldirectinputFielEntityDocumentVal ==
                              index + 1
                                ? classes.errorstyle
                                : classes.uploads
                            }
                          >
                            {renewaldirectInputField.document.length > 0
                              ? renewaldirectInputField.document[0].name
                              : "Upload Document"}
                          </Button>
                        </label>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          id="outlined-full-width"
                          placeholder="Placeholder"
                          style={{ backgroundColor: "#fff" }}
                          name="date"
                          type="date"
                          fullWidth
                          margin="normal"
                          value={renewaldirectInputField.date}
                          onChange={(event) =>
                            renewaldirectHandleInputChangeEntity(index, event)
                          }
                          InputLabelProps={{
                            shrink: true,
                            className: !renewaldirectinputFielEntityDateVal
                              ? classes.errorstylelabel
                              : null,
                          }}
                          InputProps={{
                            classes: {
                              focused:
                                renewaldirectinputFielEntityDateVal == index + 1
                                  ? classes.errorstyle
                                  : null,
                              notchedOutline:
                                renewaldirectinputFielEntityDateVal == index + 1
                                  ? classes.errorstyle
                                  : null,
                            },
                          }}
                          variant="outlined"
                        />
                      </Grid>
                    </>
                  )
                )}
              </Grid>

              <Grid
                container
                xs={12}
                justify="flex-end"
                style={{ marginBottom: "30px" }}
              >
                <Box component="span" mx={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#ccc",
                      height: "30px",
                      color: "black",
                    }}
                    className={classes.button}
                    onClick={renewaldirectHandleRemoveFieldsEntity}
                    startIcon={
                      <ArrowBackIcon
                        style={{ fontSize: "20px", color: "black" }}
                      />
                    }
                  >
                    REMOVE ADD
                  </Button>
                </Box>

                <Box component="span">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: "#060454", height: "30px" }}
                    className={classes.button}
                    onClick={renewaldirectHandleAddFieldsEntity}
                    endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                  >
                    ADD MORE
                  </Button>
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              spacing={0}
              style={{
                backgroundColor: "#fff",
                padding: 30,
                marginTop: "50px",
              }}
            >
              <h4 style={{ position: "absolute", top: "935px" }}>
                Shareholders Particulars:
              </h4>

              <Grid container xs={12} spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-full-width"
                    placeholder="Authorised Share Capital"
                    name="authorised"
                    fullWidth
                    multiline
                    value={renewaldirectauthorisedsharecapital}
                    onChange={renewaldirectauthorisedOnchange}
                    margin="normal"
                    style={{ backgroundColor: "#fff" }}
                    InputLabelProps={{
                      shrink: true,
                      className: !renewaldirectauthorisedsharecapitalvalidator
                        ? classes.errorstylelabel
                        : null,
                    }}
                    InputProps={{
                      classes: {
                        focused: !renewaldirectauthorisedsharecapitalvalidator
                          ? classes.errorstyle
                          : null,
                        notchedOutline:
                          !renewaldirectauthorisedsharecapitalvalidator
                            ? classes.errorstyle
                            : null,
                      },
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    id="outlined-full-width"
                    placeholder="Authorized Share Capital Divided Into"
                    name="sharedividedinto"
                    fullWidth
                    multiline
                    value={renewaldirectsharedividedinto}
                    onChange={renewaldirectsharedividedOnchange}
                    margin="normal"
                    style={{ backgroundColor: "#fff" }}
                    InputLabelProps={{
                      shrink: true,
                      className: !renewaldirectsharedividedintovalidator
                        ? classes.errorstylelabel
                        : null,
                    }}
                    InputProps={{
                      classes: {
                        focused: !renewaldirectsharedividedintovalidator
                          ? classes.errorstyle
                          : null,
                        notchedOutline: !renewaldirectsharedividedintovalidator
                          ? classes.errorstyle
                          : null,
                      },
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    id="outlined-full-width"
                    placeholder="Share Division Ratio"
                    name="Sharedivisionratio"
                    fullWidth
                    multiline
                    value={renewaldirectsharedivisionratio}
                    onChange={renewaldirectshareratioOnchange}
                    margin="normal"
                    style={{ backgroundColor: "#fff" }}
                    InputLabelProps={{
                      shrink: true,
                      className: !renewaldirectsharedivisionratiovalidator
                        ? classes.errorstylelabel
                        : null,
                    }}
                    InputProps={{
                      classes: {
                        focused: !renewaldirectsharedivisionratiovalidator
                          ? classes.errorstyle
                          : null,
                        notchedOutline:
                          !renewaldirectsharedivisionratiovalidator
                            ? classes.errorstyle
                            : null,
                      },
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="outlined-full-width"
                    placeholder="Issued share capital of the company"
                    name="issuedsharecapital"
                    fullWidth
                    multiline
                    value={renewaldirectissuedsharecapital}
                    onChange={renewaldirectissuedcapitalOnchange}
                    margin="normal"
                    style={{ backgroundColor: "#fff" }}
                    InputLabelProps={{
                      shrink: true,
                      className: !renewaldirectissuedsharecapitalvalidator
                        ? classes.errorstylelabel
                        : null,
                    }}
                    InputProps={{
                      classes: {
                        focused: !renewaldirectissuedsharecapitalvalidator
                          ? classes.errorstyle
                          : null,
                        notchedOutline:
                          !renewaldirectissuedsharecapitalvalidator
                            ? classes.errorstyle
                            : null,
                      },
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    id="outlined-full-width"
                    placeholder="Total Paid Up Capital"
                    name="paidupcapital"
                    fullWidth
                    multiline
                    value={renewaldirectpaidupcapital}
                    onChange={renewaldirectpaidupcapitalOnchange}
                    margin="normal"
                    style={{ backgroundColor: "#fff" }}
                    InputLabelProps={{
                      shrink: true,
                      className: !renewaldirectpaidupcapitalvalidator
                        ? classes.errorstylelabel
                        : null,
                    }}
                    InputProps={{
                      classes: {
                        focused: !renewaldirectpaidupcapitalvalidator
                          ? classes.errorstyle
                          : null,
                        notchedOutline: !renewaldirectpaidupcapitalvalidator
                          ? classes.errorstyle
                          : null,
                      },
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid container xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <h4>Present Members/Shareholders (Note: a resident is defined as any citizen or non-citizen living in Nigeria for one year and above)</h4>
                  </Grid>
                  {renewalShareInputFieldNonEntity.map(
                    (renewalShareInputFieldNonEntitys, index) => (
                      <>
                        <Grid item xs={6}>
                          <TextField
                            id="outlined-full-width"
                            placeholder="Name"
                            name="name"
                            fullWidth
                            multiline
                            margin="normal"
                            value={renewalShareInputFieldNonEntitys.name}
                            onChange={(event) =>
                              renewalShareInputFieldChangeNonEntity(
                                index,
                                event
                              )
                            }
                            InputProps={{
                              classes: {
                                focused:
                                  renewalShareinputFielEntityNameVal ==
                                  index + 1
                                    ? classes.errorstyle
                                    : null,
                                notchedOutline:
                                  renewalShareinputFielEntityNameVal ==
                                  index + 1
                                    ? classes.errorstyle
                                    : null,
                              },
                            }}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Nationality"
                            name="nationality"
                            fullWidth
                            multiline
                            margin="normal"
                            value={renewalShareInputFieldNonEntitys.nationality}
                            onChange={(event) =>
                              renewalShareInputFieldChangeNonEntity(
                                index,
                                event
                              )
                            }
                            InputProps={{
                              classes: {
                                focused:
                                  renewalShareinputFielEntityNationalityVal ==
                                  index + 1
                                    ? classes.errorstyle
                                    : null,
                                notchedOutline:
                                  renewalShareinputFielEntityNationalityVal ==
                                  index + 1
                                    ? classes.errorstyle
                                    : null,
                              },
                            }}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            id="outlined-full-width"
                            placeholder="Number of share"
                            name="numberofshare"
                            fullWidth
                            multiline
                            margin="normal"
                            value={
                              renewalShareInputFieldNonEntitys.number_of_shares
                            }
                            onChange={(event) =>
                              renewalShareInputFieldChangeNonEntity(
                                index,
                                event
                              )
                            }
                            InputProps={{
                              classes: {
                                focused:
                                  renewalShareinputFielEntityShareVal ==
                                  index + 1
                                    ? classes.errorstyle
                                    : null,
                                notchedOutline:
                                  renewalShareinputFielEntityShareVal ==
                                  index + 1
                                    ? classes.errorstyle
                                    : null,
                              },
                            }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="outlined-full-width"
                            placeholder="Resident"
                            name="resident"
                            fullWidth
                            multiline
                            margin="normal"
                            value={renewalShareInputFieldNonEntitys.resident}
                            onChange={(event) =>
                              renewalShareInputFieldChangeNonEntity(
                                index,
                                event
                              )
                            }
                            InputProps={{
                              classes: {
                                focused:
                                  renewalShareinputFielEntityResidentVal ==
                                  index + 1
                                    ? classes.errorstyle
                                    : null,
                                notchedOutline:
                                  renewalShareinputFielEntityResidentVal ==
                                  index + 1
                                    ? classes.errorstyle
                                    : null,
                              },
                            }}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            id="outlined-full-width"
                            placeholder="Address"
                            name="address"
                            fullWidth
                            multiline
                            margin="normal"
                            value={renewalShareInputFieldNonEntitys.address}
                            onChange={(event) =>
                              renewalShareInputFieldChangeNonEntity(
                                index,
                                event
                              )
                            }
                            InputProps={{
                              classes: {
                                focused:
                                  renewalShareinputFielEntityAddressVal ==
                                  index + 1
                                    ? classes.errorstyle
                                    : null,
                                notchedOutline:
                                  renewalShareinputFielEntityAddressVal ==
                                  index + 1
                                    ? classes.errorstyle
                                    : null,
                              },
                            }}
                            variant="outlined"
                          />
                        </Grid>
                      </>
                    )
                  )}
                </Grid>

                <Grid
                  container
                  xs={12}
                  justify="flex-end"
                  style={{ marginBottom: "30px" }}
                >
                  <Box component="span" mx={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor: "#ccc",
                        height: "30px",
                        color: "black",
                      }}
                      className={classes.button}
                      onClick={renewalShareInputFieldNonEntityRemoveFields}
                      startIcon={
                        <ArrowBackIcon
                          style={{ fontSize: "20px", color: "black" }}
                        />
                      }
                    >
                      REMOVE ADD
                    </Button>
                  </Box>

                  <Box component="span">
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ backgroundColor: "#060454", height: "30px" }}
                      className={classes.button}
                      onClick={renewalShareInputFieldNonEntitysAddFields}
                      endIcon={
                        <ArrowForwardIcon style={{ fontSize: "20px" }} />
                      }
                    >
                      ADD MORE
                    </Button>
                  </Box>
                </Grid>

                {
                  // start area for  cooporate entiry
                }

                <Grid container xs={12} spacing={1}>
                  <Grid item xs={12} style={{ marginBottom: "10px" }}>
                    <h4>Where the Shareholders above are corporate entities, state the country of incorporation</h4>
                  </Grid>
                  {renewalShareCoporate.map(
                    (renewalShareCoporateValue, index) => (
                      <Grid
                        container
                        xs={12}
                        spacing={1}
                        style={{
                          border: "solid 1px #000",
                          marginBottom: "40px",
                          borderRadius: "20px",
                        }}
                      >
                        <Grid item xs={12}>
                          <Box
                            style={{
                              position: "absolute",
                              marginTop: "-34px",
                              background: "#fff",
                            }}
                            component="span"
                            mx={1}
                          >
                            <h4>
                              Share Holder Corporate - Entity {index + 1}{" "}
                            </h4>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="outlined-full-width"
                            placeholder="Name"
                            name="name"
                            fullWidth
                            multiline
                            margin="normal"
                            value={renewalShareCoporateValue.name}
                            onChange={(event) =>
                              renewalShareCoporateInputChange(index, event)
                            }
                            InputProps={{
                              classes: {
                                focused:
                                  renewalShareCoporateNameValueval == index + 1
                                    ? classes.errorstyle
                                    : null,
                                notchedOutline:
                                  renewalShareCoporateNameValueval == index + 1
                                    ? classes.errorstyle
                                    : null,
                              },
                            }}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            variant="outlined"
                            id="outlined-full-width"
                            placeholder="Address"
                            name="address"
                            fullWidth
                            multiline
                            margin="normal"
                            value={renewalShareCoporateValue.address}
                            onChange={(event) =>
                              renewalShareCoporateInputChange(index, event)
                            }
                            InputProps={{
                              classes: {
                                focused:
                                  renewalShareAddressValueval == index + 1
                                    ? classes.errorstyle
                                    : null,
                                notchedOutline:
                                  renewalShareAddressValueval == index + 1
                                    ? classes.errorstyle
                                    : null,
                              },
                            }}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            id="outlined-full-width"
                            placeholder="Number of share"
                            name="share"
                            fullWidth
                            multiline
                            margin="normal"
                            value={renewalShareCoporateValue.share}
                            onChange={(event) =>
                              renewalShareCoporateInputChange(index, event)
                            }
                            InputProps={{
                              classes: {
                                focused:
                                  renewalShareCoporateShareValueval == index + 1
                                    ? classes.errorstyle
                                    : null,
                                notchedOutline:
                                  renewalShareCoporateShareValueval == index + 1
                                    ? classes.errorstyle
                                    : null,
                              },
                            }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="outlined-full-width"
                            placeholder="Country"
                            name="country"
                            fullWidth
                            multiline
                            margin="normal"
                            value={renewalShareCoporateValue.country}
                            onChange={(event) =>
                              renewalShareCoporateInputChange(index, event)
                            }
                            InputProps={{
                              classes: {
                                focused:
                                  renewalShareCoporateCountryValueval ==
                                  index + 1
                                    ? classes.errorstyle
                                    : null,
                                notchedOutline:
                                  renewalShareCoporateCountryValueval ==
                                  index + 1
                                    ? classes.errorstyle
                                    : null,
                              },
                            }}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    )
                  )}
                </Grid>

                <Grid
                  container
                  xs={12}
                  justify="flex-end"
                  style={{ marginBottom: "30px" }}
                >
                  <Box component="span" mx={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor: "#ccc",
                        height: "30px",
                        color: "black",
                      }}
                      className={classes.button}
                      onClick={renewalShareCoporateRemoveFields}
                      startIcon={
                        <ArrowBackIcon
                          style={{ fontSize: "20px", color: "black" }}
                        />
                      }
                    >
                      REMOVE ADD
                    </Button>
                  </Box>

                  <Box component="span">
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ backgroundColor: "#060454", height: "30px" }}
                      className={classes.button}
                      onClick={renewalShareCoporateAddFields}
                      endIcon={
                        <ArrowForwardIcon style={{ fontSize: "20px" }} />
                      }
                    >
                      ADD MORE
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              justify="flex-end"
              style={{ marginBottom: "50px" }}
            >
              <Box component="span">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={!formloader ? companyDirectPreview : null}
                  // onClick={!formloader ? companyDirectSubmit : null}
                  style={{
                    backgroundColor: "#060454",
                    height: "30px",
                    marginTop: "15px",
                    marginRight: "255px",
                  }}
                  className={classes.button}
                  endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                >
                  Preview & Continue{" "}
                  {formloader ? <CircularProgress size={24} /> : null}
                </Button>
              </Box>
            </Grid>
          </>
        ) : (
          <div
            style={{
              backgroundColor: "#fff",
              marginTop: 25,
              padding: 30,
              marginBottom: "7rem",
              width: "100%",
            }}
          >
            {previousFIlledData?.renewaldirectorparticulars && (
              <>
                {/* Company Contact Details */}
                <Grid container>
                  {previousFIlledData?.renewaldirectorparticulars?.map(
                    (e, index) => (
                      <>
                        <Grid xs={12}>
                          <div style={{ margin: "2rem 0", fontSize: "18px" }}>
                            <b>Renewal Directors Particulars</b>
                          </div>
                        </Grid>
                        {e.status === 0 && (
                          <>
                            <Grid
                              xs={5}
                              style={{
                                display: "",
                                flexDirection: "",
                                marginRight: "1rem",
                              }}
                            >
                              <div>
                                <b>Renewal director particulars {index + 1}</b>{" "}
                              </div>
                              <div style={{ display: "" }}>
                                {" "}
                                <div>name</div>
                                <div>
                                  {" "}
                                  <input disabled value={e?.name} />
                                </div>
                              </div>
                              <div style={{ display: "" }}>
                                {" "}
                                <div>date of birth</div>
                                <input disabled value={e?.dob} />
                              </div>
                              <div style={{ display: "" }}>
                                {" "}
                                <div>ocupation</div>
                                <input disabled value={e?.ocupation} />
                              </div>
                              <div style={{ display: "" }}>
                                {" "}
                                <div>address</div>
                                <input disabled value={e?.address} />
                              </div>
                              <div style={{ display: "" }}>
                                {" "}
                                <div>nationality</div>
                                <input disabled value={e?.nationality} />
                              </div>
                            </Grid>
                          </>
                        )}{" "}
                      </>
                    )
                  )}
                </Grid>
              </>
            )}
            <Grid>
              <Button
                variant="contained"
                color="primary"
                // onClick={!formloader ? previewStageData : null}
                style={{ backgroundColor: "#060454", height: "30px" }}
                className={classes.button}
                endIcon={<ArrowForwardIcon style={{ fontSize: "20px" }} />}
                onClick={() => {
                  setformEdit(true);
                  setserverData(false);
                }}
              >
                Refill Form
              </Button>
            </Grid>
          </div>
        )}
      </Grid>

      <AppBar position="fixed" className={classes.appBarButom} color="#060454">
        <Typography
          variant="span"
          color="inherit"
          noWrap
          style={{ textAlign: "center", color: "#fff", alignItems: "center" }}
          justify="center"
        >
          Copyright © 2022 <b>Oil and Gas Free Zones Authority</b>. All Rights Reserved.
        </Typography>
      </AppBar>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    position: "relative",
  },
  checkboxLabel: {
    fontSize: 14,
  },
  errorstyle: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
  appBarButom: {
    top: "auto",
    bottom: 0,
    height: "40px",
    backgroundColor: "#060454",
    paddingTop: "5px",
    paddingButtom: "5px",
    alignItems: "center",
    justifyContent: "center",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "60%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

export default Renewaldirectshareinfo;
