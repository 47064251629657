import logo from './logo.svg';
// import Login from './components/Login';
import Login from './Views/Login/Login';
import Applicationform from './Views/ApplicationForms/Applicationform';
import Dashboard from './Views/Dashboard/Dashboard';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Presentation from './Views/Presentation/Presentation';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
const theme = createTheme();
//const useStyles = makeStyles((theme) => (root : {background: theme.palette.primary.main,}));
function App() {
    return (
      <ThemeProvider theme={theme}>
      <Router>
        <div>
          <main>
            <Route exact path="/" component={Applicationform} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/launch" component={Presentation} />
            <Route path="/dashboard" component={Dashboard} />
          </main>
        </div>
      </Router>
      </ThemeProvider>
    );
}
// renewabizcorpdynamicapprovedvendorval
export default App;
